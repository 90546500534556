import api from "Helpers/Api";
import {
  PropertiesListRequest,
  PropertiesListResponse
} from "Services/Properties";

export default async function propertiesList(params: PropertiesListRequest) {
  const url = "/properties/";
  const query = { params };

  const { data } = await api.get<PropertiesListResponse>(url, query);

  return data;
}
