import React, { useState } from "react";

import Search from "Components/Form/Inputs/Search";
import * as Styled from "./styles";
import { TSection } from "Services/Article/section";
import ArticleSection from "Components/ArticleSection";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

export const sections: TSection[] = [
  { title: "Matérias Recentes", slug: "recentes", perPage: 6 },
  { title: "Rurax na Mídia", category: "RURAX", slug: "rurax", perPage: 3 },
  { title: "Economia", category: "ECONOMIA", slug: "economia", perPage: 6 },
  {
    title: "Mercado",
    category: "MERCADO,COTAÇÕES",
    slug: "mercado",
    perPage: 6
  },
  {
    title: "Agricultura",
    category: "AGRICULTURA",
    slug: "agricultura",
    perPage: 6
  },
  { title: "Pecuária", category: "PECUARIA", slug: "pecuaria", perPage: 6 }
];

const findSectionByCatSlug = (categorySlug: string) => {
  const section = sections.find(section => section.slug === categorySlug);

  return section ? section : sections[0];
};

type TParams = {
  category: string;
};

const Home: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchParam = params.get("s") || "";

  const { category: categorySlug } = useParams<TParams>();
  const [searchParams, setSearchParams] = useState(searchParam);
  const [search, setSearch] = useState(searchParam);

  return (
    <Styled.Container>
      <Helmet>
        <meta name="description" content="Produtos à venda" />
        <link rel="canonical" href="https://rurax.com.br/noticias/" />
      </Helmet>
      <Search
        setValue={setSearchParams}
        defaultValue={searchParams}
        placeholder="O que está buscando?"
        submit={() => setSearch(searchParams)}
        transparent={false}
      />

      {categorySlug ? (
        <ArticleSection
          key={`section-article-category`}
          section={findSectionByCatSlug(categorySlug)}
          search={search}
          perPage={9}
          hidePagination={false}
        />
      ) : search ? (
        <ArticleSection
          key={`section-article-search`}
          section={{ title: "Todas as notícias" }}
          search={search}
          hidePagination={false}
        />
      ) : (
        <>
          <Styled.Info>
            Aqui você encontra as principais notícias e inovações do mercado
            rural para se manter informado do Agronegócio Brasileiro e do Mundo.
            Matérias sobre pecuária, agricultura, mercado e muito mais.
          </Styled.Info>
          {sections.map((section, index) => (
            <>
              <ArticleSection
                key={`section-article-${index}`}
                section={section}
                link={section.slug}
                perPage={section.perPage}
              />
            </>
          ))}
        </>
      )}
    </Styled.Container>
  );
};

export default Home;
