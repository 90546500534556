import { IMenuItemModel } from "../index";
import { FaChartBar } from "react-icons/fa";
import Routes from "Routes";
import React from "react";

const menu: IMenuItemModel = {
  name: "AgroBoletins",
  icon: <FaChartBar />,
  link: Routes.powerbi
};

export default menu;
