import Routes from "Routes";
import { IMenuItemModel } from "../index";
import { BiStore } from "react-icons/bi";
import React from "react";

const menu: IMenuItemModel = {
  name: "Lojas",
  icon: <BiStore />,
  link: Routes.stores
};

export default menu;
