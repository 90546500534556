import styled from "styled-components";
import { FaSpinner } from "react-icons/fa";

export const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  min-height: 100px;
`;

export const Spinner = styled(FaSpinner).attrs(props => ({
  icon: FaSpinner
}))`
  font-size: 36px;
  color: ${({ theme }) => theme.colors.green.normal};
  animation: icon-spin 2s infinite linear;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
