import CounterBtn from "Components/Buttons/Counter";
import React from "react";
import * as Styled from "./styles";
import { theme } from "theme/theme";
import { FaMinus, FaPlus } from "react-icons/fa";

interface Props {
  setCartCount(value: number): void;
  count: number;
  isAvailable?: boolean;
}

const gray = theme.colors.gray.normal;

//TODO:  Refactor this component to not use the style prop
const CounterBtns: React.FC<Props> = ({
  setCartCount,
  count,
  isAvailable = true
}) => {
  return (
    <Styled.Counter>
      <CounterBtn
        icon={<FaMinus />}
        action={() => isAvailable && count > 1 && setCartCount(count - 1)}
        // @ts-ignore
        style={{
          borderRadius: "60px 0px 0px 60px",
          backgroundColor: isAvailable ? "" : gray,
          cursor: isAvailable ? "pointer" : "auto"
        }}
      />
      <Styled.Display isAvailable={isAvailable}>{count}</Styled.Display>
      <CounterBtn
        icon={<FaPlus />}
        action={() => isAvailable && setCartCount(count + 1)}
        // @ts-ignore
        style={{
          borderRadius: "00px 60px 60px 0px",
          backgroundColor: isAvailable ? "" : gray,
          cursor: isAvailable ? "pointer" : "auto"
        }}
      />
    </Styled.Counter>
  );
};

export default CounterBtns;
