import React, { useEffect, useState } from "react";
import * as Styled from "./styles";
import DatePicker from "react-date-picker";
import { INITIAL_CHART_DATE } from "Helpers/consts";

export interface IRangeDate {
  startDate: Date;
  endDate: Date;
}

interface Props {
  title?: string;
  name: string;
  initialStartDate: Date;
  endDate: Date;
  changeStartDate(e: Date): void;
  changeEndDate(e: Date): void;
  maxDate?: Date;
  minDate?: Date;
  labelStartDate?: string;
  labelEndDate?: string;
}

const RangeDateField: React.FC<Props> = ({
  title = "",
  name,
  initialStartDate,
  endDate,
  changeStartDate,
  changeEndDate,
  maxDate = new Date(),
  minDate = new Date(INITIAL_CHART_DATE),
  labelStartDate = "De",
  labelEndDate = "Até"
}) => {
  const [startDate, setStartDate] = useState<Date>();

  useEffect(() => {
    // if initialDate is less than minDate, put minDate on state
    if (new Date(initialStartDate) < new Date(minDate)) {
      setStartDate(minDate);
      changeStartDate(minDate);
    } else {
      setStartDate(new Date(initialStartDate));
    }
  }, [initialStartDate]);

  return (
    <Styled.Field>
      {title && <Styled.Title>{title}</Styled.Title>}
      <Styled.Labels>
        <Styled.Label>
          <Styled.DateTile>{labelStartDate}</Styled.DateTile>
          <DatePicker
            onChange={changeStartDate}
            value={startDate}
            name="start_date"
            maxDate={new Date(maxDate)}
            minDate={new Date(minDate)}
            format="dd/MM/yyyy"
          />
        </Styled.Label>
        <Styled.Label>
          <Styled.DateTile>{labelEndDate}</Styled.DateTile>
          <DatePicker
            onChange={changeEndDate}
            value={endDate}
            name="end_date"
            maxDate={new Date(maxDate)}
            minDate={new Date(minDate)}
            format="dd/MM/yyyy"
          />
        </Styled.Label>
      </Styled.Labels>
    </Styled.Field>
  );
};
export default RangeDateField;
