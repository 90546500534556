import api from "Helpers/Api";
import { IArticle } from "Services/Article/article";

export default async function articleUpdate(
  articleId: string,
  params: IArticle
) {
  const { data } = await api.put(`/articles/update/${articleId}`, params);

  return data;
}
