import { useQuery } from "@tanstack/react-query";
import fairFetch from "Api/fairs/fetch";

export default function useFair(fairId: string) {
  const query = () => fairFetch(fairId);

  return useQuery({
    queryKey: ["fairsList", fairId],
    queryFn: query,
    keepPreviousData: true
  });
}
