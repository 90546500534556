import styled from "styled-components";

interface IListItem {
  size: number;
  inline: boolean;
  hover: boolean;
  isOne: boolean;
}

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;
  margin: 20px 0 0 0;

  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;

export const TotalResults = styled.span`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.gray.dark};
  white-space: nowrap;
  margin-left: auto;
  margin-top: 20px;
  @media ${({ theme }) => theme.devices.tablet} {
    margin-top: 20px;
    margin-left: 0;
    text-align: right;
  }
`;

export const SelectFiltersContainer = styled.div`
  padding: 0;
`;

export const Filters = styled.div`
  background-color: ${({ theme }) => theme.colors.white.normal};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  min-width: 25%;
  width: 25%;
  margin-top: 20px;
  margin-bottom: auto;

  @media ${({ theme }) => theme.devices.laptop} {
    margin-right: 20px;
  }
  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
    margin-top: 40px;
  }

  -webkit-box-shadow: -6px 12px 39px -23px rgba(0, 0, 0, 1);
  -moz-box-shadow: -6px 12px 39px -23px rgba(0, 0, 0, 1);
  box-shadow: -6px 12px 39px -23px rgba(0, 0, 0, 1);
`;

export const Filter = styled.div`
  margin-bottom: 20px;
`;
export const ListContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

interface IList {
  inline: boolean;
  size?: number;
}

export const List = styled.div<IList>`
  margin-top: 20px;

  @supports (display: grid) {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(
      auto-fit,
      minmax(${({ size = 300 }) => size}px, 1fr)
    );

    @media (max-width: 630px) {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }

  @supports not (display: grid) {
    display: flex;
    justify-content: space-between;
    flex-wrap: ${({ inline }) => (inline ? "nowrap" : "wrap")};
  }

  overflow-x: ${({ inline }) => (inline ? "scroll" : "inherit")};
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    margin-left: 0;
  }
  @media ${({ theme }) => theme.devices.laptop} {
    overflow: visible;
  }
`;

export const NoResults = styled.span`
  margin-top: 20px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.gray.dark};
  letter-spacing: 1px;
  flex-grow: 1;
`;

export const EmptyItem = styled.div<IListItem>`
  width: calc(${({ size }) => size}% - 20px);
  margin-bottom: 20px;
  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
    ${({ inline }) => inline && "margin-right: 10px;"}
  }
`;

export const BtnContainer = styled.div`
  margin: 0 10px 10px 10px;
  display: flex;
  flex-direction: column;
`;
