import { call, put, takeLatest } from "redux-saga/effects";

import types from "./types";
import Session from "Services/Session";

export interface IAuthAction {
  type: string;
  payload: any;
}

function* login(action: IAuthAction) {
  try {
    const login = yield call(
      Session.login,
      action.payload.email,
      action.payload.password
    );
    yield put({ type: types.AUTHENTICATE, payload: login });
  } catch (error) {
    yield put({ type: types.AUTHENTICATE_ERROR, payload: error });
  }
}

function* tokenLogin(action: IAuthAction) {
  try {
    const login = yield call(Session.tokenLogin, action.payload.refreshToken);
    yield put({ type: types.AUTHENTICATE, payload: login });
  } catch (error) {
    yield put({ type: types.AUTHENTICATE_ERROR, payload: error });
  }
}

function* mailConfirm(action: IAuthAction) {
  try {
    const login = yield call(Session.mailConfirm, action.payload.mailToken);
    yield put({ type: types.AUTHENTICATE, payload: login });
  } catch (error) {
    yield put({ type: types.AUTHENTICATE_ERROR, payload: error });
  }
}

function* unauthenticate(action: IAuthAction) {
  try {
    yield call(Session.unauthenticate);
    yield put({ type: types.UNAUTHENTICATED });
  } catch (error) {
    yield put({ type: types.AUTHENTICATE_ERROR, payload: error });
  }
}

function* socialLogin(action: IAuthAction) {
  try {
    const login = yield call(
      Session.socialLogin,
      action.payload.email,
      action.payload.googleId && action.payload.googleId,
      action.payload.facebookId && action.payload.facebookId
    );
    yield put({ type: types.AUTHENTICATE, payload: login });
  } catch (error) {
    yield put({ type: types.AUTHENTICATE_ERROR, payload: error });
  }
}

function* enableToken(action: IAuthAction) {
  try {
    yield call(Session.enableToken, action.payload.enableToken);
  } catch (error) {
    yield put({ type: types.AUTHENTICATE_ERROR, payload: error });
  }
}

function* sagas() {
  yield takeLatest(types.AUTHENTICATING, login);
  yield takeLatest(types.AUTH_TOKEN, tokenLogin);
  yield takeLatest(types.MAIL_CONFIRM, mailConfirm);
  yield takeLatest(types.ENABLE_TOKEN, enableToken);
  yield takeLatest(types.UNAUTHENTICATE, unauthenticate);
  yield takeLatest(types.AUTHENTICATE_SOCIAL, socialLogin);
}

export default sagas;
