import styled from "styled-components";
interface IFilter {
  show?: boolean;
}

export const Filter = styled.div<IFilter>`
  padding: 12px 0;
  transition: 0.8s;
  border-bottom: 1px solid #ebebeb;

  &:last-child {
    border: none;
  }
`;

export const Content = styled.div<IFilter>`
  padding: 0.2rem 1rem;
  background: ${({ theme }) => theme.colors.white.normal};
  transition: 0.8s;
  max-height: ${({ show }) => (show ? "0" : "5000px")};
  opacity: ${({ show }) => (show ? "0" : "1")};
  visibility: ${({ show }) => (show ? "hidden" : "visible")};
`;

export const FilterTitle = styled.div<IFilter>` 
    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;
    width: 100%

    padding: 8px;

    background: ${({ theme }) => theme.colors.white.normal};

    svg {
        transition: transform 0.5s;
        margin-right: 1rem;
        transform: rotate(${({ show }) => (show ? "0deg" : "90deg")})
    }

    span {
        margin-left: 0.5rem;
        font-weight: 600;
        font-size: 1rem;

        color: ${({ theme }) => theme.colors.gray.dark};
        letter-spacing: 1px;
    }
`;
