import Routes from "Routes";
import { IMenuItemModel } from "../index";
import { BsCalendarDate } from "react-icons/bs";
import React from "react";

const menu: IMenuItemModel = {
  name: "Feiras",
  icon: <BsCalendarDate />,
  link: Routes.fairs
};

export default menu;
