import React from "react";
import * as Styled from "./styles";
import handleViewport from "react-in-viewport";

interface Props {
  children?: any;
  size: number;
  inline: boolean;
  hover: boolean;
  isOne: boolean;
  inViewport?: boolean;
  forwardedRef?: React.MutableRefObject<HTMLDivElement>;
  click(): void;
}

const ListItem: React.FC<Props> = ({
  children,
  isOne,
  hover,
  size,
  inline,
  forwardedRef,
  click
}) => {
  return (
    <Styled.ListItem
      isOne={isOne}
      hover={hover}
      size={size}
      inline={inline}
      ref={forwardedRef}
      onClick={click}
      className="product-item"
    >
      {children}
    </Styled.ListItem>
  );
};

export default handleViewport<Props>(ListItem, {}, { disconnectOnLeave: true });
