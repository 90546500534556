import api from "Helpers/Api";
import {
  BoletinsListRequest,
  BoletinsListResponse
} from "Services/Boletins/boletins";

export default async function getBoletins({
  page,
  categories,
  types,
  search
}: BoletinsListRequest) {
  const { data } = await api.get<BoletinsListResponse>("/boletins/list", {
    params: {
      page,
      perPage: 10,
      categories,
      types,
      search
    }
  });

  return data;
}
