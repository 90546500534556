import React from "react";
import moment from "moment";
import "moment/locale/pt-br";
import * as Styled from "./styles";
import { TArticle } from "Services/Article/article";

interface Props {
  data: TArticle;
  link: string;
}

const ArticleItem: React.FC<Props> = ({ data, link }) => {
  return (
    <Styled.Container>
      <Styled.ArticleLink to={link || ""}>
        <Styled.Content>
          {data.publication_date && (
            <Styled.Date>
              {moment(data.publication_date)
                .add(1, "days")
                .format("DD/MMM/YYYY")
                .toUpperCase()}
            </Styled.Date>
          )}
          <Styled.Picture>
            {data.image_url ? (
              <Styled.Image src={data.image_url} alt="Article Image" />
            ) : (
              <Styled.Placeholder />
            )}
          </Styled.Picture>
          <Styled.InfoContainer>
            <Styled.Info>
              <Styled.Title>{data.title}</Styled.Title>
              <Styled.Description>{data.sub_title}</Styled.Description>
            </Styled.Info>
          </Styled.InfoContainer>
        </Styled.Content>
      </Styled.ArticleLink>
    </Styled.Container>
  );
};

export default ArticleItem;
