import api from "Helpers/Api";
import { TCampaign } from "Services/Campaigns/campaigns";

export default async function campaignFetch(campaignId: string) {
  try {
    const response = await api.get<TCampaign>(`/campaign/fetch/${campaignId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch campaign data:", error);
    return null;
  }
}
