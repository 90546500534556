import api from "Helpers/Api";
import {
  CampaignsListResponse,
  ICampaignUpdate
} from "Services/Campaigns/campaigns";

export default async function campaignCreate(params: ICampaignUpdate) {
  const { data } = await api.post<CampaignsListResponse>(
    `/campaign/create`,
    params
  );

  return data;
}
