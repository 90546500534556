import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { ReducerState } from "Redux/store";

import * as Styled from "./styles";
import { IPage } from "Services/pagination";

interface Props {
  setPage(pagination: number): void;
  pagination: IPage | null;
  siblingCount?: number;
  scrollToTop?: boolean;
}

const Pagination: React.FC<Props> = ({
  pagination = null,
  setPage,
  siblingCount = 1,
  scrollToTop = true
}) => {
  const [pageLinks, setPageLinks] = useState<(string | number)[] | undefined>(
    []
  );
  const mobile = useSelector((state: ReducerState) => state.locale.mobile);

  const range = (firstPage: number, lastPage: number) => {
    const length = lastPage - firstPage + 1;
    return Array.from({ length }, (_, idx) => idx + firstPage);
  };

  function paginationRange(pagination: IPage | null) {
    if (!pagination) return [];

    const totalPageNumbers = siblingCount + 5;

    // Case 1: When the num of pages < the page numbers we want to show
    if (totalPageNumbers >= pagination.lastPage)
      return range(1, pagination.lastPage);

    const leftSiblingIndex = Math.max(pagination.page - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      pagination.page + siblingCount,
      pagination.lastPage
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < pagination.lastPage - 2;

    const firstPageIndex = 1;
    const lastPageIndex = pagination.lastPage;

    // Case 2: No left dots to show, but rights dots to be shown
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, "|", pagination.lastPage];
    }

    // Case 3: No right dots to show, but left dots to be shown
    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(
        pagination.lastPage - rightItemCount + 1,
        pagination.lastPage
      );
      return [firstPageIndex, "|", ...rightRange];
    }

    // Case 4: Both left and right dots to be shown
    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, "|", ...middleRange, "|", lastPageIndex];
    }
  }

  useEffect(() => {
    setPageLinks(paginationRange(pagination));
  }, [pagination, siblingCount]);

  const changePage = (value: number) => {
    if (pagination) {
      setPage(value);
      scrollToTop && window.scrollTo(0, 0);
    }
  };

  return (
    <Styled.Container>
      {pagination && pagination.page > 1 && (
        <Styled.PageLink onClick={() => changePage(pagination.page - 1)}>
          Anterior
        </Styled.PageLink>
      )}
      {!mobile && pagination && pageLinks && (
        <Styled.PageNumberContainer>
          {pageLinks.map((link, index) => {
            return typeof link === "number" ? (
              <Styled.PageNumber
                active={link === pagination.page}
                key={`pagination-number-${index}`}
                onClick={() => changePage(link)}
              >
                {link}
              </Styled.PageNumber>
            ) : (
              <>...</>
            );
          })}
        </Styled.PageNumberContainer>
      )}
      {pagination &&
        pagination.page !== pagination.lastPage &&
        pagination.lastPage !== 0 && (
          <Styled.PageLink onClick={() => changePage(pagination.page + 1)}>
            Próxima
          </Styled.PageLink>
        )}
    </Styled.Container>
  );
};

export default Pagination;
