import React from "react";
import * as Styled from "./styles";
import FormStores from "./FormStores";

export const OficialStores: React.FC = () => {
  return (
    <Styled.Container>
      <Styled.TextContent>
        <Styled.Title>
          Faça parte do marketplace que mais cresce no mercado
        </Styled.Title>
        <Styled.Text>
          Divulgue sua loja no nosso site e aumente suas vendas.
        </Styled.Text>
      </Styled.TextContent>
      <Styled.FormContainer>
        <FormStores />
      </Styled.FormContainer>
    </Styled.Container>
  );
};
