import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  createRef,
  useCallback
} from "react";
import FormContext from "Components/Form/context";
import * as Styled from "./styles";
import { TOption } from "Services/Input/options";
import { gray } from "./themes";
import { ISelectTheme } from "./theme";
import { TError } from "Services/Error/error";
interface Props {
  name: string;
  options: TOption[];
  placeholder?: string;
  change?(i: any): void;
  width?: number;
  required?: boolean;
  status?(i: boolean): void;
  defaultValue: any;
  theme?: ISelectTheme;
  label?: string;
  disabled?: boolean;
}
const Select: React.FC<Props> = ({
  name,
  placeholder = "",
  options,
  change,
  defaultValue,
  status = () => {},
  width = 120,
  required = false,
  theme = gray,
  disabled = false,
  label
}) => {
  const [expand, setExpand] = useState(false);
  const [value, setValue] = useState(defaultValue || "");
  const [error, setError] = useState<TError>(
    required
      ? {
          message: "Campo Obrigatório",
          status: false
        }
      : {
          message: "",
          status: false
        }
  );
  const valueName = options.find(option => option.value === value);
  const { registerField, setFieldError } = useContext(FormContext);
  const inputRef = useMemo(() => createRef<HTMLInputElement>(), []);
  const wrapperRef = useMemo(() => createRef<HTMLDivElement>(), []);
  useEffect(() => {
    if (inputRef.current && defaultValue) {
      inputRef.current.value = defaultValue;
      setError({ message: "", status: false });
    }
  }, [defaultValue, inputRef]);
  useEffect(() => {
    required && setFieldError && setFieldError(name, error);
  }, [defaultValue, error, name, required, setFieldError]);
  useEffect(() => {
    registerField &&
      inputRef.current &&
      registerField(name, inputRef.current, required, false);
  }, [inputRef, name, registerField, required]);
  const expandClick = (value: any) => {
    required && status && status(true);
    setExpand(!expand);
    setValue(value);
    change && change(value);
  };
  useEffect(() => {
    value === null && status(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const outsideClick = useCallback(
    (e: MouseEvent) => {
      const el = e.target;
      if (
        el instanceof Node &&
        wrapperRef.current &&
        !wrapperRef.current.contains(el)
      ) {
        setExpand(false);
      }
    },
    [wrapperRef]
  );
  useEffect(() => {
    if (expand) document.addEventListener("mousedown", outsideClick);
    else document.removeEventListener("mousedown", outsideClick);
    return () => {
      document.removeEventListener("mousedown", outsideClick);
    };
  }, [expand, outsideClick]);
  return (
    <Styled.Container
      textAlign={theme.textAlign}
      width={width}
      marginBottom={theme.marginBottom}
      ref={wrapperRef}
    >
      {label ? <Styled.SchoolingLabel>{label}</Styled.SchoolingLabel> : <></>}
      <input type="hidden" name={name} value={value} ref={inputRef} />
      <Styled.Selected
        border={theme.border}
        background={theme.background}
        brightness={theme.brightness}
        onClick={() => !disabled && setExpand(!expand)}
        disabled={disabled}
      >
        <Styled.Label colorLabel={theme.colorLabel} justify={theme.justify}>
          {valueName ? valueName.name : placeholder}
        </Styled.Label>
        <Styled.Expand expandColor={theme.expandColor}>
          {expand ? <Styled.ExpandLess /> : <Styled.ExpandMore />}
        </Styled.Expand>
      </Styled.Selected>
      <Styled.Options
        textColor={theme.textColor}
        scroll={theme.scroll}
        expand={expand}
      >
        {options.map((option, key) => (
          <Styled.Option
            background={theme.backgroundOption}
            brightness={theme.brightnessOption}
            hover={theme.hover}
            border={theme.borderOption}
            key={`option-${key}`}
            onClick={() => expandClick(option.value)}
          >
            {option.name}
          </Styled.Option>
        ))}
      </Styled.Options>
    </Styled.Container>
  );
};
export default Select;
