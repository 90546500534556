import styled from "styled-components";
import TerrainIcon from "@material-ui/icons/TerrainTwoTone";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.white.normal};
  margin-bottom: 10px;
  padding: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ProductImageContainer = styled.div`
  display: flex;
  width: 120px;
  height: 120px;
  background-color: ${({ theme }) => theme.colors.gray.light};
  @media ${({ theme }) => theme.devices.tablet} {
    display: none;
  }
`;

export const ProductImage = styled.img`
  object-fit: cover;
  width: 100%;
`;

export const Placeholder = styled(TerrainIcon)`
  width: 30px;
  margin: auto;
  color: ${({ theme }) => theme.colors.gray.dark};
`;

export const CartInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding-left: 20px;
  padding-right: 20px;
  @media ${({ theme }) => theme.devices.tablet} {
    padding-left: 0;
  }
`;

export const ProductTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.green.light};
  @media ${({ theme }) => theme.devices.tablet} {
    margin-bottom: 10px;
  }
`;

export const RemoveFromCart = styled.span`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.gray.normal};
  cursor: pointer;
  margin-top: 4px;

  @media ${({ theme }) => theme.devices.laptop} {
    &:hover {
      color: ${({ theme }) => theme.colors.gray.dark};
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    margin-top: 20px;
  }
`;

export const ProductQty = styled.span`
  color: ${({ theme }) => theme.colors.gray.dark};
`;

export const ProductInfo = styled.div`
  color: ${({ theme }) => theme.colors.gray.normal};

  label {
    font-size: ${({ theme }) => theme.fontSize.mm};
    font-weight: bold;
  }

  span {
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`;

export const Price = styled.h2`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.green.light};
`;

export const VariantItem = styled.p`
  color: ${({ theme }) => theme.colors.gray.normal};
  font-size: ${({ theme }) => theme.fontSize.mm};

  margin: 0;
  margin-bottom: 16px;
`;
