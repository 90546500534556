import api from "Helpers/Api";
import {
  listCategoriesRequest,
  listCategoriesResponse
} from "Services/Category";

export default async function listCategories(params: listCategoriesRequest) {
  const { segment } = params;
  const url = `/categories/${segment}`;

  const { data } = await api.get<listCategoriesResponse>(url, { params });

  return data;
}
