import React, {
  useState,
  useContext,
  useEffect,
  createRef,
  useMemo,
  useCallback,
  useRef
} from "react";
import api from "Helpers/Api";

import FormContext from "Components/Form/context";
import * as Styled from "./styles";
import validators from "./Validations";
import { TError } from "Services/Error/error";
import HoverInfoIcon from "Components/Info";

type TChangeEvent =
  | React.ChangeEvent<HTMLInputElement>
  | React.ChangeEvent<HTMLTextAreaElement>;
interface Props {
  label?: string;
  placeholder?: string;
  validation?:
    | "email"
    | "cpfcnpj"
    | "password"
    | "cep"
    | "creditCard"
    | "CreditCardDate"
    | "phone"
    | "url"
    | "date";
  required?: boolean;
  confirm?: string;
  name?: string;
  defaultValue?: string | number | null;
  mask?: string; // TODO: accept over one mask or change so that mask doesnt validate
  textarea?: boolean;
  defaultError?: string;
  change?(value: any): void;
  blur?(input: string): void;
  duplicated?: string;
  outError?: string;
  type?: "text" | "number" | "password";
  detailsLabel?: string;
  min_height?: string;
  maxLength?: number;
  disabled?: boolean;
}

const TextInput: React.FC<Props> = ({
  label,
  name,
  validation,
  required = false,
  defaultValue = "",
  confirm,
  placeholder = "",
  textarea = false,
  mask = "",
  defaultError,
  outError = "",
  change,
  blur,
  duplicated,
  type = "text",
  detailsLabel,
  min_height = "0px",
  maxLength,
  disabled = false
}) => {
  const {
    registerField,
    setFieldError,
    getField,
    deleteField,
    submitted
  } = useContext(FormContext);

  const [error, setError] = useState<TError>(
    required && defaultValue === ""
      ? {
          message: "",
          status: true
        }
      : {
          message: "",
          status: false
        }
  );

  useEffect(() => {
    if (submitted === true) {
      const field = name && getField(name);
      const ref = field && field.ref;
      const value = ref && ref.value;

      setError(
        required && defaultValue === "" && (value === "" || !value)
          ? {
              message: "Campo Obrigatório",
              status: true
            }
          : {
              message: "",
              status: false
            }
      );
    }
  }, [defaultValue, required, submitted]);

  const inputRef = useMemo(() => createRef<HTMLInputElement>(), []);
  const inputRefArea = useMemo(() => createRef<HTMLTextAreaElement>(), []);

  useEffect(() => {
    outError !== "" &&
      setError({
        status: true,
        message: outError
      });
  }, [outError]);

  const confirmField = (v: string) => {
    if (confirm) {
      const field = getField(confirm);
      if (field) {
        const { value } = field.ref;
        if (v !== value) {
          setError({
            message: defaultError || "Campos não conferem",
            status: true
          });
        } else {
          setError({
            message: "",
            status: false
          });
        }
      }
    }
  };

  useEffect(() => {
    if (inputRef.current && defaultValue) {
      inputRef.current.value = defaultValue.toString();
      //setError({ message: "", status: false });
    }
    if (inputRefArea.current && defaultValue) {
      inputRefArea.current.value = defaultValue.toString();
      //setError({ message: "", status: false });
    }
  }, [defaultValue, inputRef, inputRefArea]);

  const time = useRef(0);

  const throttle = useCallback(
    (func: any) => {
      clearTimeout(time.current);
      time.current = setTimeout(func, time.current === 0 ? 0 : 200);
    },
    [time]
  );

  const checkDuplicated = async (input: string) => {
    if (duplicated) {
      try {
        const { data } = await api.get(`/users/verify/${duplicated}`, {
          params: { check: input }
        });
        if (!data.status) {
          setError({
            message: data.data || "Erro ao inserir email",
            status: true
          });
        }
      } catch (error) {
        setError({
          message: "Erro no servidor",
          status: true
        });
      }
    }
  };

  useEffect(() => {
    registerField &&
      name &&
      inputRef.current &&
      registerField(name, inputRef.current, required, false);
    registerField &&
      name &&
      inputRefArea.current &&
      registerField(name, inputRefArea.current, required, false);
    return () => {
      deleteField && name && deleteField(name);
    };
  }, [inputRef, inputRefArea, name, registerField, required, deleteField]);

  useEffect(() => {
    required && name && setFieldError && setFieldError(name, error);
  }, [defaultValue, error, name, required, setFieldError]);

  const changeHandler = (e: TChangeEvent) => {
    const { value } = e.target;
    change && change(value);

    if (required && value === "") {
      setError({ message: "Campo Obrigatório", status: true });
    } else {
      setError({ message: "", status: false });
    }

    if (validation && value !== "") {
      if (!validators[validation].validate(value)) {
        setError({ message: validators[validation].error, status: true });
      } else {
        setError({ message: "", status: false });
      }
    }

    if (duplicated && value !== "") {
      throttle(() => checkDuplicated(value));
    }
    if (confirm && value !== "") {
      confirmField(value);
    }
  };

  return (
    <Styled.Fieldset
      valid={!error.status}
      className={error.status ? "error" : ""}
    >
      <Styled.RowDiv>
        <Styled.Label min_height={min_height}>{label}</Styled.Label>
        {detailsLabel && <HoverInfoIcon message={detailsLabel}></HoverInfoIcon>}
      </Styled.RowDiv>
      {!textarea ? (
        <Styled.InputMaskHtml
          mask={mask}
          onChange={changeHandler}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            blur && blur(e.target.value);
            changeHandler(e);
          }}
          disabled={disabled}
        >
          <Styled.Input
            type={type}
            name={name}
            ref={inputRef}
            defaultValue={defaultValue}
            placeholder={placeholder}
            maxLength={maxLength}
          />
        </Styled.InputMaskHtml>
      ) : (
        <Styled.Textarea
          ref={inputRefArea}
          name={name}
          onChange={changeHandler}
          onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) => {
            blur && blur(e.target.value);
            changeHandler(e);
          }}
          defaultValue={defaultValue}
          placeholder={placeholder}
          maxLength={maxLength}
        />
      )}
      {error.message && (
        <Styled.Error valid={!error.status}>{error.message}</Styled.Error>
      )}
    </Styled.Fieldset>
  );
};

export default TextInput;
