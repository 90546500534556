import React, {
  ChangeEvent,
  useState,
  useEffect,
  useCallback,
  useContext,
  createRef,
  useMemo
} from "react";

import FormContext from "Components/Form/context";
import * as Styled from "./styles";

type SelectItem = {
  name: string;
  value: any;
};

interface Props {
  value: string;
  name: string;
  select(s: string): Promise<SelectItem[]>;
  update(v: any): void;
  getId?: boolean;
  placeholder?: string;
}

const AutoComplete: React.FC<Props> = ({
  value,
  select,
  update,
  name,
  getId = false,
  placeholder = ""
}) => {
  const [options, setOptions] = useState<SelectItem[]>([]);
  const [validNames, setValidNames] = useState<Array<string>>([]);
  const [input, setInput] = useState<string>(value);
  const [id, setId] = useState();
  const { registerField } = useContext(FormContext);
  const inputRef = useMemo(() => createRef<HTMLInputElement>(), []);
  const wrapperRef = useMemo(() => createRef<HTMLDivElement>(), []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    getOptions(value);
    setInput(value);
  };

  const getOptions = async (search: string) => {
    const data = await select(search);
    setOptions(data);
    setValidNames(data.map(item => item.name));
  };

  const updateValue = useCallback(
    (name: string, value: any) => {
      setInput(name);
      getId && setId(value);
      update({ value, professionValue: name });
      setOptions([]);
    },
    [getId, update]
  );

  useEffect(() => {
    options.forEach(option => {
      if (option.name.toLowerCase() === input.toLowerCase()) {
        updateValue(option.name, option.value);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  useEffect(() => {
    registerField &&
      inputRef.current &&
      registerField(name, inputRef.current, false, false);
  }, [inputRef, name, registerField]);

  const outsideClick = useCallback(
    (e: MouseEvent) => {
      const el = e.target;
      if (
        el instanceof Node &&
        wrapperRef.current &&
        !wrapperRef.current.contains(el)
      ) {
        setOptions([]);
      }
    },
    [wrapperRef]
  );

  useEffect(() => {
    if (options.length > 0)
      document.addEventListener("mousedown", outsideClick);
    else document.removeEventListener("mousedown", outsideClick);

    return () => {
      document.removeEventListener("mousedown", outsideClick);
    };
  }, [options, outsideClick]);

  const checkIfValid = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const index = validNames.indexOf(value);
    if (index < 0) {
      setInput("");
    }
  };

  return (
    <Styled.Container ref={wrapperRef}>
      {getId ? (
        <>
          <Styled.Input
            type="text"
            value={input}
            onChange={handleChange}
            placeholder={placeholder}
            autoComplete="disable"
            onBlur={checkIfValid}
          />
          <input type="hidden" value={id} ref={inputRef} />
        </>
      ) : (
        <Styled.Input
          type="text"
          value={input}
          onChange={handleChange}
          ref={inputRef}
          placeholder={placeholder}
          autoComplete="disable"
          onBlur={checkIfValid}
        />
      )}
      <Styled.Select>
        {options.map(
          (option, key) =>
            option.name !== input && (
              <Styled.Option
                key={`option-${key}`}
                onClick={() => updateValue(option.name, option.value)}
              >
                {option.name}
              </Styled.Option>
            )
        )}
      </Styled.Select>
    </Styled.Container>
  );
};

export default AutoComplete;
