import styled from "styled-components";
import InfoIcon from "@material-ui/icons/InfoRounded";

export const Info = styled(InfoIcon).attrs(props => ({
  fontSize: "medium"
}))`
  color: ${({ theme }) => theme.colors.green.normal};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.green.dark};
  }
`;
