import Hover from "Components/Hover";
import * as Styled from "./styles";
import React from "react";

interface Props {
  message: string;
}

const HoverInfoIcon: React.FC<Props> = ({ message }) => {
  return (
    <Hover message={message}>
      <Styled.Info />
    </Hover>
  );
};

export default HoverInfoIcon;
