export default function salaryString(salary: number) {
  const formater = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  });
  return formater.format(salary);
}

export const formatNumber = (n: number) => {
  const formater = new Intl.NumberFormat("pt-BR");
  return formater.format(n);
};

export const convertPrice = (price: string) => {
  let parsed = price.replace(/\D+/g, "").split("");
  if (parsed.length < 2) parsed = ["0", "0", ...parsed];
  parsed[parsed.length - 2] = "." + parsed[parsed.length - 2];
  return parseFloat(parsed.join("")) === 0 ? null : parseFloat(parsed.join(""));
};
