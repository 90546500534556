enum BACKEND_ENDPOINTS {
  dev = "http://34.107.233.243/api",
  master = "https://rurax.com.br/api",
  local = "http://localhost:3333/api"
}
let backend_url = "https://rurax.com.br/api";

export const MARKETPLACE = process.env.REACT_APP_BACKEND !== "master";

switch (process.env.REACT_APP_BACKEND) {
  case "dev":
    backend_url = BACKEND_ENDPOINTS.dev;
    break;
  case "master":
    backend_url = BACKEND_ENDPOINTS.master;
    break;
  case "local":
    backend_url = BACKEND_ENDPOINTS.local;
    break;
  default:
    backend_url = BACKEND_ENDPOINTS.dev;
    break;
}

const config = {
  backend: backend_url,
  socket:
    process.env.NODE_ENV === "development"
      ? "ws://localhost:3333/api"
      : "wss://rurax.com.br/api"
};

export default config;
