import { BsFillPeopleFill } from "react-icons/bs";
import { IMenuItemModel } from "../index";
import Routes from "Routes";
import React from "react";

const menu: IMenuItemModel = {
  name: "Vagas",
  icon: <BsFillPeopleFill />,
  link: Routes.jobs
};

export default menu;
