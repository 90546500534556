import React from "react";
import * as Styled from "./styles";
import handleViewport from "react-in-viewport";

export interface ListItemProps {
  children?: React.FC;
  size: number;
  inline: boolean;
  hover: boolean;
  isOne: boolean;
  inViewport?: boolean;
  forwardedRef?: React.MutableRefObject<HTMLDivElement>;
  click?(): void;
}

function ListItem({
  children,
  isOne,
  hover,
  size,
  inline,
  forwardedRef,
  click
}: ListItemProps) {
  return (
    <Styled.ListItem
      isOne={isOne}
      hover={hover}
      size={size}
      inline={inline}
      ref={forwardedRef}
      onClick={click}
      className="product-item"
    >
      {children}
    </Styled.ListItem>
  );
}

export default handleViewport<ListItemProps>(
  ListItem,
  {},
  { disconnectOnLeave: true }
);
