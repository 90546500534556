import { AudioPlayerContext } from "Contexts/AudioPlayerContext";
import { calculaTime } from "Helpers/calculateTime";
import React, { useContext, useEffect, useRef, useState } from "react";
import PlayPauseButton from "./PlayerPauseButton";

import * as Styled from "./styles";

const AudioPlayer: React.FC = () => {
  const { isPlaying, setIsPlaying, currentBoletim } = useContext(
    AudioPlayerContext
  );

  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const audioPlayer = useRef<HTMLMediaElement | null>(null); // audio component
  const progressBar = useRef<HTMLInputElement | null>(null); // progress bar
  const animationRef = useRef<number | null>(null); // reference the animations

  useEffect(() => {
    if (audioPlayer.current && progressBar.current) {
      const seconds = Math.floor(audioPlayer.current.duration);
      seconds && setDuration(seconds);
      progressBar.current.max = seconds.toString();
    }
  }, [
    audioPlayer.current && audioPlayer.current.onloadedmetadata,
    audioPlayer.current && audioPlayer.current.readyState
  ]);

  useEffect(() => {
    if (isPlaying && currentBoletim && currentBoletim.id) {
      audioPlayer.current && audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current && audioPlayer.current.pause();
      animationRef.current && cancelAnimationFrame(animationRef.current);
    }

    /* if (currentBoletim && isPlaying) {
      const interval = setTimeout(() => {
        // plays 10 seconds
      }, 10000);

      return () => clearTimeout(interval);
    } */
  }, [isPlaying, currentBoletim && currentBoletim.id]);

  useEffect(() => {
    if (audioPlayer.current) {
      audioPlayer.current.currentTime = 0;
    }
  }, [currentBoletim && currentBoletim.id]);

  const changeRange = () => {
    if (audioPlayer.current && progressBar.current) {
      audioPlayer.current.currentTime = Number(progressBar.current.value);
      changePlayerCurrentTime();
    }
  };

  const whilePlaying = () => {
    animationRef.current = requestAnimationFrame(whilePlaying);
    if (progressBar.current && audioPlayer.current) {
      progressBar.current.value = audioPlayer.current.currentTime.toString();
      changePlayerCurrentTime();
    }
  };

  const changePlayerCurrentTime = () => {
    if (progressBar.current && audioPlayer.current) {
      const seconds = Math.floor(audioPlayer.current.duration);
      progressBar.current.style.setProperty(
        "--seek-before-width",
        `${(Number(progressBar.current.value) / (duration || seconds)) * 100}%`
      );
      setCurrentTime(Number(progressBar.current.value));
    }
  };

  return (
    <Styled.AudioPlayer>
      <audio
        ref={audioPlayer}
        src={(currentBoletim && currentBoletim.url) || undefined}
        preload="metadata"
      ></audio>
      <Styled.ToggleButtons>
        <PlayPauseButton />
      </Styled.ToggleButtons>
      <Styled.PlayerContainer>
        <Styled.Timers>{calculaTime(currentTime)}</Styled.Timers>
        <Styled.ProgressBar
          type="range"
          ref={progressBar}
          defaultValue={0}
          onChange={changeRange}
        />
        <Styled.Timers>{calculaTime(duration)}</Styled.Timers>
      </Styled.PlayerContainer>
    </Styled.AudioPlayer>
  );
};

export default AudioPlayer;
