import React, { useState, useCallback, useEffect } from "react";
import api from "Helpers/Api";
import { useHistory, useParams } from "react-router-dom";
import Routes from "Routes";

import * as Styled from "./styles";
import List from "Components/List";
import { IPagination } from "Services/pagination";
import { TRange } from "Services/pagination";
import PageTitle from "Components/Base/PageTitle";
import Cities, { ICity } from "Components/Form/Inputs/Cities";
import States, { IState } from "Components/Form/Inputs/States";
import AdItem from "Components/AdItem";
import CheckboxSearch, {
  IOptions
} from "Components/Form/Inputs/CheckboxSearch";
import { TSubcategory } from "Services/Category";
import { TError } from "Services/Error/error";
import { IAd } from "Services/Ad/ad";
import stringRange from "Helpers/stringRange";
import {
  PRODUCT_VEHICLE_CATEGORY_ID,
  TECHNICAL_ATTRIBUTES
} from "Helpers/consts";

import FilterItems from "Components/Filters/FilterItems";
import RangeField from "Components/Form/Inputs/RangeField";
import Sorting, { TSortingValue } from "Components/Sorting";
import { Categories } from "Components/Filters/Categories";

interface Props {
  row?: number;
  perPage?: number;
  hideSearch?: boolean;
  hidePagination?: boolean;
  hideFilters?: boolean;
  hideSorting?: boolean;
  inline?: boolean;
  userId?: number;
  published?: boolean;
  title?: string;
  link?: string;
  setNoResults?: React.Dispatch<React.SetStateAction<boolean>>;
}

type TParams = {
  category: string;
  subcategory: string;
};

const VehicleList: React.FC<Props> = ({
  row = 3,
  perPage = 12,
  hideSearch = false,
  hideFilters = false,
  hidePagination = false,
  hideSorting = false,
  inline = false,
  userId,
  published = true,
  title,
  link = "",
  setNoResults
}) => {
  const history = useHistory();

  const { category: c = "", subcategory: s = "" } = useParams<TParams>();

  const [sorting, setSorting] = useState<TSortingValue>();
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);
  const [priceRange, setPriceRange] = useState<TRange>({ min: 0, max: 0 });
  const [undefinedPrices, setUndefinedPrices] = useState<boolean>(true);
  const [queryError, setQueryError] = useState<boolean>(false);

  const [years, setYears] = useState<IOptions[]>([]);
  const [brands, setBrands] = useState<IOptions[]>([]);
  const [fuels, setFuels] = useState<IOptions[]>([]);
  const [colors, setColors] = useState<IOptions[]>([]);
  const [selectedYears, setSelectedYears] = useState<IOptions[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<IOptions[]>([]);
  const [selectedFuels, setSelectedFuels] = useState<IOptions[]>([]);
  const [selectedColors, setSelectedColors] = useState<IOptions[]>([]);

  const [subcategorySearch, setSubcategorySearch] = useState<string>("");
  const [subcategories, setSubcategories] = useState<IOptions[]>([]);
  const [urlSubcategory, setUrlSubcategory] = useState<TSubcategory>();
  const [selectedSubcategories, setSelectedSubcategories] = useState<
    IOptions[]
  >([]);
  const [subcategoriesError, setSubcategoriesError] = useState<TError>({
    status: false,
    message: ""
  });

  const getSubcategoriesFilter = () => {
    const subcategoriesFitler = urlSubcategory
      ? urlSubcategory.id
      : selectedSubcategories.map((option: IOptions) => option.id).join(",");
    return subcategoriesFitler;
  };

  const getCheckboxFilter = (selectedOptions: IOptions[]) => {
    return selectedOptions.map((option: IOptions) => option.id).join(",");
  };

  const queryTechnicalValues = useCallback(
    async (technicalAttributeIds, setFilterValues) => {
      try {
        const { data } = await api.get<TSubcategory[]>(`/attributes/values/`, {
          params: {
            technicalAttributeIds: technicalAttributeIds.join(),
            categories: PRODUCT_VEHICLE_CATEGORY_ID,
            states: getCheckboxFilter(states),
            cities: getCheckboxFilter(cities)
          }
        });

        const options = data.map(
          (item: any): IOptions => ({
            id: item.value,
            name: item.value,
            qty: item.adsQty
          })
        );

        setFilterValues(options);
      } catch (error) {}
    },
    [
      selectedBrands,
      selectedFuels,
      selectedYears,
      selectedColors,
      cities,
      states,
      selectedSubcategories
    ]
  );

  const querySubcategories = useCallback(async () => {
    try {
      const { data } = await api.get<TSubcategory[]>(
        "/categories/subcategories",
        {
          params: {
            search: subcategorySearch,
            categories: PRODUCT_VEHICLE_CATEGORY_ID,
            states: getCheckboxFilter(states),
            cities: getCheckboxFilter(cities),
            hideEmpty: true
          }
        }
      );
      const options = data.map(
        (item: TSubcategory): IOptions => ({
          id: item.id,
          name: item.title,
          subvalue: item.slug,
          qty: item.adsQty
        })
      );

      const subcategoryByUrl = data.find(item => item.slug === s);

      if (s !== "" && !subcategoryByUrl) history.push(Routes.notFound);

      subcategoryByUrl && setUrlSubcategory(subcategoryByUrl);
      setSubcategories(options);

      if (!options.length) {
        setSubcategoriesError({
          status: true,
          message: "Nenhum resultado encontrado."
        });
      } else {
        setSubcategoriesError({
          status: false,
          message: ""
        });
      }
    } catch {
      setSubcategoriesError({
        status: true,
        message: "Erro no servidor, tente mais tarde."
      });
    }
  }, [s, subcategorySearch, states, cities]);

  const query = useCallback(
    async (search: string = "", page: number = 1) => {
      try {
        const response = api.get<IPagination<IAd>>("/products/", {
          params: {
            vehicles: true,
            search,
            categories: PRODUCT_VEHICLE_CATEGORY_ID,
            subcategories: getSubcategoriesFilter(),
            states: getCheckboxFilter(states),
            cities: getCheckboxFilter(cities),
            years: getCheckboxFilter(selectedYears),
            brands: getCheckboxFilter(selectedBrands),
            fuels: getCheckboxFilter(selectedFuels),
            colors: getCheckboxFilter(selectedColors),
            price: stringRange(priceRange, setPriceRange),
            undefinedPrices,
            published,
            page,
            perPage,
            sort: sorting && sorting.sort,
            order: sorting && sorting.order,
            userId: userId && userId
          }
        });

        const { data } = await response;
        return data;
      } catch {
        setQueryError(true);
        setNoResults && setNoResults(true);
      }
    },
    //TODO: Fix setNoResult from parent creating a loop when put in dependecies.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      cities,
      states,
      priceRange,
      undefinedPrices,
      published,
      selectedSubcategories,
      urlSubcategory,
      selectedYears,
      selectedBrands,
      selectedFuels,
      selectedColors,
      userId,
      perPage,
      sorting
    ]
  );

  const subcategoryOptions = subcategories.filter(
    (subcategory: IOptions) =>
      selectedSubcategories &&
      selectedSubcategories.filter(
        (item: IOptions) => item.id === subcategory.id
      ).length === 0
  );

  useEffect(() => {
    querySubcategories();
  }, [querySubcategories]);

  useEffect(() => {
    queryTechnicalValues([TECHNICAL_ATTRIBUTES["MARCA"]], setBrands);
    queryTechnicalValues(
      [
        TECHNICAL_ATTRIBUTES["ANO_DE_FABRICACAO"],
        TECHNICAL_ATTRIBUTES["ANO_DO_MODELO"]
      ],
      setYears
    );
    queryTechnicalValues([TECHNICAL_ATTRIBUTES["COMBUSTIVEL"]], setFuels);
    queryTechnicalValues([TECHNICAL_ATTRIBUTES["COR"]], setColors);
  }, [queryTechnicalValues]);

  let pageTitle = "Veículos";
  if (urlSubcategory) pageTitle += ` - ${urlSubcategory.title}`;

  return (
    <Styled.Container>
      <PageTitle
        link={link}
        sideChildren={
          !hideSorting && <Sorting setSorting={setSorting}></Sorting>
        }
      >
        {title ? title : pageTitle}
      </PageTitle>
      {((urlSubcategory && s !== "") || !s) && (
        <List
          query={query}
          hideSearch={hideSearch}
          hideFilters={hideFilters}
          hidePagination={hidePagination}
          sorting={sorting}
          seeMoreLink={link}
          seeMoreText={"Ver mais veículos"}
          inline={inline}
          row={row}
          filters={{
            states: getCheckboxFilter(states),
            cities: getCheckboxFilter(cities),
            price: stringRange(priceRange),
            categories: [PRODUCT_VEHICLE_CATEGORY_ID],
            subcategories: getSubcategoriesFilter()
          }}
          segment="vehicles"
          itemlayout={(item: IAd) => (
            <AdItem
              viewCount={userId ? true : false}
              item={item}
              link={Routes.vehicle.replace(":slug", item.slug)}
            />
          )}
          queryError={queryError || !!c || (!!s && subcategoriesError.status)}
          setNoResults={setNoResults}
        >
          <FilterItems title="Estados">
            <States
              name="states"
              setValue={setStates}
              segment="vehicles"
              categories={PRODUCT_VEHICLE_CATEGORY_ID}
              subcategories={getSubcategoriesFilter()}
              label={""}
            />
          </FilterItems>
          <FilterItems title="Cidades">
            <Cities
              name="cities"
              setValue={setCities}
              states={states}
              segment="vehicles"
              categories={PRODUCT_VEHICLE_CATEGORY_ID}
              subcategories={getSubcategoriesFilter()}
              hiddenLabel={true}
            />
          </FilterItems>
          <FilterItems title="Categorias">
            <Categories
              categoriesError={subcategoriesError}
              categoryOptions={subcategoryOptions}
              categorySearch={subcategorySearch}
              selectedCategories={selectedSubcategories}
              setCategorySearch={setSubcategorySearch}
              setSelectedCategories={setSelectedSubcategories}
              segment={{
                name: "vehicles",
                subvalue: "veiculos"
              }}
            />
          </FilterItems>
          {!!brands.length && (
            <FilterItems title="Marca">
              <CheckboxSearch
                label=""
                options={brands}
                hideSearch={true}
                selected={selectedBrands}
                setSelected={setSelectedBrands}
              />
            </FilterItems>
          )}
          {!!years.length && (
            <FilterItems title="Ano">
              <CheckboxSearch
                label=""
                options={years}
                hideSearch={true}
                selected={selectedYears}
                setSelected={setSelectedYears}
              />
            </FilterItems>
          )}
          <FilterItems title="Preços">
            <RangeField
              name="price"
              isCurrency={true}
              change={setPriceRange}
              values={priceRange}
              undefinedValues={undefinedPrices}
              setUndefinedValues={setUndefinedPrices}
              undefinedValuesMessage={"Incluir veículos sem preço"}
            />
          </FilterItems>
          {!!fuels.length && (
            <FilterItems title="Combustível">
              <CheckboxSearch
                label=""
                options={fuels}
                hideSearch={true}
                selected={selectedFuels}
                setSelected={setSelectedFuels}
              />
            </FilterItems>
          )}
          {!!colors.length && (
            <FilterItems title="Cores">
              <CheckboxSearch
                label=""
                options={colors}
                hideSearch={true}
                selected={selectedColors}
                setSelected={setSelectedColors}
              />
            </FilterItems>
          )}
        </List>
      )}
      {/* <Related
        userid={user ? user.id : ""}
        segment={segment}
        mobile={mobile}
        excludeId={0}
      /> */}
    </Styled.Container>
  );
};

export default VehicleList;
