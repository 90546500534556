import React from "react";

const LogoWhite: React.FC = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 337.6 81.18"
    >
      <path
        fill="#FFF"
        d="M65.23,54.41,41.05,40.53l24.18-13.9Zm-4.14,5.78C53,64.9,45,69.62,36.86,74.28a2.66,2.66,0,0,1-2.19.18c-8.54-4.84-17-9.77-25.52-14.69a5.45,5.45,0,0,1-.63-.54L24,50.34c3.49-2,7-4,10.49-6a2.13,2.13,0,0,1,1.71-.17c8.74,5,17.44,10,26.32,15.08C61.93,59.64,61.52,59.94,61.09,60.19ZM5.59,26.59,29.86,40.52l-24.27,14Zm8.92-8.5c6.56-3.8,13.1-7.61,19.69-11.35a2.43,2.43,0,0,1,2-.21c8.69,5,17.34,10,26.23,15.11-.55.38-.94.68-1.36.92q-12.15,7-24.3,14a2.08,2.08,0,0,1-2.42.15C26.09,31.9,17.76,27.14,9.46,22.36c-.3-.17-.56-.38-1.05-.72ZM70.89,40.64c0-6.35,0-12.7,0-19a2.21,2.21,0,0,0-1.24-2.26c-11-6.33-22-12.71-33.08-19A2.58,2.58,0,0,0,34.41.18C23.32,6.52,12.28,13,1.2,19.33A2.08,2.08,0,0,0,0,21.43q0,19,0,38.08a2.24,2.24,0,0,0,1.33,2.22q16.47,9.45,32.88,19a2.33,2.33,0,0,0,2.72,0q16.23-9.52,32.52-18.93a2.62,2.62,0,0,0,1.47-2.67C70.86,53,70.89,46.81,70.89,40.64Z"
      />
      <path
        fill="#FFF"
        d="M100.93,18.88h-6.3v9.05h6.3c2.93,0,4.85-1.88,4.85-4.53S103.86,18.88,100.93,18.88Zm5.19,28.31L99.44,33.51H94.63V47.19H88V12.9h13.41c7,0,11.1,4.77,11.1,10.5a9.21,9.21,0,0,1-6.25,9l7.64,14.79Z"
      />
      <path
        fill="#FFF"
        d="M130.47,47.47c-7,0-12.58-4.76-12.58-12V12.9h6.67V35.2c0,3.94,2.31,6.3,5.91,6.3s6-2.35,6-6.3V12.9h6.63V35.44C143.06,42.71,137.49,47.47,130.47,47.47Z"
      />
      <path
        fill="#FFF"
        d="M161.56,18.88h-6.29v9.05h6.29c2.93,0,4.85-1.88,4.85-4.53S164.49,18.88,161.56,18.88Zm5.19,28.31-6.68-13.68h-4.8V47.19h-6.68V12.9H162c7,0,11.1,4.77,11.1,10.5a9.21,9.21,0,0,1-6.25,9l7.64,14.79Z"
      />
      <path
        fill="#FFF"
        d="M191.49,23l-4.32,12.47h8.5Zm8,24.17-2-6.07H185.29l-2.06,6.07h-7L188.7,12.9h5.24l12.49,34.29Z"
      />
      <polygon
        fill="#FFF"
        points="229.3 47.19 222.67 35.24 216.09 47.19 208.45 47.19 219.07 29.61 209.12 12.9 216.71 12.9 222.67 23.98 228.68 12.9 236.22 12.9 226.28 29.61 236.94 47.19 229.3 47.19"
      />
      <path
        fill="#FFF"
        d="M95.64,64.75l-.86-2.51H89.86L89,64.75H87l4.57-12.46h1.52l4.57,12.46ZM92.37,55.1l-2,5.53h3.84Z"
      />
      <path
        fill="#FFF"
        d="M105.31,64.85a4.85,4.85,0,0,1-3.73-1.28l1.17-1.17a3.43,3.43,0,0,0,2.56.93c1.08,0,1.94-.39,1.94-1.23,0-.59-.33-1-1.19-1.05l-1.42-.12C103,60.79,102,60,102,58.48c0-1.73,1.49-2.71,3.35-2.71a4.85,4.85,0,0,1,3.29,1l-1.12,1.13a3.37,3.37,0,0,0-2.19-.64c-1.07,0-1.61.47-1.61,1.13s.28.93,1.22,1l1.4.12c1.67.14,2.65.93,2.65,2.5C109,63.89,107.42,64.85,105.31,64.85Z"
      />
      <path
        fill="#FFF"
        d="M116,64.75v-.9a3.19,3.19,0,0,1-2.37,1,3.12,3.12,0,0,1-2.19-.8,3.29,3.29,0,0,1-.89-2.51V55.87h1.79v5.41a1.73,1.73,0,0,0,1.78,2,1.77,1.77,0,0,0,1.84-2V55.87h1.79v8.88Z"
      />
      <path
        fill="#FFF"
        d="M124.91,64.75v-.81a2.91,2.91,0,0,1-2.36.91,3.14,3.14,0,0,1-2.38-.8,2.72,2.72,0,0,1-.7-1.91c0-1.49,1-2.56,3.06-2.56h2.35V59c0-1.12-.56-1.66-1.95-1.66a2.08,2.08,0,0,0-1.94.86l-1.17-1.1A3.63,3.63,0,0,1,123,55.77c2.44,0,3.66,1,3.66,3v5.94Zm0-3.92h-2.09c-1.05,0-1.59.47-1.59,1.27s.51,1.26,1.63,1.26a2.13,2.13,0,0,0,1.66-.5,1.83,1.83,0,0,0,.39-1.32Z"
      />
      <path
        fill="#FFF"
        d="M138.9,64.06a3,3,0,0,1-2.15.79,2.76,2.76,0,0,1-2.33-1v4.49h-1.79V55.87h1.75v1a2.73,2.73,0,0,1,2.35-1,3.08,3.08,0,0,1,2.17.79c.89.89,1,2.39,1,3.74S139.79,63.17,138.9,64.06Zm-2.63-6.7c-1.61,0-1.85,1.37-1.85,2.94s.24,3,1.85,3,1.84-1.38,1.84-3S137.88,57.36,136.27,57.36Z"
      />
      <path
        fill="#FFF"
        d="M144.2,64.75a2.26,2.26,0,0,1-2.43-2.45v-10h1.79v9.9c0,.68.24,1,1,1h.79v1.53Z"
      />
      <path
        fill="#FFF"
        d="M151.56,64.75v-.81a2.92,2.92,0,0,1-2.37.91,3.14,3.14,0,0,1-2.38-.8,2.77,2.77,0,0,1-.7-1.91c0-1.49,1-2.56,3.07-2.56h2.34V59c0-1.12-.56-1.66-1.94-1.66a2.08,2.08,0,0,0-1.94.86l-1.18-1.1a3.65,3.65,0,0,1,3.19-1.28c2.43,0,3.66,1,3.66,3v5.94Zm0-3.92h-2.08c-1.05,0-1.59.47-1.59,1.27s.5,1.26,1.62,1.26a2.15,2.15,0,0,0,1.67-.5,1.83,1.83,0,0,0,.38-1.32Z"
      />
      <path
        fill="#FFF"
        d="M158.21,64.75a2.31,2.31,0,0,1-2.43-2.47V57.4h-1V56h1V53.34h1.78V56h1.72V57.4h-1.72v4.79a.9.9,0,0,0,1,1h.74v1.53Z"
      />
      <path
        fill="#FFF"
        d="M165.79,64.75v-.81a2.91,2.91,0,0,1-2.36.91,3.13,3.13,0,0,1-2.38-.8,2.72,2.72,0,0,1-.7-1.91c0-1.49,1-2.56,3.06-2.56h2.34V59c0-1.12-.56-1.66-1.94-1.66a2.08,2.08,0,0,0-1.94.86l-1.17-1.1a3.63,3.63,0,0,1,3.18-1.28c2.43,0,3.66,1,3.66,3v5.94Zm0-3.92h-2.08c-1,0-1.59.47-1.59,1.27s.51,1.26,1.63,1.26a2.13,2.13,0,0,0,1.66-.5,1.83,1.83,0,0,0,.38-1.32Z"
      />
      <path
        fill="#FFF"
        d="M171.86,57.4v7.35h-1.78V57.4h-1V56h1V54.67a2.31,2.31,0,0,1,2.43-2.47h1.12v1.52h-.79a.91.91,0,0,0-1,1V56h1.77V57.4Z"
      />
      <path
        fill="#FFF"
        d="M180.7,63.8a3.87,3.87,0,0,1-5.3,0,4.66,4.66,0,0,1-1.05-3.5,4.64,4.64,0,0,1,1.05-3.48,3.87,3.87,0,0,1,5.3,0,4.64,4.64,0,0,1,1.05,3.48A4.66,4.66,0,0,1,180.7,63.8Zm-1.34-5.93a1.84,1.84,0,0,0-1.32-.51,1.77,1.77,0,0,0-1.29.51,3.44,3.44,0,0,0-.61,2.43,3.44,3.44,0,0,0,.61,2.43,1.75,1.75,0,0,0,1.29.53,1.82,1.82,0,0,0,1.32-.53A3.44,3.44,0,0,0,180,60.3,3.44,3.44,0,0,0,179.36,57.87Z"
      />
      <path
        fill="#FFF"
        d="M188.56,58a1.62,1.62,0,0,0-1.33-.6,1.82,1.82,0,0,0-1.76,2v5.39h-1.79V55.87h1.75v1a3,3,0,0,1,2.36-1.07,2.75,2.75,0,0,1,2.12.84Z"
      />
      <path
        fill="#FFF"
        d="M201.69,64.75V59.34c0-1.38-.78-2-1.78-2a1.76,1.76,0,0,0-1.84,1.89v5.5h-1.78V59.34a1.73,1.73,0,0,0-1.79-2,1.77,1.77,0,0,0-1.84,2v5.41h-1.78V55.87h1.75v.9a3.19,3.19,0,0,1,2.36-1,2.85,2.85,0,0,1,2.52,1.29,3.38,3.38,0,0,1,2.78-1.29,3.23,3.23,0,0,1,2.24.8,3.29,3.29,0,0,1,.95,2.51v5.67Z"
      />
      <path
        fill="#FFF"
        d="M210.52,64.75v-.81a2.91,2.91,0,0,1-2.36.91,3.13,3.13,0,0,1-2.38-.8,2.72,2.72,0,0,1-.7-1.91c0-1.49,1-2.56,3.06-2.56h2.34V59c0-1.12-.56-1.66-1.94-1.66a2.08,2.08,0,0,0-1.94.86l-1.17-1.1a3.62,3.62,0,0,1,3.18-1.28c2.43,0,3.66,1,3.66,3v5.94Zm0-3.92H208.4c-1.05,0-1.59.47-1.59,1.27s.51,1.26,1.63,1.26a2.13,2.13,0,0,0,1.66-.5,1.83,1.83,0,0,0,.38-1.32Z"
      />
      <path
        fill="#FFF"
        d="M223.33,64.75v-1a2.71,2.71,0,0,1-2.34,1,3.06,3.06,0,0,1-2.17-.79c-.9-.89-1-2.41-1-3.76s.1-2.85,1-3.74a3,3,0,0,1,2.15-.79,2.76,2.76,0,0,1,2.33,1V52.29h1.78V64.75Zm-1.87-7.39c-1.61,0-1.86,1.37-1.86,2.94s.25,3,1.86,3,1.84-1.38,1.84-3S223.07,57.36,221.46,57.36Z"
      />
      <path
        fill="#FFF"
        d="M228.74,60.83A2.18,2.18,0,0,0,231,63.31a2.64,2.64,0,0,0,2.15-.91l1.14,1.07A4.16,4.16,0,0,1,231,64.85c-2.23,0-4-1.17-4-4.55,0-2.87,1.48-4.53,3.76-4.53s3.76,1.75,3.76,4.27v.79Zm3.75-2.45a1.82,1.82,0,0,0-1.75-1.12A1.85,1.85,0,0,0,229,58.38a2.89,2.89,0,0,0-.23,1.2h4A2.73,2.73,0,0,0,232.49,58.38Z"
      />
      <path
        fill="#FFF"
        d="M244.86,64.75v-.81a2.9,2.9,0,0,1-2.36.91,3.14,3.14,0,0,1-2.38-.8,2.77,2.77,0,0,1-.7-1.91c0-1.49,1-2.56,3.06-2.56h2.35V59c0-1.12-.56-1.66-1.94-1.66a2.09,2.09,0,0,0-1.95.86l-1.17-1.1A3.65,3.65,0,0,1,243,55.77c2.43,0,3.66,1,3.66,3v5.94Zm0-3.92h-2.08c-1.05,0-1.6.47-1.6,1.27s.51,1.26,1.63,1.26a2.12,2.12,0,0,0,1.66-.5,1.78,1.78,0,0,0,.39-1.32Z"
      />
      <path
        fill="#FFF"
        d="M251.85,68.44a4.12,4.12,0,0,1-3.13-1.17l1.14-1.14a2.51,2.51,0,0,0,1.92.79,2,2,0,0,0,2.08-2.26V63.52a2.73,2.73,0,0,1-2.29,1,3,3,0,0,1-2.12-.79c-.85-.86-1-2.1-1-3.61s.14-2.74,1-3.6a3,3,0,0,1,2.14-.77,2.72,2.72,0,0,1,2.33,1v-1h1.73v8.82A3.57,3.57,0,0,1,251.85,68.44Zm.21-11.08c-1.61,0-1.82,1.38-1.82,2.78s.21,2.79,1.82,2.79,1.8-1.39,1.8-2.79S253.67,57.36,252.06,57.36Z"
      />
      <path
        fill="#FFF"
        d="M262.88,58a1.63,1.63,0,0,0-1.33-.6,1.83,1.83,0,0,0-1.77,2v5.39H258V55.87h1.75v1a2.93,2.93,0,0,1,2.36-1.07,2.77,2.77,0,0,1,2.12.84Z"
      />
      <path
        fill="#FFF"
        d="M270.58,63.8a3.87,3.87,0,0,1-5.3,0,4.66,4.66,0,0,1-1-3.5,4.64,4.64,0,0,1,1-3.48,3.87,3.87,0,0,1,5.3,0,4.64,4.64,0,0,1,1.05,3.48A4.66,4.66,0,0,1,270.58,63.8Zm-1.35-5.93a1.8,1.8,0,0,0-1.31-.51,1.77,1.77,0,0,0-1.29.51A3.39,3.39,0,0,0,266,60.3a3.39,3.39,0,0,0,.62,2.43,1.75,1.75,0,0,0,1.29.53,1.78,1.78,0,0,0,1.31-.53,3.39,3.39,0,0,0,.62-2.43A3.39,3.39,0,0,0,269.23,57.87Z"
      />
      <path
        fill="#FFF"
        d="M279,64.75V59.34a1.73,1.73,0,0,0-1.79-2,1.76,1.76,0,0,0-1.83,2v5.41h-1.79V55.87h1.75v.9a3.15,3.15,0,0,1,2.36-1,3.1,3.1,0,0,1,2.19.8,3.29,3.29,0,0,1,.89,2.51v5.67Z"
      />
      <path
        fill="#FFF"
        d="M284.33,60.83a2.18,2.18,0,0,0,2.25,2.48,2.66,2.66,0,0,0,2.16-.91l1.13,1.07a4.15,4.15,0,0,1-3.32,1.38c-2.22,0-4-1.17-4-4.55,0-2.87,1.49-4.53,3.76-4.53s3.76,1.75,3.76,4.27v.79Zm3.74-2.45a1.82,1.82,0,0,0-1.75-1.12,1.85,1.85,0,0,0-1.77,1.12,2.74,2.74,0,0,0-.22,1.2h4A2.73,2.73,0,0,0,288.07,58.38Z"
      />
      <path
        fill="#FFF"
        d="M294.93,68.44a4.14,4.14,0,0,1-3.13-1.17l1.14-1.14a2.51,2.51,0,0,0,1.92.79A2,2,0,0,0,297,64.66V63.52a2.76,2.76,0,0,1-2.3,1,2.92,2.92,0,0,1-2.11-.79c-.86-.86-1-2.1-1-3.61s.14-2.74,1-3.6a3,3,0,0,1,2.13-.77,2.71,2.71,0,0,1,2.33,1v-1h1.73v8.82A3.56,3.56,0,0,1,294.93,68.44Zm.21-11.08c-1.61,0-1.82,1.38-1.82,2.78s.21,2.79,1.82,2.79S297,61.54,297,60.14,296.75,57.36,295.14,57.36Z"
      />
      <path
        fill="#FFF"
        d="M307,63.8a3.87,3.87,0,0,1-5.3,0,4.66,4.66,0,0,1-1-3.5,4.64,4.64,0,0,1,1-3.48,3.87,3.87,0,0,1,5.3,0,4.64,4.64,0,0,1,1.05,3.48A4.66,4.66,0,0,1,307,63.8Zm-1.34-5.93a1.84,1.84,0,0,0-1.32-.51,1.77,1.77,0,0,0-1.29.51,3.39,3.39,0,0,0-.61,2.43,3.39,3.39,0,0,0,.61,2.43,1.75,1.75,0,0,0,1.29.53,1.82,1.82,0,0,0,1.32-.53,3.44,3.44,0,0,0,.61-2.43A3.44,3.44,0,0,0,305.67,57.87Zm-.88-3.61h-1.35l1.32-2.83h2Z"
      />
      <path
        fill="#FFF"
        d="M313.49,64.85c-2,0-3.95-1.22-3.95-4.55s2-4.53,3.95-4.53A3.63,3.63,0,0,1,316.41,57l-1.22,1.19a2.08,2.08,0,0,0-1.7-.84,2,2,0,0,0-1.64.79,3.25,3.25,0,0,0-.53,2.15,3.31,3.31,0,0,0,.53,2.17,2,2,0,0,0,1.64.79,2.08,2.08,0,0,0,1.7-.84l1.22,1.17A3.65,3.65,0,0,1,313.49,64.85Z"
      />
      <path
        fill="#FFF"
        d="M317.94,54.12V52.23h1.89v1.89ZM318,64.75V55.87h1.79v8.88Z"
      />
      <path
        fill="#FFF"
        d="M328.06,63.8a3.6,3.6,0,0,1-2.67,1,3.54,3.54,0,0,1-2.64-1,4.66,4.66,0,0,1-1.05-3.5,4.64,4.64,0,0,1,1.05-3.48,3.54,3.54,0,0,1,2.64-1,3.6,3.6,0,0,1,2.67,1,4.68,4.68,0,0,1,1.05,3.48A4.71,4.71,0,0,1,328.06,63.8Zm-1.35-5.93a1.83,1.83,0,0,0-1.32-.51,1.77,1.77,0,0,0-1.29.51,3.44,3.44,0,0,0-.61,2.43,3.44,3.44,0,0,0,.61,2.43,1.75,1.75,0,0,0,1.29.53,1.8,1.8,0,0,0,1.32-.53,3.44,3.44,0,0,0,.61-2.43A3.44,3.44,0,0,0,326.71,57.87Z"
      />
      <path
        fill="#FFF"
        d="M333.9,64.85a4.84,4.84,0,0,1-3.72-1.28l1.17-1.17a3.43,3.43,0,0,0,2.55.93c1.09,0,2-.39,2-1.23,0-.59-.34-1-1.19-1.05l-1.42-.12c-1.66-.14-2.63-.89-2.63-2.45,0-1.73,1.49-2.71,3.35-2.71a4.87,4.87,0,0,1,3.29,1l-1.12,1.13a3.37,3.37,0,0,0-2.19-.64c-1.07,0-1.61.47-1.61,1.13s.28.93,1.22,1l1.4.12c1.67.14,2.65.93,2.65,2.5C337.6,63.89,336,64.85,333.9,64.85Z"
      />
    </svg>
  );
};

export default LogoWhite;
