import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
export const Boletim = styled.div`
  display: flex;
  flex-direction: column;
`;

interface DescriptionProps {
  isOpen: boolean;
}

export const Description = styled.div<DescriptionProps>`
    display: flex;
    flex-direction: column;
    transition: 1s;
    max-height: 100%;
    max-height: ${({ isOpen }) => (isOpen ? "250px" : "0")};
    opacity: ${({ isOpen }) => (!isOpen ? "0" : "1")};
    visibility: ${({ isOpen }) => (!isOpen ? "hidden" : "visible")};
    margin: ${({ isOpen }) => (isOpen ? "0" : "-15px 0")};
    width: 100%;
    background: #4cb967;
    padding: 15px 21px 
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 25px;
    z-index: 0;

    @media ${({ theme }) => theme.devices.tablet} {
      width: auto;
    }

`;
export const TitleDescription = styled.h2`
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.white.normal}
    margin: 0;
    padding: 0;

    @media ${({ theme }) => theme.devices.tablet} {
      font-size: 1rem;
    }

`;
export const TextDescription = styled.p`
  color: ${({ theme }) => theme.colors.white.normal}
  margin: 8px 0;
  padding: 0;

  max-width: 100%;

  inline-size: 100%;
  overflow-wrap: break-word;

  @media ${({ theme }) => theme.devices.tablet} {
    font-size: 0.8rem;
  }
`;

export const BoletimCard = styled.div<DescriptionProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  padding: 18px 21px;
  border-radius: ${({ isOpen }) => (isOpen ? "9px 9px 0 0" : "9px")};
  position: relative;

  @media ${({ theme }) => theme.devices.tablet} {
    width: auto;
  }

  z-index: 1;
`;

export const Icon = styled.div<DescriptionProps>`
  position: absolute;
  bottom: 5px;
  right: 15px;
  cursor: pointer;

  transition: 0.3s;
  transform: rotate(${({ isOpen }) => (isOpen ? "-180deg" : "0")});

  svg {
    font-size: ${({ theme }) => theme.fontSize.md2};
    color: ${({ theme }) => theme.colors.gray.normal};
  }
`;

export const BoletimContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BoletimHeader = styled.div`
  display: flex;
  gap: 16px;
`;

export const Stack = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: row;
  height: fit-content;
`;

export const Title = styled.h1`
    font-size: ${({ theme }) => theme.fontSize.md2};

    margin: 0;
    padding 0;

    @media ${({ theme }) => theme.devices.tablet} {
      font-size: ${({ theme }) => theme.fontSize.md};
    }
`;

export const Categories = styled.div`
  display: flex;
  gap: 0.5rem;

  flex-wrap: wrap;
`;

export const Category = styled.p`
  margin: 0;
  font-size: 0.9rem;
  background-color: #a0aec0;
  color: ${({ theme }) => theme.colors.white.normal};
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;

  @media ${({ theme }) => theme.devices.tablet} {
    font-size: 0.7rem;
  }
`;

export const HStack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
`;

export const BoletimAudio = styled.div`
  margin-top: 16px;
`;

export const Icons = styled.div`
  display: flex;
  gap: 16px;
  color: ${({ theme }) => theme.colors.gray.normal};
  cursor: pointer;

  font-size: ${({ theme }) => theme.fontSize.md};

  svg {
    &:hover {
      transform: scale(1.2);
    }
  }
`;

export const Visualizations = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray.normal};
  cursor: pointer;

  font-size: ${({ theme }) => theme.fontSize.md};

  p {
    font-size: 18px;
    margin: 0;
    padding: 0;
  }
`;
