import React, { useCallback, useEffect, useState } from "react";

import * as Styled from "./styles";
import { TArticle } from "Services/Article/article";
import List from "Components/List";
import ListItem from "./ListItem";
import { IPagination } from "Services/pagination";
import { TSection } from "Services/Article/section";
import Routes from "Routes";
import PageTitle from "Components/Base/PageTitle";
import api from "Helpers/Api";

interface Props {
  section?: TSection;
  row?: number;
  link?: string;
  search?: string;
  excludeId?: string;
  perPage?: number;
  hidePagination?: boolean;
  setNoResults?(value: any): void;
}

const ArticleSection: React.FC<Props> = ({
  section,
  row = 3,
  link = "",
  search = "",
  excludeId = "",
  perPage = search ? 9 : 6,
  hidePagination = true,
  setNoResults
}) => {
  const [noResult, setNoResult] = useState(false);

  const query = useCallback(
    async (search: string = "", page: number = 1) => {
      try {
        const { data } = await api.get<IPagination<TArticle>>(
          "/articles/list",
          {
            params: {
              search: search,
              category: section && section.category,
              tags: section && section.tags,
              page: page,
              perPage: perPage,
              excludeId: excludeId
            }
          }
        );

        const response = data;

        if (!response.data.length) {
          setNoResult(true);
          setNoResults && setNoResults(true);
        }

        return response;
      } catch (error) {
        setNoResult(true);
        setNoResults && setNoResults(true);
      }
    },
    [search, excludeId, section]
  );

  const getTitle = () => {
    if (section) {
      const title = !search
        ? section.title
        : section.title !== "Pesquisa"
        ? `Resultados de "${search}" em ${section.title}`
        : `Resultados de "${search}"`;
      return title;
    } else return "Notícias";
  };

  return (
    <>
      {(search || (!search && !noResult)) && (
        <Styled.Section>
          <PageTitle link={link}> {getTitle()} </PageTitle>
          <Styled.ArticlesContainer>
            <List
              setNoResults={setNoResults}
              hideSearch
              hidePagination={hidePagination}
              hideFilters
              seeMoreLink={link}
              seeMoreText={section && `Ver mais em ${section.title}`}
              row={row}
              size={400}
              segment="articles"
              ownSearch={search}
              query={query}
              itemlayout={(item: TArticle) => (
                <ListItem
                  data={item}
                  link={Routes.article.replace(":slug", item.slug)}
                />
              )}
            />
          </Styled.ArticlesContainer>
        </Styled.Section>
      )}
    </>
  );
};

export default ArticleSection;
