import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;

  @media ${({ theme }) => theme.devices.mobileL} {
    padding: 12px 0;
  }
`;

export const TextContent = styled.div`
  background-color: #f4d03f;
  background-image: linear-gradient(132deg, #f4d03f 0%, #16a085 100%);
  height: 350px;
  padding: 12px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.devices.tablet} {
    font-size: ${({ theme }) => theme.fontSize.mm};
    padding: 12px 16px;
  }
`;

export const Title = styled.h1`
  margin: 0;
  padding: 0;

  text-align: right;
  color: #fff;

  font-weight: 600;

  max-width: 500px;
  font-size: ${({ theme }) => theme.fontSize.xlg};

  @media ${({ theme }) => theme.devices.mobileL} {
    font-size: ${({ theme }) => theme.fontSize.lg};
    max-width: 80vw;
  }
`;

export const Text = styled.p`
  margin: 0;
  padding: 0;

  color: #fff;
  text-align: left;

  font-weight: 400;

  font-size: ${({ theme }) => theme.fontSize.md};

  @media ${({ theme }) => theme.devices.mobileL} {
    font-size: ${({ theme }) => theme.fontSize.mm};
    text-align: center;
    margin-right: 22px;
  }
`;

export const FormContainer = styled.div`
  width: fit-content;

  margin: 0 auto;
`;
