import React from "react";
import * as Styled from "./styles";
import NumberInput from "../NumberInput";
import { TRange } from "Services/pagination";
import CheckBox from "../CheckBox";

type TChangeEvent = React.ChangeEvent<HTMLInputElement>;

interface Props {
  title?: string;
  name: string;
  suffix?: string;
  isCurrency?: boolean;
  values?: TRange;
  undefinedValues?: boolean;
  undefinedValuesMessage?: string;
  setUndefinedValues?: React.Dispatch<React.SetStateAction<boolean>>;
  change: React.Dispatch<React.SetStateAction<TRange>>;
}

const parseValue = (value: string, isCurrency: boolean) => {
  const parsedValue = isCurrency
    ? value.replace(/\D+/g, "")
    : value.replaceAll(".", "").replace(",", ".");

  return parseFloat(parsedValue);
};

const RangeField: React.FC<Props> = ({
  title = "",
  name,
  suffix = "",
  isCurrency = false,
  values = { min: 0, max: 0 },
  undefinedValues,
  setUndefinedValues,
  undefinedValuesMessage = "Incluir não definido",
  change
}) => {
  return (
    <Styled.Field>
      {title && <Styled.Title>{title}</Styled.Title>}
      <Styled.Labels>
        <Styled.Label>
          <NumberInput
            name={name}
            label="De"
            suffix={suffix}
            isCurrency={isCurrency}
            defaultValue={isCurrency ? values.min / 100 : values.min}
            onChange={(e: TChangeEvent) => {
              change({
                ...values,
                min: parseValue(e.target.value, isCurrency)
              });
            }}
          />
        </Styled.Label>
        <Styled.Label>
          <NumberInput
            name={name}
            label="Até"
            suffix={suffix}
            isCurrency={isCurrency}
            defaultValue={isCurrency ? values.max / 100 : values.max}
            onChange={(e: TChangeEvent) => {
              change({
                ...values,
                max: parseValue(e.target.value, isCurrency)
              });
            }}
          />
        </Styled.Label>
      </Styled.Labels>
      {setUndefinedValues && typeof undefinedValues !== "undefined" && (
        <CheckBox
          checked={undefinedValues}
          defaultValue={undefinedValues}
          change={() => {
            setUndefinedValues(!undefinedValues);
          }}
        >
          {undefinedValuesMessage}
        </CheckBox>
      )}
    </Styled.Field>
  );
};
export default RangeField;
