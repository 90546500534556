import styled from "styled-components";

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100vh;

  justify-content: center;
  align-items: center;

  svg#Layer_1 {
    width: 100%;
    max-width: 250px;
  }
`;
