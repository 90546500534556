import styled from "styled-components";

export const CallbackContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 200px;
  height: 95px;
  border-radius: 5px;

  background-color: ${({ theme }) => theme.colors.green[700]};
  p {
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.colors.white.normal};
    font-weight: bold;
  }
`;

export const Thumbnail = styled.img`
  width: 200px;
  height: 95px;

  border-radius: 5px;
`;
