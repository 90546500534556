import styled from "styled-components";

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  width: 100%;
  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;
export const BoletimContent = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  margin-right: 40px;

  flex-direction: column;
`;

export const Filters = styled.div`
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  min-width: 25%;
  width: 25%;
  margin-top: 20px;
  margin-bottom: auto;

  @media ${({ theme }) => theme.devices.laptop} {
    margin-right: 20px;
  }
  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
    margin-top: 40px;
  }
`;
