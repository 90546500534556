import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.green[700]};
  color: #fff;
  padding: 8px;
  border-radius: 50%;
  font-size: 16px;
  cursor: pointer;
  border: none;

  .play {
    position: relative;
    left: 2px;
  }

  &:hover {
    filter: brightness(0.9);
  }
`;
