import styled from "styled-components";
import { FaUser } from "react-icons/fa";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;

export const NoData = styled.span`
  margin-top: 20px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.gray.dark};
  letter-spacing: 1px;
  flex-grow: 1;
`;

export const PageTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 100px;
  }
`;

export const Avatar = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: 125px;
  height: 125px;
  display: flex;
  flex-direction: row;
  justify-contens: center;
  margin: 0 auto;
  border: 3px solid ${({ theme }) => theme.colors.green.normal};

  img {
    object-fit: cover;
    width: 100%;
  }
`;
export const Placeholder = styled(FaUser).attrs(props => ({
  icon: FaUser
}))`
  font-size: 30px;
  margin: 0 auto;
  color: ${props => props.theme.colors.gray.normal};
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.mm};
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.gray.dark};
  text-align: center;
  a {
    text-align: center;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.gray.dark};
    margin-top: 5px;

    &:hover {
      color: ${({ theme }) => theme.colors.green.dark};
    }

    svg {
      padding-right: 5px;
    }
  }
`;

export const AvatarContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white.normal};
  border-radius: ${({ theme }) => theme.borderRadius}
  padding: 60px;
  place-content: center;
  display: flex;
  flex-direction: column;
`;
export const AnnounceAccountContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white.normal};
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius}
  flex-direction: column;
  margin-left: 25px;
  @media ${({ theme }) => theme.devices.tablet} {
    margin-left: 0px;
  }
`;
export const DataContainer = styled.div`
  padding: 15px;

  @media ${({ theme }) => theme.devices.tablet} {
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const Title = styled.span`
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${({ theme }) => theme.colors.gray.dark};
  font-weight: 900;
  letter-spacing: 1px;
  margin-top: 5px;
  @media ${({ theme }) => theme.devices.tablet} {
    display: flex;
    padding-bottom: 20px;
  }
`;

export const BtnsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white.normal};
  display: flex;
  padding-top: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  @media ${({ theme }) => theme.devices.tablet} {
    width: 50%
    flex-direction: column;
    padding-top: 0px;
    align-content: center;
  }
`;

export const SingleBtn = styled.div`
  padding-bottom: 15px;
  width: 25%;
  @media ${({ theme }) => theme.devices.tablet} {
    padding-right: 0px;
    width: 100%;
  }
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.gray.light};
  border-style: unset;
  width: 95%;
  font-size: 16px;
  height: 55px;
  cursor: pointer;
  font-weight: 900;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.gray.normal};
`;
