import styled from "styled-components";

export const Container = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 36px;
    color: ${({ theme }) => theme.colors.green.normal};
    animation: icon-spin 2s infinite linear;
  }
`;
