import { IValidation } from "./";

const Validation: IValidation = {
  error: "Por favor, digite uma URL válida",
  validate: (input: string) =>
    /^https:\/\/(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?$/.test(
      input
    )
};

export default Validation;
