import { IMenuItemModel } from "../index";
import Routes, { Account } from "Routes";

const NeedHelp: IMenuItemModel = {
  name: "Precisa de Ajuda",
  link: Routes.leases.replace(":category?", ""),
  submenu: [
    {
      category: "Sua conta",
      link: Account.home
    },
    {
      category: "Frete e prazo de entrega",
      link: Routes.deliveryConditions
    },
    {
      category: "Devoluções e reembolsos",
      link: Routes.devolutionConditions
    },
    {
      category: "Meios de Pagamento",
      link: Routes.paymentConditions
    },
    {
      category: "Fale conosco",
      link: Routes.talkToUs
    }
  ]
};

export default NeedHelp;
