import { IUserSession } from "Contexts/AdminAuthContext";
import adminApi from "Helpers/adminApi";

interface ILogin {
  email: string;
  password: string;
}

export async function loginAdmin({ email, password }: ILogin) {
  return await adminApi.post<IUserSession>("/session/adminLogin/", {
    email,
    password
  });
}
