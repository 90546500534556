import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import * as Styled from "./styles";
import Form from "Components/Form";
import FormContext from "Components/Form/context";
import RadioSet from "Components/Form/Inputs/RadioSet";
import ButtonPrimary from "Components/Buttons/Primary";
import Routes from "Routes";
import localeActions from "Redux/Locale/actions";
import { FaRegCheckCircle } from "react-icons/fa";
import { BiCar, BiCartAlt, BiHomeAlt, BiWrench } from "react-icons/bi";
import { MdWorkOutline } from "react-icons/md";

type TSegment = {
  title: string;
  value: string;
  link: string;
  segment?:
    | "products"
    | "vehicles"
    | "properties"
    | "jobs"
    | "leases"
    | "services";
  icon?: React.FC;
};

export interface IError {
  message: string;
  field: string;
  validation: string;
}

interface Props {}

const CreateAd: React.FC<Props> = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [error, setError] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [selected, setSelected] = useState("");

  const segments: TSegment[] = [
    {
      title: "Imóvel Rural",
      value: "properties",
      segment: "properties",
      link: Routes.property,
      icon: () => <BiHomeAlt />
    },
    // TODO: Put leases into properties (lists and creation (select/checkbox))
    // {
    //   title: "Arrendamento",
    //   value: "properties",
    //   segment: "leases",
    //   link: Routes.lease
    // },
    {
      title: "Produto",
      value: "products",
      segment: "products",
      link: Routes.product,
      icon: () => <BiCartAlt />
    },
    {
      title: "Veículo",
      value: "vehicles",
      segment: "vehicles",
      link: Routes.vehicle,
      icon: () => <BiCar />
    },
    {
      title: "Vaga",
      value: "jobs",
      segment: "jobs",
      link: Routes.job,
      icon: () => <MdWorkOutline />
    },
    {
      title: "Serviço",
      value: "jobs",
      segment: "services",
      link: Routes.service,
      icon: () => <BiWrench />
    }
  ];

  const createAd = async () => {
    const segment = segments.find(item => item.title === selected);

    setLoading(true);

    if (!loading && segment && segment.segment) {
      try {
        history.push(
          Routes.adCreateEditGeneralInformation
            .replace(":segment", segment.segment)
            .replace("/:slug?", "")
        );
        dispatch(localeActions.modal());
      } catch (error) {
        if (error.response) {
          const errors: string[] = error.response.data.map(
            (data: IError, key: number) => data.message
          );
          errors && setError([errors.join(" ")]);
        } else {
          setError(["Erro no servidor, tente mais tarde."]);
        }
        setLoading(false);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Styled.Container>
        <Styled.Content>
          <Styled.Body>
            <Form submit={createAd} defaultError={error}>
              <Styled.Fields>
                <Styled.SearchCategory>
                  <input type="hidden" name="segment" value={selected} />
                  <Styled.List>
                    {segments.map((segment, index) => (
                      <>
                        <Styled.ListItems
                          key={index}
                          onClick={() => setSelected(segment.title)}
                          selected={selected === segment.title}
                        >
                          <Styled.ListTitle>
                            {segment.icon()}
                            {segment.title}
                          </Styled.ListTitle>
                          {selected === segment.title && <FaRegCheckCircle />}
                        </Styled.ListItems>
                      </>
                    ))}
                  </Styled.List>
                </Styled.SearchCategory>
              </Styled.Fields>
              <Styled.ButtonsContainer>
                <ButtonPrimary type="submit" disabled={!selected}>
                  Publicar
                </ButtonPrimary>
              </Styled.ButtonsContainer>
            </Form>
          </Styled.Body>
        </Styled.Content>
      </Styled.Container>
    </>
  );
};

export default CreateAd;
