import styled from "styled-components";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { ISelectTheme } from "./theme";
interface IContainer {
  width: number;
  marginBottom: ISelectTheme["marginBottom"];
  textAlign: ISelectTheme["textAlign"];
}
export const Container = styled.div<IContainer>`
  min-width: ${({ width }) => width}px;
  border-right-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  text-align: ${({ textAlign }) => (textAlign ? `center` : `unset`)};
  cursor: pointer;
  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
  }
`;
export const Message = styled.span`
  font-size: ${({ theme }) => theme.fontSize.sm};
  padding-bottom: 2px;
  color: ${({ theme }) => theme.colors.gray.dark};
  text-align: right;
`;
interface ISelected {
  background: ISelectTheme["background"];
  brightness: ISelectTheme["brightness"];
  border: ISelectTheme["border"];
  disabled: boolean;
}
export const Selected = styled.div<ISelected>`
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  border: none;
  display: flex;
  flex-direction: row;
  background-color: ${({ theme, background }) =>
    background === "gray"
      ? theme.colors[background].light
      : theme.colors[background].normal};
  border-radius: ${({ theme }) =>
    `${theme.borderRadius} 0 0 ${theme.borderRadius}`};
  flex-grow: 1;
`;
interface ILabel {
  colorLabel: ISelectTheme["colorLabel"];
  justify: ISelectTheme["justify"];
}
export const Label = styled.div<ILabel>`
  flex-grow: 1;
  font-size: 13px;
  padding: 5px;
  color: ${({ colorLabel, theme }) =>
    colorLabel === "gray"
      ? theme.colors[colorLabel].dark
      : theme.colors[colorLabel].normal};
  font-weight: 500;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: ${({ justify }) => `${justify}`};
`;
interface IExpand {
  expandColor: ISelectTheme["expandColor"];
}
export const Expand = styled.div<IExpand>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left: 1px solid
    ${({ theme, expandColor }) => theme.colors[expandColor].dark};
  color: ${({ theme, expandColor }) => theme.colors[expandColor].dark};
  margin: 4px 0;
  padding: 0 5px;
`;
export const ExpandMore = styled(ExpandMoreIcon)``;
export const ExpandLess = styled(ExpandLessIcon)``;
interface IOptions {
  expand: boolean;
  textColor: ISelectTheme["textColor"];
  scroll: ISelectTheme["scroll"];
}
export const Options = styled.div<IOptions>`
  max-height: ${({ expand }) => (expand ? "200px" : "0px")};
  color: ${({ textColor, theme }) => theme.colors[textColor].normal};
  z-index: 1;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  top: calc(100%);
  left: 0;
  right: 0;
  transition: max-height ${({ theme }) => theme.animation.duration};
  &::-webkit-scrollbar {
    display: ${({ scroll }) => (scroll ? "none" : "auto")};
  }
  border-radius: ${({ theme }) =>
    `0 0 ${theme.borderRadius} ${theme.borderRadius}`};
`;
interface IOption {
  background: ISelectTheme["backgroundOption"];
  brightness: ISelectTheme["brightnessOption"];
  hover: ISelectTheme["hover"];
  border: ISelectTheme["borderOption"];
}
export const Option = styled.div<IOption>`
  padding: 5px;
  border-bottom: ${({ border }) => border && `1px solid`};
  background-color: ${({ theme, background }) =>
    theme.colors[background].light};
  @media ${({ theme }) => theme.devices.laptop} {
    &:hover {
      background-color: ${({ hover, theme }) => theme.colors[hover].normal};
    }
  }
`;
interface IError {
  valid: boolean;
}
export const Error = styled.span<IError>`
  color: ${({ theme, valid }) =>
    valid ? theme.colors.gray.dark : theme.colors.red.normal};
  font-family: ${({ theme }) => theme.fonts.secundary};
  font-size: ${({ theme }) => theme.fontSize.sm};
  padding-top: 5px;
  height: 10px;
  text-align: right;
`;
export const SchoolingLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: ${({ theme }) => theme.fontSize.mm};
  letter-spacing: 2px;
  font-weight: 600;
  padding-bottom: 10px;
`;
