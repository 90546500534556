import styled, { keyframes } from "styled-components";

interface InformativeProps {
  isOpen: boolean;
}

const appearOnScreen = keyframes`
    0% { right: -50px; opacity: 0; }
    100% { right: 10px; opacity: 1; }
`;

export const Container = styled.div<InformativeProps>`
  animation-name: ${appearOnScreen};
  animation-duration: 1s;

  transition: 0.5s;
  position: fixed;
  bottom: 10px;
  right: ${({ isOpen }) => (isOpen ? "10px" : "-50px")};

  z-index: 99999;

  width: 480px;

  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  height: 150px;
  background: #fff;

  border-radius: 0 5px 5px 0;
  border-left: 3px solid ${({ theme }) => theme.colors.green.normal};
  box-shadow: ${({ theme }) => theme.shadow.bottomRight};

  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
    max-width: 350px;
    height: 170px;
  }
`;

export const CloseIcon = styled.label`
  display: inline-block;
  position: absolute;
  color: ${({ theme }) => theme.colors.gray.normal};
  cursor: pointer;

  top: 8px;
  right: 8px;
`;

export const Content = styled.div`
  margin-left: 16px;
  padding-right: 2px;

  button {
    padding: 5px 10px;
  }
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.base};
`;

export const Informative = styled.p`
    font-size: ${({ theme }) => theme.fontSize.base}
    padding-right: 5px;
`;
