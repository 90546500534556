import styled from "styled-components";

import { NavLink } from "react-router-dom";

import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

interface IMenuContainer {
  toggle: boolean;
}

export const MenuContainer = styled.ul<IMenuContainer>`
  display: flex;
  height: 40px;

  align-items: center;

  flex-grow: 1;
  flex-direction: row;
  list-style: none;
  margin: 0;
  margin-top: 10px;
  padding: 0 16px;
  font-weight: 600;
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.mm};

  @media ${({ theme }) => theme.devices.tablet} {
    overflow: scroll;
    position: fixed;
    height: 100%;
    padding: 0;
    margin-top: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999999 !important;
    background-color: ${({ theme }) => theme.colors.green.normal};
    transform: translateX(${({ toggle }) => (toggle ? "-100%" : "0")});
    width: 100vw;
    transition: transform ${({ theme }) => theme.animation.duration};
    will-change: transform;
    flex-direction: column;
    font-size: ${({ theme }) => theme.fontSize.mm};
    justify-content: flex-start;
  }
`;

export const MenuItem = styled.li`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  align-items: center;
  gap: 10px;
  justify-content: center;

  color: ${props => props.theme.colors.gray.dark};
  cursor: pointer;
  text-align: center;
  margin-right: 20px;
  padding-right: 16px;
  font-size: 0.9em;

  @media ${({ theme }) => theme.devices.tablet} {
    background-color: ${props => props.theme.colors.green.normal};
    color: ${props => props.theme.colors.white.normal};
    flex-grow: 0;
    text-align: left;
    position: relative;
    padding: 7px 0;
    margin-right: 0;
  }

  svg {
    font-size: 1.2rem;
  }
`;

export const MenuItemLink = styled(NavLink)`
  display: flex;
  gap: 10px;
  align-items: center;

  text-decoration: none;
  position: relative;
  color: ${props => props.theme.colors.gray.dark};

  &:hover {
    color: ${props => props.theme.colors.green.dark};
  }

  &.active {
    color: ${props => props.theme.colors.green.normal};
  }

  @media ${({ theme }) => theme.devices.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    color: ${props => props.theme.colors.white.normal};

    &.active {
      color: ${props => props.theme.colors.gray.dark};
      pointer-events: none;
    }
  }
`;

export const MenuItemIcon = styled.span`
  display: inline-block;
`;

interface ISubmenuContainer {
  expand: boolean;
  hover: boolean;
}

export const SubmenuContainer = styled.div<ISubmenuContainer>`
  display: flex;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;

  width: 100%;
  min-width: max-content;

  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;

  padding: ${({ hover }) => (hover ? "12px 24px" : "0")};
  transform: translateY(-2px);

  background-color: ${props => props.theme.colors.white.normal};
  transition: max-height ${({ theme }) => theme.animation.duration};
  transition: padding ${({ theme }) => theme.animation.duration};
  transition: opacity ${({ theme }) => theme.animation.duration};
  will-change: max-height;
  margin-top: 2px;
  @media ${({ theme }) => theme.devices.laptop} {
    position: absolute;
    background-color: ${props => props.theme.colors.white.normal};
    box-shadow: ${props => props.theme.shadow.vertical};
    top: 100%;
    left: 0;
    right: 0;
    max-height: ${({ hover }) => (hover ? "500px" : "0")};
    opacity: ${({ hover }) => (hover ? "1" : "0")};
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  @media ${({ theme }) => theme.devices.tablet} {
    background-color: ${props => props.theme.colors.green.dark};
    flex-direction: column;
    max-height: ${({ expand }) => (expand ? "100vh" : "0")};
    opacity: ${({ expand }) => (expand ? "1" : "0")};
  }

  @media ${({ theme }) => theme.devices.laptop} {
    flex-direction: column;
  }
`;

export const SubmenuCategory = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 5px 36px 5px 5px
  padding: 0;
  @media ${({ theme }) => theme.devices.tablet} {
    margin: 0;
    position: relative;
  }
`;

export const SubmenuCategoryTitle = styled(NavLink)`
  color: ${({ theme }) => theme.colors.gray.normal};
  padding: 0 0 10px 0;
  text-align: left;
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.colors.green.normal};
  }
  @media ${({ theme }) => theme.devices.tablet} {
    position: relative;
    color: ${props => props.theme.colors.gray.light};
    padding: 0;
    margin: 10px 25px;
    justify-content: center;
    flex-direction: column;
    display: flex;
    height: 25px;
  }
`;

interface ISubmenuItemContainer {
  expand: boolean;
}

export const SubmenuItemContainer = styled.div<ISubmenuItemContainer>`
  @media ${({ theme }) => theme.devices.tablet} {
    max-height: ${({ expand }) => (expand ? "300px" : "0")};
    transition: max-height ${({ theme }) => theme.animation.duration};
    will-change: max-height;
    overflow: hidden;
  }
`;

export const SubmenuItem = styled.li`
  color: ${props => props.theme.colors.gray.dark};
  padding: 0 0 5px 0;
  text-align: left;
  &:hover {
    color: ${props => props.theme.colors.green.normal};
  }
  @media ${({ theme }) => theme.devices.tablet} {
    background-color: ${props => props.theme.colors.green.normal};
    color: ${props => props.theme.colors.gray.normal};
    flex-direction: column;
  }
`;

export const MobileToggle = styled(MenuIcon).attrs(props => ({
  fontSize: "large"
}))`
  font-size: 40px;
  color: ${props => props.theme.colors.gray.normal};
`;

export const MenuClose = styled(CloseIcon).attrs(props => ({
  fontSize: "large"
}))`
  font-size: 40px;
  margin-top: 7px;
  z-index: 999999999;
  position: absolute;
  right: 78px;
  color: ${({ theme }) => theme.colors.white.normal};
`;
export const ExpandContainer = styled.div`
  position: absolute;
  bottom: 0;
  color: ${({ color, theme }) => (color ? color : theme.colors.green.light)};
  pointer-events: all;
  z-index: 2;
`;

export const ExpandMore = styled(ExpandMoreIcon)``;

export const ExpandLess = styled(ExpandLessIcon)``;

export const Logout = styled.li`
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSize.mm};
  color: ${({ theme }) => theme.colors.green.dark};
  margin-top: auto;
  padding: 30px;
  text-align: right;
`;

export const Publish = styled.li`
  margin-left: auto;
  padding: 10px 22px 10px 22px;
  background-color: ${({ theme }) => theme.colors.orange.normal};
  color: ${({ theme }) => theme.colors.white.normal};
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: ${({ theme }) => theme.fontSize.sm};
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.orange.light};
  }
`;

export const AnnounceContainer = styled.div`
  display: flex;

  margin-left: auto;

  gap: 18px;

  @media ${({ theme }) => theme.devices.tablet} {
    display: none;
  }
`;
