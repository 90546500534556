import React, { useState, useEffect, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { MARKETPLACE } from "config";

import * as Styled from "./styles";
import Menu from "./Menu";
import Profile from "./Profile";
import Logo from "./Logo";
import { useDispatch, useSelector } from "react-redux";
import { ReducerState } from "Redux/store";
import MyAccount from "./MyAccount";

import Routes from "Routes";
import Select from "Components/Form/Inputs/Select";
import api from "Helpers/Api";
import cartActions from "Redux/Cart/actions";
import localeActions from "Redux/Locale/actions";
import { CartReponse } from "Redux/Cart/types";
import BackendErrorHelper from "Helpers/BackendErrorHelper";
import { createTrack } from "Helpers/tracking";
import { IAdTracking } from "Services/Tracking/tracking";
import { TSegmentsName } from "Services/Segment/segment";
import Ticker from "Components/Ticker";

const Header: React.FC = () => {
  const { gaId } = useSelector((state: ReducerState) => state.locale);
  const { mobile } = useSelector((state: ReducerState) => state.locale);
  const { user } = useSelector((state: ReducerState) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const [account, setAccount] = useState<boolean>(false);
  const [segment, setSegment] = useState<TSegmentsName | "default">("default");
  const [search, setSearch] = useState<string>();
  const history = useHistory();

  const { cartItems, cartId } = useSelector(
    (state: ReducerState) => state.cart
  );

  const cartCount = useMemo(
    () =>
      Object.keys(cartItems).length > 0
        ? Object.values(cartItems)
            .map(cartItem => cartItem.qty)
            .reduce((sum, current) => sum + current)
        : 0,

    [cartItems]
  );

  useEffect(() => {
    MARKETPLACE && cartId && verifyCart();

    var scrollTrigger = 60;

    if (window.screen.width < 600) {
      window.onscroll = function() {
        if (header.current) {
          // We add pageYOffset for compatibility with IE.
          if (
            window.scrollY >= scrollTrigger ||
            window.pageYOffset >= scrollTrigger
          ) {
            header.current.classList.add("white");
          } else {
            header.current.classList.remove("white");
          }
        }
      };
    }
  }, []);

  const verifyCart = async () => {
    try {
      const { data } = await api.get<CartReponse>(`/cart/${cartId}`);
      data.items.forEach(item => {
        dispatch(
          cartActions.addCart({ id: item.id, qty: item.qty }, data.cartId)
        );
      });
      Object.keys(cartItems).forEach(cartItem => {
        if (!data.items.some(item => item.id === cartItem)) {
          dispatch(cartActions.removeCart(cartItem));
        }
      });
    } catch (e) {
      if (BackendErrorHelper.checkIfDefaultError(e)) {
        const message = BackendErrorHelper.getDefaultErrorMessage(e.response);
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else if (BackendErrorHelper.checkIfValidationError(e)) {
        const message = BackendErrorHelper.getValidatorErrorMessage(e.response);
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else {
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message: "Erro ao recuperar o carrinho"
          })
        );
      }
      Object.keys(cartItems).forEach(item => {
        dispatch(cartActions.removeCart(item));
      });
      dispatch(cartActions.setCartId(""));
    }
  };

  const header = useRef<HTMLHeadingElement>(null);

  const DropSegments = [
    { name: "O que está buscando?", value: "default" },
    {
      name: "Imóveis Rurais",
      value: "imoveis",
      link: Routes.properties.replace(":category?", "")
    },
    {
      name: "Arrendamentos",
      value: "arrendamentos",
      link: Routes.leases.replace(":category?", "")
    },
    {
      name: "Produtos",
      value: "produtos",
      link: Routes.products
        .replace(":category?", "")
        .replace("/:subcategory?", "")
    },
    {
      name: "Veículos",
      value: "veiculos",
      link: Routes.vehicles.replace("/:subcategory?", "")
    },
    {
      name: "Vagas",
      value: "vagas",
      link: Routes.jobs
    },
    {
      name: "Serviços",
      value: "servicos",
      link: Routes.services
    },
    {
      name: "Lojas",
      value: "stores",
      link: Routes.stores
    }
  ];

  MARKETPLACE &&
    DropSegments.push(
      {
        name: "Boletins",
        value: "boletins",
        link: Routes.boletins
      },
      {
        name: "Destaques",
        value: "adplans",
        link: Routes.adplans
      }
    );

  useEffect(() => {
    setSearch("");
  }, [segment]);

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);
  }

  const submit = () => {
    const event: IAdTracking = {
      TYPE: "INDEX_SEARCH",
      GA_ID: gaId,
      USER_ID: user ? user.id : "",
      DATA: {
        CATEGORY: segment === "default" ? "" : segment,
        SEARCH: search || ""
      },
      PAGE_REF: location.pathname,
      TAGS: "mode=model1,version=v2,test=a"
    };
    dispatch(
      localeActions.addTracking(
        // TODO: change types from createTrack to accept empty value
        createTrack(event, segment as any)
      )
    );
    if (segment !== "default") {
      history.push(`/${segment}?s=${search}`);
    } else {
      history.push(`/busca?s=${search}`);
    }
  };

  const onEnter = function(event: React.KeyboardEvent) {
    var keyPressed = event.keyCode || event.which;

    if (keyPressed === 13) submit();
  };

  return (
    <>
      <Styled.HeaderContainer ref={header}>
        {!mobile && (
          <Styled.TopContainer>
            <Styled.MenuSecundary></Styled.MenuSecundary>
          </Styled.TopContainer>
        )}
        <Styled.MiddleContainer>
          {mobile && <Menu account={setAccount} />}
          <Styled.LogoContainer>
            <Styled.Logo to="/">
              <Logo />
            </Styled.Logo>
          </Styled.LogoContainer>
          {!mobile && (
            <>
              <Styled.FormContainer onKeyPress={onEnter}>
                <Select
                  change={value => {
                    setSegment(value);
                  }}
                  defaultValue={"default"}
                  name={"segment"}
                  options={DropSegments}
                  width={210}
                ></Select>
                <Styled.Search
                  placeholder={
                    segment !== "default"
                      ? "O que está buscando?"
                      : "Selecione o segmento..."
                  }
                  onChange={onChange}
                  value={search || ""}
                />

                <Styled.SearchBtn type="submit" onClick={submit}>
                  <Styled.SearchIcon />
                </Styled.SearchBtn>
              </Styled.FormContainer>
            </>
          )}
          {MARKETPLACE && (
            <Styled.CartConainer to={Routes.cart}>
              <Styled.CartIcon />

              <Styled.BadgeCircle>{cartCount}</Styled.BadgeCircle>
            </Styled.CartConainer>
          )}
          {!mobile && <Profile account={setAccount} />}
        </Styled.MiddleContainer>
        {!mobile && <Menu account={setAccount} />}
        <MyAccount show={account} toggle={setAccount} />
      </Styled.HeaderContainer>
      {mobile ? (
        <Styled.TickerMobile>
          <Ticker />
        </Styled.TickerMobile>
      ) : (
        <Ticker />
      )}
      {mobile && (
        <Styled.FormContainer onKeyPress={onEnter}>
          <Styled.Search
            placeholder="O que está buscando?"
            onChange={onChange}
            value={search}
          />

          <Styled.SearchBtn type="submit" onClick={submit}>
            <Styled.SearchIcon />
          </Styled.SearchBtn>
        </Styled.FormContainer>
      )}
    </>
  );
};

export default Header;
