import ButtonPrimary from "Components/Buttons/Primary";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useHistory } from "react-router-dom";

import * as Styled from "./styles";

interface Props {
  days?: string;
}

const TrialDays: React.FC<Props> = ({ days = "5" }) => {
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(true);

  const handleSeeButton = () => {
    setIsOpen(false);
    history.push("/minha-conta/plans/");
  };

  return (
    <>
      <Styled.Container isOpen={isOpen}>
        <Styled.CloseIcon onClick={() => setIsOpen(false)}>
          <AiOutlineClose />
        </Styled.CloseIcon>
        <Styled.Content>
          <Styled.Title>Informativo</Styled.Title>
          <Styled.Informative>
            Faltam <strong>{days} dias</strong> para o fim do seu trial. Após o
            período de teste, o valor do plano será creditado do seu cartão!
          </Styled.Informative>
          <ButtonPrimary action={handleSeeButton}>Ver plano</ButtonPrimary>
        </Styled.Content>
      </Styled.Container>
    </>
  );
};

export default TrialDays;
