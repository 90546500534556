import styled from "styled-components";
interface IWrap {
  wrap: boolean;
  space: boolean;
}

interface ISize {
  size: string;
}

export const Container = styled.div<IWrap>`
  display: flex;
  flex-direction: row;
  flex-wrap: ${({ wrap }) => (wrap ? "nowrap" : "wrap")};
  justify-content: ${({ space }) => space && "space-around"};
`;

export const ColContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  @media ${({ theme }) => theme.devices.tablet} {
    margin-right: auto;
  }
`;

export const LabelTitle = styled.label`
  width: 100%;
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: ${({ theme }) => theme.fontSize.mm};
  letter-spacing: 2px;
  font-weight: 600;
  padding-bottom: 10px;
  margin-bottom: 16px;
`;

export const Radio = styled.input<ISize>`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.gray.dark};
  cursor: pointer;
  width: ${({ size }) => size === "md" && "20px"};
  height: ${({ size }) => size === "md" && "20px"};
`;

export const Content = styled.div`
  display: flex;
  margin-right: 10px;
`;

export const Label = styled.label<ISize>`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: ${({ theme, size }) => theme.fontSize[size]};
  font-weight: 400;
  // padding-bottom: 10px;
  text-transform: capitalize;
  cursor: pointer;
  align-self: center;
`;
