import React, { useState } from "react";
import * as Styled from "./styles";
import TextInput from "Components/Form/Inputs/Text";
import ButtonPrimary from "Components/Buttons/Primary";
import RangeDateField from "Components/Form/Inputs/RangeDateField";

import AutoComplete from "Components/Form/Inputs/AutoComplete";
import { queryStates } from "Helpers/citiesAndStatesQuery";
import api from "Helpers/Api";
import Form from "Components/Form";
import { useDispatch } from "react-redux";
import BackendErrorHelper from "Helpers/BackendErrorHelper";
import localeActions from "Redux/Locale/actions";
import fairCreate from "Api/fairs/create";
import { useHistory } from "react-router-dom";

const AdminFairsCreate: React.FC = () => {
  const history = useHistory();

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [state, setState] = useState<any | null>(null);
  const [city, setCity] = useState<any | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const dispatch = useDispatch();

  const handleEndDate = (e: Date) => {
    setEndDate(e);
  };

  const handleStartDate = (e: Date) => {
    setStartDate(e);
  };

  const queryCities = async (search = "") => {
    const { data } = await api.get("/location/cities", {
      params: { search, states: state ? state.value : "" }
    });
    return data
      .map((city: any) => ({ name: city.name, value: city.id }))
      .slice(0, 4);
  };

  const onChangeForm = async (inputs: any) => {
    try {
      const params = {
        ...inputs,
        stateId: state && state.value.toString(),
        cityId: city && city.value.toString(),
        startDate: startDate,
        endDate: endDate
      };

      const response = await fairCreate(params);
      response &&
        dispatch(
          localeActions.addTooltip({
            type: "success",
            message: "Feira criada com sucesso!"
          })
        );
      history.push(`/admin/fairs`);
    } catch (error) {
      if (BackendErrorHelper.checkIfDefaultError(error)) {
        const message = BackendErrorHelper.getDefaultErrorMessage(
          error.response
        );
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else if (BackendErrorHelper.checkIfValidationError(error)) {
        const message = BackendErrorHelper.getValidatorErrorMessage(
          error.response
        );
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else {
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message: "Error ao criar feira"
          })
        );
      }
    }
  };

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Title>Criar Feira</Styled.Title>
        <Form submit={onChangeForm}>
          <TextInput
            label="Nome da Feira"
            name="title"
            placeholder="Digite o nome da feira"
            required
          />
          <Styled.RangeDate>
            <RangeDateField
              maxDate={new Date(8640000000000000)}
              minDate={new Date(-8640000000000000)}
              labelStartDate="Início"
              labelEndDate="Fim"
              name="date"
              initialStartDate={startDate}
              endDate={endDate}
              changeEndDate={handleEndDate}
              changeStartDate={handleStartDate}
            />
          </Styled.RangeDate>
          <Styled.LocalizationContainer>
            <Styled.Item>
              <Styled.InputTitle>Estado:</Styled.InputTitle>
              <AutoComplete
                name="state_id"
                value={(state && state.value) || ""}
                select={queryStates}
                update={setState}
                placeholder="Digite seu estado"
              />
            </Styled.Item>
            <Styled.Item>
              <Styled.InputTitle>Cidade:</Styled.InputTitle>
              <AutoComplete
                name="city_id"
                value={(city && city.value) || ""}
                select={queryCities}
                update={setCity}
                placeholder="Digite sua cidade"
              />
            </Styled.Item>
          </Styled.LocalizationContainer>
          <TextInput label="Site" name="site" placeholder="Digite o site" />
          <Styled.ImageContainer>
            <TextInput
              label="Link da imagem-banner."
              name="imageUrl"
              placeholder="Digite o link da imagem aqui."
              change={value => setImagePreview(value)}
              required
            />
            {imagePreview && <Styled.Preview src={imagePreview} />}
          </Styled.ImageContainer>
          <Styled.ButtonContainer>
            <ButtonPrimary type="submit" color="orange">
              Criar
            </ButtonPrimary>
          </Styled.ButtonContainer>
        </Form>
      </Styled.Content>
    </Styled.Container>
  );
};

export default AdminFairsCreate;
