import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FaCalendarAlt, FaMapMarkerAlt } from "react-icons/fa";

import * as Styled from "./styles";

import FairImage from "Assets/Images/Fairs/fair-200.png";
import { ICity } from "Components/Form/Inputs/Cities";
import { TFair } from "Services/Fairs/fairs";
import { IState } from "Views/Quotations/QuotationsFilter";
import ButtonPrimary from "Components/Buttons/Primary";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Modal from "Components/Modal";
import fairDelete from "Api/fairs/delete";

import BackendErrorHelper from "Helpers/BackendErrorHelper";
import localeActions from "Redux/Locale/actions";
import Loading from "Components/Loading";

interface FairItemProps {
  fair: TFair;
}

export default function FairItem({
  fair: { city, end_date, site, start_date, state, title, image_url, id },
  ...rest
}: FairItemProps) {
  const validDate = new Date(start_date) <= new Date(end_date);

  const history = useHistory();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteFair = async () => {
    try {
      setIsLoading(true);
      const response = await fairDelete(id);
      response &&
        dispatch(
          localeActions.addTooltip({
            type: "success",
            message: "Excluído com sucesso!"
          })
        );
    } catch (error) {
      if (BackendErrorHelper.checkIfDefaultError(error)) {
        const message = BackendErrorHelper.getDefaultErrorMessage(
          error.response
        );
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else if (BackendErrorHelper.checkIfValidationError(error)) {
        const message = BackendErrorHelper.getValidatorErrorMessage(
          error.response
        );
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else {
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message: "Erro ao tentar excluir feira."
          })
        );
      }
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  return (
    <Styled.FairItem key={`fair`} {...rest}>
      <Styled.ImageContainer>
        <img
          src={urlIsImage(image_url) ? image_url : FairImage}
          alt="Feira agrícola"
          width={"200px"}
        />
      </Styled.ImageContainer>
      <Styled.FairInfo>
        <Styled.FairTitle>{title}</Styled.FairTitle>
        <Styled.Content>
          <Styled.Data>
            <Styled.Location>
              <Styled.Icon>
                <FaMapMarkerAlt />
              </Styled.Icon>
              {getFairLocation(city, state)}
            </Styled.Location>
            {validDate && start_date && (
              <Styled.Date>
                <Styled.Icon>
                  <FaCalendarAlt />
                </Styled.Icon>
                {getFairStartDate(start_date)}
              </Styled.Date>
            )}
            {validDate && end_date && (
              <Styled.Date>
                <Styled.Icon>
                  <FaCalendarAlt />
                </Styled.Icon>
                {getFairsEndDate(end_date)}
              </Styled.Date>
            )}
          </Styled.Data>
          <Styled.Buttons>
            <ButtonPrimary
              color="green"
              action={() => history.push(`/admin/fairs/${id}`)}
            >
              <FaEdit />
              Editar
            </ButtonPrimary>
            <ButtonPrimary color="red" action={() => setIsModalOpen(true)}>
              <MdDelete />
              Excluir
            </ButtonPrimary>
          </Styled.Buttons>
        </Styled.Content>
      </Styled.FairInfo>
      {isModalOpen && (
        <Modal handleClose={setIsModalOpen} title={title} isOpen={isModalOpen}>
          <Styled.DeleteContainer>
            <p>Tem certeza que deseja excluir essa feira?</p>
            <Styled.DeleteContainerButtons>
              <ButtonPrimary
                color="red"
                action={handleDeleteFair}
                disabled={isLoading}
              >
                {isLoading ? <Loading /> : "Sim"}
              </ButtonPrimary>
              <ButtonPrimary
                color="orange"
                action={() => setIsModalOpen(false)}
              >
                Cancelar
              </ButtonPrimary>
            </Styled.DeleteContainerButtons>
          </Styled.DeleteContainer>
        </Modal>
      )}
    </Styled.FairItem>
  );
}

export function getFairStartDate(start_date: string) {
  if (start_date) {
    const formattedDate = moment(start_date).format("LL");

    return `Início: ${formattedDate}`;
  }

  return "Sem data definida";
}

export function getFairsEndDate(end_date: string) {
  if (end_date) {
    const formattedDate = moment(end_date).format("LL");

    return `Fim: ${formattedDate}`;
  }

  return "Sem data definida";
}

export function getFairLocation(city: ICity, state: IState) {
  if (city && state) {
    return `${city.name} ${state && ` - ${state.name}`}`;
  }

  if (city) {
    return city.name;
  }

  if (state) {
    return state.name;
  }

  return "Sem local definido";
}

export function getFairWebsite(site: string) {
  if (
    site &&
    (site.includes(".com") || site.includes(".org") || site.includes(".br"))
  ) {
    return `https://${site.replace(/\s+/g, "").replace(/https?:\/\//gm, "")}`;
  }
  return "";
}

export function urlIsImage(url?: string): boolean {
  if (!url) return false;

  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}
