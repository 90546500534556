import React, { useEffect, useRef } from "react";

import * as Styled from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { ReducerState } from "Redux/store";
import { TTooltip } from "Services/Locale/tooltip";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import localeActions from "Redux/Locale/actions";

const Tooltips: React.FC = () => {
  const dispatch = useDispatch();
  const { tooltips } = useSelector((state: ReducerState) => state.locale);
  const timeref = useRef(0);
  interface Status {
    [key: string]: "red" | "green" | "orange";
  }

  const status: Status = {
    error: "red",
    success: "green",
    warning: "orange"
  };
  useEffect(() => {
    if (tooltips.filter((tooltip: TTooltip) => tooltip.status).length > 0) {
      const timeOut = tooltips.map((tooltip: TTooltip) =>
        tooltip.timeout ? tooltip.timeout : 1500
      );

      timeref.current = setTimeout(() => {
        dispatch(
          localeActions.deleteTooltip(
            tooltips.map((tooltip: TTooltip) => tooltip.status).indexOf(true)
          )
        );
      }, timeOut[0]);
    }
    return () => {
      clearTimeout(timeref.current);
    };
  }, [dispatch, tooltips]);

  return (
    <Styled.Container>
      <TransitionGroup>
        {tooltips.map(
          (tooltip: TTooltip, index: number) =>
            tooltip.status && (
              <CSSTransition
                key={`tooltip-${index}`}
                timeout={300}
                classNames="slideup"
              >
                <Styled.TooltipContainer>
                  <Styled.Tooltip color={status[tooltip.type]}>
                    {tooltip.message}
                  </Styled.Tooltip>
                </Styled.TooltipContainer>
              </CSSTransition>
            )
        )}
      </TransitionGroup>
    </Styled.Container>
  );
};

export default Tooltips;
