import { AudioPlayerContext } from "Contexts/AudioPlayerContext";
import React, { useContext, useEffect, useState } from "react";
import { FaPlay, FaPause } from "react-icons/fa";

import * as Styled from "./styles";

export const PlayPauseButton: React.FC = () => {
  const {
    currentURL,
    setCurrentURL,
    setIsOpen,
    isOpen,
    isPlaying,
    setIsPlaying
  } = useContext(AudioPlayerContext);

  const togglePlayPause = () => {
    setIsPlaying(previous => !previous);
  };

  return (
    <Styled.Button onClick={togglePlayPause}>
      {isPlaying ? <FaPause /> : <FaPlay className="play" />}
    </Styled.Button>
  );
};

export default PlayPauseButton;
