import styled from "styled-components";

export const Fields = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

export const SearchCategory = styled.div``;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media ${({ theme }) => theme.devices.tablet} {
    justify-content: center;
  }
`;

export const Container = styled.div``;

export const Content = styled.div`
  border-radius: 5px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white.normal};
  margin: 0 auto;
  padding: 0;
`;

export const Body = styled.div`
  padding: 2px 2.5rem;
  margin-bottom: 20px;
`;

export const List = styled.ul`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  padding: 0;

  justify-content: center;
  align-items: center;

  border: 1px solid #d2d2d2;

  border-radius: 5px;
  min-width: 21rem;
`;

interface ListItemsProps {
  selected?: boolean;
}
export const ListItems = styled.li<ListItemsProps>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 45px;
  width: 100%;
  padding: 0 10px;

  cursor: pointer;

  background: ${({ selected, theme }) =>
    selected ? theme.colors.green.normal : theme.colors.white.normal};
  color: ${({ selected, theme }) =>
    selected ? theme.colors.white.normal : theme.colors.gray.dark};
  border-bottom: 1px solid #d2d2d2;

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-bottom: none;
    border-radius: 0 0 4px 4px;
  }

  svg:first-child {
    color: ${({ selected, theme }) =>
      selected ? theme.colors.white.normal : theme.colors.green.normal};
  }

  &:hover {
    filter: brightness(0.9);
  }
`;

export const ListTitle = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
    font-size: 1.2rem;
  }
`;
