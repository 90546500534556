import styled from "styled-components";

export const CallbackContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;
  border-radius: 3px;

  background-color: ${({ theme }) => theme.colors.gray.normal};
  p {
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.colors.white.normal};
    font-weight: bold;
  }
`;
