import Routes from "Routes";
import { IMenuItemModel } from "../index";
import { BiNews } from "react-icons/bi";
import React from "react";

const menu: IMenuItemModel = {
  name: "Notícias",
  icon: <BiNews />,
  link: Routes.articles.replace(":category?", "")
};

export default menu;
