import { useQuery } from "@tanstack/react-query";
import listCategories from "Api/categories/[segment]";
import { IOptions } from "Components/Form/Inputs/CheckboxSearch";
import arrayToString from "Helpers/arrayToString";
import { listCategoriesRequest } from "Services/Category";
import { TError } from "Services/Error/error";
import { useParams } from "react-router-dom";

interface UseCategoriesProps extends listCategoriesRequest {
  selectedCategories: IOptions[];
}

interface Params {
  category: string;
}

export default function useCategories({
  selectedCategories,
  ...queryParams
}: UseCategoriesProps) {
  const { category: categoryParams = "" } = useParams<Params>();

  const query = () => listCategories(queryParams);

  const { data, isError } = useQuery({
    queryKey: ["listCategories", ...Object.values(queryParams)],
    queryFn: query,
    keepPreviousData: true
  });

  const categoriesError: TError = {
    status: isError,
    message: isError ? "Erro no servidor, tente novamente mais tarde" : ""
  };

  const categoriesOptions = data
    ? data
        .map(
          (item): IOptions => ({
            id: item.id,
            name: item.title,
            subvalue: item.slug,
            qty: item.adsQty
          })
        )
        .filter(
          category =>
            selectedCategories.filter(
              (item: IOptions) => item.id === category.id
            ).length === 0
        )
    : [];

  const categoryByUrl = data && data.find(item => item.slug === categoryParams);

  const categoriesFilter = categoryByUrl
    ? categoryByUrl.id
    : arrayToString(selectedCategories);

  return {
    categoriesFilter,
    categoryByUrl,
    categoriesOptions,
    categoriesError
  };
}
