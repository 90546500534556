import HiddenField from "Components/Form/Inputs/Hidden";
import React, {
  createRef,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { TError } from "Services/Error/error";
import * as Styled from "./styles";
import FormContext from "Components/Form/context";

type TChangeEvent = React.ChangeEvent<HTMLInputElement>;
type TFocusEvent = React.FocusEvent<HTMLInputElement>;

interface Props {
  name: string;
  label?: string;
  detailsLabel?: string;
  suffix?: string;
  required?: boolean;
  isCurrency?: boolean;
  defaultValue?: string | number;
  decimalPlaces?: number;
  allowNegative?: boolean;
  autoFocus?: boolean;
  onChange?(e: TChangeEvent): void;
  onBlur?(e: boolean): void;
}

const NumberInput: React.FC<Props> = ({
  name,
  label,
  detailsLabel,
  suffix = "",
  required = false,
  isCurrency = false,
  defaultValue,
  decimalPlaces = 2,
  allowNegative = false,
  autoFocus = false,
  onChange,
  onBlur
}) => {
  const { registerField, deleteField, setFieldError } = useContext(FormContext);
  const inputRef = useMemo(() => createRef<HTMLInputElement>(), []);

  useEffect(() => {
    registerField &&
      name &&
      inputRef.current &&
      registerField(name, inputRef.current, false, false);
    return () => {
      deleteField && deleteField(name);
    };
  }, [inputRef, name, registerField, deleteField, required]);

  const [error, setError] = useState<TError>(
    required && defaultValue === ""
      ? {
          message: "Campo Obrigatório",
          status: true
        }
      : {
          message: "",
          status: false
        }
  );

  useEffect(() => {
    setError(
      required && defaultValue === ""
        ? {
            message: "Campo Obrigatório",
            status: true
          }
        : {
            message: "",
            status: false
          }
    );
  }, [defaultValue, required]);

  const changeHandler = (event: TChangeEvent) => {
    onChange && event && onChange(event);
    if (required && !event) {
      setError({ message: "Campo Obrigatório", status: true });
    } else {
      setError({ message: "", status: false });
    }
  };
  useEffect(() => {
    required && setFieldError && setFieldError(name, error);
  }, [defaultValue, error, name, required, setFieldError]);

  const blurHandler = (e: string) => {
    if (e.length === 0) {
      onBlur && onBlur(false);
    } else {
      onBlur && onBlur(true);
    }
  };

  return (
    <>
      <Styled.Fieldset>
        {label && <Styled.Label htmlFor={name}>{label}</Styled.Label>}
        {detailsLabel && (
          <Styled.DetailsLabel>{detailsLabel}</Styled.DetailsLabel>
        )}
        <Styled.CurrencyCustomInput
          id={name}
          name={name}
          value={defaultValue}
          onChangeEvent={(event: TChangeEvent) => changeHandler(event)}
          onBlur={(event: TFocusEvent) => blurHandler(event.target.value)}
          precision={decimalPlaces}
          decimalSeparator=","
          thousandSeparator="."
          allowNegative={allowNegative}
          allowEmpty={false}
          prefix={isCurrency ? "R$ " : ""}
          suffix={suffix}
          autoFocus={autoFocus}
          required
        />
      </Styled.Fieldset>
      {defaultValue ? (
        <HiddenField name={name} value={defaultValue} />
      ) : (
        <HiddenField name={name} value={""} />
      )}
      <Styled.Error valid={!error.status}>{error.message}</Styled.Error>
    </>
  );
};

export default NumberInput;
