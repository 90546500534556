import { IValidation } from "./";

const Validation: IValidation = {
  error: "Por favor, digite um CPF ou CNPJ válido",
  validate: (value: string) => {
    if (value.length > 15)
      return /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/g.test(
        value.replace("_", "")
      );
    if (typeof value !== "string") return false;
    const cpf = value.replace(/[^\d]+/g, "");
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
    const cpfNumber = cpf.split("").map(el => +el);
    const rest = (count: number) =>
      ((cpfNumber
        .slice(0, count - 12)
        .reduce((soma, el, index) => soma + el * (count - index), 0) *
        10) %
        11) %
      10;
    return rest(10) === cpfNumber[9] && rest(11) === cpfNumber[10];
  }
};

export default Validation;
