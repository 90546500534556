import { ActionType } from "typesafe-actions";

import types from "./types";
import * as actions from "./actions";
import { TProfession } from "Services/User/user";
export type TAuthAction = ActionType<typeof actions>;

export interface IUserStore {
  avatar: string;
  email: string;
  name: string;
  newsletter: boolean;
  email_confirmation: boolean;
  id: string;
  phone: string;
  profession: TProfession;
}

export interface ISubscriptionsStore {
  id: string;
  canceled_at: string;
  current_price: string;
  end_date: string;
  interval: string;
  on_trial: boolean;
  plan_id: string;
  start_date: string;
  status: string;
}
export interface IAuthModel {
  authenticating: boolean;
  authenticated: boolean;
  token: string;
  refreshToken?: string;
  user?: IUserStore | null;
  subscriptions?: ISubscriptionsStore[] | null;
  error?: string[];
}

export interface BaseAction {
  type: string;
  payload?: any;
}

export const initialState: IAuthModel = {
  authenticating: true,
  authenticated: false,
  token: "",
  user: null,
  subscriptions: null
};

// TODO: Type the return of the reducer
const AuthReducer = (state: IAuthModel = initialState, action: BaseAction) => {
  switch (action.type) {
    case types.AUTHENTICATING:
      return { ...state, authenticating: true };
    case types.AUTH_TOKEN:
      return { ...state, authenticating: true };
    case types.MAIL_CONFIRM:
      return { ...state, authenticating: true };
    case types.ENABLE_TOKEN:
      return { ...state };
    case types.AUTHENTICATE:
      return {
        ...state,
        ...action.payload,
        error: null,
        authenticated: true,
        authenticating: false
      };
    case types.UNAUTHENTICATE:
      return { ...state, authenticating: true };
    case types.AUTHENTICATE_SOCIAL:
      return {
        ...state,
        ...action.payload,
        error: null,
        authenticated: true,
        authenticating: false
      };
    case types.UNAUTHENTICATED:
      return {
        ...state,
        error: undefined,
        authenticated: false,
        authenticating: false,
        user: null
      };
    case types.AUTHENTICATE_ERROR:
      return { ...state, error: action.payload, authenticating: false };
    case types.UPDATE_USER:
      return { ...state, user: action.payload.user };
    case types.UPDATE_AVATAR:
      return {
        ...state,
        user: { ...state.user, avatar: action.payload.avatar }
      };
  }

  return state;
};

export default AuthReducer;
