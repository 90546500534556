import Loading from "Components/Loading";
import Modal from "Components/Modal";
import api from "Helpers/Api";
import removeRegexFromText from "Helpers/removeRegex";
import stringPrice from "Helpers/stringPrice";
import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import { useSelector } from "react-redux";
import { ReducerState } from "Redux/store";
import { IAd } from "Services/Ad/ad";
import * as Styled from "./styles";

interface AdItemProps {
  item: IAd;
  link: string;
  viewCount?: boolean;
}

export default function AdItem({ item, link, viewCount = false }: AdItemProps) {
  const [modal, setModal] = useState<boolean>(false);

  const token: string = useSelector((state: ReducerState) => state.auth.token);

  api.defaults.headers["Authorization"] = `bearer ${token}`;

  const handleAplicarDestaqueClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setModal(true);
  };

  const handleEfetivarDestaque = async (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    try {
      await api.post<void>(`/useradplans/apply/${id}`);
      handleCloseModalAplicarDestaque(e);
    } catch (e) {
      console.error(e);
    }
  };

  const handleCloseModalAplicarDestaque = (e?: React.MouseEvent) => {
    e?.preventDefault();
    setModal(false);
  };

  return (
    <Styled.Container to={link}>
      {item.possuiDestaque && <Styled.Destaque>Destaque</Styled.Destaque>}
      {viewCount && (
        <Styled.ViewCount>
          <Styled.Views>{item.views} visualizações</Styled.Views>
          <Styled.Contacts>{item.contacts} contatos</Styled.Contacts>
        </Styled.ViewCount>
      )}
      <Styled.Content>
        <Styled.Picture>
          {item.images && item.images.length > 0 ? (
            <LazyLoad
              placeholder={<Loading />}
              height={200}
              offset={[-200, 0]}
              once
              style={{ width: "100%" }}
            >
              <Styled.Image src={item.images[0].image} alt={item.slug} />
            </LazyLoad>
          ) : (
            <Styled.Placeholder />
          )}
        </Styled.Picture>
        <Styled.InfoContainer>
          <Styled.Info>
            <Styled.Title>
              {String(removeRegexFromText(item.title)).toUpperCase()}
            </Styled.Title>

            {window.location.href.indexOf("minha-conta") !== -1 &&
              !item.possuiDestaque && (
                <span onClick={handleAplicarDestaqueClick}>
                  Aplicar destaque
                </span>
              )}

            {/* <Styled.Category>
              {item.category}
              {item.category && item.subcategory ? " - " : ""}
              {item.subcategory}
            </Styled.Category>
            <Styled.Vocation>
              {item.vocation && item.vocation.pt}
            </Styled.Vocation>

            <Styled.Location>
              {item.size !== 0 && item.size && item.measure && (
                <Styled.LocationInfo>
                  {formatNumber(item.size)} {item.measure && item.measure.abr}
                </Styled.LocationInfo>
              )}
              <Styled.LocationInfo>
                {item.city}
                {item.city && item.state ? "/" : ""}
                {item.state}
              </Styled.LocationInfo>
              <Styled.LocationInfo>
                {item.years && item.years.manufacturedYear}
                {item.years && item.years.modelYear ? "/" : ""}
                {item.years && item.years.modelYear}
              </Styled.LocationInfo>
            </Styled.Location> */}
          </Styled.Info>
        </Styled.InfoContainer>
        <Styled.Price>
          {item.price && item.price !== 0
            ? stringPrice(item.price / 100)
            : "Ver anúncio"}
        </Styled.Price>
      </Styled.Content>
      {modal && (
        <Modal
          title="Aplicar destaque"
          handleClose={() => handleCloseModalAplicarDestaque()}
        >
          <Styled.ModalContent>
            Deseja realmente aplicar destaque ao anúncio?
            <div>
              <Styled.Button onClick={e => handleCloseModalAplicarDestaque(e)}>
                Cancelar
              </Styled.Button>
              <Styled.Button onClick={e => handleEfetivarDestaque(e, item.id)}>
                Aplicar
              </Styled.Button>
            </div>
          </Styled.ModalContent>
        </Modal>
      )}
    </Styled.Container>
  );
}
