import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;

  display: flex;

  flex-direction: column;
  height: 100%;
  padding: 0 16px;
  border-radius: 30px;
  padding-top: 25px;

  background-color: white;
`;

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  margin-top: 8px;

  padding-inline-start: 20px;
`;

interface RouteBoxProps {
  isActive: boolean;
}

export const RouteBox = styled.div<RouteBoxProps>`
    display: flex;
    position: relative;

    cursor: pointer;

    align-items: center;
    gap: 24px;

    font-size: 1rem;
    color: ${({ theme, isActive }) =>
      !isActive ? theme.colors.gray.normal : theme.colors.green.normal};

    &:after {
        content: "";
        position: absolute;
        width: 4px;
        border-radius: 5px
        height: 25px;
        right: 0;
        background: ${({ theme, isActive }) =>
          isActive ? theme.colors.green.normal : "transparent"};
    
    }
`;

export const LogoContainer = styled.div`
  display: flex;

  flex-direction: column

    gap: 24px

    svg {
    padding: 0 16px;
    width: 200px;
  }
`;

export const LogoLine = styled.div`
  display: flex;
  height: 1px;
  width: 100%;
  background: rgba(135, 140, 189, 0.3);
  margin-bottom: 20px;
`;
