import moment from "moment";
import { TSegmentsName } from "Services/Segment/segment";
import { IAdTracking } from "Services/Tracking/tracking";

enum ITEM_TYPES {
  properties = "IMOV",
  products = "PROD",
  jobs = "JOBS",
  leases = "IMOV",
  articles = "NEWS",
  services = "JOBS",
  vehicles = "VEIC",
  user = "USER"
}

export const createTrack = (tracking: IAdTracking, segment: TSegmentsName) => {
  const defaultTrack = {
    TYPE: tracking.TYPE,
    GA_ID: tracking.GA_ID,
    USER_ID: tracking.USER_ID,
    ITEM_ID: tracking.ITEM_ID,
    ITEM_TYPE: (segment && ITEM_TYPES[segment]) || "",
    DATA: tracking.DATA,
    PAGE_REF: tracking.PAGE_REF,
    TAGS: tracking.TAGS,
    EVENT_TIME: moment().format("YYYY-MM-DD HH:mm:ss")
  };
  if (tracking.DATA) {
    return {
      ...defaultTrack,
      DATA: tracking.DATA
    };
  }
  return defaultTrack;
};
