enum types {
  UPDATE_MOBILE = "UPDATE_MOBILE",
  ADD_TOOLTIP = "ADD_TOOLTIP",
  DELETE_TOOLTIP = "DELETE_TOOLTIP",
  ADD_GAID = "ADD_GAID",
  MODAL = "MODAL",
  UPDATE_TRACKING = "UPDATE_TRACKING",
  ADD_TRACKING = "ADD_TRACKING",
  ADD_RTIME = "ADD_RTIME",
  OPEN_POUP = "OPEN_POUP",
  CLOSE_POUP = "CLOSE_POUP"
}

export default types;
