import React, { ReactNode } from "react";
import * as Styled from "./styles";
import { FaSpinner } from "react-icons/fa";

interface ILoading {
  children?: ReactNode;
  color?: string;
}

const LoadingIcon: React.FC<ILoading> = ({ children, color }) => {
  return (
    <Styled.Container>
      {children}
      <FaSpinner color={color} />
    </Styled.Container>
  );
};

export default LoadingIcon;
