import React, { useState, useEffect } from "react";
import CheckboxSearch from "../CheckboxSearch";
import { TError } from "Services/Error/error";

import api from "Helpers/Api";
import { TCity } from "Services/Location/location";
import { IState } from "../States";
import HiddenField from "../Hidden";

export interface ICity {
  id: number;
  name: string;
  qty?: number;
}

interface Props {
  name: string;
  defaultValue?: ICity[];
  setValue(value: any): void;
  states: IState[];
  segment?: string;
  hiddenLabel?: boolean;
  categories?: string;
  subcategories?: string;
  required?: boolean;
}

export default function Cities({
  setValue,
  states,
  name,
  defaultValue,
  required = false,
  segment,
  hiddenLabel,
  categories,
  subcategories
}: Props) {
  const [search, setSearch] = useState("");
  const [cities, setCities] = useState<ICity[]>([]);
  const [selected, setSelected] = useState<ICity[]>(
    defaultValue ? defaultValue : []
  );
  const [error, setError] = useState<TError>({
    status: false,
    message: ""
  });

  async function queryCities(search: string = "", states: IState[]) {
    try {
      const { data } = await api.get<TCity[]>("/location/cities", {
        params: {
          search,
          states: states.map((state: IState) => state.id).join(","),
          segment,
          categories,
          subcategories
        }
      });
      setCities(
        data.map((city: TCity) => ({
          id: city.id,
          name: city.name,
          qty: city.adsQty
        }))
      );

      setError({
        status: false,
        message: ""
      });
    } catch (error) {
      if (search.length === 0) {
        setError({
          status: true,
          message: "Nenhuma cidade pode ser encontrada."
        });
      } else if (
        search.length !== 0 &&
        error.response &&
        error.response.status === 404
      ) {
        setError({
          status: true,
          message: "Nenhum resultado encontrado."
        });
      } else {
        setError({
          status: true,
          message: "Erro no servidor, tente mais tarde."
        });
      }
    }
  }

  const options = cities.filter(
    (cities: any) =>
      selected.filter((item: ICity) => item.id === cities.id).length === 0
  );

  useEffect(() => {
    queryCities(search, states);
  }, [search, states, categories, subcategories]);

  useEffect(() => {
    setValue(selected);
  }, [selected, setValue]);

  return (
    <>
      <CheckboxSearch
        label={!hiddenLabel ? "Cidades" : ""}
        search={search}
        setSearch={setSearch}
        options={[...selected, ...options.slice(0, 6)]}
        selected={selected}
        setSelected={setSelected}
        placeholder="Digite o nome da cidade"
        showQty={true}
        error={error}
      />

      <HiddenField
        name={name}
        value={selected.map(city => city.id).join(",")}
      />
    </>
  );
}
