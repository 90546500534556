import { IoMdCloseCircleOutline } from "react-icons/io";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";

interface ContainerProps {
  isOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  position: fixed;
  transition: 1s;
  max-height: ${({ isOpen }) => (isOpen ? "150px" : "0")};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  visibility: ${({ isOpen }) => (isOpen ? "1" : "0")};
  pointer-events: ${({ isOpen }) => (isOpen ? "auto" : "none")};
  background-color: #fff;
  width: 100vw;
  bottom: 0;
  left: 0;
  z-index: 9;

  justify-content: center;

  box-shadow: -4px 3px 19px 0px rgba(0, 0, 0, 0.41);
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 8px;
`;

export const AudioContent = styled.div`
  display: flex;

  background: #fff;
  padding: 25px 21px;
  border-radius: 15px;
`;

export const Close = styled(CloseIcon).attrs(props => ({
  fontSize: "small"
}))`
  position: absolute;
  right: 20px;
  color: ${({ theme }) => theme.colors.gray.normal};
  font-size: ${({ theme }) => theme.fontSize.md2};
  font-weight: bold;
  &:hover {
    color: ${({ theme }) => theme.colors.gray.dark};
    text-decoration: none;
    cursor: pointer;
  }

  &:focus {
    color: ${({ theme }) => theme.colors.gray.dark};
    text-decoration: none;
    cursor: pointer;
  }
`;

export const BoletimInformations = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  > p {
    margin: 0;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.gray.dark};
  }
`;

export const InteractiveIcons = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${({ theme }) => theme.colors.gray.normal};
  gap: 12px;

  margin: 0 24px;

  svg {
    &:hover {
      transform: scale(1.2);
    }
  }
`;
