import React, { useState, useCallback } from "react";
import Routes from "Routes";
import api from "Helpers/Api";
import * as Styled from "./styles";
import List from "Components/List";
import { IPagination } from "Services/pagination";
import { TRange } from "Services/pagination";
import PageTitle from "Components/Base/PageTitle";
import Cities, { ICity } from "Components/Form/Inputs/Cities";
import States, { IState } from "Components/Form/Inputs/States";
import RangeField from "Components/Form/Inputs/RangeField";
import { TJob } from "Services/Job/job";
import ListItem from "./ListItem";
import stringRange from "Helpers/stringRange";
import FilterItems from "Components/Filters/FilterItems";

interface Props {
  row?: number;
  perPage?: number;
  hideSearch?: boolean;
  hidePagination?: boolean;
  hideFilters?: boolean;
  inline?: boolean;
  userId?: number;
  segment: "jobs" | "services";
  title?: string;
  published?: boolean;
  link?: string;
  setNoResults?: React.Dispatch<React.SetStateAction<boolean>>;
}

const JobList: React.FC<Props> = ({
  row = 3,
  perPage = 15,
  hideSearch = false,
  hideFilters = false,
  hidePagination = false,
  inline = false,
  userId,
  segment,
  published = true,
  title,
  link = "",
  setNoResults
}) => {
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);
  const [salaryRange, setSalaryRange] = useState<TRange>({ min: 0, max: 0 });
  const [undefinedSalaries, setUndefinedSalaries] = useState<boolean>(true);
  const [queryError, setQueryError] = useState<boolean>(false);

  const query = useCallback(
    async (search: string = "", page: number = 1) => {
      try {
        setQueryError(false);
        const response = api.get<IPagination<TJob>>("/jobs/", {
          params: {
            search,
            segment,
            states: states.map((state: IState) => state.id).join(","),
            cities: cities.map((city: ICity) => city.id).join(","),
            salary: stringRange(salaryRange, setSalaryRange),
            undefinedSalaries,
            published,
            page,
            perPage: perPage,
            userId: userId && userId
          }
        });
        const { data } = await response;
        return data;
      } catch {
        setQueryError(true);
        setNoResults && setNoResults(true);
      }
    },
    [
      segment,
      states,
      cities,
      salaryRange,
      undefinedSalaries,
      published,
      setNoResults,
      perPage,
      userId
    ]
  );

  const segmentName = segment === "jobs" ? "Vagas" : "Serviços";

  return (
    <Styled.Container>
      {title ? (
        <PageTitle>{title}</PageTitle>
      ) : (
        <PageTitle link={link}>{segmentName}</PageTitle>
      )}
      <List
        query={query}
        hideSearch={hideSearch}
        hideFilters={hideFilters}
        hidePagination={hidePagination}
        seeMoreLink={link}
        seeMoreText={`Ver mais ${segmentName.toLowerCase()}`}
        inline={inline}
        segment={segment}
        row={row}
        itemlayout={(item: TJob) => (
          <ListItem
            viewCount={userId ? true : false}
            data={item}
            link={
              segment === "jobs"
                ? Routes.job.replace(":slug", item.slug ? item.slug : "")
                : Routes.service.replace(":slug", item.slug ? item.slug : "")
            }
          />
        )}
        queryError={queryError}
        setNoResults={setNoResults}
      >
        <Styled.Filter>
          <FilterItems title="Estados">
            <States
              name="states"
              setValue={setStates}
              segment={segment}
              label=""
            />
          </FilterItems>
          <FilterItems title="Cidades">
            <Cities
              name="cities"
              setValue={setCities}
              states={states}
              segment={segment}
              hiddenLabel={true}
            />
          </FilterItems>
          <FilterItems title="Salário">
            <RangeField
              title=""
              name="salary"
              isCurrency={true}
              change={setSalaryRange}
              values={salaryRange}
              undefinedValues={undefinedSalaries}
              setUndefinedValues={setUndefinedSalaries}
              undefinedValuesMessage={`Incluir ${
                segment === "jobs" ? "vagas" : "serviços"
              } com salário a combinar`}
            />
          </FilterItems>
        </Styled.Filter>
      </List>
    </Styled.Container>
  );
};

export default JobList;
