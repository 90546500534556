import api from "Helpers/Api";
import { CampaignsListResponse } from "Services/Campaigns/campaigns";

export default async function campaignAddImage(
  campaignId: string,
  formData: FormData
) {
  const { data } = await api.post<CampaignsListResponse>(
    `/campaign/image/${campaignId}`,
    formData
  );

  return data;
}
