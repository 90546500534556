import React, { useCallback, useEffect, useState } from "react";

import * as Styled from "./styles";
import ButtonPrimary from "Components/Buttons/Primary";
import { useHistory } from "react-router-dom";

const StoresHome: React.FC = () => {
  const history = useHistory();
  // TODO: When have stores enough make stores section to home
  return (
    <>
      <Styled.Section>
        <Styled.Title>Lojas oficiais</Styled.Title>
        <Styled.Description>
          Torne sua loja parte da nossa lista de fornecedores e eleve seu
          negócio.
        </Styled.Description>
        <Styled.Stores>
          <Styled.StoreLogo
            src="https://storage.googleapis.com/rurax/docs/agros.png"
            alt="Logo da loja"
          />
        </Styled.Stores>
        <Styled.ButtonContainer>
          <ButtonPrimary
            type="button"
            color="orange"
            action={() => history.push("/lojas-online")}
          >
            Quero cadastrar minha loja.
          </ButtonPrimary>
        </Styled.ButtonContainer>
      </Styled.Section>
    </>
  );
};

export default StoresHome;
