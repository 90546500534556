import { useQuery } from "@tanstack/react-query";
import fairsList from "Api/fairs/list";
import { FairsListRequest } from "Services/Fairs/fairs";

export default function useFairs(params: FairsListRequest) {
  const query = () => fairsList(params);

  return useQuery({
    queryKey: ["fairsList", ...Object.values(params)],
    queryFn: query,
    keepPreviousData: true
  });
}
