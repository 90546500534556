import React, { useState, ReactElement, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReducerState } from "Redux/store";
import * as Styled from "./styles";
import Filters from "Components/Filters";
import Loading from "Components/Loading";
import { useLocation } from "react-router-dom";
import { TSegmentsName } from "Services/Segment/segment";
import ListItem from "./ListItem";
import SeeMore from "Components/Buttons/SeeMore";
import { UseQueryResult } from "@tanstack/react-query";
import { IPagination } from "Services/pagination";
import { IAdTracking } from "Services/Tracking/tracking";
import { createTrack } from "Helpers/tracking";
import localeActions from "Redux/Locale/actions";
import { TSortingValue } from "Components/Sorting";
import Pagination2 from "Components/Pagination2";

interface List2Props<TItem> {
  children?: ReactElement;
  segment?: TSegmentsName;
  useData(params: any): UseQueryResult<IPagination<TItem>, unknown>;
  dataParams: any;
  ItemLayout: React.FC<TItem>;
  hideSearch?: boolean;
  hidePagination?: boolean;
  hideFilters?: boolean;
  seeMoreLink?: string;
  seeMoreText?: string;
  scrollToTopOnPageChange?: boolean;
  filters?: { [key: string]: any };
  sorting?: TSortingValue;
  inline?: boolean;
  row?: number;
  hover?: boolean;
  size?: number;
}

export default function List2<TItem>({
  children,
  segment,
  useData,
  dataParams,
  ItemLayout,
  hideSearch = false,
  hidePagination = false,
  hideFilters = false,
  seeMoreLink,
  seeMoreText = "Ver mais",
  scrollToTopOnPageChange = true,
  filters,
  sorting,
  inline = false,
  row = 3,
  hover = true,
  size
}: List2Props<TItem & { id?: string }>) {
  const location = useLocation();
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);
  const searchParams = params.get("s") || "";
  const { mobile } = useSelector((state: ReducerState) => state.locale);
  const { gaId, tracking } = useSelector((state: ReducerState) => state.locale);
  const { user } = useSelector((state: ReducerState) => state.auth);
  const [search, setSearch] = useState(searchParams);
  const [page, setPage] = useState(1);
  const { data: response, isLoading, isError } = useData({
    ...dataParams,
    search,
    ...sorting,
    page
  });

  const data = response && response.data;

  /**TODO
   * This function depends on the states "tracking" and "filters"
   * but because this states are frequently updated
   * this function causes multiple re-renders
   * the current solution is to remove them
   * from the dependency list
   */
  const addListToTracking = useCallback(() => {
    if (segment === "products") {
      const newTracking: IAdTracking[] = data
        ? data
            .filter(
              item =>
                tracking &&
                !tracking.find(trackingItem => trackingItem.ITEM_ID === item.id)
            )
            .map((item, index) => {
              const event: IAdTracking = {
                TYPE: "ITEM_DISPLAY",
                ITEM_TYPE: "PROD",
                GA_ID: gaId,
                USER_ID: user ? user.id : "",
                ITEM_ID: item.id,
                DATA: {
                  POS: `${(index % row) + 1}, ${Math.floor(index / row) + 1}`
                },
                PAGE_REF: location.pathname,
                TAGS: "mode=model1,version=v2,test=a"
              };
              return createTrack(event, segment ? segment : "products");
            })
        : [];

      if (filters) {
        const filtersString = Object.keys(filters)
          .filter(filter => filters[filter])
          .map(filter => `${filter}=${filters[filter]}`)
          .join("&");

        const filter: IAdTracking = {
          TYPE: "PAGE_SEARCH",
          GA_ID: gaId,
          USER_ID: user ? user.id : "",
          DATA: {
            SEARCH: search,
            FILTER: filtersString
          },
          PAGE_REF: location.pathname,
          TAGS: "mode=model1,version=v2,test=a"
        };

        tracking &&
          !tracking.find(
            trackingItem =>
              JSON.stringify(trackingItem) ===
              JSON.stringify(
                createTrack(filter, segment ? segment : "products")
              )
          ) &&
          newTracking.push(createTrack(filter, segment ? segment : "products"));
      }

      dispatch(localeActions.updateTracking([...tracking, ...newTracking]));
    }
  }, [segment, data, dispatch, gaId, user, row, location.pathname, search]);

  useEffect(() => addListToTracking(), [addListToTracking]);

  const addProductToTracking = (index: number, id: string) => {
    if (segment) {
      const event: IAdTracking = {
        TYPE: "ITEM_VIEW",
        GA_ID: gaId,
        USER_ID: user ? user.id : "",
        ITEM_ID: id,
        DATA: {
          POS: `${(index % row) + 1}, ${Math.floor(index / row) + 1}`
        },
        PAGE_REF: location.pathname,
        TAGS: "mode=model1,version=v2,test=a"
      };

      dispatch(
        localeActions.addTracking(
          createTrack(event, segment ? segment : "products")
        )
      );
    }
  };

  const clickOnProduct = (index: number, id: string) => {
    if (segment) {
      const event: IAdTracking = {
        TYPE: "ITEM_CLICK",
        GA_ID: gaId,
        USER_ID: user ? user.id : "",
        ITEM_ID: id,
        PAGE_REF: location.pathname,
        TAGS: "mode=model1,version=v2,test=a"
      };

      dispatch(
        localeActions.addTracking(
          createTrack(event, segment ? segment : "products")
        )
      );
    }
  };

  const placeholderLength =
    !data || data.length % row === 0 ? 0 : ((data.length % row) - row) * -1;

  const createPlaceholders = (number: number) => {
    const placeholders = [];

    for (let i = 1; i <= number; i++) {
      placeholders.push(
        <Styled.EmptyItem
          size={100 / row}
          inline={inline}
          hover={hover}
          isOne={false}
          key={`placeholder-${i}`}
        />
      );
    }

    return placeholders;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setPage(1), [...Object.values(dataParams), search, sorting]);

  return (
    <>
      <Styled.Content>
        {!hideFilters && (
          <Styled.Filters>
            <Styled.SelectFiltersContainer>
              <Filters
                total={data?.length}
                setSearch={setSearch}
                searchHidden={hideSearch}
              >
                {children}
              </Filters>
            </Styled.SelectFiltersContainer>
          </Styled.Filters>
        )}
        {isLoading ? (
          <Loading>{createPlaceholders(12).map(ph => ph)}</Loading>
        ) : (
          <Styled.ListContainer>
            {isError ? (
              <Styled.NoResults>
                <div>Erro no servidor, tente mais tarde</div>
              </Styled.NoResults>
            ) : data && data.length !== 0 ? (
              <>
                <Styled.List
                  inline={inline}
                  size={size}
                  className="products-list"
                >
                  {data &&
                    data.length &&
                    data.map((item: any, key: any) => (
                      //TODO: Fix type error
                      <ListItem
                        isOne={data.length === 1}
                        hover={hover}
                        size={100 / row}
                        inline={inline}
                        key={`list-item-${key}`}
                        onEnterViewport={() =>
                          addProductToTracking(key, item.id)
                        }
                        click={() => clickOnProduct(key, item.id)}
                      >
                        <ItemLayout {...item} />
                      </ListItem>
                    ))}
                  {!mobile &&
                    placeholderLength > 0 &&
                    createPlaceholders(placeholderLength).map(ph => ph)}
                </Styled.List>
                {!hidePagination && (
                  <Pagination2
                    page={page}
                    perPage={response.perPage}
                    lastPage={response.lastPage}
                    onPageChange={(page: number) => {
                      setPage(page);
                      scrollToTopOnPageChange && window.scrollTo(0, 0);
                    }}
                  />
                )}
                {seeMoreLink && seeMoreText && (
                  <SeeMore redirectLink={seeMoreLink}>{seeMoreText}</SeeMore>
                )}
              </>
            ) : (
              <Styled.NoResults>
                {isError ? (
                  <div>Erro no servidor, tente mais tarde</div>
                ) : (
                  <div>Nenhum resultado foi encontrado</div>
                )}
              </Styled.NoResults>
            )}
          </Styled.ListContainer>
        )}
      </Styled.Content>
    </>
  );
}
