import { IMenuItemModel } from "../index";
import Routes from "Routes";

const SiteMap: IMenuItemModel = {
  name: "Mapa do Site",
  link: Routes.leases.replace(":category?", ""),
  submenu: [
    {
      category: "Home",
      link: "/"
    },
    {
      category: "Imóveis Rurais",
      link: Routes.properties.replace(":category?", "")
    },
    {
      category: "Arrendamentos",
      link: Routes.leases.replace(":category?", "")
    },
    {
      category: "Produtos",
      link: Routes.products
        .replace(":category?", "")
        .replace("/:subcategory?", "")
    },
    {
      category: "Vagas",
      link: Routes.jobs
    },
    {
      category: "Serviços",
      link: Routes.services
    },
    {
      category: "Notícias",
      link: Routes.articles.replace(":category?", "")
    }
  ]
};

export default SiteMap;
