import styled from "styled-components";

export const Container = styled.div`
  margin: 0 5px;
  position: relative;
  @media ${({ theme }) => theme.devices.tablet} {
    margin: 10px 0;
    display: flex;
  }
`;

export const Input = styled.input`
  border: none;
  padding: 5px;
  color: ${({ theme }) => theme.colors.gray.dark};
  min-width: 250px;
  border-radius: ${({ theme }) =>
    `${theme.borderRadius} ${theme.borderRadius} 0 0`}
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.dark};
  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
    flex-grow: 1;
  }
`;

export const Select = styled.div`
  border-radius: ${({ theme }) =>
    `0 0 ${theme.borderRadius} ${theme.borderRadius}`}
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white.normal};
  flex-direction: column;
  display: flex;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 2px;
  z-index: 2;
  box-shadow: ${({ theme }) => theme.shadow.vertical};
`;

export const Option = styled.span`
  padding: 5px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray.dark};
  &:hover {
    color: ${({ theme }) => theme.colors.green.dark};
  }
`;
