import api from "Helpers/Api";
import {
  CampaignsListResponse,
  ICampaignUpdate
} from "Services/Campaigns/campaigns";

export default async function campaignUpdate(
  campaignId: string,
  params: ICampaignUpdate
) {
  const { data } = await api.put<CampaignsListResponse>(
    `/campaign/edit/${campaignId}`,
    params
  );
  return data;
}
