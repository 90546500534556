import { ICity } from "Components/Form/Inputs/Cities";
import api from "Helpers/Api";
import { ArticleRequestList, TArticle } from "Services/Article/article";
import { TSection } from "Services/Article/section";
import { IPagination } from "Services/pagination";

export default async function articlesList({
  page,
  perPage,
  search,
  section
}: ArticleRequestList) {
  const { data } = await api.get<IPagination<TArticle>>("/articles/list", {
    params: {
      search: search,
      category: section && section.category,
      tags: section && section.tags,
      page: page,
      perPage: perPage
    }
  });

  return data;
}
