import styled from "styled-components";

interface IForm {
  error: boolean;
  alignItems?: string;
}

export const Form = styled.form<IForm>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems};
  width: 100%;
  .error {
    ${({ error }) =>
      error &&
      "animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;"}
  }
`;

export const Fields = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  & > fieldset {
    flex: 0 0 calc(49% - 25px);
  }
  @media ${({ theme }) => theme.devices.laptop} {
    & > fieldset:nth-child(odd) {
      margin-right: 50px;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;

export const Error = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 20px;
  height: 35px;
  font-size: ${({ theme }) => theme.fontSize.sm};
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.red.normal};
`;
