import email from "./Email";
import cpfcnpj from "./CpfCnpj";
import password from "./Password";
import cep from "./Cep";
import creditCard from "./CreditCard";
import CreditCardDate from "./CreditCardDate";
import phone from "./PhoneNumber";
import url from "./Url";
import date from "./Date";

export interface IValidation {
  error: string;
  validate: {
    (input: string): boolean;
  };
}

interface IValidators {
  [name: string]: IValidation;
}

const validators: IValidators = {
  email,
  cpfcnpj,
  password,
  cep,
  creditCard,
  CreditCardDate,
  phone,
  url,
  date
};

export default validators;
