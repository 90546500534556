import React from "react";

import * as Styled from "./styles";

interface Props {
  src?: string;
}

const Thumbnail: React.FC<Props> = ({ src }) => {
  return (
    <>
      {!src && (
        <Styled.CallbackContainer>
          <p>60x60</p>
        </Styled.CallbackContainer>
      )}
    </>
  );
};

export default Thumbnail;
