import React, { createContext, ReactNode, useContext, useState } from "react";

interface ILoadingContext {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoadingContext = createContext({} as ILoadingContext);

interface Props {
  children: ReactNode;
}

export function LoadingProvider({ children }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const value = { isLoading, setIsLoading };
  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
}

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within LoadingProvider");
  }
  return context;
}
