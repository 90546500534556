import { AxiosResponse } from "axios";
import fieldsTranslation from "../Assets/locales/fieldsTraslations.json";

class BackendErrorHelper {
  checkIfDefaultError(e: any) {
    return e.response && !e.response.data.length && !e.response.data.status;
  }

  getDefaultErrorMessage(errorResponse: AxiosResponse<any> | undefined) {
    return (
      errorResponse &&
      errorResponse.data &&
      errorResponse.data.data &&
      errorResponse.data.data[0]
    );
  }

  checkIfValidationError(e: any) {
    return (
      e.response &&
      e.response.data &&
      e.response.data.length &&
      (e.response.data[0].validation || e.response.data[0].message)
    );
  }

  // TODO: search for way to pass generic type to AxiosError
  getValidatorErrorMessage(errorResponse: AxiosResponse<any> | undefined) {
    if (errorResponse && errorResponse.data) {
      const { message, field } = errorResponse.data[0]; // Return only first
      const translation =
        fieldsTranslation[field as keyof typeof fieldsTranslation];

      return translation ? message.replace(field, translation) : message;
    } else {
      // TODO: add default responde if not exists
      return "Não foi possível processar essa requisição, tente novamente mais tarde.";
    }
  }
}

export default new BackendErrorHelper();
