import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;

export const ArticlesContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: row;
  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;

export const Section = styled.div``;
