import moment from "moment";
import { IValidation } from "./";

const Validation: IValidation = {
  error: "Por favor, digite uma data válida",
  validate: (input: string) => {
    const inputDate = moment(input, "MM/YYYY");
    return inputDate && inputDate.diff(moment(), "months") > 0;
  }
};

export default Validation;
