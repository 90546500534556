import ButtonPrimary from "Components/Buttons/Primary";
import Search from "Components/Form/Inputs/Search";
import Loading from "Components/Loading";
import Pagination2 from "Components/Pagination2";

import useCampaigns from "Hooks/Campaigns/useCampaigns";
import { AdminRoutes } from "Routes";
import React, { useState } from "react";
import { useHistory } from "react-router";
import CampaignItem from "./Campaign";
import * as Styled from "./styles";

const AdminCampaigns: React.FC = () => {
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState("");

  const history = useHistory();

  const { data: campaigns, isLoading, refetch } = useCampaigns();

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleSearch = () => {
    setSearch(searchValue);
  };

  const handleCampaignChange = () => {
    refetch();
  };

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Title>Campanhas</Styled.Title>
        {isLoading ? (
          <Loading />
        ) : (
          <Styled.List>
            <ButtonPrimary
              color="green"
              action={() => history.push(AdminRoutes.campaignsCreate)}
            >
              Adicionar campanha
            </ButtonPrimary>

            {campaigns &&
              campaigns.data &&
              campaigns.data.map(campaign => (
                <CampaignItem
                  key={campaign.id}
                  campaign={campaign}
                  onCampaignChange={handleCampaignChange}
                />
              ))}
            <Pagination2
              lastPage={
                (campaigns && campaigns.data && campaigns.data.lastPage) || 1
              }
              onPageChange={handleChangePage}
              page={page}
              perPage={5}
            />
          </Styled.List>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};

export default AdminCampaigns;
