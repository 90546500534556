import styled from "styled-components";

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.black.normal};
  font-size: ${({ theme }) => theme.fontSize.mm};
  margin: 0 5px;
`;

export const SortContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white.normal};
  border-radius: 5px;
`;
