import { TbRuler2Off } from "react-icons/tb";
import * as Styled from "./styles";
import React, { ReactNode } from "react";

interface Props {
  message: string;
  enable?: boolean;
  children: ReactNode;
}

const Hover: React.FC<Props> = ({
  message,
  enable = TbRuler2Off,
  children
}) => {
  if (!enable) return <>{children}</>;

  return (
    <Styled.TooltipCard>
      <Styled.ChildrenWrapper>{children}</Styled.ChildrenWrapper>
      <Styled.TooltipBox>
        <p>{message}</p>
      </Styled.TooltipBox>
    </Styled.TooltipCard>
  );
};

export default Hover;
