import Pagination from "Components/Pagination";
import { AudioPlayerContext } from "Contexts/AudioPlayerContext";
import { BOLETINS_CATEGORIES } from "Helpers/consts";
import { useBoletins } from "Hooks/Boletins/useBoletins";
import React, { useContext, useEffect, useState } from "react";
import { ReducerState } from "Redux/store";
import { AiOutlineEye, AiOutlineShareAlt } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import FooterDisplay from "../FooterDisplay";
import { Description } from "./Description";
import PlayPauseListButton from "./PlayPauseListButton";

import * as Styled from "./styles";
import Thumbnail from "./Thumbnail";
import localeActions from "Redux/Locale/actions";
import { IPage } from "Services/pagination";

interface Props {
  categories: (string | undefined)[];
  types: (string | undefined)[];
  setResults: React.Dispatch<React.SetStateAction<number>>;
  search: string;
}

const List: React.FC<Props> = ({ categories, types, setResults, search }) => {
  const dispatch = useDispatch();

  const { isOpen, setIsOpen, setIsPlaying, setCurrentBoletim } = useContext(
    AudioPlayerContext
  );
  const mobile = useSelector((state: ReducerState) => state.locale.mobile);

  const [show, setShow] = useState("");
  const [pagination, setPagination] = useState<IPage>({
    page: 1,
    lastPage: 5,
    perPage: 10
  });

  const { data, isLoading, refetch, isError } = useBoletins({
    page: pagination.page,
    categories,
    types,
    search
  });

  useEffect(() => {
    if (data) {
      setResults(Number(data.total));
      setPagination({
        page: pagination.page,
        lastPage: Math.ceil(Number(data.total) / pagination.perPage),
        perPage: 10
      });
    }
  }, [data]);

  const handleSetPage = (page: number) => {
    if (data) {
      setPagination({
        page,
        lastPage: Math.ceil(Number(data.total) / pagination.perPage),
        perPage: 10
      });
    }
  };

  useEffect(() => {
    data &&
      setPagination({
        ...pagination,
        page: 1,
        lastPage: Math.ceil(Number(data.total) / pagination.perPage)
      });
  }, [types, categories]);

  const handleClose = () => {
    setIsOpen(false);
    setIsPlaying(false);
  };

  // TODO: Fazer tipagem
  const handleShowDescription = (e: any, id: string) => {
    e.stopPropagation();
    if (show === id) {
      setShow("");
    } else {
      setShow(id);
    }
  };

  const handleShareBoletim = async (e: any, id?: string) => {
    e.preventDefault();
    e.stopPropagation();
    const currentBoletim = `${process.env.REACT_APP_PUBLIC_URL}/boletins?current=${id}`;
    if (mobile) {
      if (navigator.share) {
        navigator
          .share({
            title: "Compartilhar boletim",
            text: `Aqui temos um boletim que pode te agradar, clique no link para ouvir: ${currentBoletim}`
          })
          .then(() => {
            dispatch(
              localeActions.addTooltip({
                type: "success",
                message: "Compartilhado com sucesso!"
              })
            );
          })
          .catch(e => {
            console.error(e);
            dispatch(
              localeActions.addTooltip({
                type: "error",
                message:
                  "Um problema ocorreu na hora de compartilhar seu boletim."
              })
            );
          });
      }
    } else {
      navigator.clipboard.writeText(
        `Aqui temos um boletim que pode te agradar, clique no link para ouvir: ${currentBoletim}`
      );
      dispatch(
        localeActions.addTooltip({
          type: "success",
          message: "Copiado com sucesso para área de transferência."
        })
      );
    }
  };

  return (
    <>
      <Styled.Container>
        {data && Number(data.total) ? (
          <>
            {data.data.map(boletim => (
              <Styled.Boletim key={boletim.id}>
                <Styled.BoletimCard isOpen={show === boletim.id}>
                  <Styled.BoletimContent>
                    <Styled.BoletimHeader>
                      {!mobile && <Thumbnail src="" />}
                      <Styled.Stack>
                        <Styled.TitleContainer>
                          <Styled.Title>{boletim.title}</Styled.Title>
                        </Styled.TitleContainer>
                        <Styled.Categories>
                          {boletim.categories.split(",").map(category => (
                            <Styled.Category>
                              {
                                BOLETINS_CATEGORIES[
                                  category as keyof typeof BOLETINS_CATEGORIES
                                ]
                              }
                            </Styled.Category>
                          ))}
                        </Styled.Categories>
                      </Styled.Stack>
                    </Styled.BoletimHeader>
                    <Styled.HStack>
                      <Styled.Visualizations>
                        <p>{boletim.plays_count || 0}</p>
                        <AiOutlineEye />
                      </Styled.Visualizations>
                      <Styled.Icons>
                        <AiOutlineShareAlt
                          onClick={e => handleShareBoletim(e, boletim.id)}
                        />
                      </Styled.Icons>
                    </Styled.HStack>
                  </Styled.BoletimContent>
                  <Styled.BoletimAudio>
                    <PlayPauseListButton boletim={boletim} />
                  </Styled.BoletimAudio>
                  <Styled.Icon
                    isOpen={show === boletim.id}
                    onClick={e => handleShowDescription(e, boletim.id)}
                  >
                    <FiChevronDown />
                  </Styled.Icon>
                </Styled.BoletimCard>
                <Description
                  description={boletim.description}
                  show={show === boletim.id}
                />
              </Styled.Boletim>
            ))}
          </>
        ) : (
          <p>Nenhum boletim foi encontrado.</p>
        )}
      </Styled.Container>
      <Pagination setPage={handleSetPage} pagination={pagination} />
      <FooterDisplay isOpen={isOpen} handleClose={handleClose} />
    </>
  );
};

export default List;
