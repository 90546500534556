import AddToCart from "Components/Buttons/AddToCart";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "Helpers/Api";

import cartActions from "Redux/Cart/actions";
import localeActions from "Redux/Locale/actions";
import authActions from "Redux/Auth/actions";
import { TCartItem } from "Services/Cart/cart";
import CounterBtns from "../Counter";
import * as Styled from "./styles";
import { ReducerState } from "Redux/store";
import { useHistory, useLocation } from "react-router-dom";
import { IAdTracking } from "Services/Tracking/tracking";
import { TSegmentsName } from "Services/Segment/segment";
import { createTrack } from "Helpers/tracking";
import BackendErrorHelper from "Helpers/BackendErrorHelper";
import { TVariant, TVariantItem } from "Services/Ad/ad";
import Hover from "Components/Hover";

interface Props extends TCartItem {
  setCartCount?(value: number): void;
  hideBtn?: boolean;
  segment?: TSegmentsName;
  variant?: TVariant | null;
  selectedVariant?: TVariantItem;
  isAvailable?: boolean;
}

type TAddCartResponse = {
  cart: string;
  message: string;
};

const AddToCartBtn: React.FC<Props> = ({
  id,
  qty,
  setCartCount,
  hideBtn = false,
  segment,
  variant,
  selectedVariant,
  isAvailable = true
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const { cartId, cartItems } = useSelector(
    (state: ReducerState) => state.cart
  );
  const { gaId } = useSelector((state: ReducerState) => state.locale);
  const { user, token } = useSelector((state: ReducerState) => state.auth);

  const verifyVariant = (variant: TVariant, selectedVariant?: TVariantItem) => {
    if (!variant) {
      return true;
    }

    if (!selectedVariant) {
      return false;
    }

    const selectedVariantIsValid =
      selectedVariant &&
      variant.items.some(variantItem => variantItem.id === selectedVariant.id);

    const variantHasStock = true; //selectedVariant.stock && selectedVariant.stock >= qty;

    return selectedVariantIsValid && variantHasStock ? true : false;
  };

  const variantIsValid = !variant || verifyVariant(variant, selectedVariant);

  const trackingEvent = () => {
    const event: IAdTracking = {
      TYPE: "ADD_CART",
      GA_ID: gaId,
      USER_ID: user ? user.id : "",
      ITEM_ID: id,
      PAGE_REF: location.pathname,
      TAGS: "mode=model1,version=v2,test=a"
    };
    dispatch(
      localeActions.addTracking(
        createTrack(event, segment ? segment : "products")
      )
    );
  };

  //TODO: Refactor using useMutations
  const addToCartAct = async (id: string, qty: number) => {
    if (!user) {
      delete axios.defaults.headers["Authorization"];
    } else {
      axios.defaults.headers["Authorization"] = `bearer ${token}`;
    }

    setLoading(true);
    try {
      if (!variantIsValid) {
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message: `Por favor, selecione ao menos uma ${(variant &&
              variant.name) ||
              "das variantes"}`
          })
        );
        setLoading(false);
        return;
      }

      if (!loading) {
        const { data } = await axios.post<TAddCartResponse>("/cart/add", {
          productId: id,
          qty,
          cartId,
          variant_item_id: selectedVariant && selectedVariant.id
        });
        dispatch(cartActions.addCart({ id, qty }, data.cart));
        trackingEvent();
        !hideBtn &&
          dispatch(
            localeActions.addTooltip({
              type: "success",
              message: data.message
            })
          );
      }
    } catch (e) {
      if (BackendErrorHelper.checkIfDefaultError(e)) {
        if (e.response.status === 401) {
          dispatch(authActions.unauthenticate());
          history.push("/login");
        } else {
          const message = BackendErrorHelper.getDefaultErrorMessage(e.response);
          dispatch(
            localeActions.addTooltip({
              type: "error",
              message
            })
          );
        }
      } else if (BackendErrorHelper.checkIfValidationError(e)) {
        const message = BackendErrorHelper.getValidatorErrorMessage(e.response);
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else {
        if (e.message === "Network Error") {
          dispatch(
            localeActions.addTooltip({
              type: "error",
              message:
                "Um erro ocorreu. Por favor, verifique sua conexão com a internet."
            })
          );
        } else {
          dispatch(
            localeActions.addTooltip({
              type: "error",
              message:
                "Erro ao adicionar produto ao carrinho, tente novamente mais tarde."
            })
          );
        }
      }
    }
    setLoading(false);
  };

  const updateCartStoreCount = async (value: number) => {
    addToCartAct(id, value);
  };

  const hoverMessage = !isAvailable
    ? "Produto não está disponível em estoque"
    : !variantIsValid
    ? `Por favor selecione um ${variant.name.toLowerCase()}`
    : "";

  return (
    <Styled.CartPlacer loading={loading}>
      <Hover message={hoverMessage} enable={!isAvailable || !variantIsValid}>
        <CounterBtns
          setCartCount={setCartCount ? setCartCount : updateCartStoreCount}
          count={qty}
          isAvailable={isAvailable && variantIsValid}
        ></CounterBtns>
      </Hover>
      {!hideBtn && (
        <Hover message={hoverMessage} enable={!isAvailable || !variantIsValid}>
          <AddToCart
            type="button"
            action={() =>
              isAvailable && variantIsValid && addToCartAct(id, qty)
            }
            isAvailable={isAvailable && variantIsValid}
          >
            {cartItems[id] ? "Atualizar carrinho" : "Adicionar ao carrinho"}
          </AddToCart>
        </Hover>
      )}
    </Styled.CartPlacer>
  );
};

export default AddToCartBtn;
