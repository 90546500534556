import styled from "styled-components";
import { theme } from "theme/theme";

const green = theme.colors.green.normal;
const white = theme.colors.white.normal;
const black = theme.colors.black.normal;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 0.5rem;
  color: ${black};

  margin-top: 16px;

  font-size: ${({ theme }) => theme.fontSize.md};
`;

interface ButtonProps {
  isCurrent?: boolean;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.75rem;
  aspect-ratio: 8/10;
  border: none;
  border: 0.05rem solid ${({ isCurrent }) => (isCurrent ? green : "none")};
  border-radius: 10%;
  background-color: ${({ isCurrent }) => (isCurrent ? green : "auto")};
  color: ${({ isCurrent }) => isCurrent && white};
  cursor: pointer;

  :hover {
    background-color: ${green};
    color: ${white};
  }
`;

export const Text = styled.p`
  margin: 0rem;
  cursor: default;
`;
