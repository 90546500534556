import React, { useState } from "react";
import * as Styled from "./styles";
import PageTitle from "Components/Base/PageTitle";
import Filters from "Components/Filters";
import FilterItems from "Components/Filters/FilterItems";
import Categories from "./Categories";
import { IOptions } from "Components/Form/Inputs/CheckboxSearch";
import Types from "./Types";
import List from "./List";
import { AudioPlayerProvider } from "Contexts/AudioPlayerContext";

interface IBoletimProps {
  message?: string;
}

const Boletins: React.FC<IBoletimProps> = ({ message }) => {
  const [categories, setCategories] = useState<IOptions[]>([]);
  const [types, setTypes] = useState<IOptions[]>([]);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);

  return (
    <AudioPlayerProvider>
      <Styled.Container>
        <PageTitle>Boletins</PageTitle>
        <Styled.Content>
          <Styled.Filters>
            <Filters searchHidden={false} total={total} setSearch={setSearch}>
              <FilterItems title="Categorias">
                <Categories selected={categories} setSelected={setCategories} />
              </FilterItems>
              <FilterItems title="Tipos">
                <Types selected={types} setSelected={setTypes} />
              </FilterItems>
              {/* TODO: Consultores: Como buscar quais consultores? */}
              {/* <FilterItems title="Consultores">
                <p>qualquer coisa</p>
              </FilterItems> */}
            </Filters>
          </Styled.Filters>

          <Styled.BoletimContent>
            <List
              categories={categories.map(current => current.subvalue)}
              types={types.map(current => current.subvalue)}
              setResults={setTotal}
              search={search}
            />
          </Styled.BoletimContent>
        </Styled.Content>
      </Styled.Container>
    </AudioPlayerProvider>
  );
};

export default Boletins;
