import { action } from "typesafe-actions";
import { IUserStore } from "./reducer";

import types from "./types";
import { SocialLoginResponse } from "Api/session/socialLogin";

const authActions = {
  authenticate: (email: string, password: string) =>
    action(types.AUTHENTICATING, { email, password }),
  tokenLogin: (refreshToken: string) =>
    action(types.AUTH_TOKEN, { refreshToken }),
  mailConfirmation: (mailToken: string) =>
    action(types.MAIL_CONFIRM, { mailToken }),
  enableToken: (enableToken: string) =>
    action(types.ENABLE_TOKEN, { enableToken }),
  unauthenticate: () => action(types.UNAUTHENTICATE),
  authenticateSocial: (loginData: SocialLoginResponse) =>
    action(types.AUTHENTICATE_SOCIAL, loginData),
  updateUser: (user: IUserStore) => action(types.UPDATE_USER, { user }),
  resetError: () => action(types.AUTHENTICATE_ERROR, { error: "" }),
  updateAvatar: (avatar: string) => action(types.UPDATE_AVATAR, { avatar })
};

export default authActions;
