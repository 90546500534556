import styled from "styled-components";

type TCartPlacer = {
  loading: boolean;
};

export const CartPlacer = styled.div<TCartPlacer>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};

  @media (max-width: 1282px) {
    flex-direction: column;
  }
`;
