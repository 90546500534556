import axios from "axios";
import config from "config";

const api = axios.create({
  baseURL: config.backend
});

api.defaults.headers.common["Content-Type"] = "application/json";
api.defaults.headers.common["Accept"] = "application/json";
api.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default api;
