enum types {
  AUTHENTICATING = "AUTHENTICATING",
  AUTHENTICATE = "AUTHENTICATE",
  AUTHENTICATE_ERROR = "AUTHENTICATE_ERROR",
  AUTH_TOKEN = "AUTH_TOKEN",
  MAIL_CONFIRM = "MAIL_CONFIRM",
  UNAUTHENTICATE = "UNAUTHENTICATE",
  UNAUTHENTICATED = "UNAUTHENTICATED",
  AUTHENTICATE_SOCIAL = "AUTHENTICATE_SOCIAL",
  ENABLE_TOKEN = "ENABLE_TOKEN",
  UPDATE_USER = "UPDATE_USER",
  UPDATE_AVATAR = "UPDATE_AVATAR"
}

export default types;
