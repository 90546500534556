import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;

  @media ${({ theme }) => theme.devices.mobileL} {
    flex-direction: column;
    margin-top: 10px;
  }
`;

export const FairItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  background-color: ${({ theme }) => theme.colors.white.normal};
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: ${({ theme }) => theme.borderRadius};
  box-sizing: border-box;
  cursor: auto;

  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;

export const FairTitle = styled.h2`
  margin: 0px;
  max-width: 600px;
  color: ${({ theme }) => theme.colors.green.normal};
  font-size: ${({ theme }) => theme.fontSize.md2};
`;

export const FairInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  width: 100%;
  margin-left: 20px;
  padding: 20px;
  font-size: ${({ theme }) => theme.fontSize.mm};
  color: ${({ theme }) => theme.colors.gray.dark};

  @media ${({ theme }) => theme.devices.tablet} {
    margin: 0px;
    padding: 0px 5px;
  }
`;

export const Icon = styled.div`
  > svg {
    margin-right: 15px;
    color: ${({ theme }) => theme.colors.green.light};
  }
`;

export const Location = styled.span`
  padding: 5px 0;
  color: ${({ theme }) => theme.colors.gray.dark};
`;

export const Date = styled.span`
  padding: 5px 0;
  color: ${({ theme }) => theme.colors.gray.dark};
`;

export const Error = styled.span`
  padding: 5px 0;
  color: ${({ theme }) => theme.colors.red.normal};
`;

export const Link = styled.a`
  box-sizing: border-box;
  width: 225px;
  background-color: ${({ theme }) => theme.colors.green.normal};
  color: ${({ theme }) => theme.colors.white.normal};
  font-weight: bold;
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 5px;
  text-decoration: none;
  transition: transform ${({ theme }) => theme.animation.duration};
  will-change: transform;
  transform: scale(1);
  font-size: ${({ theme }) => theme.fontSize.sm};
  align-self: center;
  text-align: center;

  &:hover {
    transform: scale(1.05);
  }

  @media ${({ theme }) => theme.devices.tablet} {
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`;

export const Button = styled.button`
  box-sizing: border-box;
  width: 225px;
  background-color: ${({ theme }) => theme.colors.green.normal};
  color: ${({ theme }) => theme.colors.white.normal};
  font-weight: bold;
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 5px;
  text-decoration: none;
  transition: transform ${({ theme }) => theme.animation.duration};
  will-change: transform;
  transform: scale(1);
  font-size: ${({ theme }) => theme.fontSize.sm};
  align-self: center;
  text-align: center;
  border: none;

  &:hover {
    transform: scale(1.05);
  }

  @media ${({ theme }) => theme.devices.tablet} {
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 10px;

  @media ${({ theme }) => theme.devices.mobileL} {
    margin-top: 10px;
  }
`;

export const DeleteContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;

  align-items: center;
  justify-content: center;

  gap: 16px;

  p {
    font-weight: bold;
    font-size: 1.2rem;

    text-align: center;
  }
`;

export const DeleteContainerButtons = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  gap: 16px;

  buttons {
    width: fit-content;
  }
`;
