import { IMenuItemModel } from "../index";
import Routes from "Routes";

const menu: IMenuItemModel = {
  name: "Arrendamentos",
  link: Routes.leases.replace(":category?", ""),
  submenu: [
    {
      category: "Chácaras",
      link: Routes.leases.replace(":category?", "chacaras")
    },
    {
      category: "Fazendas",
      link: Routes.leases.replace(":category?", "fazendas")
    },
    {
      category: "Frigoríficos",
      link: Routes.leases.replace(":category?", "frigorificos")
    },
    {
      category: "Granjas",
      link: Routes.leases.replace(":category?", "granjas")
    },
    {
      category: "Jazidas",
      link: Routes.leases.replace(":category?", "jazidas")
    },
    {
      category: "Laticínios",
      link: Routes.leases.replace(":category?", "laticinios")
    },
    {
      category: "Ranchos",
      link: Routes.leases.replace(":category?", "ranchos")
    },
    {
      category: "Sítios",
      link: Routes.leases.replace(":category?", "sitios")
    },
    {
      category: "Terrenos/Lotes",
      link: Routes.leases.replace(":category?", "terrenos-lotes")
    },
    {
      category: "Usinas/Destilarias",
      link: Routes.leases.replace(":category?", "usinas-destilarias")
    },
    {
      category: "Outros Imóveis Rurais",
      link: Routes.leases.replace(":category?", "outros-imoveis-rurais")
    }
  ]
};

export default menu;
