import React, { useEffect, useState } from "react";
import * as Styled from "./styles";
import TextInput from "Components/Form/Inputs/Text";
import ButtonPrimary from "Components/Buttons/Primary";
import useFair from "Hooks/Fairs/useFair";
import { useParams } from "react-router";
import RangeDateField from "Components/Form/Inputs/RangeDateField";

import AutoComplete from "Components/Form/Inputs/AutoComplete";
import { queryStates } from "Helpers/citiesAndStatesQuery";
import api from "Helpers/Api";
import Form from "Components/Form";
import fairUpdate from "Api/fairs/update";
import { useDispatch } from "react-redux";
import BackendErrorHelper from "Helpers/BackendErrorHelper";
import localeActions from "Redux/Locale/actions";

type TParams = {
  fairId: string;
};

const AdminFairsEdit: React.FC = () => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [state, setState] = useState<any | null>(null);
  const [city, setCity] = useState<any | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const { fairId } = useParams<TParams>();
  const dispatch = useDispatch();

  const { data: fair } = useFair(fairId);

  const handleEndDate = (e: Date) => {
    setEndDate(e);
  };

  const handleStartDate = (e: Date) => {
    setStartDate(e);
  };

  useEffect(() => {
    if (fair) {
      setStartDate(new Date(fair.start_date));
      setEndDate(new Date(fair.end_date));
      fair.image_url && setImagePreview(fair.image_url);
    }
  }, [fair]);

  const queryCities = async (search = "") => {
    const { data } = await api.get("/location/cities", {
      params: { search, states: state ? state.value : "" }
    });
    return data
      .map((city: any) => ({ name: city.name, value: city.id }))
      .slice(0, 4);
  };

  const onChangeForm = async (inputs: any) => {
    try {
      const params = {
        ...inputs,
        startDate: startDate,
        endDate: endDate
      };

      const response = await fairUpdate(fairId, params);
      response &&
        dispatch(
          localeActions.addTooltip({
            type: "success",
            message: "Feira atualizada com sucesso!"
          })
        );
    } catch (error) {
      if (BackendErrorHelper.checkIfDefaultError(error)) {
        const message = BackendErrorHelper.getDefaultErrorMessage(
          error.response
        );
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else if (BackendErrorHelper.checkIfValidationError(error)) {
        const message = BackendErrorHelper.getValidatorErrorMessage(
          error.response
        );
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else {
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message: "Erro ao recuperar o carrinho"
          })
        );
      }
    }
  };

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Title>Editar Feira</Styled.Title>
        {fair && (
          <Form submit={onChangeForm}>
            <TextInput
              label="Nome da Feira"
              name="title"
              defaultValue={fair.title}
              placeholder="Digite o nome da feira"
            />
            <Styled.RangeDate>
              <RangeDateField
                maxDate={new Date(8640000000000000)}
                minDate={new Date(-8640000000000000)}
                labelStartDate="Início"
                labelEndDate="Fim"
                name="date"
                initialStartDate={startDate}
                endDate={endDate}
                changeEndDate={handleEndDate}
                changeStartDate={handleStartDate}
              />
            </Styled.RangeDate>
            {/* <Styled.LocalizationContainer>
              <Styled.Item>
                <Styled.InputTitle>Estado:</Styled.InputTitle>
                <AutoComplete
                  name="state_id"
                  select={queryStates}
                  update={setState}
                  value={fair.state ? fair.state.name : ""}
                  placeholder="Digite seu estado"
                />
              </Styled.Item>
              <Styled.Item>
                <Styled.InputTitle>Cidade:</Styled.InputTitle>
                <AutoComplete
                  name="city_id"
                  select={queryCities}
                  update={setCity}
                  value={fair.city ? fair.city.name : ""}
                  placeholder="Digite sua cidade"
                />
              </Styled.Item>
            </Styled.LocalizationContainer> */}
            <TextInput
              label="Site"
              name="site"
              placeholder="Digite o site"
              defaultValue={fair.site}
            />
            <Styled.ImageContainer>
              <TextInput
                label="Link da imagem-banner."
                name="imageUrl"
                placeholder="Digite o link da imagem aqui."
                defaultValue={fair.image_url}
                change={value => setImagePreview(value)}
              />
              {imagePreview && <Styled.Preview src={imagePreview} />}
            </Styled.ImageContainer>
            <Styled.ButtonContainer>
              <ButtonPrimary type="submit" color="orange">
                Salvar
              </ButtonPrimary>
            </Styled.ButtonContainer>
          </Form>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};

export default AdminFairsEdit;
