import React, { useContext, useEffect, createRef, useMemo } from "react";

import FormContext from "Components/Form/context";

interface Props {
  name: string;
  value: string | number;
}

const HiddenField: React.FC<Props> = ({ name, value }) => {
  const { registerField, deleteField } = useContext(FormContext);
  const inputRef = useMemo(() => createRef<HTMLInputElement>(), []);

  useEffect(() => {
    registerField &&
      inputRef.current &&
      registerField(name, inputRef.current, false, false);
    return () => {
      deleteField && deleteField(name);
    };
  }, [inputRef, name, registerField, deleteField]);

  return <input type="hidden" value={value} ref={inputRef} />;
};

export default HiddenField;
