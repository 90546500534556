import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  bottom: 50px;
  right: 30px;
  z-index: 9999;
`;

interface ITooltipProps {
  color: "green" | "orange" | "red";
}

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Tooltip = styled.div<ITooltipProps>`
  background-color: ${({ theme, color }) => theme.colors[color].normal};
  color: ${({ theme }) => theme.colors.white.normal};
  padding: 10px 15px;
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.shadow.bottomRight};
  text-align: center;
`;
