import React, { useState } from "react";
import * as Styled from "./styles";
import { useLastQuotations } from "Hooks/Quotations/useLastQuotations";

const quotationSize = 720;
const quotationQty = 6;

const Ticker: React.FC = () => {
  const { data } = useLastQuotations();
  const [isPaused, setIsPaused] = useState(false);

  const rotate =
    (quotationSize * quotationQty * 100) / Number(window.screen.width);

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  return (
    <Styled.TickerWrapper>
      <Styled.TickerContent
        paused={isPaused}
        rotate={rotate}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {data &&
          data.map(quotation => (
            <Styled.TickerQuotation>
              {quotation.index} - R$ {quotation.price}{" "}
              <Styled.Variation
                value={
                  quotation.variation
                    ? Number(quotation.variation.replace(",", "."))
                    : 0
                }
              >
                {quotation.variation && quotation.variation}
              </Styled.Variation>
              <Styled.Infos>
                <Styled.Date>{quotation.close}</Styled.Date>
                <Styled.Region>{quotation.region}</Styled.Region>
              </Styled.Infos>
            </Styled.TickerQuotation>
          ))}
      </Styled.TickerContent>
    </Styled.TickerWrapper>
  );
};

export default Ticker;
