import { AudioPlayerContext } from "Contexts/AudioPlayerContext";
import React, { useContext, useEffect, useState } from "react";
import { FaPlay, FaPause } from "react-icons/fa";

import * as Styled from "Components/AudioPlayer/PlayerPauseButton/styles";
import api from "Helpers/Api";

interface Props {
  boletim: {
    id: string;
    url: string;
    title: string;
  };
}

export const PlayPauseListButton: React.FC<Props> = ({ boletim }) => {
  const {
    currentBoletim,
    setCurrentBoletim,
    setIsOpen,
    isOpen,
    isPlaying: audioPlaying,
    setIsPlaying: setAudioIsPlaying
  } = useContext(AudioPlayerContext);

  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (currentBoletim && currentBoletim.id !== boletim.id && audioPlaying) {
      setIsPlaying(false);
    }
  }, [currentBoletim]);

  useEffect(() => {
    if (currentBoletim && currentBoletim.id === boletim.id) {
      setIsPlaying(audioPlaying);
    }
  }, [audioPlaying]);

  const togglePlayPause = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!isOpen) {
      setIsOpen(true);
    }

    if (isPlaying && currentBoletim && currentBoletim.id === boletim.id) {
      setAudioIsPlaying(previous => !previous);
    } else {
      setAudioIsPlaying(true);
      setIsPlaying(true);
      setCurrentBoletim({
        ...boletim
      });
    }
  };

  return (
    <Styled.Button onClick={togglePlayPause}>
      {isPlaying ? <FaPause /> : <FaPlay className="play" />}
    </Styled.Button>
  );
};

export default PlayPauseListButton;
