import { FaBars, FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column-reverse;
    padding-bottom: 20px;
  }
`;

export const Welcome = styled.span`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.gray.dark};
  font-weight: 600;
  display: flex;
  padding-bottom: 5px;

  @media ${({ theme }) => theme.devices.tablet} {
    margin: 0 auto;
  }
`;

export const Auth = styled.div`
  padding-right: 20px;
  padding-left: 5px;
  display: flex;
  @media ${({ theme }) => theme.devices.tablet} {
    padding-right: 0;
    margin: 10px 0 20px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const AuthLinks = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: 600;

  @media ${({ theme }) => theme.devices.tablet} {
    display: flex;
    justify-content: space-between;
    margin: 10px 45px;
  }
`;

export const MyAccount = styled.span`
  text-decoration: none;
  color: ${props => props.theme.colors.green.normal};
  cursor: pointer;
  opacity: 1;
  padding-right: 20px;

  &:hover {
    opacity: 0.8;
  }
`;

export const Logout = styled.a`
  text-decoration: none;
  color: ${props => props.theme.colors.red.normal};
  cursor: pointer;
  padding-left: 5px;
  opacity: 1;
  &:hover {
    opacity: 0.8;
  }
`;

export const Picture = styled(Link)`
  background-color: ${props => props.theme.colors.gray.light};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid ${props => props.theme.colors.green.light};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  @media ${({ theme }) => theme.devices.tablet} {
    width: 80px;
    height: 80px;
    margin-top: 5px;
  }
`;

export const Avatar = styled.img`
  object-fit: cover;
  width: 100%;
`;

export const Placeholder = styled(FaUser).attrs(props => ({
  icon: FaUser
}))`
  font-size: 30px;
  margin: 0 auto;
  color: ${props => props.theme.colors.gray.normal};
`;

export const Login = styled(Link)`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.sm};
  text-decoration: none;
  font-weight: 600;
  color: ${props => props.theme.colors.gray.dark};

  @media ${({ theme }) => theme.devices.tablet} {
    margin: 30px 25px;
    font-size: ${({ theme }) => theme.fontSize.md};
    color: ${props => props.theme.colors.white.normal};
  }
`;

export const BarsIcon = styled(FaBars).attrs(props => ({
  icon: FaBars
}))`
  font-size: 25px;
  margin: 0 auto;
  cursor: pointer;
  color: ${props => props.theme.colors.gray.normal};
  @media ${({ theme }) => theme.devices.tablet} {
    display: none;
  }
`;
