import api from "Helpers/Api";

export default async function campaignEnable(campaignId: string, date: string) {
  const requestBody = {
    expires_at: date
  };
  const { data } = await api.put(`/campaign/enable/${campaignId}`, requestBody);

  return data;
}
