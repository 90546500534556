import ButtonPrimary from "Components/Buttons/Primary";
import TextInput from "Components/Form/Inputs/Text";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import * as Styled from "./styles";

import campaignUpdate from "Api/campaigns/update";
import Form from "Components/Form";
import Loading from "Components/Loading";
import BackendErrorHelper from "Helpers/BackendErrorHelper";
import useCampaign from "Hooks/Campaigns/useCampaign";
import localeActions from "Redux/Locale/actions";
import moment from "moment";
import { useDispatch } from "react-redux";
import Select from "Components/Form/Inputs/Select";
import { useHistory } from "react-router-dom";

type TParams = {
  campaignId: string;
};

const AdminCampaignsEdit: React.FC = () => {
  const history = useHistory();

  const { campaignId } = useParams<TParams>();
  const [expirationDate, setExpirationDate] = useState<Date>(new Date());
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [campaignName, setCampaignName] = useState<string>();
  const [campaignCode, setCampaignCode] = useState<string>();
  const [campaignType, setCampaignType] = useState<string>();
  const [campaignDescription, setCampaignDescription] = useState<string>();
  const [campaignText, setCampaignText] = useState<string>();
  const [redirectLink, setRedirectLink] = useState<string>();
  const dispatch = useDispatch();
  const { data: campaign, isLoading, error } = useCampaign(campaignId);
  const bannerTypes = [
    {
      value: "popup",
      name: "popup"
    },
    {
      value: "banner",
      name: "banner"
    }
  ];
  const onChangeForm = async (inputs: any) => {
    try {
      const response = await campaignUpdate(campaignId, inputs);
      response &&
        dispatch(
          localeActions.addTooltip({
            type: "success",
            message: "Campanha atualizada com sucesso!"
          })
        );
      history.push(`/admin/campaigns`);
    } catch (error) {
      if (BackendErrorHelper.checkIfDefaultError(error)) {
        const message = BackendErrorHelper.getDefaultErrorMessage(
          error.response
        );

        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else if (BackendErrorHelper.checkIfValidationError(error)) {
        const message = BackendErrorHelper.getValidatorErrorMessage(
          error.response
        );

        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else {
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message: "Erro ao editar campanha"
          })
        );
      }
    }
  };

  useEffect(() => {
    if (campaign) {
      setCampaignName(campaign.campaign_name);
      setCampaignCode(campaign.campaign_code);
      setCampaignDescription(campaign.campaign_description);
      setCampaignText(campaign.campaign_text);
      setCampaignType(campaign.campaign_type);
      setExpirationDate(new Date(campaign.expires_at));
      setStartDate(new Date(campaign.start_at));
      setRedirectLink(campaign.redirect_link);
    }
  }, [campaign]);

  if (!campaign || isLoading) {
    return <Loading />;
  }
  if (error) {
    return (
      <>
        <h1>Não foi possivel achar campanhas</h1>
      </>
    );
  }
  return (
    <>
      <Styled.Container>
        <Styled.Content>
          <Styled.Title>Editar Campanha</Styled.Title>
          <Form key={campaign ? campaign.id : "form"} submit={onChangeForm}>
            <TextInput
              label="Nome da Campanha"
              name="campaign_name"
              defaultValue={campaignName}
              placeholder="Digite o nome da campanha"
              required
            />
            <TextInput
              label="Código da Campanha"
              name="campaign_code"
              placeholder="Digite o código da campanha"
              defaultValue={campaignCode}
              required
            />
            <TextInput
              label="Link de redirecionamento"
              name="redirect_link"
              placeholder="Insira o link de redirecionamento campanha"
              defaultValue={redirectLink}
            />
            <Select
              label="Tipo da Campanha"
              name="campaign_type"
              options={bannerTypes}
              placeholder={campaignType}
              defaultValue={campaignType}
              required
            />
            <TextInput
              label="Descricao"
              name="campaign_description"
              placeholder="Digite a descrição da campanha"
              defaultValue={campaignDescription}
            />
            <TextInput
              label="Texto da Campanha"
              name="campaign_text"
              placeholder="Digite o texto da campanha"
              defaultValue={campaignText}
            />

            <TextInput
              label="Data de Início"
              name="start_at"
              placeholder="dd/mm/aaaa"
              mask="99/99/9999"
              validation="date"
              defaultValue={moment(startDate).format("DD/MM/YYYY")}
            />

            <TextInput
              label="Data de Expiração"
              name="expires_at"
              placeholder="dd/mm/aaaa"
              mask="99/99/9999"
              validation="date"
              defaultValue={moment(expirationDate).format("DD/MM/YYYY")}
            />

            <Styled.LocalizationContainer></Styled.LocalizationContainer>
            <Styled.ButtonContainer>
              <ButtonPrimary type="submit" color="orange">
                Salvar
              </ButtonPrimary>
            </Styled.ButtonContainer>
          </Form>
        </Styled.Content>
      </Styled.Container>
    </>
  );
};

export default AdminCampaignsEdit;
