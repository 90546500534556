import { action } from "typesafe-actions";

import types from "./types";
import { TTooltip } from "Services/Locale/tooltip";
import { IAdTracking } from "Services/Tracking/tracking";
import { AdRTime } from "./reducer";

const localeActions = {
  updateMobile: (mobile: boolean) => action(types.UPDATE_MOBILE, mobile),
  updateTracking: (tracking: IAdTracking[]) =>
    action(types.UPDATE_TRACKING, tracking),
  addTracking: (tracking: IAdTracking) => action(types.ADD_TRACKING, tracking),
  addTooltip: (tooltip: TTooltip) =>
    action(types.ADD_TOOLTIP, { ...tooltip, status: true }),
  deleteTooltip: (index: number) => action(types.DELETE_TOOLTIP, index),
  addGaId: (gaId: string) => action(types.ADD_GAID, gaId),
  modal: () => action(types.MODAL),
  addRTime: (RTime: AdRTime) => action(types.ADD_RTIME, RTime),
  openPopUp: () => action(types.OPEN_POUP),
  closePopUp: () => action(types.CLOSE_POUP)
};

export default localeActions;
