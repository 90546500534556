import api from "Helpers/Api";
import {
  listSubCategoriesRequest,
  listSubCategoriesResponse
} from "Services/Category";

export default async function listSubCategories(
  params: listSubCategoriesRequest
) {
  const url = `/categories/subcategories/`;

  const { data } = await api.get<listSubCategoriesResponse>(url, { params });

  return data;
}
