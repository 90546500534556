interface checkCpfCnpjProps {
  cpf?: string;
  cnpj?: string;
}

export const checkCpfCnpj = ({ cpf, cnpj }: checkCpfCnpjProps) => {
  if (cpf) {
    return { cpf: cpf.replace(/[().\s-\s/]+/g, "") };
  }
  if (cnpj) {
    return { cnpj: cnpj.replace(/[().\s-\s/]+/g, "") };
  }
};
