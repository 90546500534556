import { IValidation } from "./";

const Validation: IValidation = {
  error: "Por favor, digite um número de telefone válido",
  validate: (input: string) =>
    /\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)?\s?(?:[2-8]|9?[1-9])[0-9]{3}\s?-?\s?[0-9]{4}/.test(
      input
    )
};

export default Validation;
