import styled from "styled-components";
import { FaUser } from "react-icons/fa";

interface IBigger {
  bigAvatar: boolean;
}

export const Avatar = styled.div<IBigger>`
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  width: ${({ bigAvatar }) => (bigAvatar ? "130px" : "90px")};
  height: ${({ bigAvatar }) => (bigAvatar ? "130px" : "90px")};
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  border: 3px solid ${({ theme }) => theme.colors.green.normal};

  img {
    object-fit: cover;
    width: 100%;
  }
`;
export const Placeholder = styled(FaUser).attrs(props => ({
  icon: FaUser
}))`
  font-size: 30px;
  margin: 0 auto;
  color: ${props => props.theme.colors.gray.normal};
  &:hover {
    cursor: pointer;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.mm};
  margin: 20px 0;
  color: ${({ theme }) => theme.colors.gray.dark};
  text-align: center;

  a {
    text-align: center;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.gray.dark};
    margin-top: 5px;

    &:hover {
      color: ${({ theme }) => theme.colors.green.dark};
    }

    svg {
      padding-right: 5px;
    }
  }
`;
