import { IPage } from "Services/pagination";
import * as Styled from "./styles";
import React from "react";

interface PaginationProps extends IPage {
  onPageChange: (page: number) => void;
}

interface PaginationItemProps {
  page: number;
  isCurrent?: boolean;
  onPageChange: (page: number) => void;
}

const siblingsCount = 1;

function generatePagesArray(from: number, to: number) {
  return [...new Array(to - from)]
    .map((_, index) => {
      return from + index + 1;
    })
    .filter(page => page > 0);
}

function PaginationItem({
  page,
  isCurrent = false,
  onPageChange
}: PaginationItemProps) {
  return (
    <Styled.Button onClick={() => onPageChange(page)} isCurrent={isCurrent}>
      {page}
    </Styled.Button>
  );
}

export default function Pagination2({
  page,
  perPage,
  lastPage,
  onPageChange
}: PaginationProps) {
  const previousPages =
    page > 1 ? generatePagesArray(page - 1 - siblingsCount, page - 1) : [];

  const nextPages =
    page < lastPage
      ? generatePagesArray(page, Math.min(page + siblingsCount, lastPage))
      : [];

  return (
    <Styled.Container>
      {page > 1 + siblingsCount && (
        <>
          <PaginationItem onPageChange={onPageChange} page={1} />
          {page > siblingsCount + 2 && <Styled.Text>...</Styled.Text>}
        </>
      )}

      {previousPages.length > 0 &&
        previousPages.map(page => {
          return (
            <PaginationItem
              onPageChange={onPageChange}
              key={page}
              page={page}
            />
          );
        })}
      <PaginationItem onPageChange={onPageChange} page={page} isCurrent />
      {nextPages.length > 0 &&
        nextPages.map(page => {
          return (
            <PaginationItem
              onPageChange={onPageChange}
              key={page}
              page={page}
            />
          );
        })}

      {page + siblingsCount < lastPage && (
        <>
          {page + siblingsCount + 1 < lastPage && (
            <Styled.Text>...</Styled.Text>
          )}
          <PaginationItem onPageChange={onPageChange} page={lastPage} />
        </>
      )}
    </Styled.Container>
  );
}
