import React, { useState } from "react";
import * as Styled from "./styles";
import TextInput from "Components/Form/Inputs/Text";
import ButtonPrimary from "Components/Buttons/Primary";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import DatePicker from "react-date-picker";
import { useHistory } from "react-router-dom";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Form from "Components/Form";
import { useDispatch } from "react-redux";
import BackendErrorHelper from "Helpers/BackendErrorHelper";
import localeActions from "Redux/Locale/actions";
import articlesCreate from "Api/articles/create";

const AdminArticlesCreate: React.FC = () => {
  const history = useHistory();

  const [publicationDate, setPublicationDate] = useState<Date>(new Date());
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [editorState, setEditorState] = useState<EditorState>();

  const dispatch = useDispatch();

  const onCreateForm = async (inputs: any) => {
    try {
      const params = {
        ...inputs,
        publicationDate,
        textHtml:
          (editorState &&
            draftToHtml(convertToRaw(editorState.getCurrentContent()))) ||
          ""
      };
      const response = await articlesCreate(params);
      response &&
        dispatch(
          localeActions.addTooltip({
            type: "success",
            message: "Notícia criada com sucesso!"
          })
        );
      history.push(`/admin/articles`);
    } catch (error) {
      if (BackendErrorHelper.checkIfDefaultError(error)) {
        const message = BackendErrorHelper.getDefaultErrorMessage(
          error.response
        );
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else if (BackendErrorHelper.checkIfValidationError(error)) {
        const message = BackendErrorHelper.getValidatorErrorMessage(
          error.response
        );
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else {
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message: "Error ao criar feira"
          })
        );
      }
    }
  };

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Title>Criar Notícia</Styled.Title>
        <Form submit={onCreateForm}>
          <TextInput
            label="Título da notícia"
            name="title"
            placeholder="Digite o titulo da notícia"
            required
          />
          <TextInput
            label="Sub-titulo da notícia"
            name="subTitle"
            placeholder="Digite o sub-titulo da notícia"
            required
          />
          <TextInput
            label="Categoria da notícia"
            name="category"
            placeholder="Digite a categoria da notícia"
            required
          />
          <TextInput
            label="Tags"
            name="tags"
            placeholder="Digite as tags separadas por |"
            required
          />
          <Styled.PublicationDate>
            <Styled.PublicationLabel>
              Data de publicação.
            </Styled.PublicationLabel>
            <DatePicker
              onChange={setPublicationDate}
              value={publicationDate}
              name="publicationDate"
              format="dd/MM/yyyy"
            />
          </Styled.PublicationDate>
          <Styled.EditorContainer>
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={setEditorState}
            />
          </Styled.EditorContainer>
          <Styled.ImageContainer>
            <TextInput
              label="Link da imagem-banner."
              name="imageUrl"
              placeholder="Digite o link da imagem aqui."
              change={value => setImagePreview(value)}
              required
            />
            {imagePreview && <Styled.Preview src={imagePreview} />}
          </Styled.ImageContainer>
          <Styled.ButtonContainer>
            <ButtonPrimary type="submit" color="orange">
              Criar
            </ButtonPrimary>
          </Styled.ButtonContainer>
        </Form>
      </Styled.Content>
    </Styled.Container>
  );
};

export default AdminArticlesCreate;
