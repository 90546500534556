import api from "Helpers/Api";
import { FairsListResponse, IFairUpdate } from "Services/Fairs/fairs";

export default async function fairUpdate(fairId: string, params: IFairUpdate) {
  const { data } = await api.put<FairsListResponse>(
    `/fairs/update/${fairId}`,
    params
  );

  return data;
}
