import styled from "styled-components";

export const Container = styled.div`
  display: flex;

  width: 100%;

  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.gray.light};

  margin: 64px 24px;

  border-radius: 12px;
`;

export const Content = styled.div`
  display: flex;

  flex-direction: column;

  background-color: ${({ theme }) => theme.colors.white.normal};
  width: 100%;

  padding: 16px;
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.black.normal};
  font-weight: 700;
  margin-bottom: 2rem;
`;

export const List = styled.div`
  display: flex;

  margin-top: 16px;

  flex-direction: column;

  gap: 16px;
`;

export const SearchBox = styled.div`
  width: 100%;

  input {
    height: 40px;
  }

  svg {
    box-sizing: unset;
  }
`;
