import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ReactElement
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReducerState } from "Redux/store";

import * as Styled from "./styles";
import Filters from "Components/Filters";
import Loading from "Components/Loading";
import Search from "Components/Form/Inputs/Search";
import Pagination from "Components/Pagination";
import { IPage, IPagination } from "Services/pagination";
import { useLocation } from "react-router-dom";
import { IAd } from "Services/Ad/ad";
import { TJob } from "Services/Job/job";
import { TArticle } from "Services/Article/article";
import { TSegmentsName } from "Services/Segment/segment";
import { IAdTracking } from "Services/Tracking/tracking";
import localeActions from "Redux/Locale/actions";
import ListItem from "./ListItem";
import { createTrack } from "Helpers/tracking";
import ButtonPrimary from "Components/Buttons/Primary";
import SeeMore from "Components/Buttons/SeeMore";

export interface IList {
  inline: boolean;
  size?: number;
}

interface Props {
  children?: any;
  segment?: TSegmentsName;
  ownSearch?: string;
  query(search?: string, page?: number): Promise<IPagination<any> | undefined>;
  itemlayout(props: object): ReactElement;
  ListLayout?: React.FC<IList>;
  hideSearch?: boolean;
  hidePagination?: boolean;
  hideFilters?: boolean;
  seeMoreLink?: string;
  seeMoreText?: string;
  scrollToTopOnPageChange?: boolean;
  filters?: { [key: string]: any };
  sorting?: any;
  inline?: boolean;
  row?: number;
  hover?: boolean;
  queryError?: boolean;
  setNoResults?(value: any): void;
  size?: number;
}

type TList = IAd | TJob | TArticle;

const List: React.FC<Props> = ({
  children,
  segment,
  ownSearch,
  query,
  itemlayout,
  ListLayout = Styled.List,
  hideSearch = false,
  hidePagination = false,
  hideFilters = false,
  filters,
  sorting,
  seeMoreLink,
  seeMoreText = "Ver mais",
  scrollToTopOnPageChange = true,
  inline = false,
  row = 3,
  hover = true,
  queryError = false,
  setNoResults,
  size
}) => {
  const mobile = useSelector((state: ReducerState) => state.locale.mobile);

  const { gaId, tracking } = useSelector((state: ReducerState) => state.locale);
  const { user } = useSelector((state: ReducerState) => state.auth);

  const location = useLocation();
  const dispatch = useDispatch();

  const params = new URLSearchParams(location.search);
  const searchParams = params.get("s") || "";

  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(searchParams);
  const [total, setTotal] = useState<string>();
  const [list, setList] = useState<TList[]>([]);
  const [pagination, setPagination] = useState<IPage>({
    page: 1,
    lastPage: 1,
    perPage: 12
  });
  const [networkError, setNetworkError] = useState<boolean>(false);

  const fetchList = useCallback(
    async (currentPage = pagination.page) => {
      try {
        setLoading(true);
        setNetworkError(false);

        const realSearch = ownSearch ? ownSearch : search;
        const data = await query(realSearch, currentPage);

        if (data) {
          const { data: list, total, page, lastPage, perPage } = data;

          if (total === "0" && setNoResults) {
            setNoResults(true);
          }
          setList(list);
          !hidePagination && setPagination({ page, perPage, lastPage });
          setTotal(total);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        setNetworkError(true);
        setList([]);
        setNoResults && setNoResults(true);
        console.error(error);
      }
    },
    [hidePagination, query, search, setNoResults]
  );

  const addListToTracking = useCallback(() => {
    if (segment === "products") {
      const newTracking: IAdTracking[] = list
        .filter(
          listItem =>
            tracking &&
            !tracking.find(trackingItem => trackingItem.ITEM_ID === listItem.id)
        )
        .map((item, index) => {
          const event: IAdTracking = {
            TYPE: "ITEM_DISPLAY",
            ITEM_TYPE: "PROD",
            GA_ID: gaId,
            USER_ID: user ? user.id : "",
            ITEM_ID: item.id,
            DATA: {
              POS: `${(index % row) + 1}, ${Math.floor(index / row) + 1}`
            },
            PAGE_REF: location.pathname,
            TAGS: "mode=model1,version=v2,test=a"
          };
          return createTrack(event, segment ? segment : "products");
        });
      if (filters) {
        const filtersString = Object.keys(filters)
          .filter(filter => filters[filter])
          .map(filter => `${filter}=${filters[filter]}`)
          .join("&");
        const filter: IAdTracking = {
          TYPE: "PAGE_SEARCH",
          GA_ID: gaId,
          USER_ID: user ? user.id : "",
          DATA: {
            SEARCH: search,
            FILTER: filtersString
          },
          PAGE_REF: location.pathname,
          TAGS: "mode=model1,version=v2,test=a"
        };
        tracking &&
          !tracking.find(
            trackingItem =>
              JSON.stringify(trackingItem) ===
              JSON.stringify(
                createTrack(filter, segment ? segment : "products")
              )
          ) &&
          newTracking.push(createTrack(filter, segment ? segment : "products"));
      }
      dispatch(localeActions.updateTracking([...tracking, ...newTracking]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segment, list, gaId, user, row, location.pathname, search]);

  useEffect(() => {
    addListToTracking();
  }, [addListToTracking]);

  const addProductToTracking = (index: number, item: IAd) => {
    if (segment) {
      const event: IAdTracking = {
        TYPE: "ITEM_VIEW",
        GA_ID: gaId,
        USER_ID: user ? user.id : "",
        ITEM_ID: item.id,
        DATA: {
          POS: `${(index % row) + 1}, ${Math.floor(index / row) + 1}`
        },
        PAGE_REF: location.pathname,
        TAGS: "mode=model1,version=v2,test=a"
      };
      dispatch(
        localeActions.addTracking(
          createTrack(event, segment ? segment : "products")
        )
      );
    }
  };

  const clickOnProduct = (index: number, id: string, title: string | false) => {
    if (segment) {
      const event: IAdTracking = {
        TYPE: "ITEM_CLICK",
        GA_ID: gaId,
        USER_ID: user ? user.id : "",
        ITEM_ID: id,
        PAGE_REF: location.pathname,
        TAGS: "mode=model1,version=v2,test=a"
      };

      if (title) {
        event.DATA = {
          title: title
        };
      }

      dispatch(
        localeActions.addTracking(
          createTrack(event, segment ? segment : "products")
        )
      );
    }
  };

  const time = useRef(0);

  const throttle = useCallback(
    (func: any) => {
      clearTimeout(time.current);
      time.current = setTimeout(
        () => {
          setLoading(true);
          func();
        },
        time.current === 0 ? 0 : 200
      );
    },
    [time]
  );

  // TODO: fix: on filter change go to page 1
  useEffect(() => {
    setPagination((state: IPage) => ({ ...state, page: 1 }));
  }, [query]);

  useEffect(() => {
    fetchList();
  }, [ownSearch, sorting]);

  function setPage(page: number) {
    setPagination({ ...pagination, page });
    fetchList(page);
  }
  const placeholderLength =
    list.length % row === 0 ? 0 : ((list.length % row) - row) * -1;

  const createPlaceholders = (number: number) => {
    const placeholders = [];
    for (let i = 1; i <= number; i++) {
      placeholders.push(
        <Styled.EmptyItem
          size={100 / row}
          inline={inline}
          hover={hover}
          isOne={false}
          key={`placeholder-${i}`}
        />
      );
    }
    return placeholders;
  };

  const applyFiltersOnEnter = function(event: React.KeyboardEvent) {
    var keyPressed = event.keyCode || event.which;

    if (keyPressed === 13) fetchList();
  };

  return (
    <>
      <Styled.Content>
        {!hideFilters && (
          <Styled.Filters onKeyPress={applyFiltersOnEnter}>
            {!hideSearch && (
              <Styled.SearchContainer>
                <Search
                  setValue={setSearch}
                  placeholder="O que está buscando?"
                  defaultValue={search}
                  shadow={false}
                  submit={() => fetchList()}
                />
                <Styled.TotalResults>{total} resultados</Styled.TotalResults>
              </Styled.SearchContainer>
            )}

            {!hideFilters && (
              <Styled.SelectFiltersContainer>
                <Filters searchHidden={true}>{children}</Filters>
              </Styled.SelectFiltersContainer>
            )}
            <Styled.BtnContainer>
              <ButtonPrimary type="button" action={() => fetchList()}>
                Aplicar Filtros
              </ButtonPrimary>
            </Styled.BtnContainer>
          </Styled.Filters>
        )}
        {loading ? (
          <Loading>{createPlaceholders(12).map(ph => ph)}</Loading>
        ) : (
          <Styled.ListContainer>
            {queryError ? (
              <Styled.NoResults>
                <div>Erro no servidor, tente mais tarde</div>
              </Styled.NoResults>
            ) : list.length !== 0 ? (
              <>
                <Styled.List
                  inline={inline}
                  size={size}
                  className="products-list"
                >
                  {list.map((item, key) => (
                    <ListItem
                      isOne={list.length === 1}
                      hover={hover}
                      size={100 / row}
                      inline={inline}
                      key={`list-item-${key}`}
                      onEnterViewport={() => addProductToTracking(key, item)}
                      click={() => {
                        clickOnProduct(
                          key,
                          item.id,
                          segment === "articles" && !!item.title && item.title
                        );
                      }}
                    >
                      {itemlayout(item)}
                    </ListItem>
                  ))}
                </Styled.List>
                {!mobile &&
                  placeholderLength > 0 &&
                  createPlaceholders(placeholderLength).map(ph => ph)}
                {!hidePagination && (
                  <Pagination
                    setPage={setPage}
                    pagination={pagination}
                    scrollToTop={scrollToTopOnPageChange}
                  />
                )}
                {seeMoreLink && seeMoreText && (
                  <SeeMore redirectLink={seeMoreLink}>{seeMoreText}</SeeMore>
                )}
              </>
            ) : (
              <Styled.NoResults>
                {networkError || queryError ? (
                  <div>Erro no servidor, tente mais tarde</div>
                ) : (
                  <div>Nenhum resultado foi encontrado</div>
                )}
              </Styled.NoResults>
            )}
          </Styled.ListContainer>
        )}
      </Styled.Content>
    </>
  );
};

export default List;
