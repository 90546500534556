import React, { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import "moment/locale/pt-br";
import * as Styled from "./styles";
import { TArticle } from "Services/Article/article";
import ButtonPrimary from "Components/Buttons/Primary";
import Modal from "Components/Modal";

import BackendErrorHelper from "Helpers/BackendErrorHelper";
import localeActions from "Redux/Locale/actions";
import Loading from "Components/Loading";

import { MdDelete } from "react-icons/md";
import articleDelete from "Api/articles/delete";

interface Props {
  data: TArticle;
  link: string;
}

const ArticleItem: React.FC<Props> = ({ data, link }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const handleDeleteArticle = async () => {
    try {
      setIsLoading(true);
      const response = await articleDelete(data.id);
      response &&
        dispatch(
          localeActions.addTooltip({
            type: "success",
            message: "Excluído com sucesso!"
          })
        );
    } catch (error) {
      if (BackendErrorHelper.checkIfDefaultError(error)) {
        const message = BackendErrorHelper.getDefaultErrorMessage(
          error.response
        );
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else if (BackendErrorHelper.checkIfValidationError(error)) {
        const message = BackendErrorHelper.getValidatorErrorMessage(
          error.response
        );
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else {
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message: "Erro ao tentar excluir feira."
          })
        );
      }
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.ArticleLink to={link || ""}>
          <>
            {data.publication_date && (
              <Styled.Date>
                {moment(data.publication_date)
                  .add(1, "days")
                  .format("DD/MMM/YYYY")
                  .toUpperCase()}
              </Styled.Date>
            )}
            <Styled.Picture>
              {data.image_url ? (
                <Styled.Image src={data.image_url} alt="Article Image" />
              ) : (
                <Styled.Placeholder />
              )}
            </Styled.Picture>
            <Styled.InfoContainer>
              <Styled.Info>
                <Styled.Title>{data.title}</Styled.Title>
                <Styled.Description>{data.sub_title}</Styled.Description>
              </Styled.Info>
            </Styled.InfoContainer>
          </>
        </Styled.ArticleLink>
        <ButtonPrimary color="red" action={() => setIsModalOpen(true)}>
          <MdDelete />
          Excluir
        </ButtonPrimary>
      </Styled.Content>
      {isModalOpen && (
        <Modal
          handleClose={setIsModalOpen}
          title={data.title}
          isOpen={isModalOpen}
        >
          <Styled.DeleteContainer>
            <p>Tem certeza que deseja excluir essa notícia?</p>
            <Styled.DeleteContainerButtons>
              <ButtonPrimary
                color="red"
                action={handleDeleteArticle}
                disabled={isLoading}
              >
                {isLoading ? <Loading /> : "Sim"}
              </ButtonPrimary>
              <ButtonPrimary
                color="orange"
                action={() => setIsModalOpen(false)}
              >
                Cancelar
              </ButtonPrimary>
            </Styled.DeleteContainerButtons>
          </Styled.DeleteContainer>
        </Modal>
      )}
    </Styled.Container>
  );
};

export default ArticleItem;
