import { IMenuItemModel } from "../index";
import Routes from "Routes";

const RuraxInfo: IMenuItemModel = {
  name: "Informações Rurax",
  link: Routes.leases.replace(":category?", ""),
  submenu: [
    {
      category: "Sobre nós",
      link: "/informacoes/sobre-nos/"
    },
    {
      category: "Termos e condições de uso",
      link: "/informacoes/termos-condicoes/"
    },
    {
      category: "Política de Privacidade",
      link: "/informacoes/privacidade-confidencialidade"
    },
    {
      category: "Política de Direito dos Titulares de Dados",
      link: "/informacoes/titulares-dados"
    },
    {
      category: "Segurança",
      link: "/informacoes/seguranca/"
    },

    {
      category: "Politica de Cookies",
      link: "/informacoes/cookies/"
    },
    {
      category: "Política de Preços",
      link: "/informacoes/politica-precos/"
    }
  ]
};

export default RuraxInfo;
