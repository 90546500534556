import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0;
`;

export const Header = styled.div`
  position: relative;
`;

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;

  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;

export const Info = styled.span`
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.gray.dark};
  text-align: center;
  margin: auto;
  width: 50%;
  padding: 10px;

  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.green.normal};
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.md};
  margin: 30px 0 20px 0;
  padding-bottom: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.green.normal};
`;

export const ArticlesContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: row;
  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;
