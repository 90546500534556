import React, { useContext } from "react";
import * as Styled from "./styles";
import PageTitle from "Components/Base/PageTitle";
import { useHistory } from "react-router-dom";
import { Account as Routes } from "Routes";
import UserMenu from "Components/UserMenu";
import { MARKETPLACE } from "config";

const HomeAccount: React.FC = () => {
  const history = useHistory();

  const Annonce = [
    {
      value: Routes.sell,
      name: "Minhas vendas"
    },
    {
      value: Routes.products,
      name: "Produtos"
    },
    {
      value: Routes.vehicles,
      name: "Veículos"
    },
    {
      value: Routes.properties,
      name: "Imóveis Rurais"
    },
    {
      value: Routes.leases,
      name: "Arrendamentos"
    },
    {
      value: Routes.jobs,
      name: "Vagas"
    },
    {
      value: Routes.services,
      name: "Serviços"
    },
    {
      value: Routes.integration,
      name: "Cargas"
    }
  ];

  const Account = [
    {
      value: Routes.order,
      name: "Meus Pedidos"
    },
    {
      value: Routes.privacity,
      name: "Privacidade"
    },
    {
      value: Routes.adress,
      name: "Endereços"
    },
    {
      value: Routes.infos,
      name: "Informações Gerais"
    }
  ];

  MARKETPLACE &&
    Account.push(
      {
        value: Routes.adPlan,
        name: "Meus Destaques"
      },
      {
        value: Routes.payment,
        name: "Meios de Pagamento"
      },
      {
        value: Routes.sellerData,
        name: "Área do Vendedor"
      }
    );

  const redirect = (route: Routes) => {
    history.push(route);
  };

  return (
    <Styled.Container>
      <Styled.PageTitleContainer>
        <PageTitle>Minha conta</PageTitle>
      </Styled.PageTitleContainer>
      <Styled.MainContainer>
        <Styled.AvatarContainer>
          <UserMenu bigAvatar />
        </Styled.AvatarContainer>
        <Styled.AnnounceAccountContainer>
          <Styled.DataContainer>
            <Styled.Title>Meus Anúncios</Styled.Title>
            <Styled.BtnsContainer>
              {Annonce.map((item, index) => (
                <Styled.SingleBtn key={`button-${index}`}>
                  <Styled.Button
                    onClick={() => {
                      redirect(item.value);
                    }}
                  >
                    {item.name}
                  </Styled.Button>
                </Styled.SingleBtn>
              ))}
            </Styled.BtnsContainer>
          </Styled.DataContainer>
          <Styled.DataContainer>
            <Styled.Title>Conta</Styled.Title>
            <Styled.BtnsContainer>
              {Account.map(item => (
                <Styled.SingleBtn>
                  <Styled.Button
                    onClick={() => {
                      redirect(item.value);
                    }}
                  >
                    {item.name}
                  </Styled.Button>
                </Styled.SingleBtn>
              ))}
            </Styled.BtnsContainer>
          </Styled.DataContainer>
        </Styled.AnnounceAccountContainer>
      </Styled.MainContainer>
    </Styled.Container>
  );
};

export default HomeAccount;
