import api from "Helpers/Api";

class SessionService {
  async login(email: string, password: string) {
    try {
      const { data } = await api.post("/session/login", { email, password });
      localStorage.setItem("t", data.refreshToken);
      return data;
    } catch (error) {
      if (error.response.data.status === false) {
        throw error.response.data.data;
      } else if (error.response.status === 401) {
        throw error.response.data.map((e: any) => e.message);
      } else {
        throw error.response.data.map((e: any) => e.message);
      }
    }
  }

  async socialLogin(
    email: string,
    googleId: string | null,
    facebookId: string | null
  ) {
    try {
      const { data } = await api.post("/session/socialLogin", {
        email,
        googleId,
        facebookId
      });
      localStorage.setItem("t", data.refreshToken);
      return data;
    } catch (error) {
      if (error.response.data.status === false) {
        throw error.response.data.data;
      } else if (error.response.status === 401) {
        throw error.response.data.map((e: any) => e.message);
      } else {
        throw error.response.data.map((e: any) => e.message);
      }
    }
  }

  async tokenLogin(refreshToken: string) {
    try {
      const { data } = await api.post("/session/token", { refreshToken });
      localStorage.setItem("t", data.refreshToken);
      return data;
    } catch (error) {
      const defaultError = "";
      if (error.response.status === 401) {
        localStorage.removeItem("t");
        throw defaultError;
      } else {
        throw defaultError;
      }
    }
  }

  async mailConfirm(mailToken: string) {
    try {
      const { data } = await api.put("/users/user/email/confirm", {
        mailToken
      });
      return data;
    } catch (error) {
      if (error.response.status === 404) {
        throw error.response.data.data;
      } else {
        throw error.response.data.map((e: any) => e.data);
      }
    }
  }

  async enableToken(enableToken: string) {
    try {
      const { data } = await api.put("/users/user/enable/", {
        enableToken
      });
      return data;
    } catch (error) {
      if (error.response.status === 404) {
        throw error.response.data.data;
      } else {
        throw error.response.data.map((e: any) => e.data);
      }
    }
  }
  unauthenticate() {
    localStorage.removeItem("t");
  }
}

export default new SessionService();
