import React from "react";
import * as Styled from "./styles";
import LazyLoad from "react-lazyload";
import Loading from "Components/Loading";

const Partners: React.FC = () => {
  return (
    <Styled.PartnersContainers>
      <Styled.PartnersTitle>Somos acelerados por</Styled.PartnersTitle>
      <Styled.PartnersContent>
        <a
          href="https://www.inf.ufg.br/p/39527-centro-de-excelencia-em-inteligencia-artificial-ceia"
          target="_blank"
          rel="extern noreferrer"
        >
          <LazyLoad
            placeholder={<Loading />}
            height={200}
            offset={[-200, 0]}
            once
          >
            <Styled.CEIALogo
              width="240"
              height="140"
              src="/ceialogo.png"
              alt="CEIA logo"
            />
          </LazyLoad>
        </a>
        <a href="https://www.ufg.br/" target="_blank">
          <LazyLoad placeholder={<Loading />} height={200} offset={100} once>
            <Styled.UFGLogo
              width="180"
              height="100"
              src="/ufglogo.png"
              alt="UFG logo"
            />
          </LazyLoad>
        </a>
        <a href="https://embrapii.org.br/" target="_blank">
          <LazyLoad placeholder={<Loading />} height={200} offset={100} once>
            <Styled.EmbrapiiLogo
              width="180"
              height="100"
              src="/embrapiilogo.png"
              alt="Embrapii logo"
            />
          </LazyLoad>
        </a>
        <a href="https://www.sebrae.com.br/sites/PortalSebrae/" target="_blank">
          <LazyLoad placeholder={<Loading />} height={200} offset={100} once>
            <Styled.SebraeLogo
              width="180"
              height="100"
              src="/sebraelogo.svg"
              alt="SEBRAE logo"
            />
          </LazyLoad>
        </a>
      </Styled.PartnersContent>
    </Styled.PartnersContainers>
  );
};

export default Partners;
