import styled from "styled-components";

export const AudioPlayer = styled.div`
  display: flex;

  align-items: center;
`;

export const ToggleButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const PlayerContainer = styled.div`
  display: flex;
  align-items: center;

  width: 520px;

  @media ${({ theme }) => theme.devices.tablet} {
    width: calc(100vw - 200px);
  }
`;

export const Timers = styled.div`
  font-size: 1rem;
  margin: 0 16px;
`;

export const ProgressBar = styled.input`
    --seek-before-width: 0;

    appearance: none;
    background-color: ${({ theme }) => theme.colors.green[100]};
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 12px;
    outline: none;

    &::-webkit-slider-runnable-track {
        background-color: ${({ theme }) => theme.colors.green[100]};
        border-radius: 10px;
        position: relative;
        width: 100%;
        height: 11px;
        outline: none;
    };

    &::-moz-range-track {
        background-color: ${({ theme }) => theme.colors.green[100]};
        border-radius: 10px;
        position: relative;
        width: 100%;
        height: 11px;
        outline: none;
    };

    &::-moz-focus-outer {
        border: 0;
    };

    &::before {
        content: '';
        height: 12px;
        width: var(--seek-before-width);
        background-color: ${({ theme }) => theme.colors.green[300]};
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        cursor: pointer;
    };


    &::-moz-range-progress {
        background-color: ${({ theme }) => theme.colors.green[300]};
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        height: 11px;
    
    };

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        border: none;
        background-color: ${({ theme }) => theme.colors.green[800]};
        background-image: url('./soundplayer.svg');
        background-position: center;
        background-size: contain;
        cursor: pointer;
        position: relative;
        margin: -7px 0 0 0;
        z-index: 3;
        box-sizing: border-box;
    };

    &:active::-webkit-slider-thumb {
            transform: scale(1.2);
            background-color: ${({ theme }) => theme.colors.green[400]};
        };


    &::-moz-range-thumb {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        border: transparent;
        background-color: ${({ theme }) => theme.colors.green[800]};
        background-image: url('./soundplayer.svg');
        background-position: center;
        background-size: contain;
        cursor: pointer;
        position: relative;
        z-index: 3;
        box-sizing: border-box;
    };

    &:active::-moz-range-thumb {
            transform: scale(1.2);
            background-color: ${({ theme }) => theme.colors.green[400]};
        }
    }
`;
