const regexPhoneNumberDDDSpecific = /\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)?\s?(?:[2-8]|9?[1-9])[0-9]{3}\s?-?\s?[0-9]{4}\s?|(?:[2-8]|9?[1-9])[0-9]{3}\s?-?\s?[0-9]{4}\s?/gim;

const regexEmail = /([a-z0-9_.-]+@[a-z0-9_.-]+)/gim;

const regexLinks = /((https:\/\/|http:\/\/|www\.)[a-z0-9_.\-/:?=]+)/gim;

export default function removeRegexFromText(
  text: string | null,
  regex: RegExp = regexPhoneNumberDDDSpecific
) {
  return text ? text.replace(regex, "") : text;
}

export function removeManyRegexFromText(
  text: string | null,
  regexList: RegExp[] = [regexPhoneNumberDDDSpecific, regexEmail, regexLinks]
) {
  regexList.forEach(regex => {
    text = text ? text.replace(regex, "") : text;
  });

  return text;
}
