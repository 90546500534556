import { IMenuItemModel } from "../index";
import Routes from "Routes";

const Links: IMenuItemModel = {
  name: "Links",
  link: Routes.leases.replace(":category?", ""),
  submenu: [
    {
      category: "Termos e condições",
      link: `https://storage.googleapis.com/rurax/docs/RURAX%20-%20Termos%20e%20Condi%C3%A7%C3%B5es%20Gerais.pdf`
    },
    {
      category: "Políticas de Publicação",
      link: `https://storage.googleapis.com/rurax/docs/RURAX%20-%20Pol%C3%ADtica%20de%20Privacidade%20e%20confidencialidade%20da%20Informa%C3%A7%C3%A3o.pdf`
    },
    {
      category: "Política de Privacidade",
      link: `https://storage.googleapis.com/rurax/docs/RURAX%20-%20Pol%C3%ADtica%20de%20Privacidade%20e%20confidencialidade%20da%20Informa%C3%A7%C3%A3o.pdf`
    },
    {
      category: "Orientações de Contato",
      link: `https://storage.googleapis.com/rurax/docs/RURAX%20-%20Orienta%C3%A7%C3%B5es%20de%20Contato%20pelo%20Site.pdf`
    },
    {
      category: "Formulário de Requisição de Direitos",
      link: `https://storage.googleapis.com/rurax/docs/RURAX%20%E2%80%93%20MODELO%20DE%20FORMUL%C3%81RIO%20DE%20REQUISI%C3%87%C3%83O%20DE%20DIREITOS_ATUAL.docx`
    },
    {
      category: "Termo de Contratação, Destaque e publicidade",
      link: `https://storage.googleapis.com/rurax/docs/(VF)%20-%20RURAX%20-%20Termo%20de%20Contrata%C3%A7%C3%A3o%20Destaque%20e%20Publicidade_ATUAL.docx`
    },
    {
      category: "Termo de Uso e Anúncio (Contrato Anunciante Vendedor)",
      link: `https://storage.googleapis.com/rurax/docs/(VF)%20-%20RURAX%20-%20Termo%20de%20Uso%20e%20An%C3%BAncio%20(Contrato%20Anunciante%20Vendedor)_ATUAL.docx`
    }
  ]
};

export default Links;
