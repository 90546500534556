import * as Styled from "./styles";
import React from "react";
import Select from "Components/Form/Inputs/Select";
import { white } from "Components/Form/Inputs/Select/themes";
import { GENERAL_SORTINGS } from "Helpers/consts";
import { TOrder } from "Services/pagination";

export type TSortingValue = {
  sort?: string;
  order?: TOrder;
};

export type TSortingOptions = {
  name: string;
  value: TSortingValue;
};

interface Props {
  sortingOptions?: TSortingOptions[];
  setSorting: React.Dispatch<React.SetStateAction<any>>;
}

const Sorting: React.FC<Props> = ({
  setSorting,
  sortingOptions = GENERAL_SORTINGS
}) => {
  return (
    <Styled.SortContainer>
      <Styled.Label>ordenar: </Styled.Label>
      <Select
        change={value => setSorting(value)}
        name="sorting"
        options={sortingOptions}
        defaultValue={sortingOptions[0].value}
        theme={white}
      ></Select>
    </Styled.SortContainer>
  );
};

export default Sorting;
