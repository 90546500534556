import React, { ReactNode, useContext } from "react";
import * as Styled from "./styles";
import Sidebar from "./Sidebar";
import { Redirect } from "react-router";
import { AdminAuthContext } from "Contexts/AdminAuthContext";

interface IProps {
  children: ReactNode;
}

const ProtectedLayout: React.FC<IProps> = ({ children }) => {
  const { isAuthenticated } = useContext(AdminAuthContext);

  if (!isAuthenticated) {
    return <Redirect to="/admin/login" />;
  }

  return (
    <Styled.Container>
      <Sidebar />
      <Styled.Content>{children}</Styled.Content>
    </Styled.Container>
  );
};

export default ProtectedLayout;
