import { FaShoppingCart } from "react-icons/fa";

import colors from "color";
import styled from "styled-components";
interface IButton {
  disable: boolean;
  color: "green";
}

export const CartIcon = styled(FaShoppingCart).attrs(props => ({
  icon: FaShoppingCart
}))`
  font-size: 1.5em;
  margin: 0 10px;
  color: ${props => props.theme.colors.white.normal};
`;

export const Label = styled.span`
  font-size: ${({ theme }) => theme.fontSize.mm};
  vertical-align: super;
`;

export const Button = styled.button<IButton>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-family: ${({ theme }) => theme.fonts.secundary};
  font-size: ${({ theme }) => theme.fontSize.mm};
  color: ${({ theme }) => theme.colors.white.normal};
  font-weight: 600;
  min-width: 150px;
  padding: 10px 20px;
  border-radius: 60px;

  @media ${({ theme }) => theme.devices.tablet} {
    flex-grow: 1;
  }

  background-color: ${({ theme, disable, color }) =>
    disable ? theme.colors.gray.normal : theme.colors[color].dark};

  cursor: ${({ disable }) => (disable ? "auto" : "pointer")};

  &:hover {
    background-color: ${({ theme, disable, color }) =>
      disable
        ? theme.colors.gray.normal
        : colors(theme.colors[color].darker)
            .lighten(0.2)
            .toString()};
  }

  &:active {
    background-color: ${({ theme, disable, color }) =>
      disable
        ? theme.colors.gray.normal
        : colors(theme.colors[color].normal)
            .darken(0.2)
            .toString()};
  }

  &:focus {
    outline: none;
  }
`;
