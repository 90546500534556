import React, { useContext } from "react";
import { MdLogin } from "react-icons/md";
import Form from "Components/Form";
import TextInput from "Components/Form/Inputs/Text";
import ButtonPrimary from "Components/Buttons/Primary";
import * as Styled from "./styles";
import { AdminAuthContext } from "Contexts/AdminAuthContext";
import adminApi from "Helpers/adminApi";
import { loginAdmin } from "Api/admin/loginAdmin";
import { useDispatch } from "react-redux";
import localeActions from "Redux/Locale/actions";
import BackendErrorHelper from "Helpers/BackendErrorHelper";
import { useHistory } from "react-router-dom";

const AdminLogin: React.FC = () => {
  const { setAuth, isAuthenticated } = useContext(AdminAuthContext);

  const dispatch = useDispatch();

  const history = useHistory();

  if (isAuthenticated) {
    history.push("/admin/fairs");
  }

  const login = async (inputs: any) => {
    const { email, password } = inputs;

    try {
      const response = await loginAdmin({ email, password });
      if (response && response.data) {
        setAuth({
          token: response.data.token,
          refreshToken: response.data.refreshToken,
          user: response.data.user
        });
        adminApi.defaults.headers.common["Authorization"] = response.data.token;
        history.push("/admin/fairs");
      }
    } catch (error) {
      if (BackendErrorHelper.checkIfDefaultError(error)) {
        const message = BackendErrorHelper.getDefaultErrorMessage(
          error.response
        );
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else if (BackendErrorHelper.checkIfValidationError(error)) {
        const message = BackendErrorHelper.getValidatorErrorMessage(
          error.response
        );
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else {
        console.log("entrei");
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message:
              "Error ao tentar fazer login. Por favor, tente novamente mais tarde."
          })
        );
      }
    }
  };

  return (
    <Styled.Container>
      <Styled.Content>
        <Form
          alignItems="center"
          submit={login}
          formBottom={
            <Styled.ButtonContainer>
              <Styled.ButtonLogin>
                <ButtonPrimary type="submit">
                  Entrar&nbsp;&nbsp;
                  <MdLogin />
                </ButtonPrimary>
              </Styled.ButtonLogin>
            </Styled.ButtonContainer>
          }
        >
          <Styled.Fields>
            <TextInput
              name="email"
              label="Email"
              placeholder="Digite seu email"
              required
              validation="email"
            />
            <TextInput
              name="password"
              label="Senha"
              placeholder="Digite sua senha"
              required
              type={"password"}
            />
          </Styled.Fields>
        </Form>
      </Styled.Content>
    </Styled.Container>
  );
};

export default AdminLogin;
