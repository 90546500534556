import { FaSearch, FaShoppingCart } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";

export const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 9;

  @media ${({ theme }) => theme.devices.tablet} {
    position: fixed;
    background-color: ${props => props.theme.colors.white};
    box-shadow: none;
    overflow: hidden;
    justify-content: space-between;
    left: 0;
    right: 0;
    padding: 15px 20px;
    flex-direction: row;
    align-items: center;
    box-shadow: ${({ theme }) => theme.shadow.vertical};
  }

  &.white {
    background-color: #fff;
  }
`;

export const TopContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.green.dark};
  padding: 0 30px;
  @media ${({ theme }) => theme.devices.tablet} {
    background: none;
    align-items: center;
    display: flex;
    padding-right: 0;
  }
`;

export const MenuSecundary = styled.div``;

export const MiddleContainer = styled.div`
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${({ theme }) => theme.devices.tablet} {
    padding: 0px;
    padding-right: 20px;
    width: 100%;
  }
`;

export const Logo = styled(Link)`
  width: 300px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${props => props.theme.colors.white.normal};
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  img {
    width: 100%;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    width: 230px;
    padding: 0;
    top: 5px;
    bottom: 5px;
  }
`;

export const FormContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white.normal};
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.gray.normal};

  @media ${({ theme }) => theme.devices.laptop} {
    margin: 20px 5vw;
  }
  @media ${({ theme }) => theme.devices.tablet} {
    position: relative;
    margin: 20px 15px 0 15px;
    border-radius: 4px;
    border: none;
  }
`;
export const SearchTitle = styled.h1`
  font-weight: 300;
  color: ${({ theme }) => theme.colors.green.normal};
  margin: 0 0 20px 0;
`;
export const SearchCategory = styled.div``;

export const SearchSubmit = styled.div`
  margin-top: 15px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`;

export const SearchDescription = styled.p`
  text-align: center;
  margin: 0 0 20px 0;
  font-size: ${({ theme }) => theme.fontSize.mm};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.gray.light};
`;

export const Search = styled.input`
  border: none;
  flex-grow: 1;
  padding: 10px 5px;
  border-right: 1px solid ${({ theme }) => theme.colors.gray.normal};
  border-left: 1px solid ${({ theme }) => theme.colors.gray.normal};
  @media ${({ theme }) => theme.devices.tablet} {
    border: none;
  }
`;

export const SearchIcon = styled(FaSearch).attrs(props => ({
  icon: FaSearch
}))`
  font-size: 19px;
  margin: 0 auto;
  color: ${props => props.theme.colors.white.normal};
`;

export const SearchBtn = styled.button`
  background-color: ${({ theme }) => theme.colors.green.normal};
  border: none;
  color: ${({ theme }) => theme.colors.white.normal};
  margin: 0;
  padding: 0 25px;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 10px;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
`;

export const LogoContainer = styled.div`
  display: flex;
  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
    place-content: center;
  }
`;

export const CartConainer = styled(NavLink)`
  position: relative;
`;

export const CartIcon = styled(FaShoppingCart).attrs(props => ({
  icon: FaShoppingCart
}))`
  font-size: 25px;
  margin: 0 auto;
  cursor: pointer;
  padding-right: 20px;
  color: ${props => props.theme.colors.gray.normal};
`;

export const BadgeCircle = styled.div`
  background-color: ${({ theme }) => theme.colors.green.normal};
  color: ${({ theme }) => theme.colors.white.normal};
  font-size: ${({ theme }) => theme.fontSize.sm};
  position: absolute;
  display: flex;
  width: 20px;
  height: 20px;
  top: -5px;
  right: 10px;
  border-radius: 50%;
  justify-content: center;
`;

export const TickerMobile = styled.div`
  margin-top: 130px;
`;
