import api from "Helpers/Api";
import { useQuery } from "@tanstack/react-query";
import { TLastQuotation } from "Services/Quotations/quotations";

const getLastQuotations = async () => {
  const { data } = await api.get<TLastQuotation[]>(`/commodities/ticker`);
  return data;
};

export function useLastQuotations() {
  return useQuery(["lastQuotations"], () => getLastQuotations());
}
