import React, {
  useState,
  useContext,
  useEffect,
  createRef,
  useMemo
} from "react";
import FormContext from "Components/Form/context";
import { TError } from "Services/Error/error";
import * as Styled from "./styles";
import { TOption } from "Services/Input/options";
type TChangeEvent = React.ChangeEvent<HTMLInputElement>;
interface Props {
  name: string;
  label?: string;
  defaultValue?: string;
  required?: boolean;
  options: TOption[];
  change?(input: string): void;
  size?: string;
  space?: boolean;
  wrap?: boolean;
  disabled?: boolean;
  onChange?(selected: string, prev: string): void;
}
const RadioSet: React.FC<Props> = ({
  name,
  label,
  defaultValue,
  required = false,
  options = [],
  change,
  size = "sm",
  wrap = false,
  space = false,
  disabled = false,
  onChange
}) => {
  const { registerField, setFieldError } = useContext(FormContext);
  const [value, setValue] = useState<string>(defaultValue || "");
  const [error] = useState<TError>(
    required
      ? {
          message: "Campo Obrigatório",
          status: true
        }
      : {
          message: "",
          status: true
        }
  );
  useEffect(() => {
    setValue(defaultValue || "");
  }, [defaultValue]);
  const inputRef = useMemo(() => createRef<HTMLInputElement>(), []);
  useEffect(() => {
    registerField &&
      inputRef.current &&
      registerField(name, inputRef.current, required, false);
  }, [defaultValue, inputRef, name, registerField, required]);
  useEffect(() => {
    required &&
      setFieldError &&
      setFieldError(name, {
        status: error.message === "" ? false : required,
        message: error.message || ""
      });
  }, [defaultValue, error, inputRef, name, required, setFieldError]);
  const clickHandler = (e: TChangeEvent) => {
    onChange && onChange(e.target.value, value);
    setValue(e.target.value);
    change && change(e.target.value);
  };
  return (
    <Styled.ColContainer>
      {label && <Styled.LabelTitle>{label}</Styled.LabelTitle>}
      <Styled.Container wrap={wrap} space={space}>
        <input type="hidden" value={value} name={name} ref={inputRef} />
        {options.map((option, key) => (
          <Styled.Content key={`radio-${key}`}>
            <Styled.Radio
              defaultValue={defaultValue}
              type="radio"
              id={`${name}-${key}`}
              value={option.value}
              checked={value === option.value}
              onChange={clickHandler}
              size={size}
              disabled={disabled}
            />
            <Styled.Label htmlFor={`${name}-${key}`} size={size}>
              {option.name}
            </Styled.Label>
          </Styled.Content>
        ))}
      </Styled.Container>
    </Styled.ColContainer>
  );
};
export default RadioSet;
