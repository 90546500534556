import styled from "styled-components";
import { FaSearch } from "react-icons/fa";

interface IContainer {
  border: string;
  shadow: boolean;
}

interface ISearchLayout {
  transparent: boolean;
}

export const Container = styled.div<IContainer>`
  background-color: ${({ theme }) => theme.colors.white.normal};
  border-radius: ${({ border }) => border};
  box-shadow: ${({ shadow, theme }) =>
    shadow ? theme.shadow.vertical : "unset"};
  outline: ${({ shadow, theme }) =>
    shadow ? "none" : `${theme.colors.gray.normal} solid 1px`};
  flex-grow: 1;
  display: flex;
  @media ${({ theme }) => theme.devices.tablet} {
    margin-top: 10px;
  }
`;

export const Input = styled.input.attrs(props => ({ type: "text" }))`
  border: none;
  flex-grow: 1;
  padding-left: 10px;
  color: ${({ theme }) => theme.colors.green.dark};
  background-color: transparent;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export const ButtonIcon = styled.button`
  border: none;
  background: transparent;
  outline: none;
`;

export const SearchButton = styled.button<ISearchLayout>`
  background-color: ${({ theme, transparent }) =>
    transparent ? theme.colors.white.normal : theme.colors.green.normal};

  padding: ${({ transparent }) => (transparent ? "0" : "0 25px")};

  border: none;
  margin: 0;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 10px;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
`;

export const SearchIcon = styled(FaSearch).attrs(props => ({
  icon: FaSearch
}))<ISearchLayout>`
  font-size: 19px;
  padding: 7px 10px;
  margin: 0 auto;
  color: ${({ theme, transparent }) =>
    transparent ? theme.colors.gray.dark : theme.colors.white.normal};
`;
