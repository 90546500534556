import React, { ReactNode } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

import * as Styled from "./styles";

interface Props {
  type?: "submit" | "button" | "reset";
  disabled?: boolean;
  children?: ReactNode;
  action?(): void;
  color?: "green";
  icon: JSX.Element;
  rest?: any;
}

const CounterBtn: React.FC<Props> = props => {
  const {
    disabled = false,
    type = "button",
    children,
    action,
    color = "green",
    icon,
    ...rest
  } = props;

  return (
    <Styled.Button
      disable={disabled}
      type={type}
      color={color}
      onClick={disabled ? () => null : action}
      className="button-primary"
      {...rest}
    >
      <Styled.CounterIcon>{icon}</Styled.CounterIcon>

      <Styled.Label>{children}</Styled.Label>
    </Styled.Button>
  );
};

export default CounterBtn;
