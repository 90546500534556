import React, { ReactNode } from "react";
import * as Styled from "./styles";

interface ILoading {
  children?: ReactNode;
}

const Loading: React.FC<ILoading> = ({ children }) => {
  return (
    <Styled.Container>
      {children}
      <Styled.Spinner />
    </Styled.Container>
  );
};

export default Loading;
