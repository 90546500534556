import styled, { keyframes } from "styled-components";

const tickerAnimation = (rotate: number) => keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-${rotate}%);
  }
`;

export const TickerWrapper = styled.div`
  overflow: hidden;

  background-color: ${({ theme }) => theme.colors.black.normal};
`;

interface TickerContentProps {
  rotate: number;
  paused: boolean;
}

export const TickerContent = styled.div<TickerContentProps>`
  white-space: nowrap;
  display: inline-block;
  padding-right: 100%;
  cursor: grab;
  animation: ${({ rotate }) => tickerAnimation(rotate)} 80s linear infinite;
  animation-play-state: ${({ paused }) => (paused ? "paused" : "running")};
  animation-delay: 5s;

  display: flex;
  gap: 8px;

  margin: 0 4px;
`;

export const TickerQuotation = styled.span`
  padding: 0 12px;

  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1.6px;

  height: 39px;

  border-left: 1px rgba(255, 255, 255, 0.2) solid;

  &:last-child {
    border-right: 1px rgba(255, 255, 255, 0.2) solid;
  }
`;

interface IVariationProps {
  value: number;
}

export const Variation = styled.span<IVariationProps>`
  display: inline-block;
  margin-left: 6px;
  color: ${({ theme, value }) =>
    value < 0
      ? theme.colors.red.normal
      : value > 0
      ? theme.colors.green.normal
      : theme.colors.gray.normal};
`;

export const Infos = styled.div`
  display: flex;

  flex-direction: column;
  margin-left: 9px;
`;

export const Date = styled.span`
  display: inline-block;
  font-size: 9px;
  color: ${({ theme }) => theme.colors.gray.normal};
`;

export const Region = styled.span`
  display: inline-block;
  font-size: 9px;
  color: ${({ theme }) => theme.colors.gray.normal};
`;
