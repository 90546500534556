import { IMenuItemModel } from "../index";
import Routes from "Routes";
import { AiOutlineShopping } from "react-icons/ai";
import React from "react";

const menu: IMenuItemModel = {
  name: "Produtos",
  link: Routes.productsWithCategory
    .replace(":category?", "")
    .replace("/:subcategory?", ""),
  icon: <AiOutlineShopping />,
  submenu: [
    {
      category: "Aditivos e Fertilizantes Agrícolas",
      link: Routes.productsWithCategory
        .replace(":category?", "aditivos-e-fertilizantes-agricolas")
        .replace(":subcategory?", ""),
      items: [
        {
          name: "Aditivos",
          link: Routes.productsWithCategory
            .replace(":category?", "aditivos-e-fertilizantes-agricolas")
            .replace(":subcategory?", "aditivos")
        },
        {
          name: "Cascas",
          link: Routes.productsWithCategory
            .replace(":category?", "aditivos-e-fertilizantes-agricolas")
            .replace(":subcategory?", "cascas")
        },
        {
          name: "Corretivos",
          link: Routes.productsWithCategory
            .replace(":category?", "aditivos-e-fertilizantes-agricolas")
            .replace(":subcategory?", "corretivos")
        },
        {
          name: "Fertilizantes Inôrganicos",
          link: Routes.productsWithCategory
            .replace(":category?", "aditivos-e-fertilizantes-agricolas")
            .replace(":subcategory?", "fertilizantes-inorganicos")
        },
        {
          name: "Fertilizantes Orgânicos",
          link: Routes.productsWithCategory
            .replace(":category?", "aditivos-e-fertilizantes-agricolas")
            .replace(":subcategory?", "fertilizantes-organicos")
        },
        {
          name: "Gesso Agrícola",
          link: Routes.productsWithCategory
            .replace(":category?", "aditivos-e-fertilizantes-agricolas")
            .replace(":subcategory?", "gesso-agricola")
        },
        {
          name: "Outros Fertilizantes Agrícolas",
          link: Routes.productsWithCategory
            .replace(":category?", "aditivos-e-fertilizantes-agricolas")
            .replace(":subcategory?", "outros-fertilizantes-agricolas")
        }
      ]
    },
    {
      category: "Alimentos em Geral",
      link: Routes.productsWithCategory
        .replace(":category?", "alimentos-em-geral")
        .replace(":subcategory?", ""),
      items: [
        {
          name: "Açucar",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "acucar")
        },
        {
          name: "Arroz",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "arroz")
        },
        {
          name: "Café",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "cafe")
        },
        {
          name: "Carnes",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "carnes")
        },
        {
          name: "Castanhas",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "castanhas")
        },
        {
          name: "Cogumelo",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "cogumelo")
        },
        {
          name: "Embutidos/Defumados",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "embutidos-defumados")
        },
        {
          name: "Feijão",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "feijao")
        },
        {
          name: "Frutas",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "frutas")
        },
        {
          name: "Frutas Secas",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "frutas-secas")
        },
        {
          name: "Frutos do Mar",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "frutos-do-mar")
        },
        {
          name: "Hortaliças",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "hortalicas")
        },
        {
          name: "Laticínios",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "laticinios")
        },
        {
          name: "Mandioca",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "mandioca")
        },
        {
          name: "Mel de Abelha",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "mel-de-abelha")
        },
        {
          name: "Milho",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "milho")
        },
        {
          name: "Óleos",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "oleos")
        },
        {
          name: "Ovos",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "ovos")
        },
        {
          name: "Palmitos",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "palmitos")
        },
        {
          name: "Sementes",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "sementes")
        },
        {
          name: "Subprodutos/Resíduos",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "subprodutos-residuos")
        },
        {
          name: "Temperos e Especiarias",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "temperos-e-especiarias")
        },
        {
          name: "Trigo",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "trigo")
        },
        {
          name: "Outros Alimentos",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-em-geral")
            .replace(":subcategory?", "outros-alimentos")
        }
      ]
    },
    {
      category: "Alimentos para Nutrição Animal",
      link: Routes.productsWithCategory
        .replace(":category?", "alimentos-para-nutricao-animal")
        .replace(":subcategory?", ""),
      items: [
        {
          name: "Aditivos",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "aditivos")
        },
        {
          name: "Alfafa",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "alfafa")
        },
        {
          name: "Algaroba",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "algaroba")
        },
        {
          name: "Algodão",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "algodao")
        },
        {
          name: "Alpiste",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "alpiste")
        },
        {
          name: "Amendoim",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "amendoim")
        },
        {
          name: "Amido",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "amido")
        },
        {
          name: "Arroz",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "arroz")
        },
        {
          name: "Aveia",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "aveia")
        },
        {
          name: "Cana de Açúcar",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "cana-de-acucar")
        },
        {
          name: "Cevada",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "cevada")
        },
        {
          name: "Farinhas",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "farinhas")
        },
        {
          name: "Feno",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "feno")
        },
        {
          name: "Girassol",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "girassol")
        },
        {
          name: "Larvas/Insetos",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "larvas-insetos")
        },
        {
          name: "Levedura",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "levedura")
        },
        {
          name: "Milheto",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "milheto")
        },
        {
          name: "Milho",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "milho")
        },
        {
          name: "Painço",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "painco")
        },
        {
          name: "Polpa Cítrica",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "polpa-citrica")
        },
        {
          name: "Pré-secado",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "pre-secado")
        },
        {
          name: "Rações",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "racoes")
        },
        {
          name: "Sal",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "sal")
        },
        {
          name: "Silagem",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "silagem")
        },
        {
          name: "Soja",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "soja")
        },
        {
          name: "Sorgo",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "sorgo")
        },
        {
          name: "Subprodutos/Resíduos",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "subprodutos-residuos")
        },
        {
          name: "Trigo",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "trigo")
        },
        {
          name: "Triticale",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "triticale")
        },
        {
          name: "Outros Alimentos para Nutrição Animal",
          link: Routes.productsWithCategory
            .replace(":category?", "alimentos-para-nutricao-animal")
            .replace(":subcategory?", "outros-alimentos-para-nutricao-animal")
        }
      ]
    },
    {
      category: "Animais",
      link: Routes.productsWithCategory
        .replace(":category?", "animais")
        .replace(":subcategory?", ""),
      items: [
        {
          name: "Asininos",
          link: Routes.productsWithCategory
            .replace(":category?", "animais")
            .replace(":subcategory?", "asininos")
        },
        {
          name: "Aves",
          link: Routes.productsWithCategory
            .replace(":category?", "animais")
            .replace(":subcategory?", "aves")
        },
        {
          name: "Búfalos",
          link: Routes.productsWithCategory
            .replace(":category?", "animais")
            .replace(":subcategory?", "bufalo")
        },
        {
          name: "Cães",
          link: Routes.productsWithCategory
            .replace(":category?", "animais")
            .replace(":subcategory?", "caes")
        },
        {
          name: "Caprinos",
          link: Routes.productsWithCategory
            .replace(":category?", "animais")
            .replace(":subcategory?", "caprinos")
        },
        {
          name: "Cavalos",
          link: Routes.productsWithCategory
            .replace(":category?", "animais")
            .replace(":subcategory?", "cavalos")
        },
        {
          name: "Coelhos",
          link: Routes.productsWithCategory
            .replace(":category?", "animais")
            .replace(":subcategory?", "coelhos")
        },
        {
          name: "Embriões",
          link: Routes.productsWithCategory
            .replace(":category?", "animais")
            .replace(":subcategory?", "embrioes")
        },
        {
          name: "Escargot",
          link: Routes.productsWithCategory
            .replace(":category?", "animais")
            .replace(":subcategory?", "escargot")
        },
        {
          name: "Gado de Corte",
          link: Routes.productsWithCategory
            .replace(":category?", "animais")
            .replace(":subcategory?", "gado-de-corte")
        },
        {
          name: "Gado de Leite",
          link: Routes.productsWithCategory
            .replace(":category?", "animais")
            .replace(":subcategory?", "gado-de-leite")
        },
        {
          name: "Lhamas/Alpacas",
          link: Routes.productsWithCategory
            .replace(":category?", "animais")
            .replace(":subcategory?", "lhamas-alpacas")
        },
        {
          name: "Muares",
          link: Routes.productsWithCategory
            .replace(":category?", "animais")
            .replace(":subcategory?", "muares")
        },
        {
          name: "Ovinos",
          link: Routes.productsWithCategory
            .replace(":category?", "animais")
            .replace(":subcategory?", "ovinos")
        },
        {
          name: "Peixes",
          link: Routes.productsWithCategory
            .replace(":category?", "animais")
            .replace(":subcategory?", "peixes")
        },
        {
          name: "Sêmen",
          link: Routes.productsWithCategory
            .replace(":category?", "animais")
            .replace(":subcategory?", "semen")
        },
        {
          name: "Suínos",
          link: Routes.productsWithCategory
            .replace(":category?", "animais")
            .replace(":subcategory?", "suinos")
        },
        {
          name: "Outros Animais",
          link: Routes.productsWithCategory
            .replace(":category?", "animais")
            .replace(":subcategory?", "outros-animais")
        }
      ]
    },
    {
      category: "Defensivos Agrícolas",
      link: Routes.productsWithCategory
        .replace(":category?", "defensivos-agricolas")
        .replace(":subcategory?", ""),
      items: [
        {
          name: "Acaricidas",
          link: Routes.productsWithCategory
            .replace(":category?", "defensivos-agricolas")
            .replace(":subcategory?", "acaricidas")
        },
        {
          name: "Combate às Pragas",
          link: Routes.productsWithCategory
            .replace(":category?", "defensivos-agricolas")
            .replace(":subcategory?", "combate-as-pragas")
        },
        {
          name: "Defensivos Orgânicos",
          link: Routes.productsWithCategory
            .replace(":category?", "defensivos-agricolas")
            .replace(":subcategory?", "defensivos-organicos")
        },
        {
          name: "Desfolhantes",
          link: Routes.productsWithCategory
            .replace(":category?", "defensivos-agricolas")
            .replace(":subcategory?", "desfolhantes")
        },
        {
          name: "Dessecantes",
          link: Routes.productsWithCategory
            .replace(":category?", "defensivos-agricolas")
            .replace(":subcategory?", "dessecantes")
        },
        {
          name: "Espalhantes Adesivos",
          link: Routes.productsWithCategory
            .replace(":category?", "defensivos-agricolas")
            .replace(":subcategory?", "espalhantes-adesivos")
        },
        {
          name: "Fungicidas",
          link: Routes.productsWithCategory
            .replace(":category?", "defensivos-agricolas")
            .replace(":subcategory?", "fungicidas")
        },
        {
          name: "Herbicidas",
          link: Routes.productsWithCategory
            .replace(":category?", "defensivos-agricolas")
            .replace(":subcategory?", "herbicidas")
        },
        {
          name: "Inseticidas",
          link: Routes.productsWithCategory
            .replace(":category?", "defensivos-agricolas")
            .replace(":subcategory?", "inseticidas")
        },
        {
          name: "Óleos",
          link: Routes.productsWithCategory
            .replace(":category?", "defensivos-agricolas")
            .replace(":subcategory?", "oleos")
        },
        {
          name: "Outros Defensivos Agrícolas",
          link: Routes.productsWithCategory
            .replace(":category?", "defensivos-agricolas")
            .replace(":subcategory?", "outros-defensivos-agricolas")
        }
      ]
    },
    {
      category: "Embalagens",
      link: Routes.productsWithCategory
        .replace(":category?", "embalagens")
        .replace(":subcategory?", ""),
      items: [
        {
          name: "Acessórios para Embalagens",
          link: Routes.productsWithCategory
            .replace(":category?", "embalagens")
            .replace(":subcategory?", "acessorios-para-embalagens")
        },
        {
          name: "Bombonas",
          link: Routes.productsWithCategory
            .replace(":category?", "embalagens")
            .replace(":subcategory?", "bombonas")
        },
        {
          name: "Caixas",
          link: Routes.productsWithCategory
            .replace(":category?", "embalagens")
            .replace(":subcategory?", "caixas")
        },
        {
          name: "Galões/Tambor",
          link: Routes.productsWithCategory
            .replace(":category?", "embalagens")
            .replace(":subcategory?", "galoes-tambor")
        },
        {
          name: "Sacos de Papel",
          link: Routes.productsWithCategory
            .replace(":category?", "embalagens")
            .replace(":subcategory?", "sacos-de-papel")
        },
        {
          name: "Sacos de Ráfia",
          link: Routes.productsWithCategory
            .replace(":category?", "embalagens")
            .replace(":subcategory?", "sacos-de-rafia")
        },
        {
          name: "Sacos Especiais",
          link: Routes.productsWithCategory
            .replace(":category?", "embalagens")
            .replace(":subcategory?", "sacos-especiais")
        },
        {
          name: "Sacos para Cultivo",
          link: Routes.productsWithCategory
            .replace(":category?", "embalagens")
            .replace(":subcategory?", "sacos-para-cultivo")
        },
        {
          name: "Outras Embalagens",
          link: Routes.productsWithCategory
            .replace(":category?", "embalagens")
            .replace(":subcategory?", "outras-embalagens")
        }
      ]
    },
    {
      category: "Instalações Rurais",
      link: Routes.productsWithCategory
        .replace(":category?", "instalacoes-rurais")
        .replace(":subcategory?", ""),
      items: [
        {
          name: "Água",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "agua")
        },
        {
          name: "Armazenagem",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "armazenagem")
        },
        {
          name: "Baias",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "baias")
        },
        {
          name: "Balanças",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "balancas")
        },
        {
          name: "Bebedouros",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "bebedouros")
        },
        {
          name: "Bezerreiros",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "bezerreiros")
        },
        {
          name: "Câmaras Frigoríficas",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "camaras-frigorificas")
        },
        {
          name: "Cercamento",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "cercamento")
        },
        {
          name: "Climatizadores",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "climatizadores")
        },
        {
          name: "Cobertura",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "cobertura")
        },
        {
          name: "Comedouros",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "comedouros")
        },
        {
          name: "Comunicação",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "comunicacao")
        },
        {
          name: "Contenção",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "contencao")
        },
        {
          name: "Fábricas",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "fabricas")
        },
        {
          name: "Habitação",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "habitacao")
        },
        {
          name: "Iluminação/Energia",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "iluminacao-energia")
        },
        {
          name: "Indústria",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "industria")
        },
        {
          name: "Lago/Lagoa/Represa",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "lago-lagoa-represa")
        },
        {
          name: "Materiais para construção",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "materiais-para-construcao")
        },
        {
          name: "Mobília/Decoração",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "mobilia-decoracao")
        },
        {
          name: "Saneamento",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "saneamento")
        },
        {
          name: "Segurança",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "seguranca")
        },
        {
          name: "Tanques",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "tanques")
        },
        {
          name: "Viveiros",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "viveiros")
        },
        {
          name: "Outras Instalações Rurais",
          link: Routes.productsWithCategory
            .replace(":category?", "instalacoes-rurais")
            .replace(":subcategory?", "outras-instalacoes-rurais")
        }
      ]
    },
    {
      category: "Máquinas Pesadas",
      link: Routes.productsWithCategory
        .replace(":category?", "maquinas-pesadas")
        .replace(":subcategory?", ""),
      items: [
        {
          name: "Aeronaves",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-pesadas")
            .replace(":subcategory?", "aeronaves")
        },
        {
          name: "Classificadoras de Grãos",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-pesadas")
            .replace(":subcategory?", "classificadoras-de-graos")
        },
        {
          name: "Colheitadeiras/Colhedoras",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-pesadas")
            .replace(":subcategory?", "colheitadeiras-colhedoras")
        },
        {
          name: "Escavadeiras",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-pesadas")
            .replace(":subcategory?", "escavadeiras")
        },
        {
          name: "Guindastes",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-pesadas")
            .replace(":subcategory?", "guindastes")
        },
        {
          name: "Mini Carregadeiras",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-pesadas")
            .replace(":subcategory?", "mini-carregadeiras")
        },
        {
          name: "Mini Escavadeiras",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-pesadas")
            .replace(":subcategory?", "mini-escavadeiras")
        },
        {
          name: "Motoniveladoras",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-pesadas")
            .replace(":subcategory?", "motoniveladoras")
        },
        {
          name: "Pás Carregadeiras",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-pesadas")
            .replace(":subcategory?", "pas-carregadeiras")
        },
        {
          name: "Perfuratriz",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-pesadas")
            .replace(":subcategory?", "perfuratriz")
        },
        {
          name: "Retroescavadeiras",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-pesadas")
            .replace(":subcategory?", "retroescavadeiras")
        },
        {
          name: "Rolos Compactadores",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-pesadas")
            .replace(":subcategory?", "rolos-compactadores")
        },
        {
          name: "Tratores de Esteira",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-pesadas")
            .replace(":subcategory?", "tratores-de-esteira")
        },
        {
          name: "Outras máquinas pesadas",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-pesadas")
            .replace(":subcategory?", "outras-maquinas-pesadas")
        }
      ]
    },
    {
      category: "Máquinas/Equipamentos",
      link: Routes.productsWithCategory
        .replace(":category?", "maquinas-equipamentos")
        .replace(":subcategory?", ""),
      items: [
        {
          name: "Abastecedores de Pulverizadores",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "abastecedores-de-pulverizadores")
        },
        {
          name: "Cortadores de Grama",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "cortadores-de-grama")
        },
        {
          name: "Distribuidores",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "distribuidores")
        },
        {
          name: "Empilhadeiras",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "empilhadeiras")
        },
        {
          name: "Enfardadeiras",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "enfardadeiras")
        },
        {
          name: "Enleiradores",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "enleiradores")
        },
        {
          name: "Espalhadores",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "espalhadores")
        },
        {
          name: "Extratoras de Grãos",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "extratoras-de-graos")
        },
        {
          name: "Grades Agrícolas",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "grades-agricolas")
        },
        {
          name: "Guinchos Agrícolas",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "guinchos-agricolas")
        },
        {
          name: "Lavadoras De Alta Pressão",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "lavadoras-de-alta-pressao")
        },
        {
          name: "Motobombas",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "motobombas")
        },
        {
          name: "Motocultivadores",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "motocultivadores")
        },
        {
          name: "Motosserras",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "motosserras")
        },
        {
          name: "Perfuradores de Solo",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "perfuradores-de-solo")
        },
        {
          name: "Picadores",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "picadores")
        },
        {
          name: "Plainas e Pás",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "plainas-e-pas")
        },
        {
          name: "Plataformas",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "plataformas")
        },
        {
          name: "Pulverizadores",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "pulverizadores")
        },
        {
          name: "Roçadeiras",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "rocadeiras")
        },
        {
          name: "Rolos Faca",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "rolos-faca")
        },
        {
          name: "Segadeiras",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "segadeiras")
        },
        {
          name: "Semeadoras",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "semeadoras")
        },
        {
          name: "Subsoladores",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "subsoladores")
        },
        {
          name: "Tratores",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "tratores")
        },
        {
          name: "Vagões Forrageiros",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "vagoes-forrageiros")
        },
        {
          name: "Vagões Misturadores",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "vagoes-misturadores")
        },
        {
          name: "Outras Máquinas Agrícolas",
          link: Routes.productsWithCategory
            .replace(":category?", "maquinas-equipamentos")
            .replace(":subcategory?", "outras-maquinas-agricolas")
        }
      ]
    },
    {
      category: "Medicamentos",
      link: Routes.productsWithCategory
        .replace(":category?", "medicamentos")
        .replace(":subcategory?", ""),
      items: [
        {
          name: "Hormônios",
          link: Routes.productsWithCategory
            .replace(":category?", "medicamentos")
            .replace(":subcategory?", "hormonios")
        },
        {
          name: "Medicamentos Homeopáticos",
          link: Routes.productsWithCategory
            .replace(":category?", "medicamentos")
            .replace(":subcategory?", "medicamentos-homeopaticos")
        },
        {
          name: "Medicamentos Orgânicos",
          link: Routes.productsWithCategory
            .replace(":category?", "medicamentos")
            .replace(":subcategory?", "medicamentos-organicos")
        },
        {
          name: "Medicamentos Veterinários",
          link: Routes.productsWithCategory
            .replace(":category?", "medicamentos")
            .replace(":subcategory?", "medicamentos-veterinarios")
        },
        {
          name: "Vacinas",
          link: Routes.productsWithCategory
            .replace(":category?", "medicamentos")
            .replace(":subcategory?", "vacinas")
        },
        {
          name: "Vermífugos",
          link: Routes.productsWithCategory
            .replace(":category?", "medicamentos")
            .replace(":subcategory?", "vermifugos")
        },
        {
          name: "Outros Medicamentos",
          link: Routes.productsWithCategory
            .replace(":category?", "medicamentos")
            .replace(":subcategory?", "outros-medicamentos")
        }
      ]
    },
    {
      category: "Mudas/Ramas",
      link: Routes.productsWithCategory
        .replace(":category?", "mudas-ramas")
        .replace(":subcategory?", ""),
      items: [
        {
          name: "Acessórios/Utensílios",
          link: Routes.productsWithCategory
            .replace(":category?", "mudas-ramas")
            .replace(":subcategory?", "acessorios-utensilios")
        },
        {
          name: "Mudas",
          link: Routes.productsWithCategory
            .replace(":category?", "mudas-ramas")
            .replace(":subcategory?", "mudas")
        },
        {
          name: "Ramas",
          link: Routes.productsWithCategory
            .replace(":category?", "mudas-ramas")
            .replace(":subcategory?", "ramas")
        }
      ]
    },
    {
      category: "Peças/Acessórios",
      link: Routes.productsWithCategory
        .replace(":category?", "pecas-acessorios")
        .replace(":subcategory?", ""),
      items: [
        {
          name: "Peças para Caminhões",
          link: Routes.productsWithCategory
            .replace(":category?", "pecas-acessorios")
            .replace(":subcategory?", "pecas-para-caminhoes")
        },
        {
          name: "Peças para Colheitadeiras",
          link: Routes.productsWithCategory
            .replace(":category?", "pecas-acessorios")
            .replace(":subcategory?", "pecas-para-colheitadeiras")
        },
        {
          name: "Peças para Escavadeiras",
          link: Routes.productsWithCategory
            .replace(":category?", "pecas-acessorios")
            .replace(":subcategory?", "pecas-para-escavadeiras")
        },
        {
          name: "Peças para Grades Agrícolas",
          link: Routes.productsWithCategory
            .replace(":category?", "pecas-acessorios")
            .replace(":subcategory?", "pecas-para-grades-agricolas")
        },
        {
          name: "Peças para Pás Carregadeiras",
          link: Routes.productsWithCategory
            .replace(":category?", "pecas-acessorios")
            .replace(":subcategory?", "pecas-para-pas-carregadeiras")
        },
        {
          name: "Peças para Pulverizadores Agrícolas",
          link: Routes.productsWithCategory
            .replace(":category?", "pecas-acessorios")
            .replace(":subcategory?", "pecas-para-pulverizadores-agricolas")
        },
        {
          name: "Peças para Retroescavadeiras",
          link: Routes.productsWithCategory
            .replace(":category?", "pecas-acessorios")
            .replace(":subcategory?", "pecas-para-retroescavadeiras")
        },
        {
          name: "Peças para Roçadeiras Agrícolas",
          link: Routes.productsWithCategory
            .replace(":category?", "pecas-acessorios")
            .replace(":subcategory?", "pecas-para-rocadeiras-agricolas")
        },
        {
          name: "Peças para Tratores",
          link: Routes.productsWithCategory
            .replace(":category?", "pecas-acessorios")
            .replace(":subcategory?", "pecas-para-tratores")
        },
        {
          name: "Outras Peças e Acessórios Agricolas",
          link: Routes.productsWithCategory
            .replace(":category?", "pecas-acessorios")
            .replace(":subcategory?", "outras-pecas-e-acessorios-agricolas")
        }
      ]
    },
    {
      category: "Sementes",
      link: Routes.productsWithCategory
        .replace(":category?", "sementes")
        .replace(":subcategory?", ""),
      items: [
        {
          name: "Culturas",
          link: Routes.productsWithCategory
            .replace(":category?", "sementes")
            .replace(":subcategory?", "culturas")
        },
        {
          name: "Diversas",
          link: Routes.productsWithCategory
            .replace(":category?", "sementes")
            .replace(":subcategory?", "diversas")
        },
        {
          name: "Forrageiras",
          link: Routes.productsWithCategory
            .replace(":category?", "sementes")
            .replace(":subcategory?", "forrageiras")
        },
        {
          name: "Gramas",
          link: Routes.productsWithCategory
            .replace(":category?", "sementes")
            .replace(":subcategory?", "gramas")
        }
      ]
    },
    {
      category: "Outros Produtos",
      link: Routes.productsWithCategory
        .replace(":category?", "outros-produtos")
        .replace(":subcategory?", "")
    }
  ]
};

export default menu;
