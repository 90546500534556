import styled from "styled-components";

interface IListItem {
  size: number;
  inline: boolean;
  hover: boolean;
  isOne: boolean;
}

export const ListItem = styled.div<IListItem>`
  @supports (display: grid) {
    width: 100%;
    height: 100%;
  }

  @supports not (display: grid) {
    width: 32.5%;

    @media (max-width: 619px) {
      width: 100%;
    }

    @media (min-width: 620px) and (max-width: 1023px) {
      width: 49%;
    }
  }

  display: flex;
  flex-direction: column;

  cursor: pointer;
  transition: transform ${({ theme }) => theme.animation.duration};
  transform: scale(1);
  will-change: transform;

  @media ${({ theme }) => theme.devices.laptop} {
    &:hover {
      ${({ hover }) => hover && "transform: scale(1.1)"};
    }
  }
`;
