import React, { useState } from "react";
import * as Styled from "./styles";
import Routes from "Routes";
import { TRange } from "Services/pagination";
import { IAd } from "Services/Ad/ad";
import PageTitle from "Components/Base/PageTitle";
import Cities, { ICity } from "Components/Form/Inputs/Cities";
import AdItem from "Components/AdItem";
import { TSegment } from "Services/Segment/segment";
import States, { IState } from "Components/Form/Inputs/States";
import RangeField from "Components/Form/Inputs/RangeField";
import RadioSet from "Components/Form/Inputs/RadioSet";
import CheckboxSearch, {
  IOptions
} from "Components/Form/Inputs/CheckboxSearch";
import stringRange from "Helpers/stringRange";
import {
  CONVERTABLE_MEASUREMENT_UNITS,
  PROPERTY_FARM_CATEGORY_ID,
  VOCATIONS
} from "Helpers/consts";
import FilterItems from "Components/Filters/FilterItems";
import Sorting, { TSortingValue } from "Components/Sorting";
import { Categories } from "Components/Filters/Categories";
import List2 from "Components/List2";
import useProperties from "Hooks/Properties/useProperties";
import useCategories from "Hooks/Categories/useCategories";
import arrayToString from "Helpers/arrayToString";

interface Props {
  row?: number;
  perPage?: number;
  hideSearch?: boolean;
  hidePagination?: boolean;
  hideFilters?: boolean;
  hideSorting?: boolean;
  inline?: boolean;
  userId?: number;
  segment: TSegment;
  published?: boolean;
  title?: string;
  link?: string;
  setNoResults?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IVocation {
  name: string;
  value: string;
  id: string;
}

const PropertyList: React.FC<Props> = ({
  row = 3,
  perPage = 12,
  hideSearch = false,
  hideFilters = false,
  hidePagination = false,
  hideSorting = false,
  inline = false,
  userId,
  published = true,
  title,
  link = "",
  segment
}) => {
  const [sorting, setSorting] = useState<TSortingValue>();
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);
  const [priceRange, setPriceRange] = useState<TRange>({ min: 0, max: 0 });
  const [sizeRange, setSizeRange] = useState<TRange>({ min: 0, max: 0 });
  const [undefinedPrices, setUndefinedPrices] = useState<boolean>(true);
  const [undefinedSizes, setUndefinedSizes] = useState<boolean>(true);
  const [vocations, setVocations] = useState<IVocation[]>([]);
  const [measureId, setMeasureId] = useState<string>("39");
  const [categoriesSearch, setCategoriesSearch] = useState<string>("");
  const [selectedCategories, setSelectedCategories] = useState<IOptions[]>([]);

  const statesFilter = arrayToString(states);
  const citiesFilter = arrayToString(cities);

  const {
    categoriesError,
    categoriesFilter,
    categoriesOptions,
    categoryByUrl
  } = useCategories({
    selectedCategories,
    segment: "properties",
    search: categoriesSearch,
    states: statesFilter,
    cities: citiesFilter,
    hideEmpty: true
  });

  const categoryIds = categoriesFilter.split(",");

  const showVocation =
    (categoryByUrl && categoryByUrl.id === PROPERTY_FARM_CATEGORY_ID) ||
    (categoryIds && categoryIds.includes(PROPERTY_FARM_CATEGORY_ID)) ||
    (!selectedCategories.length && !categoryByUrl)
      ? true
      : false;

  const categoryTitle = categoryByUrl ? ` - ${categoryByUrl.title}` : "";
  const pageTitle = `${segment.title}${categoryTitle}`;

  const suffix = CONVERTABLE_MEASUREMENT_UNITS.find(
    unit => unit.value === measureId
  );

  const sizeSuffix = suffix ? ` ${suffix.name}` : "";

  return (
    <Styled.Container>
      <PageTitle
        link={link}
        sideChildren={
          !hideSorting && <Sorting setSorting={setSorting}></Sorting>
        }
      >
        {title ? title : pageTitle}
      </PageTitle>
      <List2
        useData={useProperties}
        dataParams={{
          segment: segment.segment,
          vocations: vocations.map(vocation => vocation.id).join(","),
          categories: categoriesFilter,
          states: statesFilter,
          cities: citiesFilter,
          price: stringRange(priceRange, setPriceRange),
          size: stringRange(sizeRange, setSizeRange),
          undefinedPrices: undefinedPrices,
          undefinedSizes: undefinedSizes,
          published,
          perPage,
          order: sorting && sorting.order,
          userId: userId && userId,
          measureId
        }}
        hideSearch={hideSearch}
        hideFilters={hideFilters}
        hidePagination={hidePagination}
        sorting={sorting}
        seeMoreLink={link}
        seeMoreText={`Ver mais ${pageTitle && pageTitle.toLowerCase()}`}
        inline={inline}
        segment={segment.segment}
        row={row}
        ItemLayout={(item: IAd) => (
          <AdItem
            viewCount={userId ? true : false}
            item={item}
            link={
              segment.link
                ? segment.link.replace(":slug", item.slug)
                : Routes.property.replace(":slug", item.slug)
            }
          />
        )}
      >
        <>
          <FilterItems title="Estados">
            <States
              name="states"
              setValue={setStates}
              segment={segment.value}
              label={""}
            />
          </FilterItems>
          <FilterItems title="Cidades">
            <Cities
              name="cities"
              setValue={setCities}
              states={states}
              segment={segment.segment}
              hiddenLabel={true}
            />
          </FilterItems>
          <FilterItems title="Preço">
            <RangeField
              title=""
              name="price"
              isCurrency={true}
              values={priceRange}
              change={setPriceRange}
              undefinedValues={undefinedPrices}
              setUndefinedValues={setUndefinedPrices}
              undefinedValuesMessage={"Incluir imóveis sem preço"}
            />
          </FilterItems>
          <FilterItems title="Tamanho">
            <RadioSet
              name="measure"
              options={CONVERTABLE_MEASUREMENT_UNITS}
              change={setMeasureId}
              defaultValue={measureId}
            />
            <RangeField
              name="size"
              suffix={sizeSuffix}
              values={sizeRange}
              change={setSizeRange}
              undefinedValues={undefinedSizes}
              setUndefinedValues={setUndefinedSizes}
              undefinedValuesMessage={"Incluir imóveis sem tamanho"}
            />
          </FilterItems>
          {!categoryByUrl && (
            <FilterItems title="Categorias">
              <Categories
                categoriesError={categoriesError}
                categoryOptions={categoriesOptions}
                categorySearch={categoriesSearch}
                selectedCategories={selectedCategories}
                setCategorySearch={setCategoriesSearch}
                setSelectedCategories={setSelectedCategories}
                segment={{
                  name: "properties",
                  subvalue: "imoveis-rurais"
                }}
              />
            </FilterItems>
          )}
          {showVocation && (
            <FilterItems title="Vocação">
              <CheckboxSearch
                label=""
                options={VOCATIONS}
                hideSearch={true}
                selected={vocations}
                setSelected={setVocations}
              />
            </FilterItems>
          )}
        </>
      </List2>
    </Styled.Container>
  );
};

export default PropertyList;
