import axios from "axios";
import config from "config";

const adminApi = axios.create({
  baseURL: config.backend
});

adminApi.defaults.headers.common["Content-Type"] = "application/json";
adminApi.defaults.headers.common["Accept"] = "application/json";
adminApi.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default adminApi;
