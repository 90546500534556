import types from "./types";
import { ActionType } from "typesafe-actions";
import * as actions from "./actions";

export type TAuthAction = ActionType<typeof actions>;

export interface BaseAction {
  type: string;
  payload?: any;
}

type TCartItem = {
  qty: number;
};

interface ICartModel {
  cartItems: { [id: string]: TCartItem };
  cartId?: string;
}

export const initialState: ICartModel = {
  cartItems: {}
};

const CartReducer = (
  state: ICartModel = initialState,
  action: BaseAction
): ICartModel => {
  switch (action.type) {
    case types.ADD_CART:
      return {
        ...state,
        cartItems: action.payload.cartItem
          ? {
              ...state.cartItems,
              [action.payload.cartItem.id]: {
                qty: action.payload.cartItem.qty
              }
            }
          : state.cartItems,
        cartId: action.payload.cartId
      };
    case types.SET_CARTID:
      return {
        ...state,
        cartId: action.payload.cartId
      };
    case types.REMOVE_CART:
      const newCartItems = state.cartItems;
      delete newCartItems[action.payload.id];
      return {
        ...state,
        cartItems: Object.assign({}, newCartItems) // Prestar atenção na questão do shallow compare dentro dos objetos... Se possível, criar um novo para os eventos que dependem dele serem disparados...
      };
    case types.CLEAR_CART:
      return {
        ...state,
        cartItems: {}
      };
  }
  return state;
};
export default CartReducer;
