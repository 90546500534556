import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  lazy,
  Suspense
} from "react";
import Header from "Components/Base/Header";
import Footer from "Components/Base/Footer";
import { useSelector } from "react-redux";
import { ReducerState } from "Redux/store";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ReactGA from "react-ga4";
import { createBrowserHistory } from "history";
import TagManager from "react-gtm-module";
import localeActions from "Redux/Locale/actions";
import authActions from "Redux/Auth/actions";
import * as Styled from "./styles";
import Routes, { Account as AccountRoutes } from "Routes";
import NotFound from "./NotFound";
import Tooltips from "Components/Tooltips";
import Modal from "Components/Modal";
import CreateAd from "Components/CreateAd";
import ScrollToTop from "Components/ScrollToTop";
import Cart from "./Cart";
import api from "Helpers/Api";
import Search from "./Search";
import HomeAccount from "./Account/MyAccount";
import Cookies from "Components/Cookies";
import TrialDays from "Components/TrialDays";
import Boletins from "./Boletins";

import { IAdTracking } from "Services/Tracking/tracking";
import { createTrack } from "Helpers/tracking";
import { IAuthModel } from "Redux/Auth/reducer";
import { DAYINMILISECONDS } from "Helpers/consts";
import Loading from "Components/Loading";
import { MARKETPLACE } from "config";
import StoresHome from "Components/Home/StoresHome";
import { OficialStores } from "./OficialStores";

import { CampaignContact } from "./Campaign";
import AdPlans from "./AdPlan";

const PowerBi = lazy(() => import("./PowerBi"));
const Vehicles = lazy(() => import("./Vehicles"));
const Products = lazy(() => import("./Products"));
const Articles = lazy(() => import("./Articles"));
const Quotations = lazy(() => import("./Quotations"));
const Fairs = lazy(() => import("./Fairs"));
const Jobs = lazy(() => import("./Jobs"));
const Services = lazy(() => import("./Services"));
const Properties = lazy(() => import("./Properties"));
const EditAds = lazy(() => import("./CreateAndEditAd"));
const Account = lazy(() => import("./Account"));
const Auth = lazy(() => import("./Auth"));
const InstitucionalPages = lazy(() => import("./InstitucionalPages"));
const Home = lazy(() => import("./Home"));
const OAuthBestSend = lazy(() => import("./OAuth/OAuthBestSend"));
const Leases = lazy(() => import("./Leases"));
const Checkout = lazy(() => import("./Checkout"));
const MainBanner = lazy(() => import("./Home/MainBanner"));
const SeparetedHome = lazy(() => import("./Home/SeparetedHome"));

const Stores = lazy(() => import("./Stores"));

type TTrackingEndpointResponse = {
  status: boolean;
};

const Base: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { modal, tracking, gaId } = useSelector(
    (state: ReducerState) => state.locale
  );
  const { subscriptions }: IAuthModel = useSelector(
    (state: ReducerState) => state.auth
  );
  const { initialTime, pageRef, productId, segment } = useSelector(
    (state: ReducerState) => state.locale.adRTime
  );
  const { user } = useSelector((state: ReducerState) => state.auth);

  const CanViewFreeTrialModal = useMemo(() => {
    return subscriptions?.filter(
      current =>
        current.on_trial === true &&
        (new Date(current.start_date).getTime() - new Date().getTime()) /
          DAYINMILISECONDS >
          0
    );
  }, [subscriptions]);

  const [message, setMessage] = useState<boolean>(false);

  const checkMobile = useCallback(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.innerWidth < 1023
      ? dispatch(localeActions.updateMobile(true))
      : dispatch(localeActions.updateMobile(false));
  }, [dispatch]);

  const tokenLogin = useCallback(() => {
    const refreshToken = localStorage.getItem("t");
    if (refreshToken) {
      dispatch(authActions.tokenLogin(refreshToken));
    }
  }, [dispatch]);

  useEffect(() => {
    checkMobile();
    tokenLogin();
    window.addEventListener("resize", checkMobile);
  }, [checkMobile, tokenLogin]);

  const handleClose = () => {
    dispatch(localeActions.modal());
  };

  const sendTracking = useCallback(async () => {
    try {
      if (tracking && tracking.length > 0) {
        await api.post<TTrackingEndpointResponse>("/tracking/send", {
          tracking
        });
        dispatch(localeActions.updateTracking([]));
      }
    } catch (error) {}
  }, [tracking]);

  function initAnalitics() {
    ReactGA.initialize("UA-156429954-1");
    const history = createBrowserHistory();
    history.listen(location => {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
        title: "Rurax"
      });
    });
    const tagManagerArgs = {
      gtmId: "GTM-NJHZ5WJ",
      dataLayer: {
        userId: ""
      }
    };
    TagManager.initialize(tagManagerArgs);

    ReactGA.ga((tracker: any) => {
      dispatch(localeActions.addGaId(tracker.get("clientId")));
    });
  }

  useEffect(() => {
    initAnalitics();
  }, []);

  useEffect(() => {
    sendTracking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const trackingTimeAtPage = async () => {
    return new Promise((resolve, reject) => {
      if (!!initialTime && pageRef !== location.pathname) {
        const endDate = new Date();

        let seconds = Math.abs(
          new Date(initialTime).getTime() - endDate.getTime()
        );

        const event: IAdTracking = {
          TYPE: "PROD_RTIME",
          GA_ID: gaId,
          USER_ID: user ? user.id : "",
          ITEM_ID: productId,
          DATA: {
            TIME: seconds.toString()
          },
          PAGE_REF: pageRef,
          TAGS: "mode=model1,version=v2,test=a"
        };

        segment &&
          dispatch(localeActions.addTracking(createTrack(event, segment)));

        dispatch(
          localeActions.addRTime({
            initialTime: 0,
            pageRef: "",
            productId: "",
            segment: null
          })
        );

        resolve(event);
      } else {
        resolve(null);
      }
    });
  };

  // Taking time user spent on page
  const awaitingAsyncTracking = async () => {
    await sendTracking();
    await trackingTimeAtPage();
  };

  useEffect(() => {
    window.addEventListener("beforeunload", sendTracking);

    return () => {
      window.removeEventListener("beforeunload", sendTracking);
    };
  }, [sendTracking]);

  return (
    <Styled.Container>
      <Cookies />
      <ScrollToTop />
      <Tooltips />
      <Header />

      {CanViewFreeTrialModal &&
        CanViewFreeTrialModal.length > 0 &&
        CanViewFreeTrialModal.map(trials => (
          <TrialDays
            days={Math.ceil(
              (new Date(trials.start_date).getTime() - new Date().getTime()) /
                DAYINMILISECONDS
            ).toLocaleString()}
          />
        ))}
      <Route exact path={Routes.home}>
        <Suspense fallback={<Loading />}>
          <MainBanner />
          <SeparetedHome />
        </Suspense>
      </Route>
      <Route exact path={Routes.campaignsContacts}>
        <Suspense fallback={<Loading />}>
          <CampaignContact />
        </Suspense>
      </Route>
      <Route exact path={Routes.storeAnnoucement}>
        <Suspense fallback={<Loading />}>
          <OficialStores />
        </Suspense>
      </Route>
      <Styled.Content>
        <Route
          render={({ location }) => (
            <TransitionGroup
              style={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                position: "relative"
              }}
            >
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <Switch location={location}>
                  <Route path={Routes.login}>
                    <Suspense fallback={<Loading />}>
                      <Styled.InnerPadding>
                        <Auth />
                      </Styled.InnerPadding>
                    </Suspense>
                  </Route>

                  <Route exact path={AccountRoutes.home}>
                    <Styled.InnerPadding>
                      <HomeAccount />
                    </Styled.InnerPadding>
                  </Route>

                  <Route path={AccountRoutes.home}>
                    <Suspense fallback={<Loading />}>
                      <Styled.InnerPadding>
                        <Account />
                      </Styled.InnerPadding>
                    </Suspense>
                  </Route>

                  <Route path={Routes.adCreateEdit}>
                    <Suspense fallback={<Loading />}>
                      <Styled.InnerPadding>
                        <EditAds />
                      </Styled.InnerPadding>
                    </Suspense>
                  </Route>

                  <Route path={[Routes.job, Routes.jobs]}>
                    <Suspense fallback={<Loading />}>
                      <Styled.InnerPadding>
                        <Jobs />
                      </Styled.InnerPadding>
                    </Suspense>
                  </Route>

                  <Route path={[Routes.service, Routes.services]}>
                    <Suspense fallback={<Loading />}>
                      <Styled.InnerPadding>
                        <Services />
                      </Styled.InnerPadding>
                    </Suspense>
                  </Route>

                  <Route path={[Routes.property, Routes.properties]}>
                    <Suspense fallback={<Loading />}>
                      <Styled.InnerPadding>
                        <Properties />
                      </Styled.InnerPadding>
                    </Suspense>
                  </Route>

                  <Route path={[Routes.lease, Routes.leases]}>
                    <Suspense fallback={<Loading />}>
                      <Styled.InnerPadding>
                        <Leases />
                      </Styled.InnerPadding>
                    </Suspense>
                  </Route>
                  <Route path={[Routes.search]}>
                    <Styled.InnerPadding>
                      <Search />
                    </Styled.InnerPadding>
                  </Route>
                  <Route
                    path={[
                      Routes.productsWithCategory,
                      Routes.products,
                      Routes.product
                    ]}
                  >
                    <Suspense fallback={<Loading />}>
                      <Styled.InnerPadding>
                        <Products />
                      </Styled.InnerPadding>
                    </Suspense>
                  </Route>

                  <Route path={[Routes.vehicles, Routes.vehicle]}>
                    <Suspense fallback={<Loading />}>
                      <Styled.InnerPadding>
                        <Vehicles />
                      </Styled.InnerPadding>
                    </Suspense>
                  </Route>

                  <Route path={[Routes.adplans]}>
                    <Suspense fallback={<Loading />}>
                      <Styled.InnerPadding>
                        <AdPlans />
                      </Styled.InnerPadding>
                    </Suspense>
                  </Route>

                  <Route path={[Routes.articles, Routes.article]}>
                    <Suspense fallback={<Loading />}>
                      <Styled.InnerPadding>
                        <Articles />
                      </Styled.InnerPadding>
                    </Suspense>
                  </Route>

                  <Route path={Routes.quotations}>
                    <Suspense fallback={<Loading />}>
                      <Styled.InnerPadding>
                        <Quotations />
                      </Styled.InnerPadding>
                    </Suspense>
                  </Route>

                  <Route path={Routes.fairs}>
                    <Suspense fallback={<Loading />}>
                      <Styled.InnerPadding>
                        <Fairs />
                      </Styled.InnerPadding>
                    </Suspense>
                  </Route>

                  <Route path={Routes.powerbi}>
                    <Suspense fallback={<Loading />}>
                      <Styled.InnerPadding>
                        <PowerBi />
                      </Styled.InnerPadding>
                    </Suspense>
                  </Route>

                  {MARKETPLACE && (
                    <Route path={Routes.boletins}>
                      <Styled.InnerPadding>
                        <Boletins />
                      </Styled.InnerPadding>
                    </Route>
                  )}

                  <Route path={Routes.cart}>
                    <Styled.InnerPadding>
                      <Cart />
                    </Styled.InnerPadding>
                  </Route>

                  <Route path={[Routes.checkout, Routes.subscription]}>
                    <Suspense fallback={<Loading />}>
                      <Checkout />
                    </Suspense>
                  </Route>

                  <Route
                    path={[
                      Routes.aboutUs,
                      Routes.useConditions,
                      Routes.privacityConditions,
                      Routes.securityConditions,
                      Routes.cookieConditions,
                      Routes.dataOwnConditions,
                      Routes.devolutionConditions,
                      Routes.paymentConditions,
                      Routes.deliveryConditions,
                      Routes.talkToUs,
                      Routes.announceProducts,
                      Routes.publishServices,
                      Routes.publicity,
                      Routes.workWithUs,
                      Routes.partnerConditions,
                      Routes.pricesConditions,
                      Routes.earnMoneyConditions,
                      Routes.sellWithUs,
                      Routes.workWithUs,
                      Routes.termStores
                    ]}
                  >
                    <Suspense fallback={<Loading />}>
                      <Styled.InnerPadding>
                        <InstitucionalPages />
                      </Styled.InnerPadding>
                    </Suspense>
                  </Route>

                  <Route path={Routes.home} exact>
                    <Suspense fallback={<Loading />}>
                      <Home />
                    </Suspense>
                  </Route>

                  <Route path={Routes.bestSend} exact>
                    <Suspense fallback={<Loading />}>
                      <OAuthBestSend />
                    </Suspense>
                  </Route>

                  <Route path={[Routes.store, Routes.stores]}>
                    <Suspense fallback={<Loading />}>
                      <Styled.InnerPadding>
                        <Stores />
                      </Styled.InnerPadding>
                    </Suspense>
                  </Route>

                  <Route exact path={Routes.storeAnnoucement}>
                    <Suspense fallback={<Loading />}>
                      <></>
                    </Suspense>
                  </Route>
                  <Route exact path={Routes.campaignsContacts}>
                    <Suspense fallback={<Loading />}>
                      <></>
                    </Suspense>
                  </Route>
                  <Route component={NotFound} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Styled.Content>
      {modal && (
        <Modal title={"Publicar Anúncio"} handleClose={handleClose}>
          <CreateAd />
        </Modal>
      )}
      <Route exact path={Routes.home}>
        <Suspense fallback={<Loading />}>
          <StoresHome />
        </Suspense>
      </Route>
      <Footer />
      {message && (
        <Styled.Message>
          <Styled.Close onClick={() => setMessage(false)} />
          <Styled.MessageContainer>
            <Styled.Warning>
              <Styled.WarningIcon />
              Esta é uma versão MVP de avaliação e contém dados fictícios
              coletados da internet apenas como exemplo
            </Styled.Warning>

            <Styled.Link
              href="https://docs.google.com/forms/d/e/1FAIpQLSfV6Zp7dkrz4sBG6OY5_Rt7E1LqBZu1xJaxAotM_kp9lwqkMg/viewform?usp=sf_link"
              target="__blank"
            >
              <Styled.FileIcon />
              <p>
                Gostaríamos do seu feedback respondendo a este questionário com
                base na sua experiência com o Portal RURAX.
                <br /> <b>É simples e rápido, clique aqui para acessa-lo!</b>
              </p>
            </Styled.Link>
          </Styled.MessageContainer>
        </Styled.Message>
      )}
    </Styled.Container>
  );
};

export default Base;
