import { TCartItem } from "Services/Cart/cart";

enum types {
  ADD_CART = "ADD_CART",
  REMOVE_CART = "REMOVE_CART",
  SET_CARTID = "",
  CLEAR_CART = "CLEAR_CART"
}

export interface CartReponse {
  items: TCartItem[];
  cartId: string;
}

export default types;
