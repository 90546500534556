import styled from "styled-components";
import color from "color";
import { NavLink } from "react-router-dom";
interface IContainer {
  show: boolean;
}

export const Container = styled.div<IContainer>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  transform: translateX(${({ show }) => (show ? 0 : "100%")});
  transition: transform ${({ theme }) => theme.animation.duration};
  will-change: transform;
  transition-delay: ${({ show }) => (show ? "0" : "200ms")};
  z-index: 11;
`;

export const Bg = styled.div<IContainer>`
  background-color: ${({ theme, show }) =>
    color(theme.colors.black.normal)
      .alpha(show ? 0.6 : 0)
      .toString()};
  flex-grow: 1;
  transition: background-color ${({ theme }) => theme.animation.duration};
  will-change: background-color;
  transition-delay: ${({ show }) => (show ? "200ms" : "0")};
`;

export const Menu = styled.div`
  background-color: ${({ theme }) => theme.colors.white.normal};
  padding: 30px;
  min-width: 200px;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.devices.tablet} {
    width: 100vw;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  font-weight: 500;
  flex-grow: 1;
`;

export const LinkItem = styled(NavLink)`
  list-style: none;
  color: ${({ theme }) => theme.colors.gray.normal};
  width: 100;
  text-align: left;
  padding: 10px 15px;
  margin: 1px 0;
  text-decoration: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.normal};
  &:nth-last-child(2) {
    border-bottom: none;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.green.normal};
  }
`;

export const Logout = styled.span`
  color: ${({ theme }) => theme.colors.gray.normal};
  cursor: pointer;
  margin-top: auto;
  text-align: right;

  &:hover {
    color: ${({ theme }) => theme.colors.red.normal};
  }
`;
