import api from "Helpers/Api";
import { setCookie, parseCookies, destroyCookie } from "nookies";
import React, { useEffect } from "react";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState
} from "react";
import { useLoading } from "./LoadingProvider";
import { getUser } from "Api/admin/getUser";

interface Props {
  children: ReactNode;
}

export interface User {
  id: string;
  email: string;
  name: string;
  avatar: string;
}

export interface IUserSession {
  token: string;
  refreshToken: string;
  user?: User;
}

type AdminAuthContextProps = {
  auth: IUserSession | undefined;
  isAuthenticated: boolean;
  setAuth: Dispatch<SetStateAction<IUserSession | undefined>>;
  syncUser: (token: string, refreshToken: string) => Promise<void>;
};

export const AdminAuthContext = createContext({} as AdminAuthContextProps);

export function AdminAuthProvider({ children }: Props) {
  const [auth, setAuth] = useState<IUserSession | undefined>(undefined);

  const { setIsLoading } = useLoading();

  const isAuthenticated = !!auth;

  useEffect(() => {
    if (auth && auth.token) {
      api.defaults.headers["Authorization"] = `bearer ${auth.token}`;
      setCookie(null, "rurax.token", auth.token, {
        path: "/"
      });
      setCookie(null, "rurax.refreshToken", auth.refreshToken, {
        path: "/"
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth && auth.token]);

  const syncUser = async (token: string, refreshToken: string) => {
    const { data: auth, status } = await getUser({ token, refreshToken });

    if (auth && status) {
      setAuth({
        ...auth
      });
    } else {
      destroyCookie(null, "rurax.token", {
        path: "/"
      });
      destroyCookie(null, "rurax.refreshToken", {
        path: "/"
      });
      setAuth(undefined);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    // Se tem algo nos cookies e se tem rurax.token
    const cookies = parseCookies();
    const isEmpty = !cookies.hasOwnProperty("rurax.token");

    if (!isEmpty) {
      syncUser(cookies["rurax.token"], cookies["rurax.refreshToken"]);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AdminAuthContext.Provider
      value={{ isAuthenticated, auth, setAuth, syncUser }}
    >
      {children}
    </AdminAuthContext.Provider>
  );
}

export function signOut() {
  destroyCookie(null, "rurax.user", {
    path: "/"
  });
  destroyCookie(null, "rurax.token", {
    path: "/"
  });
  localStorage.clear();
  window.location.reload();
}
