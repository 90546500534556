import styled from "styled-components";

export const PartnersContainers = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1380px;
  align-items: center;

  margin: 22px auto;
  padding: 24px 0;
`;
export const PartnersTitle = styled.h2`
  font-weigth: bold;
  font-size: ${({ theme }) => theme.fontSize.md};
`;

export const PartnersContent = styled.div`
  display: flex;
  gap: 56px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
    margin-bottom: 48px;
  }
`;

export const CEIALogo = styled.img`
  max-width: 240px;
  max-height: 140px;
`;
export const UFGLogo = styled.img`
  max-width: 180px;
  max-height: 100px;
`;

export const EmbrapiiLogo = styled.img`
  max-width: 180px;
  max-height: 80px;
`;
export const SebraeLogo = styled.img`
  max-width: 180px;
  max-height: 100px;
`;

export const Divisor = styled.div`
  margin: 0 auto 16px auto;
  width: 90%;
  height: 0.1px;

  background: ${({ theme }) => theme.colors.gray.normal};
`;
