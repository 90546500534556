import { ActionType } from "typesafe-actions";
import actions from "./actions";
import types from "./types";
type TermsAction = ActionType<typeof actions>;
interface TermsState {
  accepted: boolean;
}
const initialState: TermsState = {
  accepted: false
};
const termsAcceptedReducer = (
  state: TermsState = initialState,
  action: TermsAction
): TermsState => {
  switch (action.type) {
    case types.SET_TERMS_ACCEPTED:
      return {
        ...state,
        accepted: action.payload
      };
    default:
      return state;
  }
};
export default termsAcceptedReducer;
