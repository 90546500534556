import { combineReducers } from "redux";

import locale from "./Locale/reducer";
import auth from "./Auth/reducer";
import cart from "./Cart/reducer";
import terms from "./Terms/reducer";

const rootReducer = combineReducers({
  locale,
  auth,
  cart,
  terms
});

export default rootReducer;
