import React, { ReactNode } from "react";

import * as Styled from "./styles";

interface Props {
  color?: "green" | "red" | "gray" | "orange";
  children?: ReactNode;
  redirectLink: string;
}

const SeeMore: React.FC<Props> = props => {
  const { color = "green", children, redirectLink } = props;

  return (
    <Styled.LinkButton to={redirectLink} color={color}>
      {children}
    </Styled.LinkButton>
  );
};

export default SeeMore;
