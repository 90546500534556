import React, { useState } from "react";
import { ReducerState } from "Redux/store";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Styled from "./styles";
import Routes from "Routes";
import { TSegment } from "Services/Segment/segment";
import PropertyList from "Views/Properties/List";
import ProductList from "Views/Products/List";
import JobList from "Views/Jobs/List";
import ArticleSection from "Components/ArticleSection";
import VehicleList from "Views/Vehicles/List";
import { sections } from "Views/Articles/Home";

const Search: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchParams = params.get("s") || "";

  const mobile = useSelector((state: ReducerState) => state.locale.mobile);

  const [noPropertyResults, setNoPropertyResults] = useState<boolean>(false);
  const [noProductsResults, setNoProductsResults] = useState<boolean>(false);
  const [noVehiclesResults, setNoVehiclesResults] = useState<boolean>(false);
  const [noJobsResults, setNoJobsResults] = useState<boolean>(false);
  const [noServicesResults, setNoServicesResults] = useState<boolean>(false);
  const [noArticleResults, setNoArticleResults] = useState<boolean>(false);

  const segment: TSegment[] = [
    {
      title: "Imóveis Rurais",
      value: "properties",
      segment: "properties",
      link: Routes.property
    },
    {
      title: "Arrendamentos",
      value: "properties",
      segment: "leases",
      link: Routes.lease
    }
  ];

  const redirect = {
    properties: Routes.properties,
    leases: Routes.leases,
    products: Routes.products,
    vehicles: Routes.vehicles,
    job: Routes.job,
    services: Routes.services,
    articles: Routes.articles
  };

  const getSearchResultsMessage = (searchText: string) => {
    if (
      noPropertyResults &&
      noProductsResults &&
      noVehiclesResults &&
      noJobsResults &&
      noServicesResults &&
      noArticleResults
    )
      return `Nenhum resultado para "${searchText}". Pesquise com outros termos.`;
    else return `Resultados para "${searchText}"`;
  };

  return (
    <>
      <Styled.Container>
        <Styled.SearchTitleBox>
          <Styled.Title>{getSearchResultsMessage(searchParams)}</Styled.Title>
        </Styled.SearchTitleBox>

        {!noPropertyResults && (
          <PropertyList
            segment={segment[0]}
            hideSearch={true}
            hidePagination={true}
            hideFilters={true}
            inline={true}
            row={4}
            perPage={4}
            hideempty={true}
            link={redirect.properties.replace(
              ":category?",
              `?s=${searchParams}`
            )}
            setNoResults={setNoPropertyResults}
          />
        )}

        {!noProductsResults && (
          <ProductList
            hideSearch={true}
            hidePagination={true}
            hideFilters={true}
            inline={true}
            row={4}
            perPage={4}
            hideempty={true}
            link={redirect.products
              .replace(":category?", `?s=${searchParams}`)
              .replace("/:subcategory?", "")}
            setNoResults={setNoProductsResults}
          />
        )}

        {!noVehiclesResults && (
          <VehicleList
            hideSearch={true}
            hidePagination={true}
            hideFilters={true}
            inline={true}
            row={4}
            perPage={4}
            hideempty={true}
            link={redirect.vehicles
              .replace(":category?", `?s=${searchParams}`)
              .replace("/:subcategory?", "")}
            setNoResults={setNoVehiclesResults}
          />
        )}

        {!noJobsResults && (
          <JobList
            segment="jobs"
            hideSearch={true}
            hidePagination={true}
            hideFilters={true}
            inline={mobile ? true : false}
            row={4}
            perPage={mobile ? 4 : 8}
            hideempty={true}
            link={redirect.job.replace(":slug", `?s=${searchParams}`)}
            setNoResults={setNoJobsResults}
          />
        )}

        {!noServicesResults && (
          <JobList
            segment="services"
            hideSearch={true}
            hidePagination={true}
            hideFilters={true}
            inline={mobile ? true : false}
            row={4}
            perPage={mobile ? 4 : 8}
            hideempty={true}
            link={`${redirect.services}?s=${searchParams}`}
            setNoResults={setNoServicesResults}
          />
        )}

        {!noArticleResults && (
          <ArticleSection
            hidePagination={true}
            section={{ ...sections[0], title: "notícias" }}
            search={searchParams}
            row={3}
            perPage={6}
            link={redirect.articles.replace(":category?", `?s=${searchParams}`)}
            setNoResults={setNoArticleResults}
          />
        )}
      </Styled.Container>
    </>
  );
};

export default Search;
