import styled from "styled-components";
import TerrainIcon from "@material-ui/icons/TerrainTwoTone";
import { Link } from "react-router-dom";

export const Container = styled(Link)`
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  flex-grow: 1;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  box-shadow: ${({ theme }) => theme.shadow.vertical};

  @media ${({ theme }) => theme.devices.tablet} {
    min-width: 300px;
  }
`;

export const Picture = styled.picture`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 200px;
  background-color: ${({ theme }) => theme.colors.white.normal};
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Placeholder = styled(TerrainIcon).attrs(() => ({
  fontSize: "large"
}))`
  width: 30px;
  margin: 80px auto;
  color: ${({ theme }) => theme.colors.gray.dark};
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
`;

export const Info = styled.div`
  background-color: ${({ theme }) => theme.colors.white.normal};
  bottom: 0;
  flex-grow: 1;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`;

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: 800;
  color: ${({ theme }) => theme.colors.gray.dark};
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const Description = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray.dark};
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const Category = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.mm};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray.dark};
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Vocation = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray.dark};
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Price = styled.span`
  font-size: ${({ theme }) => theme.fontSize.md2};
  background-color: ${({ theme }) => theme.colors.white.normal};
  font-weight: 600;
  flex-grow: 1;
  color: ${({ theme }) => theme.colors.green.normal};
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 10px;
  margin: 0 10px;
  overflow: hidden;
`;

export const Location = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.gray.dark};
`;

export const LocationInfo = styled.span`
  font-size: ${({ theme }) => theme.fontSize.sm};

  &:last-child {
    flex-grow: 1;
  }
`;

export const Footer = styled.span`
  background-color: ${({ theme }) => theme.colors.green.normal};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.sm};
  letter-spacing: 1px;
  margin: 0;
  padding: 10px 0;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white.normal};
`;

export const ViewCount = styled.div`
  background-color: ${({ theme }) => theme.colors.gray.dark};
  border: none;
  color: ${({ theme }) => theme.colors.white.normal};
  width: 100%;
  padding: 0;
  margin: 0;
  padding: 1px;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: ${({ theme }) => theme.fontSize.sm};
`;

export const Views = styled.span`
  margin-left: 10px;
`;

export const Contacts = styled.span`
  float: right;
  margin-right: 10px;
`;

export const Content = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.white.normal};
`;

export const Destaque = styled.div`
  background-color: yellow;
  color: black;
  position: absolute;
  padding: 5px 100px;
  min-width: 300px;
  transform: rotate(-45deg) translate(-37%, 0);
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  top: -20px;
  box-sizing: border-box;
`;

export const ModalContent = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
  color: black;
`;

export const Button = styled.button`
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: black;
  font-size: 16px;
  cursor: pointer;
`;
