import React, { ReactNode, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import * as Styled from "./styles";

interface Props {
  children: ReactNode;
  title: string;
}

const FilterItems: React.FC<Props> = ({ children, title }) => {
  const [show, setShow] = useState(true);

  return (
    <Styled.Filter show={show}>
      <Styled.FilterTitle
        show={show}
        onClick={e => setShow(previous => !previous)}
      >
        <span>{title}</span>
        <IoIosArrowForward color="gray" />
      </Styled.FilterTitle>
      <Styled.Content show={show}>{children}</Styled.Content>
    </Styled.Filter>
  );
};

export default FilterItems;
