import styled from "styled-components";
import { theme } from "theme/theme";

const green = theme.colors.green.light;
const gray = theme.colors.gray.normal;

interface CounterProps {
  isAvailable?: boolean;
}

export const Counter = styled.div`
  display: flex;
  align-content: flex-start;
  flex-direction: row;
`;
export const Display = styled.div<CounterProps>`
  font-size: 22px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  color: ${theme.colors.white.normal};
  background-color: ${({ isAvailable }) => (isAvailable ? green : gray)};
`;
