import { useQuery } from "@tanstack/react-query";
import propertiesList from "Api/properties";
import { PropertiesListRequest } from "Services/Properties";

export default function useProperties(params: PropertiesListRequest) {
  const query = () => propertiesList(params);

  return useQuery({
    queryKey: ["propertiesList", ...Object.values(params)],
    queryFn: query,
    keepPreviousData: true
  });
}
