import React, { useState } from "react";
import * as Styled from "../styles";

interface Props {
  description: string;
  show: boolean;
}

export const Description: React.FC<Props> = ({ description, show }) => {
  return (
    <Styled.Description isOpen={show}>
      <Styled.TitleDescription>Descrição:</Styled.TitleDescription>
      <Styled.TextDescription>{description}</Styled.TextDescription>
    </Styled.Description>
  );
};
