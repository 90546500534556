import React, { useEffect, useState } from "react";
import Routes from "Routes";
import * as Styled from "./styles";
import { TRange } from "Services/pagination";
import PageTitle from "Components/Base/PageTitle";
import Cities, { ICity } from "Components/Form/Inputs/Cities";
import States, { IState } from "Components/Form/Inputs/States";
import AdItem from "Components/AdItem";
import RangeField from "Components/Form/Inputs/RangeField";
import { IOptions } from "Components/Form/Inputs/CheckboxSearch";
import { IAd } from "Services/Ad/ad";
import stringRange from "Helpers/stringRange";
import FilterItems from "Components/Filters/FilterItems";
import Sorting, { TSortingValue } from "Components/Sorting";
import { Categories } from "Components/Filters/Categories";
import { Subcategories } from "Components/Filters/Subcategories";
import useCategories from "Hooks/Categories/useCategories";
import useSubCategories from "Hooks/Categories/useSubcategories";
import { useProducts } from "Hooks/Products/useProducts";
import List2 from "Components/List2";
import arrayToString from "Helpers/arrayToString";
import { Helmet } from "react-helmet";

interface Props {
  row?: number;
  perPage?: number;
  hideSearch?: boolean;
  hidePagination?: boolean;
  hideFilters?: boolean;
  hideSorting?: boolean;
  inline?: boolean;
  userId?: number;
  published?: boolean;
  title?: string;
  link?: string;
}

const ProductList: React.FC<Props> = ({
  row = 3,
  perPage = 12,
  hideSearch = false,
  hideFilters = false,
  hidePagination = false,
  hideSorting = false,
  inline = false,
  userId,
  published = true,
  title,
  link = ""
}) => {
  const [sorting, setSorting] = useState<TSortingValue>();
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);
  const [priceRange, setPriceRange] = useState<TRange>({ min: 0, max: 0 });
  const [undefinedPrices, setUndefinedPrices] = useState(true);
  const [categorySearch, setCategorySearch] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<IOptions[]>([]);
  const [subcategorySearch, setSubcategorySearch] = useState("");
  const [selectedSubcategories, setSelectedSubcategories] = useState<
    IOptions[]
  >([]);

  const statesFilter = arrayToString(states);
  const citiesFilter = arrayToString(cities);

  const {
    categoriesError,
    categoriesFilter,
    categoriesOptions,
    categoryByUrl
  } = useCategories({
    segment: "products",
    search: categorySearch,
    states: statesFilter,
    cities: citiesFilter,
    hideEmpty: true,
    selectedCategories
  });

  const {
    subCategoriesError,
    subCategoriesFilter,
    subCategoriesOptions,
    subcategoryByUrl
  } = useSubCategories({
    categories: categoriesFilter,
    search: subcategorySearch,
    states: statesFilter,
    cities: citiesFilter,
    hideEmpty: true,
    selectedSubcategories
  });

  const categoryTitle = categoryByUrl ? ` - ${categoryByUrl.title}` : "";
  const subcategoryTitle = subcategoryByUrl ? `/${subcategoryByUrl.title}` : "";
  const pageTitle = `Produtos Destacados${categoryTitle}${subcategoryTitle}`;

  useEffect(() => {
    setSelectedSubcategories([]);
  }, [selectedCategories]);
  return (
    <Styled.Container>
      <Helmet>
        <meta name="description" content="Produtos à venda" />
        <link rel="canonical" href="https://rurax.com.br/produtos/" />
      </Helmet>
      <PageTitle
        link={link}
        sideChildren={!hideSorting && <Sorting setSorting={setSorting} />}
      >
        {title ? title : pageTitle}
      </PageTitle>
      <List2
        useData={useProducts}
        dataParams={{
          vehicles: false,
          categories: categoriesFilter,
          subcategories: subCategoriesFilter,
          states: statesFilter,
          cities: citiesFilter,
          price: stringRange(priceRange, setPriceRange),
          undefinedPrices,
          published,
          perPage,
          sort: sorting && sorting.sort,
          order: sorting && sorting.order,
          userId: userId && userId
        }}
        hideSearch={hideSearch}
        hideFilters={hideFilters}
        hidePagination={hidePagination}
        sorting={sorting}
        inline={inline}
        seeMoreLink={link}
        seeMoreText={`Ver mais ${pageTitle.toLowerCase()}`}
        row={row}
        filters={{
          states: statesFilter,
          cities: citiesFilter,
          price: stringRange(priceRange),
          categories: categoriesFilter,
          subcategories: subCategoriesFilter
        }}
        segment="products"
        ItemLayout={(item: IAd) => (
          <>
            <AdItem
              viewCount={userId ? true : false}
              item={item}
              link={Routes.product.replace(":slug", item.slug)}
            />
          </>
        )}
      >
        <>
          <FilterItems title="Estados">
            <States
              name="states"
              setValue={setStates}
              segment="products"
              categories={categoriesFilter}
              subcategories={subCategoriesFilter}
              label=""
            />
          </FilterItems>
          <FilterItems title="Cidades">
            <Cities
              name="cities"
              setValue={setCities}
              states={states}
              segment="products"
              hiddenLabel={true}
              categories={categoriesFilter}
              subcategories={subCategoriesFilter}
            />
          </FilterItems>
          <FilterItems title="Preços">
            <RangeField
              title=""
              name="price"
              isCurrency={true}
              change={setPriceRange}
              values={priceRange}
              undefinedValues={undefinedPrices}
              setUndefinedValues={setUndefinedPrices}
              undefinedValuesMessage={"Incluir produtos sem preço"}
            />
          </FilterItems>
          {!categoryByUrl && (
            <FilterItems title="Categorias">
              <Categories
                categoriesError={categoriesError}
                categoryOptions={categoriesOptions}
                categorySearch={categorySearch}
                selectedCategories={selectedCategories}
                setCategorySearch={setCategorySearch}
                setSelectedCategories={setSelectedCategories}
                segment={{
                  name: "products",
                  subvalue: "produtos"
                }}
              />
            </FilterItems>
          )}
          {!subcategoryByUrl && (
            <FilterItems title="Subcategorias">
              <Subcategories
                subcategoriesError={subCategoriesError}
                selectedSubcategories={selectedSubcategories}
                setSelectedSubcategories={setSelectedSubcategories}
                subcategoryOptions={subCategoriesOptions}
                subcategorySearch={subcategorySearch}
                setSubCategorySearch={setSubcategorySearch}
                segment={{
                  name: "products",
                  subvalue: "produtos"
                }}
              />
            </FilterItems>
          )}
          {/* {categoryByUrl && (
            <CotationWidget
              ids={
                selectedSubcategories.length > 0
                  ? selectedSubcategories
                      .map(subcategory => subcategory.subvalue)
                      .join(",")
                  : ""
              }
            />
          )} */}
        </>
      </List2>
      {/* <Related
        userid={user ? user.id : ""}
        segment={segment}
        mobile={mobile}
        excludeId={0}
      /> */}
    </Styled.Container>
  );
};

export default ProductList;
