import { IMenuItemModel } from "../index";
import Routes from "Routes";
import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";

const menu: IMenuItemModel = {
  name: "Todas as categorias",
  link: "/secoes-produtos/",
  icon: <GiHamburgerMenu />,
  submenu: [
    {
      category: "Imóveis Rurais",
      link: Routes.properties.replace(":category?", "")
    },
    {
      category: "Veículos",
      link: Routes.vehicles.replace(":subcategory?", "")
    },
    {
      category: "Vagas",
      link: Routes.jobs
    }
  ]
};

export default menu;
