import Routes from "Routes";
import { IMenuItemModel } from "../index";
import { RiChatVoiceLine } from "react-icons/ri";
import React from "react";

const menu: IMenuItemModel = {
  name: "Boletins",
  icon: <RiChatVoiceLine />,
  link: Routes.boletins
};

export default menu;
