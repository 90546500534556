import React, { useEffect, useState } from "react";
import * as Styled from "./styles";
import TextInput from "Components/Form/Inputs/Text";
import ButtonPrimary from "Components/Buttons/Primary";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import DatePicker from "react-date-picker";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Form from "Components/Form";
import { useDispatch } from "react-redux";
import BackendErrorHelper from "Helpers/BackendErrorHelper";
import localeActions from "Redux/Locale/actions";
import articleUpdate from "Api/articles/update";
import { useParams } from "react-router";
import { useFetchArticle } from "Hooks/Articles/useFetchArticle";

type TParams = {
  articleId: string;
};

const AdminArticlesEdit: React.FC = () => {
  const [publicationDate, setPublicationDate] = useState<Date>(new Date());
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [editorState, setEditorState] = useState<EditorState>();

  const { articleId } = useParams<TParams>();

  const { data: article } = useFetchArticle(articleId);

  useEffect(() => {
    if (article) {
      const contentBlock = article.text_html && htmlToDraft(article.text_html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      }
      /* setPublicationDate() */
    }
  }, [article]);

  const dispatch = useDispatch();

  const onUpdateForm = async (inputs: any) => {
    try {
      const params = {
        ...inputs,
        publicationDate,
        textHtml:
          (editorState &&
            draftToHtml(convertToRaw(editorState.getCurrentContent()))) ||
          ""
      };
      const response = article && (await articleUpdate(article.id, params));
      response &&
        dispatch(
          localeActions.addTooltip({
            type: "success",
            message: "Notícia atualizada com sucesso!"
          })
        );
    } catch (error) {
      if (BackendErrorHelper.checkIfDefaultError(error)) {
        const message = BackendErrorHelper.getDefaultErrorMessage(
          error.response
        );
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else if (BackendErrorHelper.checkIfValidationError(error)) {
        const message = BackendErrorHelper.getValidatorErrorMessage(
          error.response
        );
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else {
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message: "Error ao criar feira"
          })
        );
      }
    }
  };

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Title>Editar Notícia</Styled.Title>
        {article && (
          <Form submit={onUpdateForm}>
            <TextInput
              label="Título da notícia"
              name="title"
              placeholder="Digite o titulo da notícia"
              defaultValue={article.title}
              required
            />
            <TextInput
              label="Sub-titulo da notícia"
              name="subtitle"
              placeholder="Digite o sub-titulo da notícia"
              defaultValue={article.sub_title}
              required
            />
            <TextInput
              label="Categoria da notícia"
              name="category"
              placeholder="Digite a categoria da notícia"
              defaultValue={article.category}
              required
            />
            <TextInput
              label="Tags"
              name="tags"
              placeholder="Digite as tags separadas por |"
              defaultValue={article.tags}
              required
            />
            <Styled.PublicationDate>
              <Styled.PublicationLabel>
                Data de publicação.
              </Styled.PublicationLabel>
              <DatePicker
                onChange={setPublicationDate}
                value={publicationDate}
                name="publicationDate"
                format="dd/MM/yyyy"
              />
            </Styled.PublicationDate>
            <Styled.EditorContainer>
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={setEditorState}
              />
            </Styled.EditorContainer>
            <Styled.ImageContainer>
              <TextInput
                label="Link da imagem-banner."
                name="imageUrl"
                placeholder="Digite o link da imagem aqui."
                change={value => setImagePreview(value)}
              />
              {imagePreview && <Styled.Preview src={imagePreview} />}
            </Styled.ImageContainer>
            <Styled.ButtonContainer>
              <ButtonPrimary type="submit" color="orange">
                Salvar
              </ButtonPrimary>
            </Styled.ButtonContainer>
          </Form>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};

export default AdminArticlesEdit;
