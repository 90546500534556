import React, { useState } from "react";

import { StylesProvider } from "@material-ui/core";
import CheckboxSearch, {
  IOptions
} from "Components/Form/Inputs/CheckboxSearch";

import * as Styled from "./styles";
import { useTypesBoletins } from "Hooks/Boletins/useTypesBoletins";
import { BOLETINS_TYPES } from "Helpers/consts";

interface Props {
  selected: IOptions[];
  setSelected: React.Dispatch<React.SetStateAction<IOptions[]>>;
}

const Types: React.FC<Props> = ({ selected, setSelected }) => {
  const { data, isLoading, refetch } = useTypesBoletins();

  return (
    <Styled.CheckBoxContainer>
      {data && (
        <CheckboxSearch
          hideSearch={true}
          // TODO: removing from this line and put in another function
          options={data.map((current, index) => ({
            id: index,
            name: BOLETINS_TYPES[current as keyof typeof BOLETINS_TYPES],
            subvalue: current
          }))}
          selected={selected}
          setSelected={setSelected}
        />
      )}
    </Styled.CheckBoxContainer>
  );
};
export default Types;
