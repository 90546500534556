import api from "Helpers/Api";
import { CampaignsListResponse } from "Services/Campaigns/campaigns";

export default async function campaignDisableImage(campaignId: string) {
  const { data } = await api.put<CampaignsListResponse>(
    `/campaign/image/disable/${campaignId}`
  );

  return data;
}
