import styled from "styled-components";

export const Section = styled.section`
  width: 100%;
  background: #fff;
  padding: 32px 0;
  margin-top: 120px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
`;

export const ButtonContainer = styled.div`
  width: fit-content;
`;

export const Stores = styled.div`
  display: flex;

  gap: 24px;
`;

export const StoreLogo = styled.img`
  max-width: 200px;
  width: 100%;

  opacity: 0.5;

  @media ${({ theme }) => theme.devices.mobileL} {
    max-width: 150px;
  }
`;

export const Description = styled.p`
  margin: 0;
  padding: 0;

  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${({ theme }) => theme.colors.gray.normal};

  @media ${({ theme }) => theme.devices.mobileL} {
    text-align: center;
    max-width: 300px;
    font-size: ${({ theme }) => theme.fontSize.mm};
  }
`;

export const Title = styled.h2`
  margin: 0;
  padding: 0;

  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSize.lg};
  color: ${({ theme }) => theme.colors.gray.dark};

  @media ${({ theme }) => theme.devices.mobileL} {
    font-size: ${({ theme }) => theme.fontSize.md2};
  }
`;
