import * as Styled from "./styles";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ReducerState } from "Redux/store";
import localeActions from "Redux/Locale/actions";

import api from "Helpers/Api";
import Form from "Components/Form";
import TextInput from "Components/Form/Inputs/Text";
import ButtonPrimary from "Components/Buttons/Primary";
import { convertPhone } from "Helpers/stringPhone";
import BackendErrorHelper from "Helpers/BackendErrorHelper";

import IconUserResearchNotCss from "Assets/Svg/user-research-not-css.jsx";
import defaultAxios from "axios";

import LoadingIcon from "Components/LoadingIcon";

interface Result {
  status: boolean;
  message: string;
}

interface Props {
  formDisabled?: boolean;
  formTitle?: string;
  formButtonText?: string;
  formRedirectLink?: string | null;
  crucialLeadCapture?: boolean;
  handleModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormCampaigns: React.FC<Props> = ({
  formDisabled = false,
  formButtonText = "Enviar",
  formRedirectLink,
  crucialLeadCapture = false,
  handleModal
}) => {
  const token: string = useSelector((state: ReducerState) => state.auth.token);
  const user = useSelector((state: ReducerState) => state.auth.user);

  api.defaults.headers["Authorization"] = `bearer ${token}`;

  const dispatch = useDispatch();

  const [email, setEmail] = useState<string | null>((user && user.email) || "");
  const [phone, setPhone] = useState<string | null>((user && user.phone) || "");
  const [result, setResult] = useState<Result | null>(null);
  const [sent, setSent] = useState("");
  const [isSubmmiting, setIsSubmitting] = useState(false);

  type TInput = {
    name: string;
    email: string;
    phone: string;
    message: string;
    website: string;
    cpf: string;
    cnpj: string;
  };

  function showErrors(message: string) {
    setResult({
      status: false,
      message
    });
    dispatch(
      localeActions.addTooltip({
        type: "error",
        message
      })
    );
  }

  async function send({ email, phone }: TInput) {
    setIsSubmitting(true);

    const formdata = new FormData();

    email && formdata.append("email", email);
    phone && formdata.append("phone", convertPhone(phone));

    const tooltipMessage = crucialLeadCapture
      ? "Boa sorte!"
      : "Contato enviado com sucesso";

    try {
      const response = await api.post("/contacts/ford", {
        email,
        phone: phone.replace(/\D/g, "")
      });
      dispatch(
        localeActions.addTooltip({
          type: "success",
          message: "Contato enviado com sucesso, retornaremos em breve."
        })
      );
      const newWindow = window.open(
        "https://goiania.navesaford.com.br/",
        "_blank"
      );
      if (newWindow) {
        newWindow.focus();
      }

      setResult({ status: true, message: tooltipMessage });

      setSent(response.data.message);
    } catch (e) {
      if (defaultAxios.isAxiosError(e)) {
        if (BackendErrorHelper.checkIfDefaultError(e)) {
          const message = BackendErrorHelper.getDefaultErrorMessage(e.response);
          showErrors(message);
        } else if (BackendErrorHelper.checkIfValidationError(e)) {
          const message = BackendErrorHelper.getValidatorErrorMessage(
            e.response
          );
          showErrors(message);
        } else {
          showErrors("Erro na requisição, por favor tente novamente");
        }
      } else {
        showErrors("Verifique se todos os campos estão completos.");
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Styled.Container disable={formDisabled}>
      {sent ? (
        <>
          <Styled.SentMessageContainer>
            <Styled.SentMessageText>{sent}</Styled.SentMessageText>
            <Styled.ImageContainer>
              <IconUserResearchNotCss />
            </Styled.ImageContainer>
          </Styled.SentMessageContainer>
        </>
      ) : (
        <>
          <Styled.Title>Quero saber mais sobre.</Styled.Title>
          <Styled.Result status={result ? result.status : true}>
            {result && result.message}
          </Styled.Result>
          <Form submit={send}>
            <>
              <Styled.FormColumn>
                <TextInput
                  name="email"
                  label="Email*"
                  placeholder="Digite seu email"
                  change={setEmail}
                  required
                  defaultValue={email}
                  validation="email"
                />
              </Styled.FormColumn>
              <Styled.FormColumn>
                <TextInput
                  name="phone"
                  label="Telefone*"
                  change={setPhone}
                  required
                  placeholder="Digite seu telefone"
                  defaultValue={phone}
                  mask={"(99) 99999-9999"}
                  validation="phone"
                />
              </Styled.FormColumn>
              <Styled.SubmitContainer>
                <Styled.ButtonsContainer>
                  <ButtonPrimary type="submit" color="orange">
                    {isSubmmiting ? <LoadingIcon /> : <>{formButtonText}</>}
                  </ButtonPrimary>
                </Styled.ButtonsContainer>
              </Styled.SubmitContainer>
            </>
          </Form>
        </>
      )}
    </Styled.Container>
  );
};

export default FormCampaigns;
