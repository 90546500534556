import api from "./Api";

export const queryCities = async ({ search = "", stateId = "" }) => {
  const { data } = await api.get("/location/cities", {
    params: { search, states: stateId ? stateId : "" }
  });
  return data
    .map((city: any) => ({ name: city.name, value: city.id }))
    .slice(0, 4);
};

export const queryStates = async (search = "") => {
  const { data } = await api.get("/location/states", { params: { search } });
  return data
    .map((state: any) => ({ name: state.name, value: state.id }))
    .slice(0, 4);
};
const queryCitiesAndStates = {
  queryCities,
  queryStates
};

export default queryCitiesAndStates;
