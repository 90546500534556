import React from "react";
import * as Styled from "./styles";
import { useDispatch, useSelector } from "react-redux";
import authActions from "Redux/Auth/actions";
import { ReducerState } from "Redux/store";
import { Account as Routes } from "Routes";
import { MenuClose } from "../Menu/styles";
import UserMenu from "Components/UserMenu";
import localeActions from "Redux/Locale/actions";
import { createTrack } from "Helpers/tracking";
import { IAdTracking } from "Services/Tracking/tracking";
import moment from "moment";
import { useLocation } from "react-router-dom";

interface IMyAccount {
  show: boolean;
  toggle(value: boolean): void;
}

const MyAccount: React.FC<IMyAccount> = ({ show = false, toggle }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state: ReducerState) => state.auth.user);
  const { gaId } = useSelector((state: ReducerState) => state.locale);

  const logout = () => {
    const now = moment().format("YYYY-MM-DD HH:mm:ss");
    const event: IAdTracking = {
      TYPE: "USER_LOGOUT",
      GA_ID: gaId,
      USER_ID: user && user.id,
      ITEM_TYPE: "USER",
      TAGS: "mode=model1,version=v2,test=a",
      PAGE_REF: location.pathname,
      EVENT_TIME: now
    };
    dispatch(localeActions.addTracking(createTrack(event, "user")));
    dispatch(authActions.unauthenticate());
  };

  const links = [
    [Routes.products, "Meus Produtos"],
    [Routes.vehicles, "Meus Veículos"],
    [Routes.properties, "Meus Imóveis Rurais"],
    [Routes.leases, "Meus Arrendamentos"],
    [Routes.jobs, "Minhas Vagas"],
    [Routes.services, "Meus Serviços"],
    [Routes.integration, "Cargas"]
  ];
  return (
    <>
      {user && (
        <Styled.Container show={show}>
          <Styled.Bg onClick={() => toggle(false)} show={show} />
          <Styled.Menu>
            <MenuClose onClick={() => toggle(false)} />
            <UserMenu toggle={toggle} />
            <Styled.LinksContainer>
              {links.map((link, index) => (
                <Styled.LinkItem
                  key={`account-menu-${index}`}
                  to={link[0]}
                  onClick={() => toggle(false)}
                >
                  {link[1]}
                </Styled.LinkItem>
              ))}
              <Styled.Logout
                onClick={() => {
                  toggle(false);
                  logout();
                }}
              >
                Sair
              </Styled.Logout>
            </Styled.LinksContainer>
          </Styled.Menu>
        </Styled.Container>
      )}
    </>
  );
};

export default MyAccount;
