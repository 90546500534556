import { useQuery } from "@tanstack/react-query";
import campaignFetch from "Api/campaigns/fetch";

export default function useCampaign(campaignId: string) {
  const query = () => campaignFetch(campaignId);

  return useQuery({
    queryKey: ["campaignList", campaignId],
    queryFn: query,
    keepPreviousData: true
  });
}
