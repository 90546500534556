import React from "react";
import { useSelector } from "react-redux";

import { ReducerState } from "Redux/store";

import { Account, Auth } from "Routes";
import * as Styled from "./styles";

interface IProfile {
  account(value: boolean): void;
  toggleMenu?(): void;
}

const Profile: React.FC<IProfile> = ({ account, toggleMenu }) => {
  const user = useSelector((state: ReducerState) => state.auth.user);

  return (
    <>
      <Styled.Container>
        {user ? (
          <>
            <Styled.Auth>
              <Styled.BarsIcon
                onClick={() => {
                  account(true);
                }}
              />
            </Styled.Auth>

            <Styled.Picture
              onClick={() => toggleMenu && toggleMenu()}
              to={Account.home}
            >
              {user.avatar && user.avatar !== "" ? (
                <Styled.Avatar className="profile-pic" src={user.avatar} />
              ) : (
                <Styled.Placeholder className="profile-pic-placeholder" />
              )}
            </Styled.Picture>
          </>
        ) : (
          <Styled.Login
            to={Auth.login}
            onClick={() => toggleMenu && toggleMenu()}
          >
            Login/Cadastro
          </Styled.Login>
        )}
      </Styled.Container>
    </>
  );
};

export default Profile;
