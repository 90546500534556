import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100%;

  * {
    box-sizing: border-box;
  }
`;

export const Content = styled.div`
  display: flex;

  width: 100%;

  margin-left: 250px;

  background-color: ${({ theme }) => theme.colors.gray.light};
  overflow-y: auto;

  align-items: end;
`;
