import styled from "styled-components";
import { Link } from "react-router-dom";

interface IPaddingProps {
  padding_right?: string;
  padding_left?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  justify-content: space-between;
  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;

export const CartItems = styled.div`
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
  }
`;

export const Order = styled.div`
  height: fit-content;
  box-sizing: border-box;
  width: 50%;
  display: flex;
  background: #fff;
  flex-direction: column;
  margin: 0 20px;
  padding: 20px;
  border-radius: 5px;
  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
    margin: 20px 0px;
    flex-direction: column;
    gap: 12px;
  }
`;

export const OrderValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const OrderSubTitle = styled.h3`
  margin: 0;
  padding-bottom: 10px;
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${({ theme }) => theme.colors.green.dark};
`;

export const OrderValue = styled.span`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.gray.normal};
`;

export const OrderTotal = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.lg};
  color: ${({ theme }) => theme.colors.green.normal};
  margin: 0;
  padding-top: 20px;
`;

export const ButtonsContainer = styled.div`
  display: flex;

  gap: 1.5rem;

  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
    flex-direction: column;
    gap: 6px;
  }
`;

export const OrderButton = styled(Link)<IPaddingProps>`
  display: flex;
  flex: 50%;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  text-decoration: none;
`;

export const IconSpan = styled.span`
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSize.md};
  margin-left: 8px;
`;
