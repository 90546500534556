import AddToCartBtn from "Components/Ad/AddToCart";
import stringPrice from "Helpers/stringPrice";
import React from "react";
import { TProductItem } from "..";
import * as Styled from "./styles";

import axios from "Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { ReducerState } from "Redux/store";
import localeActions from "Redux/Locale/actions";
import cartActions from "Redux/Cart/actions";

interface Props {
  productItem: TProductItem;
  removeProductItem?(): void;
  edit: boolean;
}

type TRemoveProductResponse = {
  status: boolean;
  cart: string;
  message: string;
};

const CartItem: React.FC<Props> = ({
  productItem,
  removeProductItem,
  edit
}) => {
  const dispatch = useDispatch();
  const { product, variantItem } = productItem;
  const { cartId } = useSelector((state: ReducerState) => state.cart);

  const removeItem = async () => {
    try {
      const { data } = await axios.put<TRemoveProductResponse>("/cart/remove", {
        qty: 0,
        cartId,
        productId: product.id
      });
      if (data.status) {
        dispatch(cartActions.removeCart(product.id));
        removeProductItem && removeProductItem();
        dispatch(
          localeActions.addTooltip({
            type: "success",
            message: "Produto removido com sucesso"
          })
        );
      }
    } catch (error) {
      dispatch(
        localeActions.addTooltip({
          type: "error",
          message: "Erro ao remover produto do carrinho, tente mais tarde"
        })
      );
    }
  };

  return (
    <Styled.Container>
      <Styled.ProductImageContainer>
        {product.image ? (
          <Styled.ProductImage src={product.image} />
        ) : (
          <Styled.Placeholder fontSize="large" />
        )}
      </Styled.ProductImageContainer>
      <Styled.CartInfo>
        <Styled.ProductTitle>{product.title}</Styled.ProductTitle>
        {variantItem && (
          <Styled.VariantItem>{variantItem.value}</Styled.VariantItem>
        )}
        {edit ? (
          <>
            <AddToCartBtn id={product.id} qty={productItem.qty} hideBtn />
            <Styled.RemoveFromCart onClick={removeItem}>
              Remover do carrinho
            </Styled.RemoveFromCart>
          </>
        ) : (
          <Styled.ProductQty>Quantidade: {productItem.qty}</Styled.ProductQty>
        )}
      </Styled.CartInfo>
      <Styled.ProductInfo>
        <label>Preço</label>
        <Styled.Price>{stringPrice(product.price / 100)}</Styled.Price>
        <label>Subtotal</label>
        <Styled.Price>
          {stringPrice((product.price / 100) * productItem.qty)}
        </Styled.Price>
        <span>vendido por {product.seller_name}</span>
      </Styled.ProductInfo>
    </Styled.Container>
  );
};

export default CartItem;
