import { createStore, applyMiddleware } from "redux";
import { Middleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { StateType } from "typesafe-actions";
import logger from "redux-logger";
import thunk from "redux-thunk";
import createSagaMIddleware from "redux-saga";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import sagas from "./sagas";
import rootReducer from "./reducers";

const persistConfig = {
  key: "rurax",
  version: 1,
  blacklist: ["locale"],
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMIddleware();
const middlewares: Array<Middleware> = [thunk, sagaMiddleware];

// Add redux logger if env is not prod or test
// if (process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "test")
//   middlewares.push(logger);

export type ReducerState = StateType<typeof rootReducer>;

export const store = createStore(
  persistedReducer,
  applyMiddleware(...middlewares)
);
sagaMiddleware.run(sagas);

export const persistor = persistStore(store);

const storeExport = {
  store,
  persistor
};

export default storeExport;
