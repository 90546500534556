import { FaAngleUp } from "react-icons/fa";
import styled from "styled-components";
export const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 10px 0px;
  color: ${({ theme }) => theme.colors.gray.dark};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: 500;
  div {
    margin: 0 30px;
  }
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.gray.dark};

    &:hover {
      color: ${({ theme }) => theme.colors.green.light};
    }
  }
  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
    div {
      margin: 0px;
    }
  }
`;

export const BackTop = styled.div`
  color: ${({ theme }) => theme.colors.gray.dark};
  padding: 15px 16px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-self: self-end;
  font-size: ${({ theme }) => theme.fontSize.md};
  align-items: center;
  margin-top: 20px;
`;

export const ArrowContainer = styled.div`
  background-color: ${props => props.theme.colors.gray.dark};
  width: 2em;
  height: 2em;
  margin-left: 16px;
  border-radius: 50%;
`;

export const ArrowUp = styled(FaAngleUp).attrs(() => ({
  icon: FaAngleUp
}))`
  right: 16px;
  margin-left: -9px;
  margin-top: -3px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: ${({ theme }) => theme.fontSize.lg};
  color: ${props => props.theme.colors.white.normal};
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.colors.green.dark};
  color: ${({ theme }) => theme.colors.white.normal};
  margin-top: 120px;
`;

export const ColumnsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.green.dark};
  color: ${({ theme }) => theme.colors.white.normal};
  display: flex;
  flex-direction: column;
  flex: 4;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSize.sm};
  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  max-width: ${({ theme }) => theme.siteWidth};
  justify-content: space-between;
  width: 100%;
  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.white.normal};
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap;
  @media ${({ theme }) => theme.devices.tablet} {
    padding-left: 25px;
  }
`;

export const SubMenu = styled.div`
  a {
    margin-right: 10px;
    padding-top: 10px;
    @media ${({ theme }) => theme.devices.tablet} {
      padding-top: 26px;
    }
    display: flex;
    color: ${({ theme }) => theme.colors.white.normal};
    flex-direction: column;
    text-decoration-line: none;
    font-size: ${({ theme }) => theme.fontSize.sm};

    &:hover {
      color: ${({ theme }) => theme.colors.green.light};
    }
  }
`;

export const CopyrightDiv = styled.div`
  flex: auto;
  color: ${({ theme }) => theme.colors.gray.dark};
  display: flex;
  justify-content: center;
  place-items: center;
  font-size: ${({ theme }) => theme.fontSize.sm};

  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;

export const ColumnTitle = styled.div`
  padding-right: 48px;
  font-weight: bold;
  padding-bottom: 16px;
  @media ${({ theme }) => theme.devices.tablet} {
    padding-bottom: 8px;
  }
`;
export const LogoContainer = styled.div`
  width: 10%;
  height: 10%;
  padding: 0px 0px 20px 0px;
  align-self: center;
  @media ${({ theme }) => theme.devices.tablet} {
    width: 40%;
    height: 40%;
  }
`;
export const SpanContainer = styled.div`
  padding: 45px 0px 15px 0px;
  width: 100%;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: 600;
  a {
    text-decoration: none;
    color: #fff;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  box-sizing: border-box;
  width: 100%;
  padding: 30px 0px 30px 40px;
  @media ${({ theme }) => theme.devices.tablet} {
    flex-flow: column;
    padding: 0px;
  }
`;

export const ContainerContent = styled.div`
  max-width: 385px;
  padding: 15px 20px;
  box-sizing: border-box;
  border-radius: 7px;
  margin: 10px 12px;
  flex: 1 1 0;
  display: flex;
  justify-content: left;
  flex-direction: column;

  @media ${({ theme }) => theme.devices.tablet} {
    padding: 20px 30px;
    margin: 0px;
    width: 100%;
  }
`;

export const BackTopSpanContainer = styled.div`
  width: 100%;
  text-align-last: end;
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: bold;
`;

export const SocialMedia = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  gap: 6px;

  p {
    color: #fff;
    margin: 0;
    padding: 0;

    font-size: ${({ theme }) => theme.fontSize.md};
    font-weight: 500;
  }
`;

export const SocialMediaIcons = styled.div`
  display: flex;

  gap: 12px;
`;
export const SocialMediaIcon = styled.a`
  width: 32px;
  height: 32px;

  cursor: pointer;

  border-radius: 50%;
  background: ${({ theme }) => theme.colors.green.normal};

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #fff;
    font-size: 1.5rem;
  }
`;
