import React, { ReactNode, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { ReducerState } from "Redux/store";

import { FaPlus, FaMinus } from "react-icons/fa";

import * as Styled from "./styles";
import Search from "Components/Form/Inputs/Search";

interface Props {
  children?: ReactNode;
  searchHidden?: boolean;
  total?: number;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
}

const Filters: React.FC<Props> = ({
  children = "",
  searchHidden = true,
  total = 0,
  setSearch
}) => {
  const { mobile } = useSelector((state: ReducerState) => state.locale);
  const [expand, setExpand] = useState(false);

  const [value, setValue] = useState("");

  const fetchList = useCallback(() => {
    if (setSearch) {
      setSearch(value);
    }
  }, [setSearch, value]);

  return (
    <Styled.Container>
      {!searchHidden && (
        <Styled.SearchContainer>
          <Search
            setValue={setValue}
            placeholder="O que está buscando?"
            defaultValue={value}
            shadow={false}
            submit={() => fetchList()}
          />
          <Styled.TotalResults>{total} resultados</Styled.TotalResults>
        </Styled.SearchContainer>
      )}
      <Styled.Title
        searchHidden={searchHidden}
        className="filters-title"
        onClick={() => setExpand(!expand)}
      >
        Filtros{" "}
        {mobile && (
          <Styled.Expand className="filters-expand-icon">
            {expand ? <FaMinus /> : <FaPlus />}
          </Styled.Expand>
        )}
      </Styled.Title>

      <Styled.Content
        className="filters-content"
        expand={mobile ? expand : true}
      >
        {children}
      </Styled.Content>
    </Styled.Container>
  );
};

export default Filters;
