import React, { useState, useEffect, useCallback } from "react";

import * as Styled from "./styles";
import FormContext from "./context";

export interface IError {
  status: boolean;
  message: string;
}

export interface IField {
  name: string;
  ref: HTMLInputElement;
  error: IError;
  checkForm: boolean;
  checkValue?: boolean;
}

interface Props {
  id?: string;
  children?: React.ReactElement[] | React.ReactElement;
  submit(inputs: any): void | Promise<void>;
  defaultError?: string[];
  formBottom?: React.ReactElement[] | React.ReactElement;
  alignItems?: string;
}

const Form: React.FC<Props> = ({
  id,
  children,
  submit,
  defaultError,
  formBottom,
  alignItems
}) => {
  const [fields, setFields] = useState<IField[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const submitHandler = (e: React.FormEvent) => {
    setSubmitted(true);
    e.preventDefault();
    const validations = fields
      .filter(
        (field: IField) => field.error.status || field.error.message !== ""
      )
      .map((field: IField) => field.error.message);
    if (validations.length === 0) {
      const values = fields.map((field: IField) => ({
        [field.name]: field.checkForm ? field.ref.checked : field.ref.value
      }));
      submit(Object.assign({}, ...values));
    } else {
      !error && setError(true);
    }
  };

  useEffect(() => {
    error && setTimeout(() => setError(false), 800);
  }, [error]);
  const getField = useCallback(
    (name: string) => fields.find((field: IField) => field.name === name),
    [fields]
  );

  const registerField = useCallback(
    (
      name: string,
      ref: HTMLInputElement,
      status: boolean,
      checkForm: boolean
    ) => {
      setFields((state: IField[]) => [
        ...state,
        { name, ref, error: { status, message: "" }, checkForm }
      ]);
    },
    []
  );

  const deleteField = useCallback((name: string) => {
    setFields((state: IField[]) => state.filter(field => field.name !== name));
  }, []);

  const setFieldError = useCallback((name: string, error: IError) => {
    setFields((state: IField[]) =>
      state.map((field: IField) =>
        field.name === name ? { ...field, error } : field
      )
    );
  }, []);

  return (
    <Styled.Form
      id="form-interested"
      onSubmit={submitHandler}
      error={error}
      alignItems={alignItems}
    >
      <FormContext.Provider
        value={{
          registerField,
          setFieldError,
          getField,
          deleteField,
          submitted
        }}
      >
        {children}
        {defaultError && defaultError.length > 0 && (
          <Styled.Error>
            {defaultError.map((message, index) => (
              <span key={`error-${index}`}>{message}</span>
            ))}
          </Styled.Error>
        )}
        {formBottom || <></>}
      </FormContext.Provider>
    </Styled.Form>
  );
};

export default Form;
