import { ReducerState } from "Redux/store";
import { Account as Routes } from "Routes";
import React from "react";
import { FaCog } from "react-icons/fa";
import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import * as Styled from "./styles";

interface IToggle {
  toggle?(value: boolean): void;
  bigAvatar?: boolean;
}

const UserMenu: React.FC<IToggle> = ({ toggle, bigAvatar = false }) => {
  const user = useSelector((state: ReducerState) => state.auth.user);
  const history = useHistory();

  return (
    <>
      {user ? (
        <>
          {user.avatar && user.avatar !== "" ? (
            <Styled.Avatar
              bigAvatar={bigAvatar}
              onClick={() => {
                history.push(Routes.infos);
              }}
              className="profile-pic"
            >
              <img src={user.avatar} alt={user.name} />
            </Styled.Avatar>
          ) : (
            <Styled.Placeholder
              className="profile-pic-placeholder"
              onClick={() => {
                history.push(Routes.infos);
              }}
            />
          )}
          <Styled.UserInfo>
            <span>
              {user.name} {user.last_name}
            </span>
            <span>{user.email}</span>
            {toggle && (
              <NavLink to={Routes.infos} onClick={() => toggle(false)}>
                <FaCog />
                Editar informações da conta
              </NavLink>
            )}
          </Styled.UserInfo>
        </>
      ) : (
        <>{history.push("/login/")}</>
      )}
    </>
  );
};

export default UserMenu;
