import React, { useState } from "react";
import * as Styled from "./styles";
import Pagination2 from "Components/Pagination2";
import Search from "Components/Form/Inputs/Search";
import Loading from "Components/Loading";
import ButtonPrimary from "Components/Buttons/Primary";
import { useHistory } from "react-router";
import { AdminRoutes } from "Routes";
import { useArticles } from "Hooks/Articles/useArticles";
import ArticleItem from "./ArticlesItem";
import { TArticle } from "Services/Article/article";

const section = { title: "Matérias Recentes", slug: "recentes", perPage: 6 };

const AdminArticles: React.FC = () => {
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState("");

  const history = useHistory();

  const { data, isLoading } = useArticles({
    page: page,
    perPage: 5,
    search,
    section
  });

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleSearch = () => {
    setSearch(searchValue);
  };

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Title>Notícias</Styled.Title>
        {isLoading ? (
          <Loading />
        ) : (
          <Styled.List>
            <ButtonPrimary
              color="green"
              action={() => history.push(AdminRoutes.articleCreate)}
            >
              Adicionar nova notícia
            </ButtonPrimary>
            <Styled.SearchBox>
              <Search
                setValue={setSearchValue}
                placeholder="O que está buscando?"
                defaultValue={searchValue}
                name="searchFairs"
                shadow={false}
                submit={handleSearch}
                transparent={false}
              />
            </Styled.SearchBox>
            {data &&
              data.data.map((article: TArticle) => (
                <ArticleItem
                  data={article}
                  link={`/admin/articles/${article.slug}`}
                />
              ))}
            {data && data.data && (
              <Pagination2
                lastPage={data.lastPage}
                onPageChange={handleChangePage}
                page={page}
                perPage={5}
              />
            )}
          </Styled.List>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};

export default AdminArticles;
