import React from "react";
import * as Styled from "./styles";

const EmptyCart: React.FC = () => {
  return (
    <Styled.EmptyCart>
      <Styled.EmptyCartText>Carrinho vazio!</Styled.EmptyCartText>
      <Styled.EmptyCardLink href="/produtos">
        <Styled.EmptyCartIcon />
      </Styled.EmptyCardLink>

      <Styled.EmptyCartBuyLinkMessage href="/produtos">
        Comece a comprar agora!
      </Styled.EmptyCartBuyLinkMessage>
    </Styled.EmptyCart>
  );
};

export default EmptyCart;
