import React, {
  useState,
  useContext,
  useEffect,
  createRef,
  useMemo,
  ReactNode
} from "react";
import * as Styled from "./styles";
import FormContext from "Components/Form/context";
import { TError } from "Services/Error/error";
interface Props {
  label?: string;
  name?: string;
  defaultValue?: boolean;
  placeholder?: string;
  details?: string;
  checked?: boolean;
  required?: boolean;
  change?(input: boolean): void;
  status?: (status: boolean) => void;
  children: ReactNode;
  disabled?: boolean;
}
const CheckBox: React.FC<Props> = ({
  label,
  name,
  defaultValue = false,
  placeholder = "",
  details = "",
  required = false,
  change,
  checked = false,
  children,
  status,
  disabled = false,
  ...rest
}) => {
  const [valid] = useState<boolean>(true);
  const [value, setValue] = useState<boolean>(checked);
  const { registerField, setFieldError, submitted } = useContext(FormContext);
  const [error, setError] = useState<TError>(
    required
      ? {
          message: "",
          status: true
        }
      : {
          message: "",
          status: false
        }
  );
  useEffect(() => {
    if (submitted === true) {
      setError(
        required && !value
          ? {
              message: "Campo Obrigatório",
              status: true
            }
          : {
              message: "",
              status: false
            }
      );
    }
  }, [defaultValue, required, submitted, value]);
  const inputRef = useMemo(() => createRef<HTMLInputElement>(), []);
  useEffect(() => {
    registerField &&
      name &&
      inputRef.current &&
      registerField(name, inputRef.current, required, true);
  }, [defaultValue, inputRef, name, registerField, required]);
  useEffect(() => {
    required &&
      name &&
      setFieldError &&
      setFieldError(name, {
        status: error.message === "" ? false : required,
        message: error.message || ""
      });
  }, [defaultValue, error, inputRef, name, required, setFieldError]);
  useEffect(() => {
    value !== checked && setValue(checked);
  }, [checked]);
  const clickHandler = () => {
    if (disabled) return;
    setValue(value => !value);
    change && change(!value);
    if (required && value === true) {
      setError({ message: "Campo Obrigatório", status: true });
    } else {
      setError({ message: "", status: false });
    }
  };
  return (
    <>
      {label && <Styled.Label>{label}</Styled.Label>}
      {details && <Styled.DetailsLabel>{details}</Styled.DetailsLabel>}
      <Styled.Field status={valid} className={error.status ? "error" : ""}>
        <Styled.CheckBoxContent>
          <Styled.CheckBox
            type="checkbox"
            name={name}
            checked={value}
            ref={inputRef}
            onChange={() => {}}
            disabled={disabled}
            {...rest}
          />
          <Styled.Check
            status={value}
            onClick={clickHandler}
            disabled={disabled}
          >
            <Styled.Icon />
          </Styled.Check>
          <Styled.Content status={value}>
            {placeholder ? placeholder : children && children}
          </Styled.Content>
        </Styled.CheckBoxContent>
        <Styled.Error valid={error.status}>{error.message}</Styled.Error>
      </Styled.Field>
    </>
  );
};
export default CheckBox;
