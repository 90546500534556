import React, { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";

import Base from "./Views";
import { store, persistor } from "./Redux/store";
import { theme, ResetCss, FontsImport } from "./theme/theme";

import { Route, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_ID } from "Helpers/consts";
import Routes, { AdminRoutes, Account, Auth } from "Routes";
import Loading from "Components/Loading";
import AdminBase from "Views/Admin";
import { AdminAuthProvider } from "Contexts/AdminAuthContext";
import { LoadingProvider } from "Contexts/LoadingProvider";

export const queryClient = new QueryClient();

const App: React.FC = () => {
  const history = createBrowserHistory();

  useEffect(() => {
    const { caches } = window;
    //TODO: Create logic to check version when deleting cache
    caches &&
      caches.keys().then(names => {
        names.forEach(name => {
          caches.delete(name);
        });
      });
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <ThemeProvider theme={theme}>
              <FontsImport />
              <ResetCss />
              <Router history={history}>
                <Switch>
                  <Route exact path={[...Object.values(AdminRoutes)]}>
                    <Suspense fallback={<Loading />}>
                      <LoadingProvider>
                        <AdminAuthProvider>
                          <AdminBase />
                        </AdminAuthProvider>
                      </LoadingProvider>
                    </Suspense>
                  </Route>
                  <Route
                    exact
                    path={[
                      ...Object.values(Routes),
                      ...Object.values(Account),
                      ...Object.values(Auth)
                    ]}
                  >
                    <Suspense fallback={<Loading />}>
                      <Base />
                    </Suspense>
                  </Route>
                </Switch>
              </Router>
            </ThemeProvider>
          </GoogleOAuthProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
