import { TRange } from "Services/pagination";

/**Function to get string range (and invert it if it's necessary) */
export default function stringRange(
  range: TRange,
  setRange?: React.Dispatch<React.SetStateAction<TRange>>
) {
  const isInvertedRange =
    range.min !== 0 && range.max !== 0 && range.min > range.max;

  const realRange = isInvertedRange
    ? `${range.max},${range.min}`
    : `${range.min},${range.max}`;

  isInvertedRange &&
    setRange &&
    setRange({
      min: range.max,
      max: range.min
    });

  return realRange;
}
