import React, { Suspense, lazy } from "react";

import Loading from "Components/Loading";

const AdPlansList = lazy(() => import("./List"));

const AdPlans: React.FC = () => {
  return (
    <Suspense fallback={<Loading />}>
      <AdPlansList />
    </Suspense>
  );
};

export default AdPlans;
