import { IUserSession } from "Contexts/AdminAuthContext";
import adminApi from "Helpers/adminApi";
import Axios from "axios";

interface getUserProps {
  token?: string;
  refreshToken: string;
}

export async function getUser({ token, refreshToken }: getUserProps) {
  try {
    if (token) {
      adminApi.defaults.headers["Authorization"] = `bearer ${token}`;
    }
    const response = await adminApi.post<IUserSession>("/session/adminToken/", {
      refreshToken
    });

    return {
      status: true,
      data: response.data
    };
  } catch (e) {
    if (Axios.isAxiosError(e)) {
      return {
        status: false,
        message: e.response?.data.message
      };
    } else {
      return {
        status: false,
        message: "Um erro ocorreu, tente novamente mais tarde."
      };
    }
  }
}
