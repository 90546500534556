import styled from "styled-components";

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Fields = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 87.5%;

  & > fieldset {
    flex: 0 0 calc(49% - 25px);
  }

  @media ${({ theme }) => theme.devices.laptop} {
    & > fieldset:nth-child(odd) {
      margin-right: 50px;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`;

export const ButtonLogin = styled.div`
  grid-column: 1 /-1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 223px;

  @media ${({ theme }) => theme.devices.tablet} {
    justify-content: center;
  }
`;

export const Container = styled.div`
  display: flex;

  width: 100vw;
  height: 100vh;

  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.gray.light};
`;

export const Content = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  max-width: 80%;
  max-height: 80%;
  width: 320px;
  height: fit-content;

  padding: 24px 12px;
  border-radius: 12px;

  background-color: ${({ theme }) => theme.colors.white.normal};
`;
