import { IValidation } from "./";

const Validation: IValidation = {
  error: "Por favor, informe um número válido!",
  validate: (input: string) =>
    /(^4[0-9]{12}(?:[0-9]{3})?$)|(^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$)|(3[47][0-9]{13})/.test(
      input.replace(/\s+/g, "")
    )
};

export default Validation;
