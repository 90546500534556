import AudioPlayer from "Components/AudioPlayer";
import { AudioPlayerContext } from "Contexts/AudioPlayerContext";
import React, { useContext } from "react";
import { AiOutlineHeart, AiOutlineShareAlt } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import localeActions from "Redux/Locale/actions";
import { ReducerState } from "Redux/store";
import * as Styled from "./styles";
import Thumbnail from "./Thumbail";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const FooterDisplay: React.FC<Props> = ({ handleClose, isOpen }) => {
  const { currentBoletim } = useContext(AudioPlayerContext);

  const dispatch = useDispatch();

  const mobile = useSelector((state: ReducerState) => state.locale.mobile);

  // Todo: Componentizar o share
  const handleShareBoletim = async (e: any, id?: string | null) => {
    e.preventDefault();
    e.stopPropagation();
    const currentBoletim = `${process.env.REACT_APP_PUBLIC_URL}/boletins?current=${id}`;
    if (mobile) {
      if (navigator.share) {
        navigator
          .share({
            title: "Compartilhar boletim",
            text: `Aqui temos um boletim que pode te agradar, clique no link para ouvir: ${currentBoletim}`
          })
          .then(() => {
            dispatch(
              localeActions.addTooltip({
                type: "success",
                message: "Compartilhado com sucesso!"
              })
            );
          })
          .catch(e => {
            console.error(e);
            dispatch(
              localeActions.addTooltip({
                type: "error",
                message:
                  "Um problema ocorreu na hora de compartilhar seu boletim."
              })
            );
          });
      }
    } else {
      navigator.clipboard.writeText(
        `Aqui temos um boletim que pode te agradar, clique no link para ouvir: ${currentBoletim}`
      );
      dispatch(
        localeActions.addTooltip({
          type: "success",
          message: "Copiado com sucesso para área de transferência."
        })
      );
    }
  };

  return (
    <>
      <Styled.Container isOpen={isOpen}>
        <Styled.Close
          onClick={() => {
            handleClose();
          }}
        />
        <Styled.Content>
          {!mobile && (
            <Styled.BoletimInformations>
              <Thumbnail />
              <p>{(currentBoletim && currentBoletim.title) || ""}</p>
            </Styled.BoletimInformations>
          )}
          <Styled.AudioContent>
            <AudioPlayer />
          </Styled.AudioContent>
          <Styled.InteractiveIcons>
            <AiOutlineShareAlt
              onClick={e =>
                handleShareBoletim(e, currentBoletim && currentBoletim.id)
              }
            />
          </Styled.InteractiveIcons>
        </Styled.Content>
      </Styled.Container>
    </>
  );
};

export default FooterDisplay;
