import { IMenuItemModel } from "../index";
import Routes from "Routes";

const EarnMoney: IMenuItemModel = {
  name: "Ganhe dinheiro conosco",
  link: Routes.leases.replace(":category?", ""),
  submenu: [
    {
      category: "Sobre como ganhar dinheiro",
      link: Routes.earnMoneyConditions
    },
    {
      category: "Seja um parceiro",
      link: Routes.partnerConditions
    },
    {
      category: "Venda no Rurax",
      link: Routes.sellWithUs
    },
    {
      category: "Anuncie seus produtos",
      link: Routes.announceProducts
    },
    {
      category: "Publique seus serviços",
      link: Routes.publishServices
    },
    {
      category: "Publicidade / Banners",
      link: Routes.publicity
    },
    {
      category: "Trabalhe Conosco",
      link: Routes.workWithUs
    }
  ]
};

export default EarnMoney;
