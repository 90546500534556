import React from "react";
import Checkbox from "../CheckBox";
import * as Styled from "./styles";
import { TError } from "Services/Error/error";
import TextInput from "Components/Form/Inputs/Text";

export interface IOptions {
  id: string | number;
  name: string;
  subvalue?: string;
  qty?: number;
}

interface Props {
  label?: string;
  placeholder?: string;
  search?: string;
  setSearch?(text: string): void;
  selected: any[];
  setSelected(selected: any): void;
  options: IOptions[];
  showQty?: boolean;
  hideSearch?: boolean;
  error?: TError;
}

const CheckboxSearch: React.FC<Props> = ({
  label,
  placeholder,
  search,
  setSearch,
  selected = [],
  setSelected,
  options = [],
  showQty = false,
  hideSearch = false,
  error = {
    status: false,
    message: ""
  }
}) => {
  const isSelected = (option: IOptions) =>
    selected.filter(item => item.id === option.id).length > 0;

  function select(key: number | string) {
    const option = options.find(op => op.id === key);
    if (option) {
      isSelected(option)
        ? setSelected(selected.filter(item => item.id !== option.id))
        : setSelected([...selected, option]);
    }
  }

  return (
    <Styled.Container>
      {label && <Styled.Label>{label}</Styled.Label>}
      {!hideSearch && (
        <TextInput
          defaultValue={search}
          change={setSearch}
          placeholder={placeholder}
        />
      )}
      {!error.status ? (
        <Styled.Options>
          {options.map(option => (
            <div key={`option-${option.id}`}>
              <Checkbox
                defaultValue={isSelected(option)}
                change={() => select(option.id)}
                checked={isSelected(option)}
              >
                {option.name}
                {showQty && option.qty && ` (${option.qty})`}
              </Checkbox>
            </div>
          ))}
        </Styled.Options>
      ) : (
        <Styled.ErrorMessage>{error.message}</Styled.ErrorMessage>
      )}
    </Styled.Container>
  );
};

export default CheckboxSearch;
