import React from "react";
import * as Styled from "./styles";
import FormCampaigns from "./FormCampaigns";

export const CampaignContact: React.FC = () => {
  return (
    <Styled.Container>
      <Styled.TextContent>
        <Styled.Text>
          Descubra o poder e a versatilidade da nova Maverick FX4 da Ford!
          <br />
          ✔️Motor 2.0L EcoBoost
          <br />
          ✔️Potência de 253cv
          <br />
          ✔️Torque de 380 Nm
          <br />
          ✔️Transmissão automática de 8 velocidades com E-Shifter e tração AWD
          <br />
          Você terá controle total em qualquer terreno. Prepare-se para uma
          experiência de condução incomparável. 🛣
        </Styled.Text>
      </Styled.TextContent>
      <Styled.FormContainer>
        <FormCampaigns />
      </Styled.FormContainer>
    </Styled.Container>
  );
};
