import styled from "styled-components";
import { FaPlus, FaTimes } from "react-icons/fa";

import { MutableRefObject } from "react";
import { css } from "styled-components";

interface ContainerProps {
  scrolling: boolean;
}

export const CarouselBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const Container = styled.div<ContainerProps>`
  width: 1415px;
  overflow: hidden;
  scroll-behavior: smooth;
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */

  border-top-left-radius: ${({ theme }) => theme.borderRadius};
  border-top-right-radius: ${({ theme }) => theme.borderRadius};

  @media ${({ theme }) => theme.devices.mobileL} {
    width: 100%;
  }
  @media ${({ theme }) => theme.devices.mobileM} {
    width: 100%;
  }
  @media ${({ theme }) => theme.devices.mobileS} {
    width: 100%;
  }
  @media ${({ theme }) => theme.devices.tablet} {
    width: 650px;
  }

  ${({ scrolling }) =>
    scrolling &&
    css`
      overflow-x: scroll;
      overflow-y: hidden;
      padding-bottom: 80px;
    `};

  ${({ scrolling }) => (scrolling ? "scroll" : "hidden")};
`;
/* @media ${({ theme }) => theme.devices.tablet} {
    overflow: scroll;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  } */

interface IWrap {
  translate: number;
}

export const Wrap = styled.div<IWrap>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  transform: ${({ translate }) => `translateX(-${translate}%)`};
  transition: transform ${({ theme }) => theme.animation.duration};
  will-change: transform;
`;

interface IItem {
  size: number;
}

export const Item = styled.div<IItem>`
  flex: 1 0 ${({ size }) => size}%;
  display: flex;
  max-width: ${({ size }) => size}%;
  position: relative;
`;

type Props = {
  position: "left" | "right";
};
export const NavigationIcon = styled.div<Props>`
  > svg {
    position: absolute;
    margin: auto 20px;
    top: 0;
    bottom: 0;
    ${({ position }) => (position === "left" ? "left: 0;" : "right: 0;")}
    padding: 10px 10px;

    border-radius: 5px;
    font-size: 32px;
    background-color: ${({ theme }) => theme.colors.green.normal};
    color: ${({ theme }) => theme.colors.white.normal};
    cursor: pointer;

    transition: all 1s;

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.green.normal};
      background-color: ${({ theme }) => theme.colors.white.normal};
      color: ${({ theme }) => theme.colors.green.normal};
    }

    @media ${({ theme }) => theme.devices.tablet} {
      display: flex;
    }
  }
`;

export const NewItems = styled(FaPlus).attrs((props): any => ({
  icon: FaPlus
}))`
  background-color: ${({ theme }) => theme.colors.gray.light};
  font-size: 32px;
  padding: 20px;
  color: ${({ theme }) => theme.colors.gray.dark};
  border-radius: 50%;
  margin: auto auto;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.green.light};
    color: ${({ theme }) => theme.colors.green.dark};
  }
`;

export const DeleteItem = styled(FaTimes).attrs((props): any => ({
  icon: FaTimes
}))`
  background-color: ${({ theme }) => theme.colors.gray.light};
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gray.dark};
  border-radius: 50%;
  cursor: pointer;
  padding: 5px 7px;
  position: absolute;
  top: 5px;
  right: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.red.normal};
    color: ${({ theme }) => theme.colors.white.normal};
  }
`;

interface IInput {
  ref: MutableRefObject<HTMLElement | null>;
}

export const Input = styled.input<IInput>`
  display: none;
`;
export const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;

// TODO: Fix this because it using bg-color on carousel card, make the response to the component that calls
export const CarouselContainer = styled.div`
  background-color: none;
  margin-top: 6px;
  width: 100%;
`;
