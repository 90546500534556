import styled from "styled-components";
import colors from "color";
interface IButton {
  disable: boolean;
  color: "green";
}
export const CounterIcon = styled.div`
  font-size: 22px;
  margin: 0 10px;
  width: 16px;
  color: ${props => props.theme.colors.white.normal};
`;

export const Label = styled.span`
  font-size: 20px;
  vertical-align: super;
`;
export const Button = styled.button<IButton>`
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 100%;
  text-align: center;
  align-items: center;
  border: none;
  font-family: ${({ theme }) => theme.fonts.secundary};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white.normal};
  font-weight: 600;
  background-color: ${({ theme, disable, color }) =>
    disable ? theme.colors.gray.normal : theme.colors[color].dark};
  cursor: ${({ disable }) => (disable ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${({ theme, disable, color }) =>
      disable
        ? theme.colors.gray.normal
        : colors(theme.colors[color].darker)
            .lighten(0.2)
            .toString()};
  }

  &:active {
    background-color: ${({ theme, disable, color }) =>
      disable
        ? theme.colors.gray.normal
        : colors(theme.colors[color].normal)
            .darken(0.2)
            .toString()};
  }

  &:focus {
    outline: none;
  }
`;
