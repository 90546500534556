import React, { useState, useEffect } from "react";
import * as Styled from "./styles";
import { FaCookieBite } from "react-icons/fa";
import ButtonPrimary from "Components/Buttons/Primary";
import Routes from "Routes";
import { Link } from "react-router-dom";

const Cookies: React.FC = () => {
  const [cookieCheck, setCookieCheck] = useState<boolean>(false);

  const getCookieLocalStorage = () => {
    return localStorage.getItem("isCookiesChecked") === "true";
  };

  const setCookieLocalStorage = (check: boolean) => {
    setCookieCheck(check);
    localStorage.setItem("isCookiesChecked", String(check));
  };

  useEffect(() => {
    setCookieLocalStorage(getCookieLocalStorage());
  }, []);

  return (
    <>
      {!cookieCheck && (
        <Styled.Container>
          <Styled.Block>
            <Styled.Icon icon={FaCookieBite} />
            <Styled.Content>
              <Styled.Titulo>
                Saiba como tratamos seus dados pessoais.
              </Styled.Titulo>
              <Styled.MainInfo>
                Ao clicar em "Aceitar todos os cookies", você concorda com o
                armazenamento de cookies no seu dispositivo para melhorar a
                navegação no site, analisar o uso do site e ajudar em nossos
                esforços de marketing.
              </Styled.MainInfo>
              <Styled.MainInfo>
                Saiba mais nas{" "}
                <Link to={Routes.cookieConditions}>Política de Cookies</Link>
              </Styled.MainInfo>
            </Styled.Content>
            <Styled.Button>
              <ButtonPrimary
                type="button"
                action={() => {
                  setCookieLocalStorage(true);
                }}
              >
                Aceitar todos os cookies
              </ButtonPrimary>
            </Styled.Button>
          </Styled.Block>
        </Styled.Container>
      )}
    </>
  );
};

export default Cookies;
