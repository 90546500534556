import campaignCreate from "Api/campaigns/create";
import ButtonPrimary from "Components/Buttons/Primary";
import Form from "Components/Form";
import Select from "Components/Form/Inputs/Select";
import TextInput from "Components/Form/Inputs/Text";
import BackendErrorHelper from "Helpers/BackendErrorHelper";
import localeActions from "Redux/Locale/actions";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Styled from "./styles";

const AdminCampaignsCreate: React.FC = () => {
  const history = useHistory();

  const bannerTypes = [
    {
      value: "popup",
      name: "popup"
    },
    {
      value: "banner",
      name: "banner"
    }
  ];
  const dispatch = useDispatch();

  const onChangeForm = async (inputs: any) => {
    try {
      const response = await campaignCreate(inputs);
      response &&
        dispatch(
          localeActions.addTooltip({
            type: "success",
            message: "Campanha criada com sucesso!"
          })
        );
      history.push(`/admin/campaigns`);
    } catch (error) {
      if (BackendErrorHelper.checkIfDefaultError(error)) {
        const message = BackendErrorHelper.getDefaultErrorMessage(
          error.response
        );
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else if (BackendErrorHelper.checkIfValidationError(error)) {
        const message = BackendErrorHelper.getValidatorErrorMessage(
          error.response
        );
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else {
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message: "Error ao criar campanha"
          })
        );
      }
    }
  };
  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Title>Criar Campanha</Styled.Title>
        <Form submit={onChangeForm}>
          <TextInput
            label="Nome da Campanha*"
            name="campaign_name"
            placeholder="Digite o nome da campanha"
            required
          />
          <TextInput
            label="Código da Campanha*"
            name="campaign_code"
            placeholder="Digite o código da campanha"
            required
          />
          <TextInput
            label="Link de redirecionamento*"
            name="redirect_link"
            placeholder="Insira o link de redirecionamento campanha"
            required
          />
          <Select
            label="Tipo da Campanha*"
            name="campaign_type"
            options={bannerTypes}
            placeholder={bannerTypes[0].value}
            defaultValue={bannerTypes[0].value}
            required
          ></Select>
          <TextInput
            label="Descricao"
            name="campaign_description"
            placeholder="Digite a descrição da campanha"
          />
          <TextInput
            label="Texto da Campanha"
            name="campaign_text"
            placeholder="Digite o texto da campanha"
          />
          <TextInput
            label="Data de Início"
            name="start_at"
            placeholder="dd/mm/aaaa"
            mask="99/99/9999"
            validation="date"
            defaultValue={moment().format("DD/MM/YYYY")}
          />
          <TextInput
            label="Data de Expiração"
            name="expires_at"
            placeholder="dd/mm/aaaa"
            mask="99/99/9999"
            validation="date"
            defaultValue={moment().format("DD/MM/YYYY")}
          />

          <Styled.ButtonContainer>
            <ButtonPrimary type="submit" color="orange">
              Criar
            </ButtonPrimary>
          </Styled.ButtonContainer>
        </Form>
      </Styled.Content>
    </Styled.Container>
  );
};

export default AdminCampaignsCreate;
