import styled from "styled-components";

export const ChildrenWrapper = styled.div``;

export const TooltipBox = styled.div`
  display: inline-block;
  position: absolute;
  z-index: 99999999;
  top: calc(100% + 10px);
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  width: 100%;
  padding: 5px 5px;
  border-radius: 4px;
  transition: visibility 0.3s, color 0.3s, background-color 0.3s, width 0.3s,
    padding 0.5s ease-in-out;

  &:before {
    content: "";
    width: 0;
    height: 0;
    top: -10px;
    position: absolute;
    border: 10px solid transparent;
    transform: rotate(135deg);
    transition: border 0.3s ease-in-out;
  }
`;

export const TooltipCard = styled.div`
  position: relative;

  & ${ChildrenWrapper}:hover + ${TooltipBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    width: fit-content;
    padding: 8px 8px;

    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8)
        rgba(0, 0, 0, 0.8);
    }
  }
`;
