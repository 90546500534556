import { ISelectTheme } from "./theme";

export const gray: ISelectTheme = {
  marginBottom: 15,
  background: "gray",
  brightness: "normal",
  border: "0px",
  textColor: "black",
  textAlign: false,
  colorLabel: "gray",
  expandColor: "gray",
  scroll: false,
  justify: "flex-start",
  backgroundOption: "gray",
  brightnessOption: "normal",
  hover: "gray",
  borderOption: false
};

export const greenM: ISelectTheme = {
  marginBottom: 0,
  background: "green",
  brightness: "light",
  border: "5px 5px 0 0",
  textColor: "green",
  textAlign: true,
  colorLabel: "white",
  expandColor: "green",
  scroll: true,
  justify: "center",
  backgroundOption: "white",
  brightnessOption: "normal",
  hover: "gray",
  borderOption: true
};

export const green: ISelectTheme = {
  marginBottom: 0,
  background: "green",
  brightness: "light",
  border: "5px 0 0 5px",
  textColor: "green",
  textAlign: true,
  colorLabel: "white",
  expandColor: "green",
  scroll: true,
  justify: "center",
  backgroundOption: "white",
  brightnessOption: "normal",
  hover: "gray",
  borderOption: true
};

export const white: ISelectTheme = {
  marginBottom: 15,
  background: "white",
  brightness: "normal",
  border: "0px",
  textColor: "black",
  textAlign: false,
  colorLabel: "gray",
  expandColor: "gray",
  scroll: false,
  justify: "flex-start",
  backgroundOption: "white",
  brightnessOption: "normal",
  hover: "gray",
  borderOption: false
};
