import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Styled from "./styles";
import Routes from "Routes";

import PageTitle from "Components/Base/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { ReducerState } from "Redux/store";
import axios from "Helpers/Api";
import localeActions from "Redux/Locale/actions";
import Loading from "Components/Loading";
import CartItem from "./CartItem";
import ButtonPrimary from "Components/Buttons/Primary";
import stringPrice from "Helpers/stringPrice";
import Shipping from "Components/Shipping";
import { TProduct } from "Services/Ad/Product/product";
import { AiOutlineShopping, AiOutlineShoppingCart } from "react-icons/ai";
import EmptyCart from "./EmptyCart";

export type TProductItem = {
  id: string;
  qty: number;
  product: TProduct;
  variantItem: {
    id: string;
    value: string;
    sku: string;
    stock: number;
    product_variant_id: string;
  };
};

interface IResponse {
  cartId: string;
  quantity: number;
  items: TProductItem[];
}

interface Props {
  order?: boolean;
  setSubtotal?(subtotal: number): void;
  setProducts?(products: TProductItem[]): void;
}

const Cart: React.FC<Props> = ({ order = false, setSubtotal, setProducts }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [productItems, setProductItems] = useState<TProductItem[]>([]);

  const { cartId, cartItems } = useSelector(
    (state: ReducerState) => state.cart
  );

  const { user } = useSelector((state: ReducerState) => state.auth);

  const subTotal = useMemo(
    () =>
      productItems.length > 0
        ? productItems
            .map(item => item.qty * item.product.price)
            .reduce((sum, price) => sum + price)
        : 0,
    [productItems]
  );

  useEffect(() => {
    setSubtotal && setSubtotal(subTotal);
  }, [setSubtotal, subTotal]);

  useEffect(() => {
    setProducts && setProducts(productItems);
  }, [setProducts, productItems]);

  useEffect(() => {
    setProductItems(state =>
      state.map(item => ({ ...item, ...cartItems[item.id] }))
    );
  }, [cartItems]);

  const queryCart = useCallback(async () => {
    setLoading(true);
    try {
      if (cartId) {
        const { data } = await axios.get<IResponse>(`/cart/${cartId}`);

        setProductItems(data.items);
      }
    } catch (error) {
      dispatch(
        localeActions.addTooltip({
          type: "error",
          message: "Erro ao buscar o carrinho, tente mais tarde"
        })
      );
    }
    setLoading(false);
  }, [cartId, dispatch]);

  useEffect(() => {
    queryCart();
  }, [queryCart]);

  return (
    <Styled.Container>
      {!order && <PageTitle>Carrinho</PageTitle>}

      {loading ? (
        <Styled.Content>
          <Loading />
        </Styled.Content>
      ) : (
        <>
          {productItems.length > 0 ? (
            <>
              <Styled.Content>
                <Styled.CartItems>
                  {productItems &&
                    productItems.map((productItem, index) => (
                      <CartItem
                        key={`cart-item-${index}`}
                        productItem={productItem}
                        edit={!order}
                        removeProductItem={() =>
                          setProductItems(state =>
                            state.filter(item => item.id !== productItem.id)
                          )
                        }
                      />
                    ))}
                </Styled.CartItems>
                {!order && (
                  <Styled.Order>
                    <Styled.OrderValuesContainer>
                      <Styled.OrderSubTitle>
                        Resumo do pedido
                      </Styled.OrderSubTitle>
                      {
                        <>
                          <Styled.OrderValue>
                            Subtotal: {stringPrice(subTotal / 100)}
                          </Styled.OrderValue>

                          <Styled.OrderTotal>
                            {stringPrice(subTotal / 100)}
                          </Styled.OrderTotal>
                        </>
                      }
                      <Styled.ButtonsContainer>
                        <Styled.OrderButton
                          to={user ? Routes.checkout : Routes.login}
                        >
                          <ButtonPrimary type="button">
                            Fechar Pedido
                            <Styled.IconSpan>
                              <AiOutlineShopping />
                            </Styled.IconSpan>
                          </ButtonPrimary>
                        </Styled.OrderButton>
                        <Styled.OrderButton
                          to={Routes.productsWithCategory.replace(
                            ":category?/:subcategory?",
                            ""
                          )}
                        >
                          <ButtonPrimary color="orange" type="button">
                            Continuar comprando
                            <Styled.IconSpan>
                              <AiOutlineShoppingCart />
                            </Styled.IconSpan>
                          </ButtonPrimary>
                        </Styled.OrderButton>
                      </Styled.ButtonsContainer>
                    </Styled.OrderValuesContainer>
                  </Styled.Order>
                )}
              </Styled.Content>
            </>
          ) : (
            <EmptyCart />
          )}
        </>
      )}
    </Styled.Container>
  );
};

export default Cart;
