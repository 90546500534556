import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import {
  FaAudioDescription,
  FaCalendarAlt,
  FaEnvelopeOpenText,
  FaLink,
  FaSearch,
  FaTrash
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Form from "Components/Form";

import * as Styled from "./styles";

import campaignDisable from "Api/campaigns/disable";
import ButtonPrimary from "Components/Buttons/Primary";
import { TCampaign } from "Services/Campaigns/campaigns";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

import campaignAddImage from "Api/campaigns/addImage";
import campaignDisableImage from "Api/campaigns/disableImage";
import campaignEnable from "Api/campaigns/enable";
import Carousel from "Components/Ad/Carousel";
import BackendErrorHelper from "Helpers/BackendErrorHelper";
import localeActions from "Redux/Locale/actions";
import { BsMegaphoneFill } from "react-icons/bs";
import TextInput from "Components/Form/Inputs/Text";
import Select from "Components/Form/Inputs/Select";

interface CampaignItemProps {
  campaign: TCampaign;
  onCampaignChange: () => void; // Adicionando a propriedade onCampaignChange
}
export type TCampaignType = {
  name: string;
};
registerPlugin(FilePondPluginImageExifOrientation);

export default function CampaignItem({
  campaign,
  onCampaignChange,
  ...rest
}: CampaignItemProps) {
  const history = useHistory();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [localCampaign, setLocalCampaign] = useState(true);

  const [file, setFile] = useState<any>([]);

  const sitePositions = [
    {
      value: "desktop",
      name: "desktop"
    },
    {
      value: "mobile",
      name: "mobile"
    },
    {
      value: "tablet",
      name: "tablet"
    }
  ];

  useEffect(() => {}, [campaign.disable_at]);

  const {
    campaign_name,
    campaign_code,
    campaign_type,
    campaign_description,
    campaign_text,
    redirect_link,
    disable_at,
    start_at,
    expires_at,
    created_at,
    updated_at,
    images,
    id
  } = campaign;
  const handleDisableCampaign = async () => {
    try {
      setIsLoading(true);
      setLocalCampaign(true);

      const response = await campaignDisable(id);

      response &&
        dispatch(
          localeActions.addTooltip({
            type: "success",
            message: "Desativada com sucesso!"
          })
        );
      onCampaignChange();
    } catch (error) {
      if (BackendErrorHelper.checkIfDefaultError(error)) {
        const message = BackendErrorHelper.getDefaultErrorMessage(
          error.response
        );

        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else if (BackendErrorHelper.checkIfValidationError(error)) {
        const message = BackendErrorHelper.getValidatorErrorMessage(
          error.response
        );

        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else {
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message: "Erro ao tentar desativar campanha."
          })
        );
      }
    }
  };

  const handleEnableCampaign = async () => {
    const formattedExpireDate = moment(campaign.expires_at).format(
      "DD/MM/YYYY"
    );
    try {
      setIsLoading(true);
      setLocalCampaign(false);
      const response = await campaignEnable(id, formattedExpireDate);

      response &&
        dispatch(
          localeActions.addTooltip({
            type: "success",
            message: "Ativada com sucesso!"
          })
        );
      onCampaignChange();
    } catch (error) {
      if (BackendErrorHelper.checkIfDefaultError(error)) {
        const message = BackendErrorHelper.getDefaultErrorMessage(
          error.response
        );

        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else if (BackendErrorHelper.checkIfValidationError(error)) {
        const message = BackendErrorHelper.getValidatorErrorMessage(
          error.response
        );

        dispatch(
          localeActions.addTooltip({
            type: "error",
            message
          })
        );
      } else {
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message: "Erro ao tentar ativar campanha."
          })
        );
      }
    }
  };

  const handleSubmitImage = async (inputs: any) => {
    try {
      const formData = new FormData();

      for (const key in inputs) {
        if (inputs.hasOwnProperty(key)) {
          formData.append(key, inputs[key]);
        }
      }

      if (file && file.length > 0) {
        formData.append("image", file[0].file);
      }

      const response = await campaignAddImage(id, formData);
      response &&
        dispatch(
          localeActions.addTooltip({
            type: "success",
            message: "Imagem adicionada com sucesso!"
          })
        );
      onCampaignChange();
      setFile([]);
    } catch (error) {
      if (BackendErrorHelper.checkIfDefaultError(error)) {
        const message = BackendErrorHelper.getDefaultErrorMessage(
          error.response
        );

        dispatch(
          localeActions.addTooltip({
            type: "error",
            message: message || "Erro ao adicionar imagem"
          })
        );
      } else if (BackendErrorHelper.checkIfValidationError(error)) {
        const message = BackendErrorHelper.getValidatorErrorMessage(
          error.response
        );

        dispatch(
          localeActions.addTooltip({
            type: "error",
            message: message || "Erro ao adicionar imagem"
          })
        );
      } else {
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message: "Erro ao adicionar imagem"
          })
        );
      }
    }
  };
  const handleDisableImage = async (idImage: any) => {
    try {
      const response = await campaignDisableImage(idImage);
      response &&
        dispatch(
          localeActions.addTooltip({
            type: "success",
            message: "Imagem desativada com sucesso!"
          })
        );
      onCampaignChange();
    } catch (error) {
      if (BackendErrorHelper.checkIfDefaultError(error)) {
        const message = BackendErrorHelper.getDefaultErrorMessage(
          error.response
        );

        dispatch(
          localeActions.addTooltip({
            type: "error",
            message: message || "Erro ao desativar imagem"
          })
        );
      } else if (BackendErrorHelper.checkIfValidationError(error)) {
        const message = BackendErrorHelper.getValidatorErrorMessage(
          error.response
        );

        dispatch(
          localeActions.addTooltip({
            type: "error",
            message: message || "Erro ao desativar imagem"
          })
        );
      } else {
        dispatch(
          localeActions.addTooltip({
            type: "error",
            message: "Erro ao desativar imagem"
          })
        );
      }
    }
  };

  return (
    <Styled.CampaignItem key={`campaign`} {...rest}>
      <Styled.DataImage>
        <Styled.ImageContainer>
          <Carousel size={1} autoPlay={true}>
            {images.map((image, index) => (
              <>
                <img key={image.id} src={image.image_url} alt={image.id} />
                <Styled.DeleteButton
                  onClick={() => handleDisableImage(image.id)}
                >
                  <FaTrash />
                </Styled.DeleteButton>
              </>
            ))}
          </Carousel>
          <Form
            key={campaign ? campaign.id : "form"}
            submit={handleSubmitImage}
          >
            <Styled.FormRow>
              <FilePond
                files={file}
                allowMultiple={false}
                onupdatefiles={setFile}
                storeAsFile={true}
                labelIdle={`
        <div class="content-filepond">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2V3zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z"></path></svg>
            <p>Clique ou arraste e solte suas <br/> imagens aqui.</p>
        </div>`}
                credits={false}
              />
            </Styled.FormRow>
            <Select
              label="Posição do Site"
              name="campaign_type"
              options={sitePositions}
              placeholder={sitePositions[0].value}
              defaultValue={sitePositions[0].value}
              required
            />
            {/* <TextInput
              label="Dimensões da imagem"
              name="required_size"
              placeholder="Digite as dimensões"
              required
            /> */}
            <ButtonPrimary color="green" type="submit">
              Adicionar Imagem
            </ButtonPrimary>
          </Form>
        </Styled.ImageContainer>
      </Styled.DataImage>

      <Styled.CampaignInfo>
        <Styled.CampaignTitle>{campaign_name}</Styled.CampaignTitle>
        <Styled.Content>
          <Styled.Data>
            <Styled.Date>
              <Styled.Icon>
                <FaSearch />
              </Styled.Icon>
              {campaign_code}
            </Styled.Date>
            <Styled.Date>
              <Styled.Icon>
                <BsMegaphoneFill />
              </Styled.Icon>
              {campaign_type}
            </Styled.Date>
            <Styled.Date>
              <Styled.Icon>
                <FaAudioDescription />
              </Styled.Icon>
              {campaign_description}
            </Styled.Date>
            <Styled.Date>
              <Styled.Icon>
                <FaEnvelopeOpenText />
              </Styled.Icon>
              {campaign_text}
            </Styled.Date>
            <Styled.Date>
              <Styled.Icon>
                <FaLink />
              </Styled.Icon>
              {redirect_link}
            </Styled.Date>
            <Styled.Date>
              <Styled.Icon>
                <FaCalendarAlt />
              </Styled.Icon>
              {getDisbaledDate(disable_at)}
            </Styled.Date>
            <Styled.Date>
              <Styled.Icon>
                <FaCalendarAlt />
              </Styled.Icon>
              {getStartDate(start_at)}
            </Styled.Date>
            <Styled.Date>
              <Styled.Icon>
                <FaCalendarAlt />
              </Styled.Icon>
              {getExpirationDate(expires_at)}
            </Styled.Date>
            <Styled.Date>
              <Styled.Icon>
                <FaCalendarAlt />
              </Styled.Icon>
              {getCreatedDate(created_at)}
            </Styled.Date>
            <Styled.Date>
              <Styled.Icon>
                <FaCalendarAlt />
              </Styled.Icon>
              {getUpdatedDate(updated_at)}
            </Styled.Date>
          </Styled.Data>
          <Styled.Buttons>
            <ButtonPrimary
              color="green"
              action={() => history.push(`/admin/campaigns/edit/${id}`)}
            >
              <FaEdit /> Editar
            </ButtonPrimary>
            <ButtonPrimary
              color="orange"
              action={
                disable_at
                  ? () => handleEnableCampaign()
                  : () => handleDisableCampaign()
              }
            >
              <MdDelete /> {disable_at ? "Ativar" : "Desativar"}
            </ButtonPrimary>
          </Styled.Buttons>
        </Styled.Content>
      </Styled.CampaignInfo>
    </Styled.CampaignItem>
  );
}

export function getStartDate(date: string) {
  if (date) {
    const formattedDate = moment(date).format("DD/MM/YYYY");

    return `Data de início: ${formattedDate}`;
  }

  return "Sem data definida";
}

export function getExpirationDate(date: string) {
  if (date) {
    const formattedDate = moment(date).format("DD/MM/YYYY");

    return `Data de expiração: ${formattedDate}`;
  }

  return "Sem data definida";
}

export function getDisbaledDate(date: string) {
  if (date) {
    const formattedDate = moment(date).format("DD/MM/YYYY");

    return `Data de desativação: ${formattedDate}`;
  }

  return "Sem data definida";
}
export function getCreatedDate(date: string) {
  if (date) {
    const formattedDate = moment(date).format("DD/MM/YYYY");

    return `Data de criação: ${formattedDate}`;
  }

  return "Sem data definida";
}
export function getUpdatedDate(date: string) {
  if (date) {
    const formattedDate = moment(date).format("DD/MM/YYYY");

    return `Data de atualização: ${formattedDate}`;
  }

  return "Sem data definida";
}

export function urlIsImage(url?: string): boolean {
  if (!url) return false;

  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}
