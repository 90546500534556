import React, { useState } from "react";
import * as Styled from "./styles";
import scrollTo from "Helpers/backToTop";
import { FooterMenuItems } from "../Header/Menu";
import { NavLink, Route } from "react-router-dom";
import Social from "./Social";
import Partners from "./Partners";
import LogoWhite from "../Header/LogoWhite";
import { GrFacebookOption } from "react-icons/gr";
import { AiOutlineInstagram } from "react-icons/ai";
import Routes from "Routes";

const Footer: React.FC = () => {
  const [showSpan, setShowSpan] = useState<boolean>(false);

  return (
    <>
      {/* <Styled.BackTop
        onClick={() => {
          scrollTo(0);
        }}
      >
        {showSpan && (
          <Styled.BackTopSpanContainer>
            Voltar ao topo
          </Styled.BackTopSpanContainer>
        )}
        <Styled.ArrowContainer
          onMouseEnter={() => setShowSpan(true)}
          onMouseLeave={() => setShowSpan(false)}
        >
          <Styled.ArrowUp />
        </Styled.ArrowContainer>
      </Styled.BackTop> */}
      <Route exact path={Routes.home}>
        <Partners />
      </Route>
      <Styled.Footer>
        <Styled.FooterContainer>
          <Styled.CopyrightDiv>
            <Styled.LogoContainer>
              <LogoWhite />
            </Styled.LogoContainer>
            <Styled.SocialMedia>
              <p>Siga em</p>
              <Styled.SocialMediaIcons>
                <Styled.SocialMediaIcon
                  target="_blank"
                  href="https://www.facebook.com/ruraxoficial"
                  className="facebook-rurax"
                >
                  <GrFacebookOption />
                </Styled.SocialMediaIcon>
                <Styled.SocialMediaIcon
                  target="_blank"
                  href="https://www.instagram.com/ruraxoficial/"
                  className="instagram-rurax"
                >
                  <AiOutlineInstagram />
                </Styled.SocialMediaIcon>
                {/* <Styled.SocialMediaIcon>
                  <GrFacebookOption />
                </Styled.SocialMediaIcon> */}
              </Styled.SocialMediaIcons>
            </Styled.SocialMedia>
          </Styled.CopyrightDiv>
        </Styled.FooterContainer>
        <Styled.ColumnsContainer>
          <Styled.Container>
            {FooterMenuItems.map((menuItem, menuKey) => (
              <Styled.ContainerContent key={menuKey}>
                <Styled.ColumnTitle>{menuItem.name}</Styled.ColumnTitle>
                {menuItem.submenu &&
                  menuItem.submenu.map((subMenuItem, subMenuKey) => (
                    <Styled.SubMenu key={subMenuKey}>
                      <NavLink exact to={subMenuItem.link || "#"}>
                        {subMenuItem.category}
                      </NavLink>
                    </Styled.SubMenu>
                  ))}
              </Styled.ContainerContent>
            ))}
          </Styled.Container>
        </Styled.ColumnsContainer>
        <Styled.SpanContainer>
          Copyright 2022 &copy;&nbsp;
          <a
            href="http://www.citiestecnologia.com.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cities Tecnologia
          </a>
        </Styled.SpanContainer>
      </Styled.Footer>
    </>
  );
};

export default Footer;
