import React from "react";

import * as Styled from "./styles";
import stringPrice from "Helpers/stringPrice";
import { TJob } from "Services/Job/job";

interface Props {
  data: TJob;
  link: string;
  viewCount?: boolean;
}
const ListItem: React.FC<Props> = ({ data, link, viewCount = false }) => {
  return (
    <Styled.Container to={link}>
      {viewCount && (
        <Styled.ViewCount>
          <Styled.Views>{data.views} visualizações</Styled.Views>
          <Styled.Contacts>{data.contacts} contatos</Styled.Contacts>
        </Styled.ViewCount>
      )}
      <Styled.Content>
        <Styled.InfoContainer>
          <Styled.Info>
            <Styled.Title>{String(data.title).toUpperCase()}</Styled.Title>

            <Styled.Location>
              {data.city}
              {data.city && data.state && "/"}
              {data.state}
            </Styled.Location>
          </Styled.Info>
        </Styled.InfoContainer>
        <Styled.Salary>
          {data.salary
            ? `Salário: ${stringPrice(data.salary / 100)}`
            : "Consultar"}
        </Styled.Salary>
      </Styled.Content>
    </Styled.Container>
  );
};

export default ListItem;
