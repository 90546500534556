import styled from "styled-components";
import CheckIcon from "@material-ui/icons/Check";
import InputMask from "react-input-mask";
interface IField {
  status: boolean;
  disabled?: boolean;
}
export const Field = styled.div<IField>`
  border: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding-top: 15px;
  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
  }
`;
export const CheckBox = styled.input`
  display: none;
`;
export const CheckBoxContent = styled.div`
  display: flex;
`;
export const Check = styled.div<IField>`
  border-radius: 3px;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  display: flex;
  align-items: center;
  border: 2px solid
    ${({ theme, status }) =>
      status ? theme.colors.green.normal : theme.colors.gray.normal};
  margin-right: 5px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  transition: opacity ${({ theme }) => theme.animation.duration};
  transition: background-color ${({ theme }) => theme.animation.duration};
  will-change: opacity, background-color;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  background-color: ${({ theme, status }) =>
    status ? theme.colors.green.normal : "transparent"};
  color: ${({ theme }) => theme.colors.white.normal};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: ${({ disabled }) => (disabled ? 0.4 : 0.6)};
  }
  & > svg {
    opacity: ${({ status }) => (status ? 1 : 0)};
  }
`;
export const Icon = styled(CheckIcon).attrs(props => ({
  fontSize: "small"
}))`
  transition: opacity ${({ theme }) => theme.animation.duration};
  will-change: opacity;
  font-size: ${({ theme }) => theme.fontSize.sm};
  position: absolute;
`;
export const Content = styled.span<IField>`
  font-family: ${({ theme }) => theme.fonts.secundary};
  color: ${({ theme, status }) =>
    status ? theme.colors.green.normal : theme.colors.gray.dark};
  font-size: ${({ theme }) => theme.fontSize.mm};
  font-weight: 500;
`;
interface IError {
  valid: boolean;
}
export const Error = styled.span<IError>`
  color: ${({ theme, valid }) =>
    valid ? theme.colors.red.normal : theme.colors.gray.dark};
  font-family: ${({ theme }) => theme.fonts.secundary};
  font-size: ${({ theme }) => theme.fontSize.sm};
  padding-top: 3px;
  margin-left: 10px;
  height: 10px;
`;
export const InputMaskHtml = styled(InputMask)``;
export const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: ${({ theme }) => theme.fontSize.mm};
  letter-spacing: 2px;
  font-weight: 600;
  padding-bottom: 10px;
`;
export const DetailsLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: 500;
  text-align: left;
`;
