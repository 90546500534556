import { createContext } from "react";
import { IError } from ".";
import { ReactInputMask } from "react-input-mask";
import { IField } from "./index";

interface I {
  getField(name: string): IField | undefined | void;
  registerField?(
    name: string,
    ref: HTMLInputElement | ReactInputMask | HTMLTextAreaElement,
    status: boolean,
    checkForm: boolean
  ): void;
  setFieldError?(name: string, error: IError): void;
  deleteField?(name: string): void;
  submitted?: boolean;
}
export default createContext<I>({ getField: () => {} });
