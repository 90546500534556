import CheckboxSearch, {
  IOptions
} from "Components/Form/Inputs/CheckboxSearch";
import { createTrack } from "Helpers/tracking";
import localeActions from "Redux/Locale/actions";
import { ReducerState } from "Redux/store";
import { TError } from "Services/Error/error";
import { TSegmentsName } from "Services/Segment/segment";
import { IAdTracking } from "Services/Tracking/tracking";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

interface Props {
  categorySearch: string;
  setCategorySearch(text: string): void;
  categoriesError: TError | undefined;
  selectedCategories: IOptions[];
  categoryOptions: IOptions[];
  setSelectedCategories(selected: any): void;
  segment: {
    name: TSegmentsName;
    subvalue: string;
  };
}

export const Categories: React.FC<Props> = ({
  categorySearch,
  setCategorySearch,
  categoriesError,
  selectedCategories,
  categoryOptions,
  setSelectedCategories,
  segment
}) => {
  const { user, token } = useSelector((state: ReducerState) => state.auth);
  const { gaId } = useSelector((state: ReducerState) => state.locale);
  const location = useLocation();
  const dispatch = useDispatch();

  const [previous, setPrevious] = useState<IOptions[] | null>(null);

  useEffect(() => {
    if (selectedCategories.length > 0) {
      const newEvent = selectedCategories
        .filter(category => {
          if (
            previous &&
            previous.some(value => value.subvalue === category.subvalue)
          ) {
            return false;
          }

          return true;
        })
        .reduce((previous, current) => {
          const event: IAdTracking = {
            TYPE: "CATEGORY_CLICK",
            GA_ID: gaId,
            USER_ID: user ? user.id : "",
            DATA: {
              CATEGORY: `${segment.subvalue}/${current.subvalue}`
            },
            PAGE_REF: location.pathname,
            TAGS: "pos=menu"
          };

          return event;
        }, {} as IAdTracking);

      if (Object.keys(newEvent).length > 0) {
        dispatch(
          localeActions.addTracking(createTrack(newEvent, segment.name))
        );
      }

      setPrevious(selectedCategories);
    } else {
      setPrevious(null);
    }
  }, [selectedCategories]);

  return (
    <CheckboxSearch
      label=""
      search={categorySearch}
      setSearch={setCategorySearch}
      options={[...selectedCategories, ...categoryOptions.slice(0, 6)]}
      selected={selectedCategories}
      setSelected={setSelectedCategories}
      placeholder="Digite o nome da categoria"
      showQty={true}
      error={categoriesError}
    />
  );
};
