import { ActionType } from "typesafe-actions";

import types from "./types";
import * as actions from "./actions";
import { TTooltip } from "Services/Locale/tooltip";
import { IAdTracking } from "Services/Tracking/tracking";
import { TSegmentsName } from "Services/Segment/segment";

export type TLocaleAction = ActionType<typeof actions>;

export type AdRTime = {
  initialTime: Date | number;
  pageRef: string;
  productId: string;
  segment: TSegmentsName | null;
};
interface ILocaleModel {
  mobile: boolean;
  tooltips: TTooltip[];
  gaId?: string | null;
  modal: boolean;
  tracking: IAdTracking[];
  adRTime: AdRTime;
  isPopUpOpen: boolean;
}

export interface BaseAction {
  type: string;
  payload?: any;
}

export const initialState: ILocaleModel = {
  mobile: false,
  tooltips: [],
  modal: false,
  tracking: [],
  adRTime: {
    initialTime: 0,
    pageRef: "",
    productId: "",
    segment: null
  },
  isPopUpOpen: false
};

const localeReducer = (
  state: ILocaleModel = initialState,
  action: TLocaleAction
): ILocaleModel => {
  switch (action.type) {
    case types.UPDATE_MOBILE:
      return { ...state, mobile: action.payload };
    case types.UPDATE_TRACKING:
      return { ...state, tracking: action.payload };
    case types.ADD_TRACKING:
      return { ...state, tracking: [...state.tracking, action.payload] };
    case types.ADD_TOOLTIP:
      return { ...state, tooltips: [...state.tooltips, action.payload] };
    case types.DELETE_TOOLTIP:
      return {
        ...state,
        tooltips: state.tooltips.filter(
          (item, index: number) => index !== action.payload
        )
      };
    case types.ADD_GAID:
      return {
        ...state,
        gaId: action.payload
      };
    case types.MODAL:
      return {
        ...state,
        modal: !state.modal
      };
    case types.ADD_RTIME:
      return {
        ...state,
        adRTime: {
          initialTime: action.payload.initialTime,
          pageRef: action.payload.pageRef,
          productId: action.payload.productId,
          segment: action.payload.segment
        }
      };
    case types.OPEN_POUP:
      return {
        ...state,
        isPopUpOpen: true
      };
    case types.CLOSE_POUP:
      return {
        ...state,
        isPopUpOpen: false
      };
  }

  return state;
};

export default localeReducer;
