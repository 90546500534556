import types from "./types";
import { action } from "typesafe-actions";
import { TCartItem } from "Services/Cart/cart";

const cartActions = {
  addCart: (cartItem: TCartItem, cartId: string) =>
    action(types.ADD_CART, { cartItem, cartId }),
  setCartId: (cartId: string) => action(types.ADD_CART, { cartId }),
  removeCart: (id: string) => action(types.REMOVE_CART, { id }),
  clearCart: () => action(types.CLEAR_CART) // Ação para limpar o carrinho
};

export default cartActions;
