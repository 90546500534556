import api from "Helpers/Api";
import React, { useEffect } from "react";
import { createContext, ReactNode, useState } from "react";
import { useLocation } from "react-router-dom";

interface Props {
  children: ReactNode;
}

interface User {
  id: string;
  email: string;
  name: string;
  avatar: string | null;
}

export interface IUserSession {
  token: string;
  refreshToken: string;
  user: User;
}

interface CurrentBoletim {
  url: string;
  id: string;
  title: string;
}

type PlayerContextProps = {
  currentBoletim: CurrentBoletim | null;
  setCurrentBoletim: React.Dispatch<
    React.SetStateAction<CurrentBoletim | null>
  >;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AudioPlayerContext = createContext({} as PlayerContextProps);

export function AudioPlayerProvider({ children }: Props) {
  const location = useLocation();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentBoletim, setCurrentBoletim] = useState<CurrentBoletim | null>(
    null
  );

  useEffect(() => {
    getCurrentAudioByLink();
  }, []);

  const getCurrentAudioByLink = async () => {
    const query = new URLSearchParams(location.search);
    const current = query.get("current");

    if (current) {
      setIsOpen(true);
      const response = await api.get(`/boletins/get/${current}`);
      response && setCurrentBoletim({ ...response.data });
    }
  };

  return (
    <AudioPlayerContext.Provider
      value={{
        currentBoletim,
        isPlaying,
        setCurrentBoletim,
        setIsPlaying,
        isOpen,
        setIsOpen
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  );
}
