import { FaCartPlus } from "react-icons/fa";
import styled from "styled-components";

export const EmptyCart = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const EmptyCartText = styled.h2`
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: ${({ theme }) => theme.fontSize.md2};
  margin: 0;
  padding: 30px;
`;

export const EmptyCartIcon = styled(FaCartPlus).attrs(props => ({
  icon: FaCartPlus
}))`
  color: ${({ theme }) => theme.colors.green.normal};
  font-size: 200px;
  padding-right: 30px;
`;

export const EmptyCartBuyLinkMessage = styled.a`
  color: ${({ theme }) => theme.colors.green.normal};
  font-size: ${({ theme }) => theme.fontSize.md};
  margin: 0;
  padding-top: 20px;
`;

export const EmptyCardLink = styled.a`
  text-decoration: none;
`;
