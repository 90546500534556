import { IMenuItemModel } from "../index";
import Routes from "Routes";

const menu: IMenuItemModel = {
  name: "Veículos",
  link: Routes.vehicles.replace("/:subcategory?", ""),
  submenu: [
    {
      category: "Caminhões",
      link: Routes.vehicles.replace(":subcategory?", "caminhoes")
    },
    {
      category: "Carretas",
      link: Routes.vehicles.replace(":subcategory?", "carretas")
    },
    {
      category: "Carrocerias/Furgões",
      link: Routes.vehicles.replace(":subcategory?", "carrocerias-furgoes")
    },
    {
      category: "Carros e Camionetes",
      link: Routes.vehicles.replace(":subcategory?", "carros-e-camionetes")
    },
    {
      category: "Onibus",
      link: Routes.vehicles.replace(":subcategory?", "onibus")
    },
    {
      category: "Utilitários",
      link: Routes.vehicles.replace(":subcategory?", "utilitarios")
    }
  ]
};

export default menu;
