import React from "react";

import * as Styled from "./styles";

interface Props {
  src?: string;
}

const Thumbnail: React.FC<Props> = ({ src }) => {
  return (
    <>
      {!src && (
        <Styled.CallbackContainer>
          <p>200x95</p>
        </Styled.CallbackContainer>
      )}
      {src && <Styled.Thumbnail src={src} />}
    </>
  );
};

export default Thumbnail;
