import React, { useState } from "react";
import * as Styled from "./styles";
import FairItem from "./Fair";
import { TFair } from "Services/Fairs/fairs";
import Pagination2 from "Components/Pagination2";
import Search from "Components/Form/Inputs/Search";
import useFairs from "Hooks/Fairs/useFairs";
import Loading from "Components/Loading";
import ButtonPrimary from "Components/Buttons/Primary";
import { useHistory } from "react-router";
import { AdminRoutes } from "Routes";

const AdminFairs: React.FC = () => {
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState("");

  const history = useHistory();

  const { data, isLoading } = useFairs({
    page: page,
    perPage: 5,
    undefinedLocation: true,
    pastFairs: false,
    search
  });

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleSearch = () => {
    setSearch(searchValue);
  };

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Title>Feiras</Styled.Title>
        {isLoading ? (
          <Loading />
        ) : (
          <Styled.List>
            <ButtonPrimary
              color="green"
              action={() => history.push(AdminRoutes.fairsCreate)}
            >
              Adicionar feira
            </ButtonPrimary>
            <Styled.SearchBox>
              <Search
                setValue={setSearchValue}
                placeholder="O que está buscando?"
                defaultValue={searchValue}
                name="searchFairs"
                shadow={false}
                submit={handleSearch}
                transparent={false}
              />
            </Styled.SearchBox>
            {data && data.data.map((fair: TFair) => <FairItem fair={fair} />)}
            {data && data.data && (
              <Pagination2
                lastPage={data.lastPage}
                onPageChange={handleChangePage}
                page={page}
                perPage={5}
              />
            )}
          </Styled.List>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};

export default AdminFairs;
