import React from "react";
import * as Styled from "./styles";

const NotFound: React.FC = () => {
  return (
    <Styled.Container>
      <Styled.Icon />
      <Styled.Message>
        A página que você está procurando
        <br /> não foi encontrada
      </Styled.Message>
    </Styled.Container>
  );
};

export default NotFound;
