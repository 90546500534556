import Routes from "Routes";
import { IMenuItemModel } from "../index";
import { GoGraph } from "react-icons/go";
import React from "react";

const menu: IMenuItemModel = {
  name: "Cotações",
  icon: <GoGraph />,
  link: Routes.quotations
};

export default menu;
