import { useQuery } from "@tanstack/react-query";
import campaignsList from "Api/campaigns/list";

export default function useCampaigns() {
  const query = () => campaignsList();

  return useQuery({
    queryKey: ["campaignsList"],
    queryFn: query,
    keepPreviousData: true
  });
}
